<div class="card" [style.minHeight]="tileDefinition.minHeight">
  <div class="background-container" [style.background]="tileDefinition.background"></div>

  <div class="content-container">
    <div class="hero-section" fxLayout.lt-md="column" fxLayout="row" fxLayoutAlign="center center">
      <div class="icons-container" *ngIf="tileDefinition.icons?.length">
        <span
          class="material-icons border-circle lrg-icon light"
          *ngFor="let icon of tileDefinition.icons"
        >
          {{ icon.icon }}
        </span>
      </div>

      <div
        class="positioned-content"
        [ngClass]="{
          'img-right': tileDefinition.contentImgPosition === 'end',
          'img-left': tileDefinition.contentImgPosition === 'start',
          'img-top': tileDefinition.contentImgPosition === 'top',
          'img-bottom': tileDefinition.contentImgPosition === 'bottom',
        }"
      >
        <div
          class="content-left"
          fxFlex.lt-md="1 0 100%"
          [style.textAlign]="tileDefinition.alignContent"
        >
          <div style="height: 100%" fxFlex="column" fxLayoutAlign="center center">
            <div fxFlex="1 1 100%" class="slide-right padding-x5">
              <h1 class="title" [style.fontSize]="tileDefinition.headingFontSize | pxSuffix">
                <span [style.color]="tileDefinition.heading1FontColor">{{
                  tileDefinition.heading1
                }}</span>
                <span [style.color]="tileDefinition.heading2FontColor">{{
                  tileDefinition.heading2
                }}</span>
              </h1>
              <div [style.fontSize]="tileDefinition.textFontSize | pxSuffix">
                <p class="description" [style.color]="tileDefinition.textFontColor">
                  {{ tileDefinition.text }}
                </p>
              </div>

              <button
                *ngFor="let linkButton of tileDefinition.linkButtons"
                mat-raised-button
                [routerLink]="linkButton.routeLink"
                [style.color]="linkButton.buttonFontColor"
                [style.fontSize]="linkButton.buttonFontSize | pxSuffix"
                [style.backgroundColor]="linkButton.buttonBackgroundColor"
                class="free-trial-button slide-top"
              >
                {{ linkButton.buttonText }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="content-right"
          fxFlex.lt-md="0 1 100%"
          fxShow
          fxHide.xs
          fxLayoutAlign="center center"
          fxFlexOrder="2"
          fxFlexOrder.lt-md="-1"
        >
          <img class="splash-image slide-left" [src]="contentImgPath" />
        </div>
      </div>
    </div>
  </div>
  <div class="fab-container" *ngIf="tileDefinition.minHeight == '100vh'">
    <span mat-button (click)="scrollToElement(bannerTileEnd)">
      <i class="chevron-down-o"></i>
    </span>
  </div>
</div>

<div #bannerTileEnd></div>
