import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TileCreatorHelpersService {
  public generateFontSize(): string[] {
    const fontSizes: string[] = [];
    for (let i = 6; i < 64; i++) {
      fontSizes.push(`${i}px`);
    }
    return fontSizes;
  }
}
