import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ListNavigationComponent } from './components/list-navigation/list-navigation.component';
import { ListNavigationItemComponent } from './components/list-navigation-item/list-navigation-item.component';
import { LIST_NAVIGATION_CONFIG } from './const/list-navigation.token';

@NgModule({
  declarations: [ListNavigationComponent, ListNavigationItemComponent],
  imports: [CommonModule, SharedModule],
  exports: [ListNavigationComponent, ListNavigationItemComponent],
})
export class ListNavigationModule {
  static forRoot(config: any): ModuleWithProviders<ListNavigationModule> {
    return {
      ngModule: ListNavigationModule,
      providers: [
        {
          provide: LIST_NAVIGATION_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
