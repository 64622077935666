import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { DataSourceDefinitionsService } from "../../data-source/data-source-definitions/services/data-source-definitions.service";
import { IPreferenceResolvedData } from "../interfaces/preference-resolved-data.interface";

@Injectable({ providedIn: 'root' })
export class PreferenceResolver  {

  constructor(
    private dataSourceDefinitionService: DataSourceDefinitionsService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IPreferenceResolvedData> | IPreferenceResolvedData {

    const alias: string = route.paramMap.get('alias');

    if (alias) {

      return this.dataSourceDefinitionService.getLocalDefinition(alias).then(dataSourceDefinition => {
        console.log(alias, dataSourceDefinition)
        return {
          dataSourceDefinition
        }
      })

    };

    return null;

  }


}