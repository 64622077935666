<suvo-bi-navigation-outlet #navigationOutlet [noMenuButton]="true">

  <ng-container mainContent>
    <div fxLayout="column" class="standard-padding">
      <div fxLayout="row" fxLayoutAlign="flex-start center">
        <button mat-icon-button (click)="toggle()" class="menu-button">
          <mat-icon>menu</mat-icon>
        </button>
        <suvo-core-breadcrumbs fxFlex="1 1" fxLayout="column"></suvo-core-breadcrumbs>
      </div>
      <router-outlet></router-outlet>
    </div>
  </ng-container>

  <ng-container leftSidenav>
    <app-sidenav></app-sidenav>
  </ng-container>

  <ng-container rightSidenav>
    <suvo-bi-filters-sidenav></suvo-bi-filters-sidenav>
  </ng-container>

</suvo-bi-navigation-outlet>
