import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { IIconColumn } from '../../../dashboards/interfaces/tiles/definition-helpers/icon-column.interface';
import { IIconColumnsTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/icon-columns-tile-definition.interface';
import { HORIZONTAL_POSITION_OPTIONS } from '../../constants/horizontal-position-options.constant';
import { ISelectOption } from '../../interfaces/select-option.interface';
import { TileCreatorHelpersService } from '../../services/tile-creator-helpers.service';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'lib-icon-columns-setup-wizard',
  templateUrl: './icon-columns-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './icon-columns-setup-wizard.component.scss'],
})
export class IconColumnsSetupWizardComponent implements OnInit, OnDestroy {
  tileDefinition: IIconColumnsTileDefinition;

  public formGroup: FormGroup;
  iconColumns;

  public horizontalPositionOptions: ISelectOption[];
  public fontSizesOptions: string[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorHelpersService: TileCreatorHelpersService,
    private readonly tileCreatorService: TileCreatorService,
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSelectOptions();
    this.initSetupForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initSelectOptions(): void {
    this.horizontalPositionOptions = HORIZONTAL_POSITION_OPTIONS;
    this.fontSizesOptions = this.tileCreatorHelpersService.generateFontSize();
  }

  public initSetupForms(): void {
    this.formGroup = this.fb.group({
      iconColumns: this.fb.array([]),
      background: [''],
    });

    if (this.tileDefinition) {
      this.formGroup.patchValue(this.tileDefinition);

      this.tileDefinition.iconColumns?.forEach((iconColumn: IIconColumn) => {
        this.addIconColumn(iconColumn);
      });
    } else {
      this.addIconColumn();
    }

    this.iconColumns = this.formGroup.controls.iconColumns;
  }

  public addIconColumn(iconColumn?: IIconColumn): void {
    const iconColumnFormGroup = this.fb.group({
      heading: [''],
      headingFontSize: ['6px'],
      headingFontColor: ['#000000'],
      text: [''],
      textFontSize: ['6px'],
      textFontColor: ['#000000'],
      icon: [''],
      iconPosition: ['start'],
    });

    iconColumnFormGroup.patchValue(iconColumn);

    (this.formGroup.controls.iconColumns as FormArray).push(iconColumnFormGroup);
  }

  public removeIconColumn(iconColumnIndex: number): void {
    (this.formGroup.controls.iconColumns as FormArray).removeAt(iconColumnIndex);
  }

  public onCreate(): void {
    Object.assign(this.tileDefinition, {
      tileType: 'icon-columns',
      canView: 'true',
      ...this.formGroup.value,
    });
    this.tileCreatorService.onChange.next();
  }
}
