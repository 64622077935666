import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import { IDataSourceSettings } from '../../data-source/interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../data-source/interfaces/data-source-settings.token';
import { IImportDefinition } from '../interfaces/import-definition.interface';
import { IImportDefinitionCreate } from '../interfaces/import-definition.create.interface';

@Injectable({
  providedIn: 'root',
})
export class ImportDefinitionService extends ApiService<unknown> {
  constructor(
    httpClient: HttpClient,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings
  ) {
    super(httpClient, dataSourceSettings.dataServiceApi, 'import-definition');
  }

  create(definition: IImportDefinitionCreate) {
    return super.post(definition) as Promise<IImportDefinition>;
  }
}
