import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IBiLink } from '../../../../../shared/interfaces/bi-link.interface';
import { take } from 'rxjs/operators';
import { OrganisationRoleModel, OrganisationRoleService, UsersSnackbarService, CreateOrganisationComponent } from '@suvo-bi-users';

@Component({
  selector: 'suvo-bi-organisation-selector-view',
  templateUrl: './organisation-selector-view.component.html',
  styleUrls: ['./organisation-selector-view.component.scss']
})
export class OrganisationSelectorViewComponent implements OnInit {

  testLinks: IBiLink[] = [
    {
      displayIcon: 'work',
      displayName: 'Arthur & Arthur International Rail Concern'
    },
    {
      displayIcon: 'work',
      displayName: 'Arthur Morgan & Chase Investment Bank'
    },
  ];
  organisationLinks: IBiLink[];

  localOrganisationsList: OrganisationRoleModel[] = [];
  loading = true;

  constructor(
    private organisationRoleService: OrganisationRoleService,
    private usersSnackbarService: UsersSnackbarService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.organisationRoleService.get('').then((res: OrganisationRoleModel[]) => {
      // if (res?.length) {
      //   res.shift();
      //   this.localOrganisationsList = res;
      // } else {
      //   this.localOrganisationsList = res;
      // }

      this.organisationLinks = res.map(organisationModel => {
        return {
          displayName: organisationModel.organisationName,
          route: ['../', organisationModel._id],
        }
      })

      this.loading = false;

    });
  }

  createOrganisation(): void {
    const dialogRef = this.dialog.open(CreateOrganisationComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(async (data) => {
      if (data) {
        console.log('Received and refreshing', data);
        this.usersSnackbarService.open('Organisation Created');
        this.ngOnInit();
      }
    });
  }
}
