<div class="view-margin map-container" fxFlex="1 1" fxLayoutAlign="stretch stretch">
  <ng-container *ngIf="!position">
    <div class="no-map" fxLayout="row" fxLayoutAlign="center center">
      <p>Coordinate data unavailable</p>
    </div>
  </ng-container>
  <div class="map-frame" fxFlex="1 1" *ngIf="position">
    <div fxFlex="1 1" [id]="mapId"></div>
  </div>
</div>
