import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { SmartGridModule } from '../../smart-grid/smart-grid.module';
import { TablesModule } from '../../tables/tables.module';
import { DataSourceModule } from '../data-source.module';
import { PropertyComponent } from './components/properties/property/property.component';
import { MajorPropertiesViewTileComponent } from './components/tiles/major-properties-view-tile/major-properties-view-tile.component';
import { ParagraphViewTileComponent } from './components/tiles/paragraph-view-tile/paragraph-view-tile.component';
import { PropertiesViewTileComponent } from './components/tiles/properties-view-tile/properties-view-tile.component';
import { RailMapViewTileComponent } from './components/tiles/rail-map-view-tile/rail-map-view-tile.component';
import { ViewTileWrapperComponent } from './components/tiles/view-tile-wrapper/view-tile-wrapper.component';
import { DataSourceItemViewComponent } from './components/views/data-source-item-view/data-source-item-view.component';
import { DataSourceItemViewsService } from './services/data-source-item-views.service';
import { DataSourceFavouritesModule } from './../data-source-favourites/data-source-favourites.module'

@NgModule({
  declarations: [
    DataSourceItemViewComponent,
    PropertiesViewTileComponent,
    ViewTileWrapperComponent,
    MajorPropertiesViewTileComponent,
    ParagraphViewTileComponent,
    RailMapViewTileComponent,
    PropertyComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SmartGridModule,
    TablesModule,
    DataSourceFavouritesModule,
  ],
  providers: [
    DataSourceItemViewsService
  ],
  exports: [
    DataSourceItemViewComponent
  ]
})
export class DataSourceItemViewsModule { }
