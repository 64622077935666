import { Component, Input, OnInit } from '@angular/core';
import { CurrentTenantService } from '@suvo-bi-users';
import { IFreeTrialTileDefinition } from '../../../../interfaces/tiles/definitions/free-trial-tile-definition.interface';

@Component({
  selector: 'suvo-free-trial-tile',
  templateUrl: './free-trial-tile.component.html',
  styleUrls: ['./free-trial-tile.component.scss'],
})
export class FreeTrialTileComponent implements OnInit {
  freeTrial = 30;

  @Input() public tileDefinition: IFreeTrialTileDefinition;

  constructor(private currentTenantService: CurrentTenantService) {}

  ngOnInit(): void {
    this.freeTrial = this.currentTenantService.currentTenantData.autoTrialPeriod;
  }
}
