import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ITileDefinition } from '../../dashboards/interfaces/tiles/definition-helpers/tile-definition.interface';
import { TILE_TYPES } from '../constants/tile-types.constant';
import { ITileType } from '../interfaces/tile-type.interface';

export interface ITileRequest {
  tileDefinition: ITileDefinition;
  tileType: ITileType;
}

@Injectable({ providedIn: 'root' })
export class TileCreatorService {
  readonly onChange = new Subject<void>();
  readonly onCreateRequest = new Subject<ITileRequest>();
  readonly onUpdateRequest = new Subject<ITileRequest>();

  requestTileCreate(tileTypeKey: string, tileDefinition: any): void {
    this.onCreateRequest.next({
      tileDefinition,
      tileType: TILE_TYPES.find(({ key }) => tileTypeKey === key),
    });
  }

  requestTileUpdate(tileDefinition: ITileDefinition) {
    this.onUpdateRequest.next({
      tileDefinition,
      tileType: TILE_TYPES.find(({ key }) => tileDefinition.tileType === key),
    });
  }
}
