import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDataSourceSettings } from '../../interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../interfaces/data-source-settings.token';
import { ApiService } from '../../../../shared/services/api.service';
import { IDataSourceDefinition } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';

@Injectable({
  providedIn: 'root',
})
export class DataSourceDefinitionsService extends ApiService<any> {
  menuItemsSubject: Subject<boolean> = new Subject<boolean>();

  internalDefinitions: IDataSourceDefinition[];
  currentDefinition: BehaviorSubject<IDataSourceDefinition>;
  constructor(
    httpClient: HttpClient,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings
  ) {
    super(httpClient, dataSourceSettings.dataServiceApi, 'definitions');
  }

  getByShortcode(shortCode: string) {
    return super.get(`/alias/${shortCode}`);
  }

  async getOrSetDefinitions(): Promise<IDataSourceDefinition[]> {
    // (Aidan): I had to disable this caching(?) if to get multi tenant to work
    // remove it in a future refactor

    // if (!this.internalDefinitions) {
      const definitions = (await this.getPaginated(null)).data;
      this.internalDefinitions = definitions as IDataSourceDefinition[];
    // }
    return this.internalDefinitions;
  }

  async getLocalDefinition(shortCode: string): Promise<IDataSourceDefinition> {
    // const localDefs = localStorage.getItem('menu');
    // if (localDefs) {
    //   // hax
    //   return (JSON.parse(localDefs).data as any[]).find((def) => {
    //     return def.alias === shortCode;
    //   });
    // }
    return (await this.getOrSetDefinitions()).find(
      (definition) => definition.alias === shortCode
    );
  }

  async getById(definitionId: string): Promise<IDataSourceDefinition> {
    // const localDefs = localStorage.getItem('menu');
    // if (localDefs) {
    //   // hax
    //   return (JSON.parse(localDefs).data as any[]).find((def) => {
    //     return def._id === formDefinitionId;
    //   });
    // }
    return ((await this.getOrSetDefinitions()) as any[]).find(
      (definition) => definition._id === definitionId
    ) as IDataSourceDefinition;
  }

  async getAllDefinitions(): Promise<IDataSourceDefinition[]> {
    // const localDefs = localStorage.getItem('menu');
    // if (localDefs) {
    //   // moar hax
    //   return (JSON.parse(localDefs).data as any[]);
    // }
    // return await (this.getPaginated(null) as any).data;
    return this.getOrSetDefinitions();
  }
}
