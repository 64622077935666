import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDataSourceDefinition } from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { IDataSourceItem } from '../../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { IViewTileDefinition } from '../../../interfaces/view-tile-definition.interface';

@Component({
  selector: 'suvo-bi-view-tile-wrapper',
  templateUrl: './view-tile-wrapper.component.html',
  styleUrls: ['./view-tile-wrapper.component.scss'],
})
export class ViewTileWrapperComponent implements OnInit {
  @Input() viewTile: IViewTileDefinition;
  @Input() item: IDataSourceItem;
  @Input() form: FormGroup;
  @Input() definition: IDataSourceDefinition;
  @Input() editItemMode: boolean;

  constructor() {}

  ngOnInit(): void {}
}
