import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IStepCheckbox } from '../../interfaces/step-checkbox.interface';

@Component({
  selector: 'app-step-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements OnInit {
  @Input() protected parentFormGroup: FormGroup;
  @Input() public readonly formGroup: FormGroup;
  @Input() protected controlName: string;

  @Input() public checkboxes: IStepCheckbox[];

  /**
   * A StepCheckbox for the "None of the above" option,
   * or omit disable this checkbox.
   */
  @Input() public none: IStepCheckbox | undefined;

  public ngOnInit(): void {
    if (this.none) {
      this.formGroup.addControl(this.none.name, this.none.formControl || new FormControl(false));
    }
    for (const checkbox of this.checkboxes) {
      this.formGroup.addControl(checkbox.name, checkbox.formControl || new FormControl(false));
    }
  }

  public onClick($event: Event, controlName: string): void {
    $event.preventDefault();

    const control = this.formGroup.controls[controlName];

    control.setValue(!control.value);

    if (this.none && control.value) {
      if (controlName === this.none.name) {
        for (const [name, control] of Object.entries(this.formGroup.controls)) {
          control.setValue(name === this.none.name ? true : false);
        }
      } else {
        this.formGroup.controls[this.none.name].setValue(false);
      }
    }
  }
}
