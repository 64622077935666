<a mat-list-item *ngIf="activeSubscription?.status === 'trialing'" [routerLink]="subscriptionRoute" class="trial-box">
  <div *ngIf="trialDaysRemaining">
    <mat-icon>info_outline</mat-icon>
    Your trial ends in
    <br />
    <b>{{ trialDaysRemaining }} days</b>
  </div>
  <div *ngIf="trialDaysRemaining == 0">
    <mat-icon>info_outline</mat-icon>
    Your trial ends
    <br />
    today
  </div>
</a>
<mat-divider></mat-divider>
<a
  mat-list-item
  *ngIf="showProfileLink && !isTenantAdmin"
  ngClass="{'disabledLink':disableProfileLink}"
  [disabled]="disableProfileLink"
  routerLink="./my-account/profile"
>
  <div fxLayout="row" fxLayoutAlign="flex-start center">
    <mat-icon>person</mat-icon>
    <div>{{ user?.firstName }} {{ user?.lastName }}</div>
  </div>
</a>

<!-- <a
  mat-list-item
  *ngIf="tenantSelectorLink && isTenantAdmin"
  [routerLink]="tenantSelectorLink"
>
  <mat-icon>store</mat-icon>
  <span>My Shops</span>
</a> -->

<div class="tenant-admin-box" *ngIf="isTenantAdmin">Tenant administrator</div>
<suvo-bi-list-navigation-item *ngFor="let link of additionalLinks" [link]="link"></suvo-bi-list-navigation-item>
<ng-container *ngIf="showOrgSelector && user">
  <mat-divider></mat-divider>
  <div mat-list-item class="active-org-selector">
    <mat-form-field>
      <mat-label>Organisation</mat-label>
      <mat-select [formControl]="selectedOrganisation" (selectionChange)="selectOrganisation()" matNativeControl>
        <ng-container *ngFor="let organisation of localOrganisationsList">
          <mat-option [value]="organisation">{{ organisation.organisationName }}</mat-option>
        </ng-container>
        <mat-option [routerLink]="['./', 'my-organisations']">Manage</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>

<mat-divider></mat-divider>
<a mat-list-item class="logout" (click)="logout()">
  <!-- <div class="logout-content" fxLayout="row" fxLayoutAlign="stretch stretch">
  </div> -->
  <div class="logout-content" fxLayoutAlign="center center">
    <mat-icon>logout</mat-icon>
    Log Out
  </div>
</a>
