import { HttpClient } from '@angular/common/http';
import { IFilters } from '../../../shared/interfaces/filters/filters.interface';
import { ITableDataSource } from '../../../shared/interfaces/table/table-data-source.interface';
import { ITablePagination } from '../../../shared/interfaces/table/table-pagination.interface';
import { ITableSort } from '../../../shared/interfaces/table/table-sort.interface';
import { ApiService } from '../../../shared/services/api.service';
import { IFiltersSettings } from '../interfaces/data-source-settings.interface';
import { Inject, Injectable } from '@angular/core';
import { FiltersSettings } from '../models/data-source-settings';
import { FiltersSettingsRef } from '../constants/filters-settings-ref.token.const';
import { FiltersSections } from '../models/sections/filters-sections.model';
import { NewDataSourceDefinitionsService } from '../../data-source/data-source-definitions/services/new-data-source-definitions.service';
import { IDataSourceDefinitionProperty } from '../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { DataSourceDefinitionPropertyType } from '../../../shared/interfaces/data-source/data-source-definitions/data-source-definition-property-type.enum';
import { ALL_CONTINENTS, CONTINENT_COUNTRIES } from '../data/country-continents';
import { FilterOperatorType } from '../../../shared/interfaces/filters/filter-operator-type.enum';

@Injectable()
export class FiltersApiService extends ApiService<IFilters> {
  private filtersSettings;

  defaultFilterSections = {};

  constructor(
    http: HttpClient,
    @Inject(FiltersSettingsRef) filtersSettings: FiltersSettings,
    private dataSourceDefinitionsService: NewDataSourceDefinitionsService,
  ) {
    super(http, filtersSettings.dataServiceApi, '');

    this.filtersSettings = filtersSettings;
  }

  async getPaginatedFilters(
    alias: string,
    metaFilters?: any,
    sort?: ITableSort,
    pagination: ITablePagination = { pageIndex: 0, pageSize: 10 },
  ): Promise<ITableDataSource<IFilters>> {
    return await this.getPaginated(metaFilters, sort, pagination, this.getEndpointPath(alias));
  }

  async getFilters(alias: string, id: string): Promise<IFilters> {
    return this.get(`${this.getEndpointPath(alias)}/${id}`) as Promise<IFilters>;
  }

  async createFilters(alias: string, filters: IFilters): Promise<void> {
    if (filters._id) {
      return this.patchFilters(alias, filters);
    }

    return this.post(filters, `${this.getEndpointPath(alias)}`).then((serverFilters: IFilters) => {
      filters._id = serverFilters._id;
      return;
    });
  }

  async patchFilters(alias: string, filters: IFilters): Promise<void> {
    const { _id } = filters;
    if (!_id) {
      return this.createFilters(alias, filters);
    }

    return this.httpClient
      .patch(await this.getRequestUrl(`${this.getEndpointPath(alias)}/${_id}`), filters)
      .toPromise()
      .then(() => {
        return;
      });
  }

  async deleteFilters(alias: string, filters: IFilters): Promise<void> {
    const { _id } = filters;
    if (!_id) {
      return;
    }

    await this.httpClient
      .delete(await this.getRequestUrl(`${this.getEndpointPath(alias)}/${_id}`))
      .toPromise();
  }

  getEndpointPath(alias) {
    let endpoint = ``;
    if (!this.filtersSettings.reverseUrlParts) {
      endpoint = `${alias}/filters`;
    } else {
      endpoint = `filters/${alias}`;
    }

    return endpoint;
  }

  async getFiltersSections(alias: string) {
    let filterSections = this.filtersSettings?.filtersSections[alias];

    if (!filterSections) {
      if (this.defaultFilterSections[alias]) {
        filterSections = this.defaultFilterSections[alias];
      } else {
        filterSections = await this.createFilterSections(alias);

        this.defaultFilterSections[alias] = filterSections;
      }
    }

    return new FiltersSections(filterSections);
  }

  async createFilterSections(alias) {
    let definition = await this.dataSourceDefinitionsService.getOneLocalByAlias(alias);

    let filterableProperties = definition.properties.filter((p) => p.defaultUserFilter);

    let sections = [];

    for (let p of filterableProperties) {
      let filterDef = this.createFilterSection(p);

      sections.push(filterDef);
    }

    let filterSections = {
      name: alias,
      shortCode: alias,
      exportRoute: `${alias}/export`,
      sections: sections,
    };

    return filterSections;
  }

  createFilterSection(property: IDataSourceDefinitionProperty) {
    let section = {
      options: [],
      filterGroup: null,
    };

    switch (property.type) {
      case DataSourceDefinitionPropertyType.Number: {
        let option = {
          filterMethod: 'between-boxes',
          filterMethodConfig: {
            title: property.displayName,
          },
          filterPropertyKeys: [property.name],
        };

        section.options.push(option);

        break;
      }
      case DataSourceDefinitionPropertyType.String: {
        let option = {
          filterMethod: 'keywords',
          filterMethodConfig: {
            useForSearch: true,
            title: property.name,
          },
          filterPropertyKeys: [property.name],
        };

        section.options.push(option);

        break;
      }
      case DataSourceDefinitionPropertyType.Reference: {
        //TODO : Some of the below mapping is almost certainly wrong! Need to come back and sort this
        let option = {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionId: property.referenceOptions.formDefinitionId,
            entityDefinitionAlias: property.name + 's', //WARN : This may not be quite right
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: [property.name],
        };

        (section.filterGroup = FilterOperatorType.Or), section.options.push(option);

        break;
      }
      case DataSourceDefinitionPropertyType.Geolocation: {
        //TODO : Not sure what to put here?
        break;
      }
      case DataSourceDefinitionPropertyType.Country: {
        //TODO : Needs some work to work with combined country / continent etc
        let option = {
          filterMethod: 'combined-nested-checkboxes',
          filterMethodConfig: {
            title: 'Location',
            showChildrenLabel: 'Show countries',
            enableShowChildren: true,
            parentData: ALL_CONTINENTS,
            parentKeyName: 'continent',
            childData: CONTINENT_COUNTRIES,
            childKeyName: 'country',
            optionsColumns: 3,
          },
          filterPropertyKeys: [property.name],
        };

        (section.filterGroup = FilterOperatorType.Or), section.options.push(option);

        break;
      }
      case DataSourceDefinitionPropertyType.Boolean: {
        let option = {
          filterMethod: 'checkbox',
          filterMethodConfig: {
            title: property.name,
            defaultValue: false,
          },
          filterPropertyKeys: [property.name],
        };

        section.options.push(option);

        break;
      }
    }

    return section;
  }
}
