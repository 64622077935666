import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthService } from '@suvo-bi-core';
import { CurrentTenantService, ITenant, ITenantRecipe, TenantRecipeService } from '@suvo-bi-users';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-new-shop',
  templateUrl: './new-shop.component.html',
  styleUrl: './new-shop.component.scss',
})
export class NewShopComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly currentTenantService: CurrentTenantService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly tenantRecipeService: TenantRecipeService,
  ) {}

  readonly questionnaireFormGroup = new UntypedFormGroup({});

  readonly formGroup = new UntypedFormGroup({
    questionnaire: this.questionnaireFormGroup,
  });

  async onSubmit() {
    const recipe: ITenantRecipe = this.formGroup.value;

    try {
      recipe.email = JSON.parse(localStorage.getItem('onboarding')).email;
    } catch (e) {}

    const { _id: tenantRecipeID } = await this.tenantRecipeService.create(recipe);

    const currentUser = this.authService.currentUser();

    if (currentUser) {
      const tenant = await lastValueFrom(
        this.http.post<ITenant>(`${environment.dataServiceApi}tenant/existing`, {
          tenantRecipeID,
          email: currentUser.email,
        }),
      );

      // TODO(fix): This doesn't work. There's no role for the tenant in the user's auth token until they reauthenticate.
      //            We need to update custom claims on the backend and force a token refresh.
      //            https://cloud.google.com/identity-platform/docs/how-to-configure-custom-claims
      //            https://app.asana.com/0/1202396341409641/1208147553710789/f
      await this.currentTenantService.init(
        `${environment.usersServiceApi}tenant/${tenant.firebaseTenantId}`,
      );

      await this.router.navigate(['/', 'shops']);
    } else {
      // Prompt an unregistered user to register
      await this.router.navigate(['/', 'register'], { queryParams: { recipe: tenantRecipeID } });
    }
  }
}
