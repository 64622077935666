<mat-dialog-content>
  <div>
    <div class="title-margin" fxLayout="row" fxLayoutAlign="space-between center">
      <h4 class="no-margin-bottom">
        <mat-icon class="title-icon">travel_explore</mat-icon>
        {{ title }}
      </h4>
      <button mat-flat-button mat-dialog-close cdkFocusInitial (click)="handleDoneClick()">
        <mat-icon>done</mat-icon>
        Done
      </button>
      <!-- <mat-button-toggle-group name="view">
      <mat-button-toggle value="lists">Lists</mat-button-toggle>
      <mat-button-toggle value="map">Map</mat-button-toggle>
    </mat-button-toggle-group> -->
    </div>
    <mat-dialog-content>
      <suvo-bi-nested-checkboxes [toggleGroups]="primaryToggleGroups"></suvo-bi-nested-checkboxes>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <suvo-bi-nested-checkboxes [toggleGroups]="secondaryToggleGroups"></suvo-bi-nested-checkboxes>
  </div>
</mat-dialog-content>
