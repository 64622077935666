import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { IFilters } from '../../../../shared/interfaces/filters/filters.interface';
import { ITableDataSource } from '../../../../shared/interfaces/table/table-data-source.interface';
import { ITablePagination } from '../../../../shared/interfaces/table/table-pagination.interface';
import { ITableSort } from '../../../../shared/interfaces/table/table-sort.interface';
import { SessionService } from '../../../../shared/services/session.service';
import { IItemsTableOptions } from '../../../data-source/data-source-items/interfaces/items-table-options.interface';
import { FiltersChannel } from '../../../filters/models/filters-channel.model';
import { Filters } from '../../../filters/models/filters.model';

interface ISavedState {
  sort: any;
  pagination: any;
}

@Component({
  selector: 'suvo-bi-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<Item, Property> implements AfterViewInit, OnDestroy {
  protected unsubscribe$ = new Subject<boolean>();

  @Input() dataSourceAlias: string;
  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;
  @Input() searchObservable: Observable<string>;
  @Input() sortPrefix: string;
  @Input() defaultSort: any;

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatTable) matTable: MatTable<Item>;

  public dataSource$: Observable<Item[]>;

  public paginationSubject = new ReplaySubject<PageEvent>(null);
  public sortSubject = new ReplaySubject<Sort>(1);
  public filtersSubject = new ReplaySubject<Filters>(1);
  public searchSubject = new ReplaySubject<string>(1);
  public refreshSubject = new BehaviorSubject(true);

  public isLoading = true;
  public noData = false;

  public pageSize: number;
  public pageSizeOptions: number[] = [10, 15, 20, 30];

  public searchFormControl: FormControl;
  public propertyColumns: Property[];
  public propertyColumnNames: string[];

  public hasRequestedData = false;

  constructor(protected readonly sessionService: SessionService) {}

  public async ngOnInit(): Promise<void> {
    const savedState = (await this.sessionService.getSessionStoreItem(
      this.tableAlias,
    )) as ISavedState;

    await this.prepareTable(savedState);
  }

  async prepareTable(savedState: ISavedState): Promise<void> {
    await this.loadColumns();
    await this.loadControls(savedState);

    // //  Data source.
    // this.paginationSubject.subscribe(any => console.log('pagination', any));
    // this.sortSubject.subscribe(any => console.log('sort', any));
    // this.filtersSubject.subscribe(any => console.log('filters', any));

    this.dataSource$ = combineLatest([
      this.sortSubject.asObservable(),
      this.paginationSubject.asObservable(),
      this.filtersSubject.asObservable().pipe(
        tap(() => {
          this.resetPagination();
        }),
      ),
      this.searchSubject.asObservable().pipe(
        tap(() => {
          if (this.matPaginator && this.hasRequestedData) {
            this.matPaginator.pageIndex = 0;
          }
          this.resetPagination();
        }),
      ),
      this.refreshSubject.asObservable(),
    ]).pipe(
      tap(() => {
        this.isLoading = true;
      }),
      debounceTime(400),
      switchMap(async ([sort, pageEvent, filters, search]) => {
        const { pageSize, pageIndex } = pageEvent;
        const pagination = { pageSize, pageIndex };

        let rawSort;

        if (sort?.active) {
          let sortActiveSplit = sort.active.split('.');
          let rawSortActive = sortActiveSplit[sortActiveSplit.length - 1];
          rawSort = { active: rawSortActive, direction: sort.direction };
        }

        await this.sessionService.setSessionStoreItem(this.tableAlias, {
          sort: rawSort,
          pagination,
        });

        this.hasRequestedData = true;

        return await this.getPaginated(filters, sort as ITableSort, pagination, search);
      }),
      map((tableDataSource) => {
        this.isLoading = false;

        if (tableDataSource === null || tableDataSource === undefined) {
          return [];
        }

        this.noData = tableDataSource.count === 0;

        if (this.matPaginator) {
          this.matPaginator.length = tableDataSource.count;
        }

        return tableDataSource.data;
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      }),
      takeUntil(this.unsubscribe$),
    );
  }

  resetPagination() {
    if (this.hasRequestedData) {
      const pagination = {
        pageSize: this.pageSize,
        pageIndex: 0,
        length: null,
      };
      this.paginationSubject.next(pagination);
    }
  }

  protected async getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<Item>> {
    return { data: [], count: 0 };
  }

  protected async loadColumns(): Promise<void> {}

  protected transformSort(sort: Sort): Sort {
    //TODO : Improve this
    if (!sort.active || !sort.direction || sort.active.includes('updatedAt')) {
      if (this.defaultSort == 'SKIP') {
        return null;
      }

      sort.active = 'updatedAt';
      sort.direction = 'desc';
    } else {
      if (this.sortPrefix) {
        sort.active = `${this.sortPrefix}.${sort.active}`;
      } else {
        sort.active = `${sort.active}`;
      }
    }
    return sort;
  }

  protected getRowRouterLink(item: Item): string[] | string {
    return null;
  }

  protected subscribeToFilters(): void {
    if (this.filtersChannel) {
      this.filtersChannel?.filters
        .pipe(
          filter((f) => !!f),
          map((f) => {
            if (this.matPaginator && this.hasRequestedData) this.matPaginator.pageIndex = 0;
            return f;
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(this.filtersSubject);
    } else {
      this.filtersSubject.next({
        name: 'Test',
        query: null,
      });
    }
  }

  protected subscribeToSearch(): void {
    if (this.searchObservable) {
      this.searchObservable.subscribe(this.searchSubject);
    } else {
      this.searchSubject.next(null);
    }
  }

  private async loadControls(savedState: ISavedState): Promise<void> {
    this.pageSize = savedState?.pagination?.pageSize
      ? savedState?.pagination?.pageSize
      : this.pageSize || this.pageSizeOptions[1];
    const defaultPagination = {
      pageSize: this.pageSize,
      pageIndex: savedState?.pagination?.pageIndex ? savedState?.pagination?.pageIndex : 0,
      length: null,
    };
    this.paginationSubject.next(defaultPagination);
  }

  public onSearchKeyUp(event: Event): void {}

  public listDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.propertyColumnNames, event.previousIndex, event.currentIndex);
  }

  protected async connectSubjects(savedState: ISavedState): Promise<void> {
    if (this.matPaginator) {
      if (savedState?.pagination) {
        this.matPaginator.pageIndex = savedState.pagination.pageIndex;
        this.matPaginator.pageSize = savedState.pagination.pageSize;
      }

      this.matPaginator?.page
        .pipe(
          map((pageEvent) => {
            if (pageEvent.pageSize !== this.pageSize) {
              pageEvent.pageIndex = 0;
              this.matPaginator.pageIndex = 0;
              this.pageSize = pageEvent.pageSize;
            }
            return pageEvent;
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(this.paginationSubject);
    } else {
      this.paginationSubject.next(null);
    }

    if (this.matSort) {
      if (savedState?.sort) {
        const sortable = {
          id: savedState.sort.active,
          start: savedState.sort.direction,
        } as MatSortable;
        this.matSort.sort(sortable);
      } else if (this.defaultSort) {
        const sortable = {
          id: this.defaultSort.active,
          start: this.defaultSort.direction,
        } as MatSortable;
        this.matSort.sort(sortable);
      }

      this.matSort?.sortChange
        .pipe(
          map((sort) => {
            if (this.matPaginator) this.matPaginator.pageIndex = 0;
            if (sort && sort.active) {
              sort = this.transformSort(sort);
            }
            return sort;
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(this.sortSubject);
      this.sortSubject.next(
        this.transformSort({
          active: this.matSort.active,
          direction: this.matSort.direction,
        }),
      );
    } else {
      this.sortSubject.next(null);
    }

    this.subscribeToFilters();
    this.subscribeToSearch();
  }

  public async ngAfterViewInit(): Promise<void> {
    let savedState;

    try {
      savedState = JSON.parse(await sessionStorage.getItem(this.tableAlias)) as ISavedState;
    } catch (e) {}

    await this.connectSubjects(savedState);
  }

  refresh() {
    this.refreshSubject.next(true);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
