<!-- <div class="card primary-background" fxLayoutAlign="center center" fxLayout="column">

  <div class="padded" fxFlex="1 1 100%" fxFlexOrder="-1" fxLayoutAlign="center center">
    <img class="banner-image" [src]="tileDefinition.imgUri">
  </div>

  <h2 class="padded">{{ tileDefinition.heading }}</h2>

  <a [routerLink]="['..', tileDefinition.routeLink]" class="padded link"> {{ tileDefinition.linkText }} </a>


</div> -->



<div class="card" fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" fxLayoutAlign="flex-start center">

    <h4>
      <mat-icon *ngIf="tileDefinition.headingIcon" class="border-circle">{{ tileDefinition.headingIcon }}</mat-icon>

    </h4>

    <div class="display-heading" [class.ticking]="ticking" fxLayout="column" fxLayoutAlign="flex-start flex-start">
      <p class="display-number" *ngIf="displayNumber">{{ displayNumber | number: '1.0-0' }}</p>
      <p class="display-number" *ngIf="!displayNumber">1000 + </p>
      <p class="display-feature" fxFlex="0 0">{{ tileDefinition.heading }}</p>
    </div>

  </div>

  <div fxLayout="column" fxLayoutAlign="flex-start stretch">
    <!-- <div fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
      <p fxFlex="1 0">{{ tileDefinition.subHeading }}</p>
    </div> -->
    <button class="button" mat-stroked-button color="primary" [routerLink]="tileDefinition.routeLink">{{ tileDefinition.linkText
      }}</button>
  </div>

</div>