import { Component, Input } from '@angular/core';
import { IFAQLibrary } from '../interfaces/faqs.interface';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent {
  @Input() public faqLibrary: IFAQLibrary;
}
