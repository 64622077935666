import { Component, Input } from '@angular/core';
import { IPresentationTileDefinition } from '../../../../interfaces/tiles/definitions/presentation-tile-definition.interface';

@Component({
  selector: 'suvo-bi-presentation-tile',
  templateUrl: './presentation-tile.component.html',
  styleUrls: ['./presentation-tile.component.scss'],
})
export class PresentationTileComponent {
  @Input() public tileDefinition: IPresentationTileDefinition;
}
