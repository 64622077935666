import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BetweenBoxesFilterOptionComponent } from '../between-boxes-filter-option/between-boxes-filter-option.component';

@Component({
  selector: 'suvo-bi-years-drop-down-option',
  templateUrl: './years-drop-down-option.component.html',
  styleUrls: ['./years-drop-down-option.component.scss']
})
export class YearsDropDownOptionComponent extends BetweenBoxesFilterOptionComponent {

  formGroup: FormGroup;
  yearOptions: number[];

  constructor() {
    super();
  }

  ngOnInit(): void {

    let yearOptions: number[] = [];
    const { min, max } = this.option.filterMethodConfig;

    for (let i = min; i <= max; i++) {
      yearOptions.push(i);
    }
    this.yearOptions = yearOptions;

    super.ngOnInit();

  }
}
