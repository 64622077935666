import { CommonModel } from '@suvo-bi-core';

export class PublicUserModel extends CommonModel<PublicUserModel> {
  _id!: string;
  firstName!: string;
  lastName!: string;
  company: string;
  email: string;
  firebaseUserId: string;
  organisationId: string;
  role: string;
  stripeCustomerURL: string;
  tenantId: string;
  constructor() {
    super(PublicUserModel);
  }
}
