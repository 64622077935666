import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-organisation-outgoing-invites-view',
  templateUrl: './organisation-outgoing-invites-view.component.html',
  styleUrls: ['./organisation-outgoing-invites-view.component.scss']
})
export class OrganisationOutgoingInvitesViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
