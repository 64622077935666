export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  firebaseUserId: string;
  gender: string;
  ethnicity: string;
  dateOfBirth?: string;
  company?: string;
  role?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  tenantId: string;
}
