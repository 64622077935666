import { OverlayModule } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EventType, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ShopSetupChecklistComponent } from '../../modules/shop-setup-checklist/shop-setup-checklist.component';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, OverlayModule, ShopSetupChecklistComponent],
  selector: 'app-checklist-widget',
  template: `
    <button mat-stroked-button color="primary" (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <mat-icon>checklist</mat-icon>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (overlayOutsideClick)="isOpen = !isOpen"
    >
      <app-shop-setup-checklist />
    </ng-template>
  `,
})
export class ChecklistWidgetComponent {
  isOpen = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(router: Router) {
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event.type === EventType.NavigationStart) {
        this.isOpen = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
