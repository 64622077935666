<mat-form-field
  class="form-field"
  appearance="outline"
  [class.mat-form-field-invalid]="control?.invalid && control?.touched"
>
  <mat-label>{{ label ? label : 'Data Source' }}</mat-label>

  <mat-select
    (valueChange)="writeValue($event)"
    [value]="value"
    [disabled]="isDisabled"
    (blur)="onBlur()"
    [class.mat-select-invalid]="control?.invalid && control?.touched"
  >
    <mat-option
      *ngFor="let definition of definitions"
      [value]="definition.alias"
      (click)="changeDataSource(definition)"
    >
      {{ definition.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="errors">
  <mat-error *ngIf="control?.hasError('required') && control?.touched">
    Data Source is required
  </mat-error>
</div>
