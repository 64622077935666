import { HttpClient } from '@angular/common/http';
import { Component, computed, effect, OnDestroy, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { SharedModule } from '@informally/admin/shared/shared.module';
import { RegisterComponent, VerifyService } from '@suvo-bi-users';
import { lastValueFrom } from 'rxjs';

@Component({
  standalone: true,
  imports: [MatCardModule, SharedModule],
  template: `
    <mat-card style="margin: 48px auto; max-width: 600px">
      <mat-card-header>
        <mat-card-title>Welcome to informally</mat-card-title>
        <mat-card-subtitle
          >One more step. Create your informally account and get started for
          free.</mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content style="margin-top: 48px" [class.working]="working">
        <lib-page-register
          #libRegister
          [validateOnly]="true"
          [showFields]="showFields"
          (registerStatusChange)="register($event)"
        />
        @if (message) {
          <p [class.error]="message.error">{{ message.value }}</p>
        }
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    '.center { text-align: center; max-width: 600px; margin: 128px auto }',
    '.error { color: indianred; }',
    '.working { opacity: 50%; pointer-events: none; }',
  ],
})
export class RegisterPageComponent implements OnDestroy {
  readonly showFields = [
    { name: 'firstName', required: true },
    { name: 'lastName', required: true },
  ];

  @ViewChild('libRegister')
  private readonly registerComponent: RegisterComponent;

  private readonly queryParams = toSignal(this.activatedRoute.queryParamMap);

  private readonly tenantRecipe = computed(() => this.queryParams().get('recipe'));

  private readonly effect = effect(() => {
    if (!this.tenantRecipe()) {
      void this.router.navigate(['/new-shop']);
    }
  });

  working = false;

  message: {
    value: string;
    error: boolean;
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly fireAuth: AngularFireAuth,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly verifyService: VerifyService,
  ) {
    try {
      const { email }: { email?: string } = JSON.parse(localStorage.getItem('onboarding'));
      if (email) this.registerComponent.registerForm.controls.email.setValue(email);
    } catch (e) {}
  }

  ngOnDestroy() {
    this.effect.destroy();
  }

  async register(registerStatusEvent: string) {
    // Library component is set to validate only
    // When it emits the event, we do the registration
    if (registerStatusEvent === 'REGISTER_ATTEMPT_COMPLETE') {
      try {
        this.working = true;
        const body = {
          ...this.registerComponent.registerForm.value,
          tenantRecipeID: this.tenantRecipe(),
        };
        await lastValueFrom(this.http.post(`${environment.dataServiceApi}tenant/new`, body));
        const { email, password } = this.registerComponent.registerForm.value;
        await this.fireAuth.signInWithEmailAndPassword(email, password);
        await this.verifyService.sendVerificationEmail();
        this.message = {
          value:
            'A verification link has been sent to your email address. Please click the link to activate your account.',
          error: false,
        };
      } catch (e) {
        this.message = {
          value: e?.error?.message,
          error: true,
        };
      } finally {
        this.working = false;
      }
    }
  }
}
