export class SitemapRouteNode {
  children!: SitemapRouteNode[];
  name!: string;
  routeType!: string;
  dashboardId!: string;
  filterGroup!: string;
  navigationLinkIcon!: string;
  isLocked!: boolean;
  isValid!: boolean;
  index!: number;
  uuid?: string;
  public: boolean;
  useGroupFilters: boolean;
}
