import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { ITableSortDirection } from '../../../../../shared/interfaces/table/table-sort.interface';
import { ReferenceLookupService } from '../../../../references/services/reference-lookup-service.service';
import { FilterOptionComponent } from '../filter-option/filter-option.component';
import { IEntity } from './entity.interface';

@Component({
  selector: 'suvo-bi-reference-autocomplete-option',
  templateUrl: './reference-autocomplete-option.component.html',
  styleUrls: ['./reference-autocomplete-option.component.scss'],
})
export class ReferenceAutocompleteOptionComponent extends FilterOptionComponent {
  separatorKeyCodes: number[] = [ENTER, COMMA];

  @ViewChild('entityInput') entityInput: ElementRef<HTMLInputElement>;
  @ViewChild('entityAutocomplete') entityAutocomplet: MatAutocomplete;
  entityInputControl: FormControl;

  chippableOptions: { key: string; primary: string; accent: string }[];

  entityOptions: IEntity[] = [];
  entityOptionsUnloaded = true;
  entityOptionsReceived = new Subject<IEntity[]>();

  selectedEntities: IEntity[] = [];
  unselectedEntities: IEntity[] = [];

  filteredEntitiesSubject = new Subject<IEntity[]>();

  entityDefinition;
  subjectDefinition;

  loadingEntities = false;

  fetchSequenceNumber = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLookupService: ReferenceLookupService,
  ) {
    super();
  }

  async ngOnInit() {
    this.entityInputControl = new FormControl();

    this.entityInputControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(100))
      .subscribe((text) => {
        return this.filterEntityOptions(text)
  });

    this.entityDefinition = await this.referenceLookupService.getDefinition(
      this.option.filterMethodConfig.entityDefinitionAlias,
    );

    this.subjectDefinition = await this.referenceLookupService.getDefinition(
      this.option.definitionAlias,
    );

    this.generateChippableOptions();

    super.ngOnInit();

    this.changeDetectorRef.detectChanges();
  }

  generateChippableOptions() {
    const propertyName = this.option.filterPropertyKeys[0];
    if (!propertyName) {
      return;
    }
    const propertyDefinition = this.subjectDefinition?.properties?.find(
      (p) => p.name === propertyName,
    );
    if (!propertyDefinition) {
      return;
    }

    const chippableOptions = propertyDefinition.displayOptions?.enumColourSet;
    this.chippableOptions = chippableOptions;

    this.changeDetectorRef.markForCheck();
  }

  filterEntityOptions(text: string | null): void {
    this.fetchOptions(text);
  }

  async fetchEntityOptions(search: string): Promise<void> {
    //Used to ensure that we don't try to use the response to an earlier fetch.
    //IDeally this would be done with rxjs, but this is a manual alternative as we're using promises for the fetch
    this.fetchSequenceNumber++;
    let currentFetchSequenceNumber = this.fetchSequenceNumber;

    let prefix = this.option.filterMethodConfig?.metaDataPrefix
      ? this.option.filterMethodConfig?.metaDataPrefix + '.'
      : null;

    let options: any = {
      pagination: {
        pageIndex: 0,
        pageSize: this.option.filterMethodConfig?.pageSize ? this.option.filterMethodConfig?.pageSize  : 40,
      },
      search,
      simpleFilters: this.option.filterMethodConfig?.simpleFilters
    };

    const defaultSort = this.entityDefinition?.tableOptions?.defaultSortOption;
    if (defaultSort) {
      options.sort = {
        active: prefix + defaultSort.property,
        direction:
          defaultSort.direction == 'ASCENDING'
            ? ITableSortDirection.Ascending
            : ITableSortDirection.Descending,
      };
    }

    this.loadingEntities = true;

    this.changeDetectorRef.markForCheck();

    let entities = await this.referenceLookupService.getPaginated(
      this.option.filterMethodConfig.entityDefinitionAlias,
      options,
      this.entityDefinition?.alias,
    );

    if(currentFetchSequenceNumber != this.fetchSequenceNumber){
      //Abort if there has already been a new fetch requested
      return;
    }

    this.loadingEntities = false;

    this.entityOptions = [];

    entities?.data.forEach((entity: any) => {
      this.entityOptions.push(entity as IEntity);
    });

    this.unselectedEntities = [...this.entityOptions];
    this.entityOptionsReceived.next(this.entityOptions);
    this.filteredEntitiesSubject.next(this.entityOptions);
  }

  /**
   *  Option Property
   */

  emitPropertyChanges() {
    this.option.filterRules = this.getFilterRules();
    this.rulesChanged.emit();
  }

  getFilterRules(): FilterRule[] {
    if (this.selectedEntities.length) {
      let key = this.option.filterPropertyKeys[0];

      const inValues = this.selectedEntities.map((entity) => entity._id);

      return [
        {
          key,
          in: inValues.join(','),
          type: this.option.filterValueType,
        },
      ];
    } else {
      return [];
    }
  }

  checkRulesMatch(rules): boolean {
    let entitiesChanged = false;

    if (rules?.length) {
      const property = rules[0] as IFilterProperty;
      property.in?.split(',').forEach((value) => {
        const existingEntity = this.selectedEntities.find((entity) => entity._id === value);

        if (!existingEntity) {
          entitiesChanged = true;
        }
      });

      this.selectedEntities.forEach((entity) => {
        const existingEntity = property.in?.split(',').find((value) => entity._id === value);

        if (!existingEntity) {
          entitiesChanged = true;
        }
      });
    } else if (this.selectedEntities.length) {
      //NOTE: Must have changed since the rules length == 0, yet we have selected entities
      entitiesChanged = true;
    }

    return !entitiesChanged;
  }

  async patchRules(rules: FilterRule[]): Promise<void> {
    let rulesMatch = this.checkRulesMatch(rules);

    if (rulesMatch) {
      return;
    }

    // const propertyName = this.option.filterPropertyKeys[0];
    // console.log({ propertyName, rules })
    //this.selectedEntities.forEach((entity) => this.removeEntity(entity, true));
    //this.selectedEntities.forEach((entity) => this.removeEntity(entity, true));
    this.removeEntities(this.selectedEntities, true);

    if (rules?.length) {
      if (!this.entityOptions.length) {
        await this.fetchOptions(null);
      }

      const property = rules[0] as IFilterProperty;

      let propertyInList = property.in?.split(',');

      let entityPromises = [];

      for (let value of propertyInList) {
        let entity = this.entityOptions.find((entity) => {
          return entity._id === value;
        });

        if (entity) {
          this.addEntity(entity, true);
        } else {
          const entityPromise = this.referenceLookupService.getOne(
            this.option.filterMethodConfig.entityDefinitionAlias,
            value,
            this.option.filterMethodConfig.entityDefinitionAlias,
          );

          entityPromises.push(entityPromise);
        }
      }

      if (entityPromises) {
        let entities = await Promise.all(entityPromises);

        for (let entity of entities) {
          this.addEntity(entity, true);
        }
      }
    }

    this.emitPropertyChanges();
  }

  /**
   * Input focussed
   */

  async fetchOptions(search: string): Promise<void> {
    this.entityOptionsUnloaded = false;
    await this.fetchEntityOptions(search);
  }

  async onInputFocus(): Promise<void> {
    this.fetchOptions(this.entityInputControl.value);
  }

  /**
   * Entity Adding and Removing
   */

  assignEntityChipColor(entity: IEntity): void {
    if (entity.chipColor) {
      return;
    }

    const chipColors = this.chippableOptions?.find((colors) => colors.key === entity.data.name);

    entity.chipColor = chipColors?.primary || '#1a78cf';
    entity.chipAccent = chipColors?.accent || '#fff';
  }

  addEntity(entity: IEntity, dontEmitChanges?: boolean): void {
    const index = this.unselectedEntities.indexOf(entity);

    if (index >= 0) {
      this.unselectedEntities.splice(index, 1);
    }

    this.assignEntityChipColor(entity);
    this.selectedEntities.push(entity);

    this.filteredEntitiesSubject.next(this.unselectedEntities);

    if (!dontEmitChanges) {
      this.emitPropertyChanges();
    }
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    this.entityInput.nativeElement.value = '';
    this.entityInputControl.setValue(null);

    if(this.selectedEntities.indexOf(event.option.value) == -1){
      const entity: IEntity = event.option.value;
      this.addEntity(entity);

      this.entityInput.nativeElement.focus();
    }
  }

  removeEntity(entity: IEntity, dontEmitChanges: boolean = false): void {
    const index = this.selectedEntities.indexOf(entity);
    if ((index) => 0) {
      this.unselectedEntities.push(entity);
      this.selectedEntities.splice(index, 1);

      this.filteredEntitiesSubject.next(this.unselectedEntities);

      if (!dontEmitChanges) {
        this.emitPropertyChanges();
      }
    }
  }

  removeEntities(entities: IEntity[], dontEmitChanges: boolean = false): void {
    let clonedEntities = JSON.parse(JSON.stringify(entities));

    for (let entity of clonedEntities) {
      const index = this.selectedEntities.findIndex((e) => {
        return entity._id == e._id;
      });

      if (index >= 0) {
        this.unselectedEntities.push(entity);
        this.selectedEntities.splice(index, 1);
      }
    }

    this.filteredEntitiesSubject.next(this.unselectedEntities);

    if (!dontEmitChanges) {
      this.emitPropertyChanges();
    }
  }

  getEntityLabel(entity: IEntity) {
    if (this.entityDefinition.referenceLabelPropertyKey) {
      if (this.entityDefinition.referenceLabelPropertyKey.split('.').length == 2) {
        let keyParts = this.entityDefinition.referenceLabelPropertyKey.split('.');

        return entity[keyParts[0]][keyParts[1]];
      } else {
        return entity[this.entityDefinition.referenceLabelPropertyKey];
      }
    } else {
      return entity?.data?.name;
    }
  }
}
