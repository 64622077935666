import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FilterOptionComponent } from '../filter-option/filter-option.component';

@Component({
  selector: 'suvo-bi-checkbox-filter-option',
  templateUrl: './checkbox-filter-option.component.html',
  styleUrls: ['./checkbox-filter-option.component.scss'],
})
export class CheckboxFilterOptionComponent
  extends FilterOptionComponent
  implements OnInit
{
  formGroup: FormGroup;

  checked;

  constructor() {
    super();

    this.formGroup = new FormGroup({
      checkbox: new FormControl(),
    });
    this.hookForm(this.formGroup);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.formGroup.controls.checkbox.value) {
      this.formGroup.controls.checkbox.setValue(
        this.option.filterMethodConfig.defaultValue,
        { emitEvent: false }
      );
    }
  }

  getFilterRules(values: any): FilterRule[] {
    return [
      {
        key: this.option.filterPropertyKeys[0],
        eq: values.checkbox,
      },
    ];
  }

  patchRules(rules: FilterRule[]) {
    const property = rules[0] as IFilterProperty;
    const patchValue = property?.eq;

    this.formGroup.controls.checkbox.patchValue(patchValue, {
      emitEvent: false,
    });
  }

  onToggle(ev) {
    this.checked = ev.checked;
  }
}
