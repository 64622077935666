import { HttpClient } from '@angular/common/http';
import { ITableDataSource } from '../../../shared/interfaces/table/table-data-source.interface';
import { ITableOptions } from '../../../shared/interfaces/table/table-options.interface';
import { ITableSort } from '../../../shared/interfaces/table/table-sort.interface';

export class TableApiService<Item> {
  constructor(
    protected httpClient: HttpClient,
    private url: string,
  ) {}

  createTableParams(options: ITableOptions): any {
    if (!options) {
      return {};
    }

    const tableOptions: any = {};

    ['filters', 'simpleFilters', 'sort', 'pagination', 'properties'].forEach((option) => {
      const value = options[option];
      if (value) {
        if (option === 'filters') {
          if (options[option]?.query?.rules?.length) {
            if (options[option]?._id) {
              tableOptions[option] = JSON.stringify({
                id: options[option]._id,
              });
            } else {
              tableOptions[option] = JSON.stringify(options[option]);
            }
          }
          return null;
        } else if (option == 'sort' && options[option]) {
          let sortToUse = JSON.parse(JSON.stringify(options[option]));

          if (sortToUse?.direction.toString() == 'desc') {
            sortToUse.direction = 'dsc';
          } else if (sortToUse?.direction.toString() == 'asc') {
            sortToUse.direction = 'asc';
          }

          tableOptions[option] = JSON.stringify(sortToUse);
        } else {
          tableOptions[option] = JSON.stringify(value);
        }
      }
    });

    if (options.search) {
      tableOptions.search = options.search;
    }
    return tableOptions;
  }

  getRequestUrl(suffix: string | string[] = ''): string {
    //  this.url ends with a trailing slash.
    return suffix
      ? typeof suffix === 'string'
        ? `${this.url}${suffix}/`
        : `${this.url}${suffix.join('/')}/`
      : this.url;
  }

  get<T>(suffix?: string | string[], params?: any): Promise<T> {
    return this.httpClient.get<T>(this.getRequestUrl(suffix), { params }).toPromise();
  }

  patchOne<T>(suffix: string | string[] = '', id: string, body: Partial<Item>): Promise<Object> {
    return this.httpClient.patch<T>(`${this.getRequestUrl(suffix)}${id}`, body).toPromise();
  }

  postOne(suffix: string | string[] = '', body: Item): Promise<Item> {
    return this.httpClient.post<Item>(this.getRequestUrl(suffix), body).toPromise();
  }

  deleteOne(suffix: string | string[] = '', id: string): Promise<Object> {
    return this.httpClient.delete(`${this.getRequestUrl(suffix)}${id}`).toPromise();
  }

  getPaginated(
    suffix: string | string[] = '',
    options: ITableOptions,
  ): Promise<ITableDataSource<Item>> {
    const params = this.createTableParams(options);
    return this.get<ITableDataSource<Item>>(suffix, params);
  }

  getOne(suffix: string | string[] = '', id: string): Promise<Item> {
    return this.httpClient.get<Item>(`${this.getRequestUrl(suffix)}${id}`).toPromise();
  }
}
