<mat-form-field appearance="outline" class="form-field-no-padding">
  <mat-label>Data Source</mat-label>
  <mat-select [value]="selectedLink">

    <mat-select-trigger>
      <span fxLayoutAlign="flex-start center">
        <mat-icon>{{ selectedLink.displayIcon }}</mat-icon>&nbsp;&nbsp;{{ selectedLink.displayName }}
      </span>
    </mat-select-trigger>

    <mat-option *ngFor="let link of selectLinks" [routerLink]="['..', link.route]" [value]="link">
      <mat-icon>{{ link.displayIcon }}</mat-icon>
      {{ link.displayName }}
    </mat-option>

  </mat-select>
</mat-form-field>