<!-- v1 -->

<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Icon Columns</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngFor="let iconColumn of iconColumns.controls; let i = index">
        <div [formGroup]="iconColumn" class="icon-columns-container">
          <div class="inputs-container">
            <mat-form-field appearance="fill">
              <mat-label>Heading</mat-label>
              <input matInput formControlName="heading" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Heading Font Size</mat-label>
              <mat-select formControlName="headingFontSize">
                <mat-option *ngFor="let sizeOption of fontSizesOptions" [value]="sizeOption">
                  {{ sizeOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="color-input">
              <mat-label>Heading Font Color</mat-label>
              <input type="color" matInput formControlName="headingFontColor" />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Text</mat-label>
              <input matInput formControlName="text" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Text Font Size</mat-label>
              <mat-select formControlName="textFontSize">
                <mat-option *ngFor="let sizeOption of fontSizesOptions" [value]="sizeOption">
                  {{ sizeOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="color-input">
              <mat-label>Text Font Color</mat-label>
              <input type="color" matInput formControlName="textFontColor" />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Icon</mat-label>
              <input matInput formControlName="icon" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Icon Position</mat-label>
              <mat-select formControlName="iconPosition">
                <mat-option
                  *ngFor="let position of horizontalPositionOptions"
                  [value]="position.key"
                >
                  {{ position.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-icon class="delete-btn" (click)="removeIconColumn(i)">delete_forever</mat-icon>
        </div>
      </ng-container>

      <button class="add-btn" mat-raised-button color="primary" (click)="addIconColumn()">
        Add Icon Column
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Styles</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="inputs-container">
        <mat-form-field appearance="fill">
          <mat-label>Background</mat-label>
          <input matInput formControlName="background" />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>

<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
