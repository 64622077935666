<h2>Which of these applies to you?</h2>

<small>Select all that apply. This information helps us to personalise your experience and get you started.</small>

<app-step-multiple-choice
  [none]="{
    name: 'none',
    label: 'I\'m not sure.',
  }"
  noneName="none"
  [checkboxes]="checkboxes"
  [parentFormGroup]="parentFormGroup"
  [formGroup]="formGroup"
  [controlName]="controlName"
/>
