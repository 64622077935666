import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CurrentTenantData } from '../../../shared/interfaces/current-tenant-data.interface';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { ITenant } from '../interfaces/tenant.interface';

@Injectable({ providedIn: 'root' })
export class CurrentTenantService {
  readonly currentTenant = signal<CurrentTenantData>(null);
  currentFirebaseTenantId: string;

  readonly currentTenantPlan = computed(() => this.currentTenant()?.plan ?? null);

  /** @deprecated Use the `currentTenant` Signal instead. */
  currentTenantData: CurrentTenantData = null;

  constructor(
    private readonly http: HttpClient,
    private readonly settings: SuvoUsersClientLibSettingsService,
  ) {}

  async init(url: string) {
    // Get initial part of config from users microservice
    this.currentTenantData = await lastValueFrom(
      this.http.get<Omit<CurrentTenantData, 'stripe_pk' | 'autoTrialPeriod'>>(url, {
        headers: { 'Platform-ID': this.settings.platformId },
      }),
    );

    // Get another part of config from subscriptions microservice
    const subcriptionSettings = await lastValueFrom(
      this.http.get<{
        autoSubscribeFreeProducts?: boolean;
        redirectToEmailAlerts?: boolean;
        stripePublishableKey?: string;
        autoTrialPeriod?: number;
      }>(
        `${this.settings.subscriptionServiceApi}tenant/${this.currentTenantData._id}/subscription-settings`,
      ),
    );

    // Combine config
    if (subcriptionSettings) {
      this.currentTenantData = {
        ...this.currentTenantData,
        ...subcriptionSettings,
        stripe_pk: subcriptionSettings.stripePublishableKey,
      };
    }

    this.currentTenant.set(this.currentTenantData);
  }

  update(tenant: Partial<ITenant>) {
    return lastValueFrom(
      this.http.patch(`${this.settings.usersServiceApi}tenant/${this.currentTenant()._id}`, tenant),
    );
  }
}
