import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countries } from '@suvo-bi-lib';
import { StepComponent } from '../../../classes/abstract-step.component';

@Component({
  selector: 'app-shop-details-step',
  templateUrl: './shop-details-step.component.html',
  styleUrls: ['../../../styles/step.styles.scss', './shop-details-step.component.scss']
})
export class ShopDetailsStepComponent extends StepComponent {
  @Input() protected readonly parentFormGroup: FormGroup;
  @Input() protected readonly controlName: string;

  public readonly countryOptions = countries;

  public readonly formGroup = new FormGroup({
    name: new FormControl(), // TODO: validators
    subdomain: new FormControl(),
    location: new FormControl('GB', [Validators.required /* TODO: is in array */])
  });

  public generateDomain() {
    if (!this.formGroup.controls.subdomain.value) {
      this.formGroup.controls.subdomain.markAsPristine();
    }
    if (this.formGroup.controls.subdomain.pristine) {
      this.formGroup.controls.subdomain.setValue(
        this.formGroup.controls.name.value
          .replace(/[^a-zA-Z]+/g, '-')
          .replace(/[^a-zA-Z-]+/g, ' ')
          .toLowerCase()
      );
    }
  }
}
