import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({ template: '' })
export abstract class StepComponent implements OnInit {
  protected abstract readonly parentFormGroup: FormGroup;
  protected abstract readonly controlName: string;

  abstract readonly formGroup: AbstractControl;

  ngOnInit() {
    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(this.controlName, this.formGroup);
    }
  }
}
