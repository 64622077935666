import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateComponent } from './private.component';

const routes: Routes = [
  {
    path: '',
    component: PrivateComponent,
    children: [
      {
        path: '',
        redirectTo: 'subscriptions',
        pathMatch: 'full',
      },
      {
        path: 'login-referrer',
        redirectTo: 'subscriptions',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        redirectTo: 'subscriptions',
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./modules/pages/pages.module').then((module) => module.PagesModule),
      },
      {
        path: 'data-sources',
        loadChildren: () =>
          import('./modules/data-sources/data-sources.module').then(
            (module) => module.DataSourcesModule,
          ),
      },
      {
        path: 'external',
        loadChildren: () =>
          import('./modules/data-source-external/data-source-external.module').then((module) => module.DataSourceExternalModule),
      },
      {
        path: 'connection',
        loadChildren: () =>
          import('./modules/data-source-connection/data-source-connection.module').then((module) => module.DataSourceConnectionModule),
      },
      {
        path: 'import',
        loadChildren: () =>
          import('./modules/import/module').then((module) => module.ImportModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./modules/subscriptions/subscriptions.module').then(
            (module) => module.SubscriptionsModule,
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then((module) => module.SettingsModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./modules/account/account.module').then((module) => module.AccountModule),
      },
      {
        path: 'media',
        loadChildren: () =>
          import('@suvo-media/modules/media/media.module').then((module) => module.MediaModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
