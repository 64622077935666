import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITableDataSource } from '../../../../shared/interfaces/table/table-data-source.interface';
import { ITableOptions } from '../../../../shared/interfaces/table/table-options.interface';
import { ITablePagination } from '../../../../shared/interfaces/table/table-pagination.interface';
import { ITableSort } from '../../../../shared/interfaces/table/table-sort.interface';
import { Filters } from '../../../filters/models/filters.model';
import { DataSourceDefinitionsService } from '../../data-source-definitions/services/data-source-definitions.service';
import { IDataSourceSettings } from '../../interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../interfaces/data-source-settings.token';
import { IExportOptions } from '../interfaces/export-options.interface';
import { IRecipe } from '../interfaces/recipe.interface';

@Injectable({
  providedIn: 'root',
})
export class DataSourceItemsService {
  cachedResults: Record<string, any> = {};
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  shortCode: string;
  host: string;

  constructor(
    private httpClient: HttpClient,
    private dataSourceDefinitionsService: DataSourceDefinitionsService,
    @Inject(DataSourceSettingsRef)
    private dataSourceSettings: IDataSourceSettings,
  ) {
    this.host = dataSourceSettings.dataServiceApi;
  }

  getUrlWithShortCode(shortCode: string): string {
    return `${this.host}${shortCode}`;
  }

  listen() {
    this.dataSourceDefinitionsService.currentDefinition
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((definition) => {
        if (definition) {
        }
      });
  }

  setShortCode(shortCode: string) {
    this.shortCode = shortCode;
  }

  getFiltersStringified(filters?: Filters): string {
    if (filters?.query?.rules?.length) {
      if (filters._id) {
        return JSON.stringify({
          id: filters._id,
        });
      } else {
        return JSON.stringify(filters);
      }
    }
    return null;
  }

  async getOne(shortCode: string, id: string, params?: any): Promise<any[] | any> {
    const shortCodeUrl = await this.getUrlWithShortCode(shortCode);
    return this.httpClient
      .get(`${shortCodeUrl}/${id}`, { params })
      .toPromise() as unknown as Promise<any[]>;
  }

  createTableOptions(
    filters?: any,
    sort?: ITableSort,
    pagination?: ITablePagination,
    entityIds?: string[],
  ): any {
    const tableOptions: any = {};

    const filtersStringified = this.getFiltersStringified(filters);
    if (filtersStringified) {
      tableOptions.filters = filtersStringified;
    }

    if (sort) {
      tableOptions.sort = JSON.stringify(sort);
    }

    if (pagination) {
      tableOptions.pagination = JSON.stringify(pagination);
    }

    if (entityIds?.length) {
      tableOptions.entityIds = JSON.stringify(entityIds);
    }

    return tableOptions;
  }

  async getPaginated(
    shortCode: string,
    filters: any,
    sort?: any,
    pagination?: any,
    entityIds?: string[],
  ): Promise<ITableDataSource<any>> {
    const shortCodeUrl = this.getUrlWithShortCode(shortCode);
    const tableOptions = this.createTableOptions(filters, sort, pagination, entityIds);
    return this.httpClient
      .get(shortCodeUrl, {
        params: tableOptions,
      })
      .toPromise() as unknown as Promise<ITableDataSource<any>>;
  }

  getCount(shortCode: string): Promise<any> {
    const shortCodeUrl = this.getUrlWithShortCode(shortCode);
    return this.httpClient.get(`${shortCodeUrl}/public-count`).toPromise();
  }

  async getAggregated(recipe: IRecipe) {
    let route = '';

    if (recipe.fullRoute) {
      route = this.host + recipe.fullRoute;
    } else {
      let shortCode = recipe.dataSource;
      const shortCodeUrl = await this.getUrlWithShortCode(shortCode);

      route = shortCodeUrl + '/aggregation';
    }

    let q = `?metric=${recipe.metric}`;

    if (recipe.groupBy) {
      q += `&groupBy=${recipe.groupBy}`;
    }

    const filtersStringified = this.getFiltersStringified(recipe.filters);
    if (filtersStringified) {
      q += `&filters=${filtersStringified}`;
    }

    const simpleFiltersStringified = JSON.stringify(recipe.simpleFilters);
    if (simpleFiltersStringified) {
      q += `&simpleFilters=${simpleFiltersStringified}`;
    }

    if (recipe.sortOrder) {
      q += `&sortOrder=${recipe.sortOrder}`;
    }
    if (recipe.maxGroups) {
      q += `&maxGroups=${recipe.maxGroups}`;
    }
    if (recipe.includeOtherGroup) {
      q += '&includeOtherGroup="true"';
    }

    if (recipe.extraParams) {
      let keys = Object.keys(recipe.extraParams);
      for (let key of keys) {
        if (recipe.extraParams.hasOwnProperty(key)) {
          q += `&${key}=${recipe.extraParams[key]}`;
        }
      }
    }

    return this.httpClient.get(route + q).toPromise() as unknown;
  }

  async getGeolocated(recipe: IRecipe) {
    if (!recipe?.dataSource) {
      throw new Error('Invalid recipe.');
    }

    let url = `${await this.getUrlWithShortCode(recipe.dataSource)}/geolocation`;

    if (recipe?.filters) {
      url += `?filters=${JSON.stringify(recipe.filters)}`;
    }

    return this.httpClient.get(url).toPromise() as unknown;
  }

  async getCsvString(
    shortCode: string,
    tableOptions?: ITableOptions,
    exportOptions?: IExportOptions,
  ): Promise<string> {
    const { filters, pagination, sort } = tableOptions;
    const params = {
      ...this.createTableOptions(filters, sort, pagination),
      ...exportOptions,
    };

    const requestUrl = `${this.getUrlWithShortCode(shortCode)}/export`;

    return this.httpClient
      .get(requestUrl, {
        responseType: 'text',
        params,
      })
      .toPromise();
  }

  async getCsv(shortCode: string, filters?: any, sort?: any, pagination?: any): Promise<string> {
    const tableOptions = this.createTableOptions(filters, sort, pagination);

    return this.httpClient
      .get(`${this.getUrlWithShortCode(shortCode)}/export`, {
        responseType: 'text',
        params: tableOptions,
      })
      .toPromise();
  }

  async patch(shortCode: string, id?: number, body?: any, url?: string): Promise<object> {
    const shortCodeUrl = this.getUrlWithShortCode(shortCode);
    let additonalUrl;
    if (id) {
      additonalUrl = `/${id}`;
      if (url) {
        additonalUrl += url;
      }
    }
    return this.httpClient.patch(await shortCodeUrl, body).toPromise();
  }

  async put(shortCode: string, url?: string, body?: any): Promise<object> {
    const shortCodeUrl = this.getUrlWithShortCode(shortCode);
    return this.httpClient.put(await shortCodeUrl, body).toPromise();
  }

  async post(shortCode: string, body?: any | any, additionalUrl?: string): Promise<object> {
    const shortCodeUrl = this.getUrlWithShortCode(shortCode);
    return this.httpClient.post(await shortCodeUrl, body).toPromise();
  }

  async delete(shortCode: string, id: number): Promise<object> {
    const shortCodeUrl = this.getUrlWithShortCode(shortCode);
    return this.httpClient.delete(shortCodeUrl).toPromise();
  }
}
