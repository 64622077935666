import { Component, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ILinkButtonTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/link-button-tile.interface';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'app-link-banner-setup-wizard',
  templateUrl: './link-banner-setup-wizard.component.html',
  styleUrls: ['./link-banner-setup-wizard.component.scss'],
})
export class LinkBannerSetupWizardComponent implements OnInit {
  tileDefinition: ILinkButtonTileDefinition;

  public firstStepSetupForm: FormGroup;

  public dataSourceObject;

  public get headingControl(): AbstractControl {
    return this.firstStepSetupForm.get('heading') as AbstractControl;
  }

  public get displayFeatureTotalControl(): AbstractControl {
    return this.firstStepSetupForm.get(
      'displayFeatureTotal'
    ) as AbstractControl;
  }

  public get subHeadingControl(): AbstractControl {
    return this.firstStepSetupForm.get('subHeading') as AbstractControl;
  }

  public get headingIconControl(): AbstractControl {
    return this.firstStepSetupForm.get('headingIcon') as AbstractControl;
  }

  public get imgUriControl(): AbstractControl {
    return this.firstStepSetupForm.get('imgUri') as AbstractControl;
  }

  public get linkTextControl(): AbstractControl {
    return this.firstStepSetupForm.get('linkText') as AbstractControl;
  }

  public get routeLinkControl(): AbstractControl {
    return this.firstStepSetupForm.get('routeLink') as AbstractControl;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorService: TileCreatorService
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSetupForms();
  }

  public initSetupForms(): void {
    this.firstStepSetupForm = this.fb.group({
      heading: ['', Validators.required],
      subHeading: ['', Validators.required],
      displayFeatureTotal: ['', Validators.required],
      headingIcon: ['', Validators.required],
      imgUri: ['', Validators.required],
      linkText: ['', Validators.required],
      routeLink: ['', Validators.required],
    });

    if (this.tileDefinition) {
      this.firstStepSetupForm.patchValue(this.tileDefinition);
    }
  }

  public initDataSource(dataSource: any): void {
    this.dataSourceObject = dataSource;
  }

  public onCreate(): void {
    Object.assign(this.tileDefinition, {
      tileType: 'link-banner',
      heading: this.headingControl.value,
      headingIcon: this.headingIconControl.value,
      subHeading: this.subHeadingControl.value,
      imgUri: this.imgUriControl.value,
      linkText: this.linkTextControl.value,
      routeLink: this.routeLinkControl.value,
      displayFeatureTotal: this.displayFeatureTotalControl.value,
      canView: true,
    });
    this.tileCreatorService.onChange.next();
  }
}
