<mat-form-field *ngIf="localFormControl" fxFlex="0 1" appearance="outline">

  <mat-label>Password</mat-label>
  <input matInput [type]="show ? 'text' : 'password'" [formControl]="localFormControl">

  <button mat-icon-button matSuffix (click)="show = !show" attr.aria-label="Hide Password" attr.aria-pressed="Hide" type="button">
    <mat-icon>{{ show ? 'visibility_off' : 'visibility' }}</mat-icon>
  </button>

  <mat-error *ngIf="localFormControl.hasError('required')">
    * Required.
  </mat-error>

  <mat-error *ngIf="localFormControl.hasError('invalid-pass')">
    Invalid password
  </mat-error>

</mat-form-field>