import { countries } from './countries';
import * as countryContinentsJson from './country-continents.json';

const module = countryContinentsJson as any;
let importData = module.default
  .filter((country) => country['sub-region'] != '')
  .map((country) => {
    if (country['sub-region'] == 'Northern America') {
      country['region'] = 'North America';
    } else if (country['region'] == 'Americas') {
      country['region'] = 'South America';
    }
    return country;
  }) as any[];

let continentNames = [...new Set(importData.map((item) => item.region))];

let continents = continentNames.map((cn) => {
  return {
    name: cn,
    value: cn,
  };
});
let continentCountries: {
  [x: string]: any[];
} = {};

importData.forEach((country) => {
  let realCountry = countries.find((c) => c.value == country['alpha-2']);
  if (!realCountry) {
    realCountry = { name: country.name, value: country['alpha-2'] };
  }

  if (continentCountries[country.region]) {
    continentCountries[country.region].push(realCountry);
  } else {
    continentCountries[country.region] = [realCountry];
  }
});

export const ALL_CONTINENTS = continents;
export const CONTINENT_COUNTRIES = continentCountries;
export const CONTINENT_NAME_SWAPS: {
  [x: string]: string;
} = {
  'North America': 'N. America',
  'South America': 'S. America',
};
