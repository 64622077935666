import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FiltersModule } from '../filters/filters.module';
import { EmailAlertService } from './services/email-alert.service';
import { ManageEmailAlertsComponent } from './components/manage-email-alerts/manage-email-alerts.component';
import { EmailAlertsIntroDialogComponent } from './dialogs/email-alerts-intro-dialog/email-alerts-intro-dialog.component';

@NgModule({
  imports: [CommonModule, SharedModule, FiltersModule],
  declarations: [ManageEmailAlertsComponent, EmailAlertsIntroDialogComponent],
  providers: [EmailAlertService],
  exports: [ManageEmailAlertsComponent],
})
export class EmailAlertLibModule {}
