import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ethnicities } from '../../../../../shared/constants/ethnicity-list';
import { SuvoUsersClientLibSettingsService } from '../../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { UsersService } from '../../../../users/services/users.service';
import { IRegisterConsentCheckbox } from '../../../interfaces/consent-box.interface';
import { SubscriptionService } from '../../../../subscription/services/subscription.service';
import { UserModel } from '../../../../users/models/user-model';
import { OrganisationModel } from '../../../../organisation/models/organisation-model';

type UserModelWithCreatedOrganisation = UserModel & {
  createdOrganisation: OrganisationModel;
};

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl): boolean {
    return (
      control &&
      control.dirty &&
      control.parent?.get('password')?.value !==
        control.parent?.get('confirmPassword')?.value
    );
  }
}

@Component({
  selector: 'lib-page-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [DatePipe],
})
export class RegisterComponent implements OnInit {
  private readonly passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  public readonly registerForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(this.passwordRegex),
    ]),
    confirmPassword: new UntypedFormControl('', [Validators.required]),
  });

  public readonly matcher = new MyErrorStateMatcher();

  public readonly genders = ['Male', 'Female', 'Other'] as const;
  public readonly ethnicityList = ethnicities;
  public readonly showRegisterDoBField =
    !!this.suvoUsersClientLibSettingsService.showRegisterDoBField;

  public registrationComplete = false;
  public registerMode: 'classic' | 'invitation' = 'classic';

  private token: string;
  private invitationEmail: string;

  public hidePass = true;
  public hideConfirmPass = true;
  public loading = false;
  public errors = [];

  @Input() private readonly showFields: Array<{
    name: string;
    required: boolean;
  }>;
  @Input() public readonly consentBoxes: IRegisterConsentCheckbox[];
  @Input() public readonly enablePasswordPreview: boolean = true;
  @Input() marketingInfo: 'checkbox' | 'statement' | undefined;
  @Input() validateOnly = false;

  @Output() onRegisterSuccess = new EventEmitter<void>();
  @Output() registerStatusChange = new EventEmitter();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly datePipe: DatePipe,
    private readonly router: Router,
    private readonly subscriptionsService: SubscriptionService,
    private readonly suvoUsersClientLibSettingsService: SuvoUsersClientLibSettingsService,
    private readonly usersService: UsersService
  ) {}

  ngOnInit(): void {
    // Add form validators
    this.registerForm.addValidators([
      this.checkPasswordsMatch,
      // this.requireCheckboxesToBeCheckedValidator(1)
    ]);
    if (!!this.marketingInfo) {
      this.registerForm.addControl(
        'marketingAllowed',
        new FormControl(this.marketingInfo === 'statement')
      );
    }

    // Add additional fields
    if (this.showFields?.length) {
      for (const field of this.showFields) {
        const formControl = new UntypedFormControl();
        if (field.name === 'phone') {
          formControl.addValidators(Validators.pattern(/[- +()0-9]{6,}/));
        }
        if (field.required) {
          formControl.addValidators(Validators.required);
        }
        this.registerForm.addControl(field.name, formControl);
      }
    }

    // TODO: Long, probably inefficient, optimise in future
    if (this.consentBoxes?.length) {
      let ensureAtLeastOneConsentCheckBoxChecked = (
        control: AbstractControl
      ): ValidationErrors | null => {
        let totalChecked = 0;
        this.consentBoxes.forEach((consentBox, index) => {
          if (this.registerForm.controls[`consentBox${index}`]?.value == true) {
            totalChecked++;
          }
        });

        if (totalChecked == 0) {
          return { requireCheckboxesToBeChecked: true };
        }

        return null;
      };

      this.consentBoxes.forEach((consentBox, index) => {
        this.registerForm.addControl(
          `consentBox${index}`,
          new UntypedFormControl(false, [
            ensureAtLeastOneConsentCheckBoxChecked,
          ])
        );

        this.registerForm.controls[`consentBox${index}`].valueChanges.subscribe(
          (newValue: string) => {
            let checked = 0;

            Object.keys(this.registerForm.controls).forEach((key) => {
              const control = this.registerForm.controls[key];

              if (key.includes('consentBox') && control.value === true) {
                checked++;
              }
            });

            if (checked < 1) {
              // TODO: MAKE CUSTOM
              Object.keys(this.registerForm.controls).forEach((key) => {
                const control = this.registerForm.controls[key];

                if (key.includes('consentBox')) {
                  control.enable({ emitEvent: false });
                }
              });
              this.registerForm.controls.consentBox0.setErrors({
                requireCheckboxesToBeChecked: true,
              });
              return null;
            }

            Object.keys(this.registerForm.controls).forEach((key) => {
              const control = this.registerForm.controls[key];

              if (control.value === false && key.includes('consentBox')) {
                control.disable({ emitEvent: false });
              }
            });
            return null;
          }
        );
      });
    }

    // Parse route params
    this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
      if (Object.keys(params).length) {
        this.registerMode = 'invitation';
        this.token = params.token;
        this.invitationEmail = params.email;
        this.registerForm.controls.email.setValue(params.email);
        this.registerForm.controls.email.disable();
      }
    });
  }

  checkPasswordsMatch = (control: AbstractControl): ValidationErrors | null => {
    if (this.registerForm) {
      return this.registerForm.get('password')?.value ===
        this.registerForm.get('confirmPassword')?.value
        ? null
        : { notSame: true };
    }
    return null;
  };

  onSubmit(): Promise<void> {
    switch (this.registerMode) {
      case 'classic':
        return this.register('public/register');
      case 'invitation':
        this.registerForm.controls.email.enable();
        this.registerForm.patchValue({ email: this.invitationEmail });
        this.registerForm.addControl(
          'token',
          new UntypedFormControl(this.token, Validators.required)
        );
        return this.register('public/invites/register');
    }
  }

  handleLoginClick() {
    this.router.navigate(['/identity']);
  }

  async register(route: string): Promise<void> {
    this.loading = true;
    this.errors = [];
    this.registerStatusChange.next('REGISTER_ATTEMPT_PENDING');
    try {
      if (this.registerForm.value?.dateOfBirth) {
        this.registerForm.value.dateOfBirth = this.datePipe.transform(
          this.registerForm.value.dateOfBirth,
          'yyyy-MM-dd'
        );
      }

      if (!this.validateOnly) {
        const registerResponse = await this.usersService.registerUser(
          route,
          this.registerForm.value
        );

        if (route === 'public/register') {
          const organisation = (
            registerResponse.endpointResponseBody as UserModelWithCreatedOrganisation
          ).createdOrganisation;
          await this.subscriptionsService.createCustomer({
            organisationId: organisation._id,
            organisationName: organisation.name,
            tenantId: organisation.tenantId,
            ownerEmail: this.registerForm.value.email,
          });
        }

        if (!registerResponse.firebaseUser.user.emailVerified) {
          this.registrationComplete = true;
          this.onRegisterSuccess.emit();
        } else {
          this.router.navigateByUrl(
            `private/invitation-list?token=${this.token}`
          );
        }
      }
      this.registerStatusChange.next('REGISTER_ATTEMPT_COMPLETE');
    } catch (err) {
      this.registerStatusChange.next('REGISTER_ATTEMPT_FAILED');
      switch (err?.error?.code) {
        case 'auth/email-already-exists':
          this.registerForm.controls.email.setErrors({ emailExists: true });
          break;

        case 'register-disabled':
          this.errors.push(err.error);
          break;

        default:
          this.errors.push({
            code: 'unknown',
            message:
              'There was an issue registering your account. Please contact support.',
          });
          break;
      }
    } finally {
      this.loading = false;
    }
  }
}

// TODO: Might be handy in future for refactoring, don't delete just yet
// requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
//   return function validate(formGroup: FormGroup) {
//     let checked = 0;

//     Object.keys(formGroup.controls).forEach(key => {
//       const control = formGroup.controls[key];

//       if (control.value === true) {
//         checked++;
//       }
//     });

//     if (checked < minRequired) {
//       return {
//         requireCheckboxesToBeChecked: true,
//       };
//     }

//     Object.keys(formGroup.controls).forEach(key => {
//       const control = formGroup.controls[key];
//       if (control.value === false && key.includes("consentBox")) {
//         control.disable();
//       }
//     });

//     return null;
//   };
// }
