import { Component, computed } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthService } from '@suvo-bi-core';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatMenuModule],
  selector: 'app-account-widget',
  template: `
    <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>person</mat-icon>
      {{ displayName() }}
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon color="primary">logout</mat-icon>
        Logout
      </button>
    </mat-menu>
  `,
})
export class AccountWidgetSelectorComponent {
  readonly displayName = computed(() => this.authService.currentUser()?.displayName);

  constructor(
    private readonly authService: AuthService,
    private readonly fireAuth: AngularFireAuth,
    private readonly router: Router,
  ) {}

  async logout() {
    await this.fireAuth.signOut();
    await this.router.navigate(['/']);
  }
}
