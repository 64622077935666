import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFilterProperty } from '../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../shared/interfaces/filters/filter-rule.type';
import { ToggleGroup } from '../models/utility/toggle-group.model';

@Injectable({
  providedIn: 'root',
})
export class FiltersUtilityService {
  constructor() {}

  createToggleGroupsFormGroup(
    parentData,
    childData,
    unsubscribeSubject
  ): {
    formGroup: FormGroup;
    toggleGroups: ToggleGroup[];
  } {
    let locationControls = {};
    let toggleGroups = [];

    parentData.forEach((parent) => {
      let formControls = {};
      let children = childData[parent.name];

      children.forEach((child) => {
        let formControl = new FormControl(true);
        formControls[child.name] = formControl;
      });

      let formGroup = new FormGroup(formControls);

      toggleGroups.push(
        new ToggleGroup(
          parent.name,
          parent.value,
          parent.valueIsList,
          formGroup,
          children,
          parent.isSecondary,
          parent.alsoFilterByChildren,
          unsubscribeSubject
        )
      );

      locationControls[parent.name] = formGroup;
    });

    return {
      formGroup: new FormGroup(locationControls),
      toggleGroups,
    };
  }

  getToggleGroupsFilterRules(
    toggleGroups: ToggleGroup[],
    parentKeyName,
    childKeyName,
    toggleGroupParentKey = 'name',
    toggleGroupChildKey = 'name'
  ): FilterRule[] {
    let equalsParents: string[] = [];
    let equalsChildren: string[] = [];

    let allParentsChecked = true;
    toggleGroups.forEach((parent) => {
      if (parent.checked) {
        equalsParents.push(parent[toggleGroupParentKey]);

        if (parent.alsoFilterByChildren) {
          for (let childName in parent.formGroup.value) {
            if (parent.formGroup.value[childName] == true) {
              const child = parent.children.find((c) => c.name === childName);

              if (child.useParentFilterProperty) {
                equalsParents.push(child[toggleGroupChildKey]);
              } else {
                equalsChildren.push(child[toggleGroupChildKey]);
              }
            }
          }
        }
      } else {
        allParentsChecked = false;
        if (parent.indeterminate) {
          for (let childName in parent.formGroup.value) {
            if (parent.formGroup.value[childName] == true) {
              const child = parent.children.find((c) => c.name === childName);

              if (child.useParentFilterProperty) {
                equalsParents.push(child[toggleGroupChildKey]);
              } else {
                equalsChildren.push(child[toggleGroupChildKey]);
              }
            }
          }
        }
      }
    });

    const rules = [];

    if (childKeyName == parentKeyName) {
      let allEquals = [];

      if (equalsParents.length) {
        allEquals = [...equalsParents];
      }
      if (equalsChildren.length) {
        allEquals = [...allEquals, ...equalsChildren];
      }

      if (allEquals.length) {
        rules.push({
          key: parentKeyName,
          in: allEquals.join(','),
        });
      }
    } else {
      if (equalsParents.length) {
        rules.push({
          key: parentKeyName,
          in: equalsParents.join(','),
        });
      }
      if (equalsChildren.length) {
        rules.push({
          key: childKeyName,
          in: equalsChildren.join(','),
        });
      }
    }

    return rules;
  }

  patchToggleGroupsFilterRules(
    toggleGroups: ToggleGroup[],
    rules: FilterRule[],
    parentKeyName,
    childKeyName,
    toggleGroupParentKey = 'name',
    toggleGroupChildKey = 'name'
  ): void {
    if (rules?.length) {
      const parentProperty = rules.find(
        (property: IFilterProperty) => property.key === parentKeyName
      ) as IFilterProperty;
      const childProperty = rules.find(
        (property: IFilterProperty) => property.key === childKeyName
      ) as IFilterProperty;

      const equalsParents = parentProperty?.in.split(',') || [];
      const equalsChildren = childProperty?.in.split(',') || [];

      toggleGroups.forEach((parent) => {
        let parentValue = parent[toggleGroupParentKey];

        if (parent.valueIsList) {
          parentValue = parentValue.split(',')[0];
        }

        if (equalsParents.find((c) => c === parentValue)) {
          parent.setChecked(true, { emitEvent: false });
        } else {
          parent.setChecked(false, { emitEvent: false });

          let hasSomeChildren = false;
          let hasAllChildren = true;

          for (let childName in parent.formGroup.controls) {
            let child = parent.children.find((c) => c.name === childName);

            if (!child) continue;

            let childValue = child[toggleGroupChildKey];

            if (child.valueIsList) {
              childValue = childValue.split(',')[0];
            }

            if (
              (child.useParentFilterProperty &&
                equalsParents.find((c) => c === childValue)) ||
              equalsChildren.find((c) => c === childValue)
            ) {
              parent.formGroup.controls[childName].setValue(true, {
                emitEvent: false,
              });
              hasSomeChildren = true;
            } else {
              parent.formGroup.controls[childName].setValue(false, {
                emitEvent: false,
              });
              hasAllChildren = false;
            }
          }
          parent.indeterminate = hasSomeChildren && !hasAllChildren;

          if (parent.children.length && hasAllChildren) {
            parent.setChecked(true, { emitEvent: false });
          }
        }
      });
    } else {
      toggleGroups.forEach((toggleGroup) => {
        toggleGroup.setChecked(false, { emitEvent: false });
        toggleGroup.indeterminate = false;
      });
    }

    // :(
  }

  updateParentState(toggleGroups: ToggleGroup[]) {
    toggleGroups.forEach((parent) => {
      let hasSomeChildren = false;
      let hasAllChildren = true;

      for (let childName in parent.formGroup.controls) {
        let child = parent.formGroup.controls[childName];

        if (!child) continue;

        if (child.value) {
          hasSomeChildren = true;
        } else {
          hasAllChildren = false;
        }
      }

      parent.checked = hasAllChildren ? true : undefined; //Note: this is important for allowing indeterminant to work!
      parent.indeterminate = hasSomeChildren && !hasAllChildren;
    });
  }

  generateDefaultFiltersName(alias: string, selectedFiltersOptions) {
    // Tries to generate a non-colliding name for a new filter
    // hacked together quick and dirty, can definitely be improved
    const newFilterBaseName = `${alias} filters`;

    // See if the base filter name exists first
    if (
      selectedFiltersOptions &&
      selectedFiltersOptions.find((filtersInstance) => {
        return filtersInstance.name.trim() === newFilterBaseName;
      })
    ) {
      // Narrow down filters
      const relevantExistingFilters = selectedFiltersOptions
        .map((filtersInstance) => {
          return filtersInstance.name.trim();
        })
        .filter((existingFilterName) => {
          return (
            existingFilterName.startsWith(newFilterBaseName) &&
            existingFilterName.match(/[0-9]$/)
          );
        });
    }
    // otherwise the base name will just be returned
    // the user will have to think of a unique name themselves

    return this.capitalizeFirstLetter(newFilterBaseName);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
