import { BannerSetupWizardComponent } from '../components/banner-setup-wizard/banner-setup-wizard.component';
import { ChartSetupWizardComponent } from '../components/chart-setup-wizard/chart-setup-wizard.component';
import { ContactsSetupWizardComponent } from '../components/contacts-setup-wizard/contacts-setup-wizard.component';
import { HeroBannerSetupWizardComponent } from '../components/hero-banner-setup-wizard/hero-banner-setup-wizard.component';
import { HeroBannerV3SetupWizardComponent } from '../components/hero-banner-v3-setup-wizard/hero-banner-v3-setup-wizard.component';
import { IconColumnsSetupWizardComponent } from '../components/icon-columns-setup-wizard/icon-columns-setup-wizard.component';
import { LinkBannerSetupWizardComponent } from '../components/link-banner-setup-wizard/link-banner-setup-wizard.component';
import { MapSetupWizardComponent } from '../components/map-setup-wizard/map-setup-wizard.component';
import { PresentationSetupWizardComponent } from '../components/presentation-setup-wizard/presentation-setup-wizard.component';
import { TableSetupWizardComponent } from '../components/table-setup-wizard/table-setup-wizard.component';
import { TextColumnsSetupWizardComponent } from '../components/text-columns-setup-wizard/text-columns-setup-wizard.component';
import { TextRowsSetupWizardComponent } from '../components/text-rows-setup-wizard/text-rows-setup-wizard.component';
import { ITileType } from '../interfaces/tile-type.interface';

export const TILE_TYPES: ITileType[] = [
  {
    key: 'chart',
    name: 'Chart',
    setupWizard: ChartSetupWizardComponent,
  },
  {
    key: 'table',
    name: 'Table',
    setupWizard: TableSetupWizardComponent,
  },
  // {
  //   key: 'map',
  //   name: 'Map',
  //   setupWizard: MapSetupWizardComponent,
  // },
  {
    key: 'banner',
    name: 'Banner',
    setupWizard: BannerSetupWizardComponent,
  },
  {
    key: 'link-banner',
    name: 'Link Banner',
    setupWizard: LinkBannerSetupWizardComponent,
  },
  // {
  //   key: 'hero-banner',
  //   name: 'Hero Banner (Legacy)',
  //   setupWizard: HeroBannerSetupWizardComponent,
  // },
  {
    key: 'hero-banner-v3',
    name: 'Hero Banner V3',
    setupWizard: HeroBannerV3SetupWizardComponent,
  },
  {
    key: 'presentation',
    name: 'Presentation',
    setupWizard: PresentationSetupWizardComponent,
  },
  {
    key: 'contacts',
    name: 'Contacts',
    setupWizard: ContactsSetupWizardComponent,
  },
  {
    key: 'text-rows',
    name: 'Text Rows',
    setupWizard: TextRowsSetupWizardComponent,
  },
  {
    key: 'text-columns',
    name: 'Text Columns',
    setupWizard: TextColumnsSetupWizardComponent,
  },
  {
    key: 'icon-columns',
    name: 'Icon Columns',
    setupWizard: IconColumnsSetupWizardComponent,
  },
];
