import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { CurrentTenantService, ITenant, TenantService } from '@suvo-bi-users';
import { Subscription } from 'rxjs';

interface IShop extends ITenant {
  role: string;
}

@Component({
  standalone: true,
  imports: [MatIconModule],
  template: `
    <div class="component-container">
      <header style="display: flex; justify-content: flex-end">
        <div class="logout-btn" (click)="onLogout()">
          <mat-icon>logout</mat-icon>
          <span>Log Out</span>
        </div>
      </header>

      @if (isInitialised) {
        <div class="centered">
          <h1 style="font-size: 2.8em">Welcome</h1>

          <p style="font-size: 1.2em">Which shop would you like to manage?</p>

          <div class="shop-list">
            @for (shop of shops; track $index) {
              <div class="shop card" (click)="onShopSelect(shop.firebaseTenantId)">
                <div class="card-heading">
                  <mat-icon>store</mat-icon>
                  <h2>{{ shop.tenantName }}</h2>
                </div>
                <small>{{ shop.role }}</small>
              </div>
            }
            <!-- <div class="new-shop card" (click)="onNewShopClick()">
              <div class="centered">
                <mat-icon>add_circle_outline</mat-icon>
                <p>Create a new shop</p>
              </div>
            </div> -->
          </div>
        </div>
      }

      @if (isLoading) {
        <div class="loading-overlay">
          <div class="centered">
            <suvo-core-loading-spinner />
          </div>
        </div>
      }
    </div>
  `,
  styles: `
    .component-container {
      min-height: calc(100vh - 64px);
      padding: 32px;
      background: linear-gradient(#161853 0%, #272c92 50%);
      color: white;
    }

    .logout-btn {
      border: 1px solid white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 12px;
      transition: 120ms ease-out;
      cursor: pointer;

      /* Put it on top of "loading" overlay, allowing logout if something is broken */
      background: #272c92;
      z-index: 10;

      &:hover {
        background: #fff2;
      }
    }

    .shop-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin: 64px 0 0;
      gap: 24px;
      text-align: left;
      font-size: 1.1em;
    }

    .shop {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 16px;
      min-height: 128px;
      min-width: 192px;
      inline-size: min-content;
      flex-flow: column nowrap;
      color: black;

      h2 {
        margin: 0;
      }
    }

    .centered {
      margin: auto;
      text-align: center;
    }

    .card-heading {
      display: flex;
      gap: 8px;

      mat-icon {
        margin-top: 4px;
      }
    }

    /*
    .new-shop {
      cursor: pointer;
      padding: 16px;
      min-height: 124px;
      min-width: 188px;
      display: flex;
      background: none;
      border: 2px solid white;

      mat-icon {
        font-size: 48px;
        height: 48px;
        width: 48px;
      }

      p {
        margin: 0;
        font-size: 1.16em;
      }
    }
    */

    .loading-overlay {
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background: white;
      opacity: 0.6;
      animation: fadeIn 0.1s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0.6;
      }
    }
  `,
})
export class ShopSelectScreenComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  shops = new Array<IShop>();
  isInitialised = false;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly angularFireAuth: AngularFireAuth,
    private readonly currentTenantService: CurrentTenantService,
    private readonly router: Router,
    private readonly tenantService: TenantService,
  ) {}

  ngOnInit() {
    this.subscription = this.angularFireAuth.user.subscribe(async (user) => {
      if (user) {
        this.isLoading = true;
        this.shops = [];
        const tenantRoles: Record<string, string> = (await user.getIdTokenResult()).claims.context
          ?.tenantRoles;
        if (!tenantRoles) {
          return this.onLogout();
        } else if (!Object.values(tenantRoles).length) {
          return this.onNewShopClick();
        }
        const tenants = await this.tenantService.getMyTenants();
        if (tenants.length == 1) {
          // This will auto-select the only tenant they manage
          return this.onShopSelect(tenants[0].firebaseTenantId);
        }
        if (tenants) {
          for (const tenant of tenants) {
            tenantRoles[tenant._id] &&
              this.shops.push({
                ...tenant,
                role: tenantRoles[tenant._id],
              });
          }
        }
        this.isLoading = false;
        this.isInitialised = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async onShopSelect(firebaseTenantID: string) {
    this.isLoading = true;

    await this.currentTenantService.init(
      `${environment.usersServiceApi}tenant/${firebaseTenantID}`,
    );

    const redirectUrl = this.activatedRoute?.snapshot?.queryParams['redirectUrl'];

    if (redirectUrl) {
      await this.router.navigateByUrl(decodeURIComponent(redirectUrl));
    } else {
      await this.router.navigate(['/', 'private', 'overview']);
    }
  }

  onNewShopClick() {
    this.router.navigate(['/', 'new-shop']);
  }

  async onLogout() {
    await this.angularFireAuth.signOut();
    await this.router.navigate(['/', 'login']);
  }
}
