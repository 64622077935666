import { ITour } from "../interfaces/tour.interface";

export const exampleTour: ITour = {
  tourId: 'example',
  steps: [
    {
      title: 'Welcome to IRJ Pro.',
      content: 'In this example tour you will see the variety of ways IRJ Pro suits your rail intelligence needs.',
      route: ['private', 'overview'],
    },
    {
      title: 'Projects',
      content: 'On the projects screen you are provided with powerful breakdowns of every rail construction project since 2012.',
      route: ['private', 'projects'],
    },
    {
      title: 'Projects',
      content: 'This map for example shows where the latest projects are taking place, allowing you to identify areas of key interest.',
      selector: '.map-container',
    },
    {
      title: 'All Projects',
      content: 'If you want to get into more detail on rail projects, we have created a set of powerful options under \'View All Projects\'',
      selector: '.link-button',
    },
    {
      title: 'All Projects',
      content: 'This is the all projects screen. Here, you can get into more detail using 3 groups of visualisation breakdowns.',
      route: ['private', 'projects', 'all', 'lists'],
    },
    {
      title: 'All Projects',
      content: 'The lists, charts and map tabs allow you to access the data in the way which best suits you.',
      selector: '.toggle-group',
    },
    {
      title: 'Filters',
      content: 'The filters tab in the top right presents you with powerful options to select the data most important to you.',
      selector: '.dashboard-icon-buttons',
    },

    {
      title: 'Rail intelligence starts with the IRJ Pro platform.',
      content: 'With one of the world\'s largest repositories of rail industry data and powerful tools to analyse them with, IRJ Pro is your solution for rail intelligence needs.',
      route: ['private', 'overview'],
    },
  ]
}