import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CONTACT_CONFIG } from './providers/contact_config_provider.token';

@NgModule({
  declarations: [ContactFormComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  exports: [ContactFormComponent],
})
export class ContactModule {
  static forRoot(config: any): ModuleWithProviders<ContactModule> {
    return {
      ngModule: ContactModule,
      providers: [
        {
          provide: CONTACT_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
