<!-- TODO: How to nicely display errors with accepting / declining invitations? -->
<div *ngIf="currentInvitation && !currentInvitationErr">
  <suvo-bi-invite-entry [name]="currentInvitation.name" [isFocused]="true" (actioned)="handleAction($event, currentInvitation.organisationId)"></suvo-bi-invite-entry>
  <mat-divider></mat-divider>
</div>
<div *ngFor="let invitation of invitationsList">
  <suvo-bi-invite-entry [name]="invitation.name" (actioned)="handleAction($event, invitation._id)"></suvo-bi-invite-entry>
  <mat-divider></mat-divider>
</div>
<div *ngIf="!currentInvitation && !invitationsList?.length">
  <p class="no-invitations-message"> No pending invitations </p>
</div>

