import { Injectable, signal } from '@angular/core';
import { ChecklistItem } from '@suvo-bi-core';

@Injectable()
export class PublicScreensChecklistItem implements ChecklistItem {
  readonly title = 'Create public screens';
  readonly routerLink = '/private/pages';

  readonly isChecked = signal(false);

  // TODO: check if tenant has any public dashboards
  readonly skippable = true;
}
