import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagePlaceholderScreenComponent } from '../manage-bi-shared/components/manage-placeholder-screen/manage-placeholder-screen.component';
import { SelectDataSourceScreenComponent } from '../manage-bi-shared/components/select-data-source-screen/select-data-source-screen.component';
import { ManageBiOrganisationsComponent } from './components/manage-bi-organisations/manage-bi-organisations.component';
import { OrganisationProfileComponent } from './components/screen-parts/organisation-profile/organisation-profile.component';
import { OrganisationInvitesViewComponent } from './components/screens/organisation-invites-view/organisation-invites-view.component';
import { OrganisationSelectorViewComponent } from './components/screens/organisation-selector-view/organisation-selector-view.component';
import { OrganisationSettingsViewComponent } from './components/screens/organisation-settings-view/organisation-settings-view.component';
import { OrganisationSubscriptionsViewComponent } from './components/screens/organisation-subscriptions-view/organisation-subscriptions-view.component';
import { OrganisationUsersComponent } from './components/screens/organisation-users/organisation-users.component';
import { OrganisationsContainerComponent } from './components/screens/organisations-container/organisations-container.component';
import { SingleOrganisationViewComponent } from './components/screens/single-organisation-view/single-organisation-view.component';

const routes: Routes = [
  {
    path: '',
    component: ManageBiOrganisationsComponent,
    children: [
      {
        path: '',
        redirectTo: 'organisations',
        pathMatch: 'full',
      },
      {
        path: 'organisations',
        data: {
          breadcrumb: 'Organisations',
          breadcrumbIcon: 'business',
        },
        component: OrganisationsContainerComponent,
        children: [
          {
            path: '',
            redirectTo: 'select',
            pathMatch: 'full',
          },
          {
            path: 'select',
            component: OrganisationSelectorViewComponent,
          },
          {
            path: ':orgRoleId',
            data: {
              breadcrumbDynamic: 'organisation-role-id',
            },
            component: SingleOrganisationViewComponent,
            children: [
              {
                path: '',
                redirectTo: 'profile',
                pathMatch: 'full',
              },
              {
                path: 'profile',
                data: {
                  breadcrumb: 'Profile',
                },
                component: OrganisationProfileComponent,
              },
              {
                path: 'settings',
                data: {
                  breadcrumb: 'Preferences',
                },
                component: OrganisationSettingsViewComponent,
              },
              {
                path: 'users',
                data: {
                  breadcrumb: 'Users & Invitations',
                },
                component: OrganisationUsersComponent,
              },
              {
                path: 'subscription',
                data: {
                  breadcrumb: 'Subscription',
                },
                component: OrganisationSubscriptionsViewComponent,
              },

              // /**
              //  * Preferences Routes
              //  */
              // {
              //   path: 'filters',
              //   data: {
              //     breadcrumb: 'Manage Filters',
              //   },
              //   children: [
              //     {
              //       path: '',
              //       pathMatch: 'full',
              //       component: SelectDataSourceScreenComponent,
              //     },
              //     {
              //       path: ':alias',
              //       component: ManagePlaceholderScreenComponent,
              //     }
              //   ]
              // }
            ],
          },
        ],
      },
      {
        path: 'invites',
        data: {
          breadcrumb: 'Invitations',
          breadcrumbIcon: 'mail_outline',
        },
        component: OrganisationInvitesViewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageBiOrganisationsRoutingModule {}
