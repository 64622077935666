<mat-sidenav-container class="sidenav-container" autosize>
  <!-- Middle Page -->
  <mat-sidenav-content class="middle-page">
    <!-- Topbar -->
    <div fxLayout="row" fxLayoutAlign="flex-end center" class="top-bar">
      <button *ngIf="!noMenuButton" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <!-- Main Content -->
    <div class="outlet-padding">
      <ng-content select="[mainContent]"></ng-content>
      <!-- <ng-container *ngTemplateOutlet="mainContent"></ng-container> -->
    </div>
  </mat-sidenav-content>

  <!-- Left Sidenav -->
  <mat-sidenav
    [opened]="true"
    class="left-sidenav"
    #linksSidenav="matSidenav"
    autoFocus="false"
    fixedInViewport="true"
    [mode]="linksSidenavMode"
  >
    <ng-content select="[leftSidenav]"></ng-content>
    <!-- <ng-container *ngTemplateOutlet="leftSidenav"></ng-container> -->
  </mat-sidenav>
</mat-sidenav-container>
