import {Component, OnInit} from '@angular/core';
import {UsersService} from '@suvo-bi-users';
import {IStat} from 'submodules/SUVO-FRONTEND-FRAMEWORK/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/visualisations/interfaces/stat.interface';

@Component({
  selector: 'app-reports-summary',
  templateUrl: './reports-summary.component.html',
  styleUrls: ['./reports-summary.component.scss']
})
export class ReportsSummaryComponent implements OnInit {
  userStats: Array<IStat> = [
  ];

  constructor(private userService: UsersService) {

  }

  async ngOnInit() {
    let last7daysResult = await this.userService.getLatestMemberRegistrations();

    let totalResult = await this.userService.getTotalMemberRegistrations();

    this.userStats = [{
      label: 'New users in last 7 days',
      value: last7daysResult.last_7_days,
      // percentageChange: 35
    },
    {
      label: 'Total users',
      value: totalResult.total
    }];
  }
}
