import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'lib-upgrade-subscription-dialog',
  templateUrl: './upgrade-subscription-dialog.component.html',
  styleUrls: ['./upgrade-subscription-dialog.component.scss'],
})
export class UpgradeSubscriptionDialogComponent implements OnInit {
  loading = false;
  currentError = null;
  enteringPaymentInfo = false;
  mayTakeAMoment = false;

  productGroups = [];

  selectedOrgCustomer;
  selectedOrganisation;
  selectedOrgCustomerSubs;
  isOrganisationAdmin = false;
  localUser;

  clientSecret: string;
  clientSecretType: string = null;

  seats = new FormControl(1);
  seatsValueSub: Subscription;

  selectedProducts = 0;
  discountPercentage = 0;
  previewSubtotal = 0;
  previewTotal = 0;

  dirtyCheckObj = {
    originalValues: {
      selectedProducts: [],
      seats: 0,
    },
    isDirty: false,
  };

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  constructor(
    private readonly dialogRef: MatDialogRef<UpgradeSubscriptionDialogComponent>,
    private suvoUsersClientLibSettingsService: SuvoUsersClientLibSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private subscriptionService: SubscriptionService,
    private stripeService: StripeService,
  ) {
    this.productGroups = data.productGroups;
    this.selectedOrgCustomer = data.selectedOrgCustomer;
    this.selectedOrganisation = data.selectedOrganisation;
    this.selectedOrgCustomerSubs = data.selectedOrgCustomerSubs;
    this.isOrganisationAdmin =
      this.selectedOrganisation.role === 'ADMIN' ? true : false;
    this.localUser = data.localUser;
  }

  ngOnInit(): void {
    this.seatsValueSub = this.seats.valueChanges.subscribe((value) => {
      if (value < 1) {
        this.seats.setValue(1);
      } else if (value > 9999999) {
        this.seats.setValue(9999999);
      }
      this.reCalculatePrice();
      this.checkIfDirty();
      this.isOrganisationAdmin = true;
    });
    this.reCalculatePrice();
  }

  confirmCardSetup(): void {
    this.loading = true;
    this.currentError = null;
    const paymentData = {
      payment_method: {
        card: this.card.element,
        billing_details: {
          name: `${this.localUser.firstName} ${this.localUser.lastName}`,
          email: `${this.localUser.email}`,
        },
      },
    };
    try {
      this.mayTakeAMoment = true;
      this.stripeService
        .confirmCardSetup(this.clientSecret, paymentData)
        .pipe(take(1))
        .subscribe(
          async (res) => {
            console.log(res);
            if (res?.error) {
              // TODO: How to throw / catch this properly?
              if (
                res?.error?.decline_code === 'insufficient_funds' ||
                res?.error?.code === 'setup_intent_authentication_failure'
              ) {
                this.currentError = {
                  message: `There was an issue processing your payment:\n${res?.error?.message}`,
                };
              } else {
                this.currentError = {
                  message: `We are unable to authenticate your card, please contact support.`,
                };
              }
              this.loading = false;
              this.mayTakeAMoment = false;
              return;
            }
            if (!this.selectedOrgCustomerSubs?.length) {
              // Start new sub
              if (res?.setupIntent?.status === 'succeeded') {
                await this.createSubscription(
                  res.setupIntent.payment_method as string,
                );
              }
            } else {
              // Upgrade from trial
              await this.updateSubscription();
            }
            await this.cleanupPayment();
          },
          async (err) => {
            // HANDLE DECLINE & 3D FAILURE HERE
            console.log(err);
            this.loading = false;
            this.mayTakeAMoment = false;
          },
        );
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.mayTakeAMoment = false;
    }
  }

  async createSubscription(approvedPaymentMethod: string): Promise<void> {
    this.currentError = null;
    this.loading = true;
    try {
      const prices: string[] = [];
      this.productGroups.forEach((productGroup) => {
        if (productGroup.isSelected) {
          prices.push(this.getPaidProduct(productGroup).stripePriceId);
        }
      });
      const createSubResponse =
        await this.subscriptionService.createSubscription(
          prices,
          this.selectedOrganisation.organisationId,
          this.seats.value,
        );
      this.clientSecret = createSubResponse.clientSecret;
      if (!this.clientSecret) {
      } else {
        this.enteringPaymentInfo = true;
        this.clientSecretType = createSubResponse.clientSecretType;
        this.stripeService
          .confirmCardPayment(this.clientSecret, {
            payment_method: approvedPaymentMethod,
          })
          .pipe(take(1))
          .subscribe(
            async (res) => {
              // await this.cleanupPayment();
              if (res.error) {
                this.dialogRef.close({
                  errorMsg:
                    'Your card cannot be processed at this time.\nPlease try a different payment method or try again later.',
                });
              } else {
                this.dialogRef.close({ success: true });
              }
            },
            (err) => {
              // HANDLE DECLINE & 3D FAILURE HERE
              console.log(err);
              this.loading = false;
            },
          );
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
      if (error?.error?.code !== null && error?.error?.code !== undefined) {
        this.currentError = error.error;
      } else {
        this.currentError = {
          message:
            'There was an issue creating your subscription. Please contact support.',
        };
      }
    }
  }

  async updateSubscription(): Promise<void> {
    this.currentError = null;
    this.loading = true;

    try {
      // TODO: Assuming user cannot have more than 1 subscription
      const checkedPrices: string[] = [];
      this.productGroups.forEach((productGroup) => {
        if (productGroup.isSelected) {
          checkedPrices.push(this.getPaidProduct(productGroup).stripePriceId);
        }
      });
      await this.subscriptionService.updateSubscription(
        checkedPrices,
        this.selectedOrganisation.organisationId,
        this.seats.value,
      );
      // await this.cleanupPayment();
      this.dialogRef.close({ success: true });
    } catch (error) {
      console.log(error);
      if (error?.error?.code !== null && error?.error?.code !== undefined) {
        this.currentError = error.error;
      } else {
        this.currentError = {
          message:
            'There was an issue updating your subscription, please try again later.',
        };
      }
    } finally {
      this.loading = false;
    }
  }

  //////// COPIED FROM MANAGE SUB COMPONENT, SHOULD WE EXTEND INSTEAD? ////////////
  async prepareSetupIntent(): Promise<void> {
    this.currentError = null;
    this.loading = true;

    try {
      // TODO: Assuming user cannot have more than 1 subscription
      const checkedPrices: string[] = [];
      this.productGroups.forEach((productGroup) => {
        if (productGroup.isSelected) {
          checkedPrices.push(this.getPaidProduct(productGroup).stripePriceId);
        }
      });
      const setupIntentRes = await this.subscriptionService.prepareSetupIntent(
        this.selectedOrganisation.organisationId,
      );
      this.clientSecret = setupIntentRes.client_secret;
      if (this.clientSecret) {
        this.enteringPaymentInfo = true;
        this.clientSecretType = 'SETUP';
      } else {
        console.log('NO CLIENT SECRET FOUND');
      }
    } catch (error) {
      console.log(error);
      if (error?.error?.code !== null && error?.error?.code !== undefined) {
        this.currentError = error.error;
      } else {
        this.currentError = {
          message:
            'There was an issue updating your subscription, please contact support.',
        };
      }
    } finally {
      this.loading = false;
    }
  }

  async cleanupPayment(): Promise<void> {
    this.clientSecret = null;
    this.clientSecretType = null;
    this.enteringPaymentInfo = false;
  }

  productSelectedCheck(): boolean {
    let oneBoxTicked = false;
    this.productGroups.forEach((productGroup) => {
      if (productGroup.isSelected) {
        oneBoxTicked = true;
      }
    });
    return oneBoxTicked;
  }

  checkIfDirty(): void {
    let tempDirty = false;
    this.dirtyCheckObj.originalValues.selectedProducts.forEach(
      (originalValue, index) => {
        if (originalValue !== this.productGroups[index].fullSelected) {
          tempDirty = true;
        }
      },
    );

    if (this.dirtyCheckObj.originalValues.seats !== this.seats.value) {
      tempDirty = true;
    }
    this.dirtyCheckObj.isDirty = tempDirty;
  }

  reCalculatePrice(): void {
    let tempPrice = 0;
    this.selectedProducts = 0;
    this.discountPercentage = 0;
    this.productGroups.forEach((productGroup) => {
      if (productGroup.isSelected) {
        this.selectedProducts++;
        tempPrice += this.getPaidProduct(productGroup).price * this.seats.value;
      }
    });
    this.previewSubtotal = tempPrice;
    switch (this.selectedProducts) {
      case 2:
        this.discountPercentage = 5;
        break;
      case 3:
        this.discountPercentage = 7;
        break;
    }
  }

  calcProductTiersPrice(tiers, startingSeats): number {
    let seatsLeft = startingSeats;
    let sum = 0;
    let previousUpTo = 0;

    for (const tier of tiers) {
      if (tier.up_to == null) {
        sum += tier.unit_amount * seatsLeft;
      } else if (tier.up_to < startingSeats) {
        const amount = tier.up_to - previousUpTo;
        sum += tier.unit_amount * amount;
        seatsLeft -= amount;
      } else {
        sum += tier.unit_amount * seatsLeft;
        break;
      }
      previousUpTo = tier.up_to;
    }
    return sum;
  }

  getPaidProduct(productGroup) {
    return productGroup.products.find((tier) => !tier.isAutoTrial);
  }
}
