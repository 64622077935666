import { ITendersCheckboxItemList } from '../interfaces/tenders-info/tenders-checkbox-item-list.interface';

export const tendersProducts: ITendersCheckboxItemList[] = [
  {
    name: 'Track',
    value: '29813120',
    checked: false,
    alsoFilterByChildren: true,
    items: [
      { name: 'Rails and fastenings', value: '2981319', checked: false },
      {
        name: 'Points, turnouts, switches, and crossings',
        value: '2981318',
        checked: false,
      },
      { name: 'Sleepers/Ties', value: '2981314', checked: false },
      { name: 'Ballastless track', value: '29813121', checked: false },
      {
        name: 'Track construction materials',
        value: '2981310',
        checked: false,
      },
      { name: 'Points heating system', value: '298134', checked: false },
      {
        name: 'Track maintenance equipment (includes lining, levelling, and tamping machines; ballast regulators and cleaners)',
        value: '298135',
        checked: false,
      },
      {
        name: 'Track renewal/laying machines and systems',
        value: '29525',
        checked: false,
      },
      {
        name: 'Track-recording cars and measuring systems',
        value: '352211',
        checked: false,
      },
      { name: 'Railway construction works', value: '452341', checked: false },
      {
        name: 'Construction work for buildings relating to railway transport',
        value: '4521332',
        checked: false,
      },
      {
        name: 'Railway-track maintenance services',
        value: '50225',
        checked: false,
      },
      {
        name: 'Railway-track inspection services',
        value: '74313147',
        checked: false,
      },
      {
        name: 'Railway engineering & consultancy services',
        value: '74231123',
        checked: false,
      },
    ],
  },
  {
    name: 'Rolling stock',
    value: '35220',
    checked: false,
    alsoFilterByChildren: true,
    items: [
      { name: 'Passenger cars/coaches', value: '352222', checked: false },
      {
        name: 'Trams and Light Rail Vehicles (LRVs)',
        value: '35222100',
        checked: false,
      },
      { name: 'Freight cars/wagons', value: '35223', checked: false },
      { name: 'Brakes and braking systems', value: '352315', checked: false },
      { name: 'Suspension systems', value: '3523154', checked: false },
      { name: 'Wheels, axles and wheelsets', value: '352314', checked: false },
      { name: 'Seats and upholstery', value: '352313', checked: false },
      {
        name: 'Doors, door control systems, and actuators',
        value: '352317',
        checked: false,
      },
      { name: 'Couplers and drawgear', value: '352312', checked: false },
      {
        name: 'Air-conditioning, heating and ventilation',
        value: '2923127',
        checked: false,
      },
      { name: 'Train lighting', value: '3152726', checked: false },
      {
        name: 'Train toilets and waste management systems',
        value: '35233',
        checked: false,
      },
      { name: 'Inter-car gangways', value: '352311', checked: false },
      { name: 'Train windows', value: '352318', checked: false },
      {
        name: 'Interior panels, flooring and fittings',
        value: '352319',
        checked: false,
      },
      {
        name: 'Repair and maintenance services of rolling stock',
        value: '50222,50224',
        checked: false,
      },
    ],
  },
  {
    name: 'Locomotives',
    value: '35210',
    checked: false,
    alsoFilterByChildren: true,
    items: [
      { name: 'Diesel locomotives', value: '35213', checked: false },
      { name: 'Electric locomotives', value: '35211', checked: false },
      { name: 'Diesel engines', value: '352131', checked: false },
      {
        name: 'Electric traction and control systems',
        value: '2981333',
        checked: false,
      },
      {
        name: 'Hydraulic transmission/drive and control systems',
        value: '352321',
        checked: false,
      },
      {
        name: 'Train auxiliary equipment and systems',
        value: '298133',
        checked: false,
      },
      {
        name: 'Locomotive/train cab equipment',
        value: '352220',
        checked: false,
      },
      {
        name: 'Repair and maintenance services of locomotives',
        value: '50221,50223',
        checked: false,
      },
    ],
  },
  {
    name: 'Signalling, Communications and IT',
    value: '2981320',
    checked: false,
    alsoFilterByChildren: true,
    items: [
      {
        name: 'ERTMS, ETCS and GSM-R systems and equipment',
        value: '2981332',
        checked: false,
      },
      { name: 'CBTC systems', value: '2981331', checked: false },
      { name: 'Signalling cables', value: '31354', checked: false },
      { name: 'Train-monitoring system', value: '2981330', checked: false },
      { name: 'Train Management System', value: '722173', checked: false },
      { name: 'Control equipment', value: '35232', checked: false },
      { name: 'Railway SCADA systems', value: '2986148', checked: false },
      {
        name: 'Level/grade crossing systems and equipment',
        value: '29813220',
        checked: false,
      },
      { name: 'Telecommunications and IT', value: '2981321', checked: false },
    ],
  },
  {
    name: 'Other',
    value: null,
    checked: false,
    alsoFilterByChildren: true,
    items: [
      {
        name: 'Electrical installations for railways',
        value: '45234115',
        checked: false,
      },
      { name: 'Traction power supply systems', value: '31333', checked: false },
      { name: 'Train simulators', value: '316414', checked: false },
      {
        name: 'Parts for railway or tramway locomotives or rolling stock',
        value: '3523',
        checked: false,
      },
      { name: 'Freight Rail', value: '60121', checked: false },
      { name: 'Passenger Rail', value: '60111', checked: false },
      {
        name: 'Public transport services by tram',
        value: '601121',
        checked: false,
      },
      {
        name: 'Passenger information and entertainment systems, public address and CCTV',
        value: '302711,3234246',
        valueIsList: true,
        checked: false,
      },
      { name: 'Fare collection equipment', value: '301444', checked: false },
      { name: 'Platform screen doors', value: '3523171', checked: false },
      {
        name: 'Support services for railway transport',
        value: '6321',
        checked: false,
      },
      {
        name: 'Technical services for Rail locomotive and rolling stock design',
        value: '742113',
        checked: false,
      },
      {
        name: 'Train/depot maintenance services and equipment',
        value: '352210',
        checked: false,
      },
      { name: 'Operations & Maintenance', value: '50226000', checked: false },
      {
        name: 'Hire/leasing of railway vehicles',
        value: '712112',
        checked: false,
      },
      { name: 'Hire/leasing of trains', value: '7121121', checked: false },
      { name: 'Hire/leasing of locomotives', value: '7121122', checked: false },
    ],
  },
];
