import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { VerifyService } from '@suvo-bi-users';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  @Output() loginStatusChange: EventEmitter<void> = new EventEmitter<void>();

  loginComponentOptions = {
    titleText: 'Log In',
    showRegister: false,
    skipVerifyStep: true,
    pwResetFirebaseTenantId: environment.platformFirebaseTenantID,
  };

  message: string;

  constructor(private readonly verifyService: VerifyService) {}

  async onLoginStatusChange(status) {
    if (status === 'USER_UNVERIFIED') {
      await this.verifyService.sendVerificationEmail();
      this.message = 'A verification link has been sent to your email address.';
    }
    this.loginStatusChange.next(status);
  }
}
