import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonComponentsModule } from '@suvo-bi-core';
import { SharedModule } from '../../shared/shared.module';
import { ToggleGroupNavigationComponent } from './components/toggle-group-navigation/toggle-group-navigation.component';

@NgModule({
  declarations: [
    ToggleGroupNavigationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommonComponentsModule,
  ],
  exports: [
    ToggleGroupNavigationComponent,
  ]
})
export class ToggleGroupNavigationModule { }
