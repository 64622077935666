<mat-nav-list class="nav-list full-width" fxLayout="column" fxLayoutAlign="flex-start">

  <!-- No Filters -->
  <ng-container *ngFor="let link of links; let last = last">
    <suvo-bi-route-selector-item [link]="link" [showIcons]="showIcons" [borderRadius]="borderRadius" [theme]="theme">
    </suvo-bi-route-selector-item>
    <mat-divider *ngIf="!last"></mat-divider>
  </ng-container>


</mat-nav-list>