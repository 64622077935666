import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MediaService } from '../../services/media.service';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-upload-control',
  templateUrl: './upload.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadControl,
      multi: true
    }
  ],
})
export class UploadControl {
  @Input()
  title: string = 'Not set'
  fileToUpload: File | null = null;
  publicUrl: string;
  isLoading = false;

  onChange = (value) => {};
  onTouched = () => {};

  constructor(
    private mediaService: MediaService
  ) {}

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files: FileList = fileInput.files as FileList;
    this.fileToUpload = files.item(0) as File;
    this.upload();
  }

  handleUrlInput(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    this.onChange(fileInput.value)
    console.log(fileInput.value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue(publicUrl: string) {
    this.publicUrl = publicUrl;
  }

  upload() {
    if (!this.fileToUpload) {
      return;
    }
    this.isLoading=true
    this.mediaService.postFile(this.fileToUpload)
      .pipe(delay(5000)) // TODO: @victor-shelepen Remove later.
      .subscribe((response) => {
        this.publicUrl = response.publicUrl;
        this.onChange(this.publicUrl);
      }, null,() => this.isLoading = false);
  }

  setLoadingStatus() {

  }
}
