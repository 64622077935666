import { Injectable } from '@angular/core';

interface ITempTenantConfig {
  showOrganisationFunctionality: boolean;
}

@Injectable()
export class SuvoUsersClientLibSettingsService {

  usersServiceApi: string;
  dataServiceApi: string;
  subscriptionServiceApi: string;
  showRegisterDoBField: boolean;
  apiKey: string;
  authDomain: string;
  title: string;
  targetTenant: string;
  platformId: string;

  // TODO: Make dynamic eventually
  tempTenantConfig: ITempTenantConfig;
  constructor(public config: any) { }

}
