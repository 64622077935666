import { IBiLink } from "../../../shared/interfaces/bi-link.interface";

export const MANAGE_ORGANISATIONS_LINK: IBiLink[] = [

  {
    displayName: 'Profile',
    displayIcon: 'business',
    route: 'profile',
  },

  {
    displayName: 'Filters',
    displayIcon: 'filter_alt',
    route: 'filters',
  },

  /**
   *    Are we doing these for organisations?
   */

  // {
  //   displayName: 'Email Alerts',
  //   displayIcon: 'email',
  //   route: 'emails',
  // },
  // {
  //   displayName: 'Favourites',
  //   displayIcon: 'star_border',
  //   route: 'favourites',
  // },
  {
    displayName: 'Users and Invitations',
    displayIcon: 'people',
    route: 'users',
  },
  {
    displayName: 'Subscription',
    displayIcon: 'card_membership',
    route: 'subscription',
  },
]