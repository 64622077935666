import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepComponent } from '../../../classes/abstract-step.component';
import { IStepCheckbox } from '../../../interfaces/step-checkbox.interface';

@Component({
  selector: 'app-requirements-step',
  templateUrl: './requirements-step.component.html',
  styleUrls: ['../../../styles/step.styles.scss', './requirements-step.component.scss']
})
export class RequirementsStepComponent extends StepComponent {
  @Input() public readonly parentFormGroup: FormGroup;
  @Input() public readonly controlName: string;

  public readonly formGroup = new FormGroup({});

  public readonly checkboxes: IStepCheckbox[] = [
    {
      name: 'monetiseData',
      label: 'To monetise my data.'
    },
    {
      name: 'analyseData',
      label: 'To analyse my data.'
    },
    {
      name: 'makeAPI',
      label: 'To create an API for my data.'
    },
    {
      name: 'reportsOrPresentation',
      label: 'To create reports or present my data.'
    }
  ];
}
