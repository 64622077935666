import { Component, Input } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SessionService } from '../../../../../shared/services/session.service';
import { IDataSourceDefinitionProperty } from '../../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { IDataSourceItem } from '../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';
import { ITableDataSource } from '../../../../../shared/interfaces/table/table-data-source.interface';
import { ITablePagination } from '../../../../../shared/interfaces/table/table-pagination.interface';
import { ITableSort } from '../../../../../shared/interfaces/table/table-sort.interface';
import { FiltersChannel } from '../../../../filters/models/filters-channel.model';
import { TableComponent } from '../../../../tables/components/table/table.component';
import { NewDataSourceDefinitionsService } from '../../../data-source-definitions/services/new-data-source-definitions.service';
import { IChipStyles } from '../../interfaces/chip-styles.interface';
import { IItemsTableOptions } from '../../interfaces/items-table-options.interface';
import { DataSourceItemChipsService } from '../../services/data-source-item-chips.service';
import { NewDataSourceItemsService } from '../../services/new-data-source-items.service';

@Component({
  selector: 'suvo-bi-data-source-items-table',
  templateUrl: './data-source-items-table.component.html',
  styleUrls: ['./data-source-items-table.component.scss'],
})
export class DataSourceItemsTableComponent extends TableComponent<
  IDataSourceItem,
  IDataSourceDefinitionProperty
> {
  @Input() dataSourceAlias: string;
  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  public pageSize = this.options?.defaultPageSize || 15;
  public canSort = true;

  // Skeletal loading - this default will be overridden if present on definition.tableOptions when it is loaded
  public columnWidths = [100];

  constructor(
    protected readonly dataSourceDefinitionsService: NewDataSourceDefinitionsService,
    protected readonly dataSourceItemsService: NewDataSourceItemsService,
    protected readonly dataSourceItemChipsService: DataSourceItemChipsService,
    protected readonly sessionService: SessionService
  ) {
    super(sessionService);
  }

  async ngOnInit() {
    super.ngOnInit();

    const definition =
      await this.dataSourceDefinitionsService.getOneLocalByAlias(
        this.dataSourceAlias
      );

    //TODO : Improve this logic - ensure canSort  set on all definitions
    if (
      definition?.tableOptions?.canSort === false ||
      this.options?.canSort === false
    ) {
      this.canSort = false;
    }
  }

  async getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string
  ): Promise<ITableDataSource<IDataSourceItem>> {
    return await this.dataSourceItemsService.getPaginated(
      this.dataSourceAlias,
      {
        filters,
        sort,
        pagination,
        search,
        properties: this.propertyColumnNames,
      }
    );
  }

  public getChipStyles(
    item: IDataSourceItem,
    property: IDataSourceDefinitionProperty
  ): IChipStyles {
    return this.dataSourceItemChipsService.getChipStyles(item, property);
  }

  protected async loadColumns(): Promise<void> {
    const definition =
      await this.dataSourceDefinitionsService.getOneLocalByAlias(
        this.dataSourceAlias
      );

    if (definition?.tableOptions?.columnWidths) {
      this.columnWidths = definition.tableOptions.columnWidths;
    }

    if (this.options.columns) {
      this.propertyColumns = this.options.columns
        .map((column) =>
          definition.properties.find((property) => property.name === column.key)
        )
        .filter((property) => !!property);
    } else {
      this.propertyColumns = definition.properties.slice(0, 7);
    }

    this.propertyColumnNames = this.propertyColumns.map(
      (property) => property.name
    );
  }

  protected getRowRouterLink(item: IDataSourceItem): string[] {
    return [...(this.options.itemRoutePrefixes || []), item._id.toString()];
  }
}
