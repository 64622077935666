import { Component, Input } from '@angular/core';
import { CurrentTenantService } from '@suvo-bi-users';
import { ITextRowsTileDefinition } from '../../../../interfaces/tiles/definitions/text-rows-tile-definition.interface';

@Component({
  selector: 'suvo-bi-text-rows-tile',
  templateUrl: './text-rows-tile.component.html',
  styleUrls: ['./text-rows-tile.component.scss'],
})
export class TextRowsTileComponent {
  @Input() public tileDefinition: ITextRowsTileDefinition;

  constructor(private readonly tenantService: CurrentTenantService) {}

  public getImgPath(path: string): string {
    return `${this.tenantService.currentTenantData.primaryWebUrl}/${path}`;
  }
}
