import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'suvo-bi-general-prompt-save-dialog',
  templateUrl: './general-prompt-save-dialog.component.html',
  styleUrls: ['./general-prompt-save-dialog.component.scss'],
})
export class GeneralPromptSaveDialogComponent {
  constructor() {}
}
