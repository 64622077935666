<div class="animated card" fxLayout="column" fxLayoutAlign="flex-start stretch">

  <!-- <mat-progress-bar *ngIf="currentProgress?.currentIndex != 0" class="progress-bar" mode="determinate"
    [value]="currentProgress?.currentIndex / (currentProgress?.totalSteps - 1) * 100">
  </mat-progress-bar> -->

  <div class="extra-padding">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 class="label">{{ currentProgress?.step.title }}</h4>
      <button mat-icon-button (click)="navigateEnd()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <p>{{ currentProgress?.step.content }}</p>
    </div>

    <div fxLayout="row" fxLayoutAlign="flex-end center">

      <!-- Step -->
      <p class="label">{{ currentProgress?.currentIndex + 1 }} / {{ currentProgress?.totalSteps }}</p>

      <!-- Previous -->
      <button mat-icon-button (click)="navigatePrevious()" [disabled]="currentProgress?.currentIndex == 0">
        <mat-icon>navigate_before</mat-icon>
      </button>

      <!-- Next/Finish -->
      <button mat-icon-button (click)="navigateNext()">
        <ng-container *ngIf="currentProgress?.currentIndex < currentProgress?.totalSteps - 1; else finish">
          <mat-icon>navigate_next</mat-icon>
        </ng-container>

        <ng-template #finish>
          <mat-icon>done</mat-icon>
        </ng-template>
      </button>

    </div>
  </div>


</div>