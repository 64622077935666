import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSidenavWidgetComponent } from './components/account-sidenav-widget/account-sidenav-widget.component';
import { ListNavigationModule } from '../list-navigation/list-navigation.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactModule } from '../contact/contact.module';
import { CONTACT_CONFIG } from '../contact/providers/contact_config_provider.token';
import { IContactConfig } from '../contact/interfaces/contact-config.interface';

@NgModule({
  declarations: [AccountSidenavWidgetComponent],
  imports: [
    CommonModule,
    SharedModule,
    ListNavigationModule,
    ContactModule.forRoot({ contactEndpoint: '' }),
  ],
  exports: [AccountSidenavWidgetComponent],
})
export class AccountSidenavWidgetModule {
  static forRoot(config: IContactConfig): ModuleWithProviders<AccountSidenavWidgetModule> {
    return {
      ngModule: AccountSidenavWidgetModule,
      providers: [
        {
          provide: CONTACT_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
