<div class="container" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
  <div class="text-container" fxLayout="column" fxLayoutAlign="center center">
    <span class="title">
      This dashboard requires a subscription that you do not have.
    </span>

    <div>
      <span class="data-source">
        {{ dataSource | titlecase }}
      </span>
    </div>

    <a class="link" routerLink="/private/my-account/subscription">
      My Subscriptions
    </a>
  </div>
</div>
