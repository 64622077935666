import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DashboardNoSubscriptionComponent } from './components/dashboards/dashboard-no-subscription/dashboard-no-subscription.component';
import { DashboardComponent } from './components/dashboards/dashboard/dashboard.component';
import { ExportDialogComponent } from './components/dialogs/export-dialog/export-dialog.component';
import { BannerTextTileComponent } from './components/tiles/banners/banner-text-tile/banner-text-tile.component';
import { BannerTileComponent } from './components/tiles/banners/banner-tile/banner-tile.component';
import { ChartTileComponent } from './components/tiles/charts/chart-tile/chart-tile.component';
import { ChartExportsComponent } from './components/tiles/charts/common/chart-exports/chart-exports.component';
import { ChartLegendComponent } from './components/tiles/charts/common/chart-legend/chart-legend.component';
import { ChartTitleComponent } from './components/tiles/charts/common/chart-title/chart-title.component';
import { SkeletonBarsComponent } from './components/tiles/charts/skeleton/skeleton-bars/skeleton-bars.component';
import { SkeletonLegendComponent } from './components/tiles/charts/skeleton/skeleton-legend/skeleton-legend.component';
import { SkeletonPieComponent } from './components/tiles/charts/skeleton/skeleton-pie/skeleton-pie.component';
import { AdvancedExportTileComponent } from './components/tiles/exports/advanced-export-tile/advanced-export-tile.component';
import { ExportButtonTileComponent } from './components/tiles/exports/export-button-tile/export-button-tile.component';
import { LinkBannerTileComponent } from './components/tiles/links/link-banner-tile/link-banner-tile.component';
import { LinkButtonTileComponent } from './components/tiles/links/link-button-tile/link-button-tile.component';
import { MapTileComponent } from './components/tiles/maps/map-tile/map-tile.component';
import { FiltersTileComponent } from './components/tiles/filters/filters-tile.component';
import { FiltersInfoTileComponent } from './components/tiles/meta/filters-info-tile/filters-info-tile.component';
import { InvalidTileComponent } from './components/tiles/meta/invalid-tile/invalid-tile.component';
import { NewTileComponent } from './components/tiles/meta/new-tile/new-tile.component';
import { NoSubscriptionComponent } from './components/tiles/no-subscription/no-subscription.component';
import { TableTileComponent } from './components/tiles/tables/table-tile/table-tile.component';
import { TileWrapperComponent } from './components/tiles/tile-wrapper/tile-wrapper.component';
import { DashboardService } from './services/dashboards.service';
import { ClusterClickPreviewDialogComponent } from './components/tiles/maps/cluster-click-preview-dialog/cluster-click-preview-dialog.component';
import { SkeletonMapComponent } from './components/tiles/maps/skeleton/skeleton-map/skeleton-map.component';
import { ItemLookupTileComponent } from './components/tiles/item-lookup-tile/item-lookup-tile.component';
import { SubscriptionModule } from '@suvo-bi-users';
import { ReactiveFormsModule } from '@angular/forms';
import { TileFiltersSelectComponent } from './components/tiles/tile-widgets/tile-filters-select/tile-filters-select.component';
import { SharedModule } from '../../shared/shared.module';
import { FiltersModule } from '../filters/filters.module';
import { SmartGridModule } from '../smart-grid/smart-grid.module';
import { ToggleGroupNavigationModule } from '../toggle-group-navigation/toggle-group-navigation.module';
import { DataSourceModule } from '../data-source/data-source.module';
import { DataSourceItemsModule } from '../data-source/data-source-items/modules/data-source-items.module';
import { DashboardSettingsService } from './services/dashboards-settings.service';
import { DeleteOptionChoiceDialogComponent } from './components/dialogs/delete-option-choice/delete-option-choice.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TileCreatorModule } from '../tile-creator/tile-creator.module';
import { DeleteConfirmationDialogComponent } from './components/dialogs/delete-confirmation/delete-confirmation.component';
import { HeroBannerTileComponent } from './components/tiles/public/hero-banner-tile/hero-banner-tile.component';
import { IconColumnsTileComponent } from './components/tiles/public/icon-columns-tile/icon-columns-tile.component';
import { TextColumnsTileComponent } from './components/tiles/public/text-columns-tile/text-columns-tile.component';
import { PresentationTileComponent } from './components/tiles/public/presentation-tile/presentation-tile.component';
import { TextRowsTileComponent } from './components/tiles/public/text-rows-tile/text-rows-tile.component';
import { ContactsTileComponent } from './components/tiles/public/contacts-tile/contacts-tile.component';
import { HeroBannerV2TileComponent } from './components/tiles/public/hero-banner-v2-tile/hero-banner-v2-tile.component';
import { StatsTileComponent } from './components/tiles/stats/stats-tile/stats-tile.component';
import { StatComponent } from './components/stat/components/stat.component';
import { HeroBannerV3TileComponent } from './components/tiles/public/hero-banner-v3-tile/hero-banner-v3-tile.component';

@NgModule({
  declarations: [
    DashboardComponent,
    TileWrapperComponent,
    InvalidTileComponent,
    NewTileComponent,
    BannerTileComponent,
    BannerTextTileComponent,
    ChartTileComponent,
    LinkBannerTileComponent,
    LinkButtonTileComponent,
    MapTileComponent,
    FiltersInfoTileComponent,
    SkeletonMapComponent,
    ClusterClickPreviewDialogComponent,
    TableTileComponent,
    ChartTitleComponent,
    ChartExportsComponent,
    ChartLegendComponent,
    SkeletonBarsComponent,
    SkeletonLegendComponent,
    SkeletonPieComponent,
    FiltersTileComponent,
    NoSubscriptionComponent,
    DashboardNoSubscriptionComponent,
    ExportButtonTileComponent,
    ExportDialogComponent,
    AdvancedExportTileComponent,
    TableTileComponent,
    ItemLookupTileComponent,
    TileFiltersSelectComponent,
    DeleteOptionChoiceDialogComponent,
    DeleteConfirmationDialogComponent,
    HeroBannerTileComponent,
    HeroBannerV2TileComponent,
    HeroBannerV3TileComponent,
    IconColumnsTileComponent,
    TextColumnsTileComponent,
    PresentationTileComponent,
    TextRowsTileComponent,
    ContactsTileComponent,
    StatsTileComponent,
    StatComponent,
  ],
  providers: [DashboardService, DashboardSettingsService],
  imports: [
    CommonModule,
    SharedModule,
    FiltersModule,
    SmartGridModule,
    NgxChartsModule,
    NgxSkeletonLoaderModule,
    ToggleGroupNavigationModule,
    DataSourceModule,
    DataSourceItemsModule,
    SubscriptionModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    TileCreatorModule,
  ],
  exports: [DashboardComponent, BannerTileComponent, TileWrapperComponent],
})
export class DashboardsModule {
  static forRoot(config: any): ModuleWithProviders<DashboardsModule> {
    const dashboardSettings = new DashboardSettingsService(config);

    return {
      ngModule: DashboardsModule,
      providers: [
        {
          provide: DashboardSettingsService,
          useValue: dashboardSettings.config,
        },
      ],
    };
  }
}
