<div class="padding">
  <div class="org-select-row" *ngIf="selectedOrganisation?.value?.role === 'ADMIN'">
    <div class="margin">&nbsp;</div>
    <button mat-raised-button color="primary" *ngIf="selectedOrganisation?.value?.role === 'ADMIN'"
      [disabled]="!selectedOrganisation.value?.organisationId" (click)="openInvitationDialog()">Invite Member</button>
  </div>
  <div [ngSwitch]="memberListLoaded">
    <ng-container *ngSwitchCase="'loaded'">
      <div class="member-list-row">
        <div>
          <p><strong>Name</strong></p>
        </div>
        <div>
          <p><strong>Role</strong></p>
        </div>
      </div>
      <div *ngFor="let member of selectedOrganisationMembers" class="member-list-row">
        <div>
          <p>{{member.publicUserProfile.firstName}} {{member.publicUserProfile.lastName}}</p>
        </div>
        <div>
          <p>{{member.role}}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
      <suvo-core-loading-spinner message="Loading Organisation Members"></suvo-core-loading-spinner>
    </ng-container>
  </div>
</div>