import { TitleCasePipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@suvo-bi-core';
import { CurrentTenantService } from '@suvo-bi-users';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatDivider, MatIconModule, MatMenuModule, RouterModule, TitleCasePipe],
  selector: 'app-account-widget',
  template: `
    <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>person</mat-icon>
      {{ displayName() }}
    </button>

    <mat-menu #menu="matMenu">
      <div mat-menu-item disabled style="text-align: center; opacity: 80%">
        {{ plan() | titlecase }} Plan
      </div>
      <mat-divider />
      <a mat-menu-item routerLink="/private/account">
        <mat-icon color="primary">person</mat-icon>
        My Account
      </a>
      <button mat-menu-item (click)="logout()">
        <mat-icon color="primary">logout</mat-icon>
        Logout
      </button>
    </mat-menu>
  `,
})
export class AccountWidgetSelectorComponent {
  private readonly authService = inject(AuthService);
  private readonly fireAuth = inject(AngularFireAuth);
  private readonly router = inject(Router);

  readonly displayName = computed(() => this.authService.currentUser()?.displayName);
  readonly plan = inject(CurrentTenantService).currentTenantPlan;

  async logout() {
    await this.fireAuth.signOut();
    await this.router.navigate(['/']);
  }
}
