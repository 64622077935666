<mat-dialog-content>
  <div fxFlex="1 1" fxLayoutAlign="center center" *ngIf="!resolvedData?.data">
    <suvo-core-loading-spinner message="Loading Cluster..."></suvo-core-loading-spinner>
  </div>
  <div *ngIf="resolvedData?.data" class="loaded-data-container">
    <div class="title-container" fxLayout="row" fxLayoutAlign="space-between center">
      <h4>{{ data.alias.charAt(0).toUpperCase() + data.alias.slice(1) }} in this Cluster</h4>
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div>
      <div
        *ngFor="let dataSourceItem of resolvedData.data"
        fxLayout="row"
        class="item-row"
        (click)="itemClicked(dataSourceItem)"
      >
        <p>{{ dataSourceItem.data.index }} - {{ dataSourceItem.data.title }}</p>
      </div>
    </div>
  </div>
</mat-dialog-content>
