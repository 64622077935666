import { Component, Input } from '@angular/core';

@Component({
  selector: 'suvo-bi-dashboard-no-subscription',
  templateUrl: './dashboard-no-subscription.component.html',
  styleUrls: ['./dashboard-no-subscription.component.scss']
})
export class DashboardNoSubscriptionComponent {
  @Input() public dataSource = 'Subscription'
}
