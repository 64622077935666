import { Component } from '@angular/core';

@Component({
  selector: 'suvo-bi-skeleton-grid',
  templateUrl: './skeleton-grid.component.html',
  styleUrls: ['./skeleton-grid.component.scss']
})
export class SkeletonGridComponent {
  skeletonLayout: string[][] = [
    ['a', 'a'],
    ['a'],
    ['a', 'a'],
  ];
}
