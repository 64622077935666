import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface IFileUploadResponse {
  publicUrl: string;
}

@Injectable()
export class MediaService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  postFile(fileToUpload: File) {
    const endpoint = 'http://localhost:3003/api/v1/upload';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const yourHeadersConfig = {
      'Platform-ID': 'suvo_bi',
    };
    return this.httpClient
      .post<IFileUploadResponse>(endpoint, formData, { headers: yourHeadersConfig });
  }
}