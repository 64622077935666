import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FilterOptionComponent } from '../filter-option/filter-option.component';

@Component({
  selector: 'suvo-bi-radio-filter-option',
  templateUrl: './radio-filter-option.component.html',
  styleUrls: ['./radio-filter-option.component.scss'],
})
export class RadioFilterOptionComponent extends FilterOptionComponent implements OnInit {
  formGroup: FormGroup;

  constructor() {
    super();

    this.formGroup = new FormGroup({
      radio: new FormControl(),
    });
    this.hookForm(this.formGroup);
  }

  getFilterRules(option): FilterRule[] {
    if (option?.radio?.value) {
      return [
        {
          key: this.option.filterPropertyKeys[0],
          eq: option?.radio?.value,
        },
      ];
    } else if (option?.radio?.extraFilterRules) {
      return option?.radio?.extraFilterRules;
    }
    return [];
  }

  patchRules(rules: FilterRule[]) {
    if (this.option.filterMethodConfig.usingExtraFilterRules) {
      let rulesString = JSON.stringify(rules);

      for (let radioOption of this.option.filterMethodConfig.options) {
        let optionRulesString = JSON.stringify(radioOption.extraFilterRules);

        if (optionRulesString == rulesString) {
          this.formGroup.patchValue({ radio: radioOption }, { emitEvent: false });
        }
      }
    } else {
      const property = rules[0] as IFilterProperty;
      const patchValue = property?.eq;

      for (let radioOption of this.option.filterMethodConfig.options) {
        if (patchValue == radioOption.value) {
          this.formGroup.patchValue({ radio: radioOption }, { emitEvent: false });
        }
      }
    }
  }
}
