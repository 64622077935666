<div class="outer-container">
  <div class="card table-card" *ngIf="allowed; else notAllowed">
    <div class="restrictions-header" *ngIf="hasRestrictions">
      <suvo-bi-limited-data-banner
        [isTrial]="isTrialing"
        [featureShortCode]="'FINDANDPAGINATE'"
        [tags]="[tileDefinition.recipe?.dataSource]"
      ></suvo-bi-limited-data-banner>
    </div>

    <h4 class="margin title" *ngIf="tileDefinition.title">
      {{ tileDefinition.title }}
    </h4>

    <suvo-bi-data-source-items-table
      *ngIf="options"
      [dataSourceAlias]="dataSourceAlias"
      [filtersChannel]="filtersChannel"
      [options]="options"
      [tableAlias]="tableAlias"
      [sortPrefix]="'data'"
    >
      <div class="no-data" noDataContent fxLayout="column" fxLayoutAlign="center center">
        <suvo-core-icon-message title="No Data" message="No data matches these filters.">
        </suvo-core-icon-message>
      </div>
    </suvo-bi-data-source-items-table>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="export-button-container"
      [ngClass]="{ 'shift-up': tileDefinition.showPageControls }"
    >
      <suvo-bi-export-with-filters
        *ngIf="showExportButton"
        [filtersChannel]="filtersChannel"
        [dataSourceAlias]="filtersChannel?.channelName"
        [disabled]="exportDisabled"
      ></suvo-bi-export-with-filters>
    </div>
  </div>

  <ng-template #notAllowed>
    <div fxFlex="1 1" fxLayoutAlign="space-between center" class="card not-allowed-message">
      <div>
        <b>This feature is available for paid subscribers.</b>
      </div>

      <button color="primary" mat-stroked-button routerLink="/private/my-account/subscription">
        View upgrade options
      </button>
    </div>
  </ng-template>
</div>
