import { Component, Input } from '@angular/core';

@Component({
  selector: 'suvo-bi-timestamps-toolbar',
  templateUrl: './timestamps-toolbar.component.html',
  styleUrls: ['./timestamps-toolbar.component.scss']
})
export class TimestampsToolbarComponent {
  @Input() public mongoObject: any;
}
