<div class="card" [style.background]="tileDefinition.background">
  <div class="content-container">
    <div
      fxFlex="1 1 100%"
      fxLayout="column"
      fxLayoutAlign="flex-start center"
      class="overview-section"
    >
      <div
        fxFlex="1 0"
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="center center"
        *ngFor="let textRow of tileDefinition.textRows; let odd = odd"
        class="feature-section"
      >
        <div class="overview-box" fxFlex="1 1 100%" fxFlexOrder="1" fxFlexOrder.xs="2">
          <p
            [style.color]="textRow.headingFontColor"
            [style.fontSize]="textRow.headingFontSize | pxSuffix"
          >
            {{ textRow.heading }}
          </p>
          <p
            class="feature-description"
            [style.color]="textRow.textFontColor"
            [style.fontSize]="textRow.textFontSize | pxSuffix"
          >
            {{ textRow.text }}
          </p>
          <button
            mat-stroked-button
            color="basic"
            [routerLink]="[linkButton.routeLink]"
            [style.color]="linkButton.buttonFontColor"
            [style.fontSize]="linkButton.buttonFontSize | pxSuffix"
            [style.backgroundColor]="linkButton.buttonBackgroundColor"
            *ngFor="let linkButton of textRow.linkButtons"
          >
            <mat-icon [style.fontSize]="linkButton.buttonFontSize | pxSuffix">{{
              linkButton.buttonIcon
            }}</mat-icon>
            <span>{{ linkButton.buttonText }}</span>
          </button>
        </div>

        <ng-container>
          <div
            class="overview-box"
            fxFlex="1 1 60%"
            [style.order]="textRow.imgPosition === 'start' ? '0' : '1'"
          >
            <img [src]="getImgPath(textRow.img)" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
