import { CommonModel } from '@suvo-bi-core';
import { ITenant } from '../interfaces/tenant.interface';
import { ITenantTheme } from '../../../shared/interfaces/tenant-theme.interface';

export class TenantModel extends CommonModel<TenantModel> implements ITenant {
  createdAt: string;
  firebaseTenantId: string;
  primaryWebUrl: string;
  tenantEmail: string;
  tenantName: string;
  updatedAt: string;
  webUrls: string[];
  trackingId?: string;
  theme?: ITenantTheme;
  constructor() {
    super(TenantModel);
  }
}
