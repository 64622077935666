<div class="screen-container" fxLayout="column" fxLayoutAlign="flex-start stretch">

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <h4 class="margin">Member List</h4>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <!-- <suvo-bi-organisation-invite-list></suvo-bi-organisation-invite-list> -->
      <suvo-bi-organisation-members-view></suvo-bi-organisation-members-view>
    </div>
  </div>

  <div class="top-margin" fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <h4 class="margin">Outgoing Invitations</h4>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <div class="padding">
        <p>Manage active / sent invites from the selected organisation here.</p>
      </div>
    </div>
  </div>


</div>