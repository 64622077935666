import { Component, OnInit } from '@angular/core';
import { ListNavigationItemComponent } from '../../../list-navigation/components/list-navigation-item/list-navigation-item.component';

@Component({
  selector: 'suvo-bi-route-selector-item',
  templateUrl: './route-selector-item.component.html',
  styleUrls: ['./route-selector-item.component.scss']
})
export class RouteSelectorItemComponent extends ListNavigationItemComponent {


}
