import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { CurrentTenantService } from '../../../tenant/services/current-tenant.service';

@Injectable()
export class SelectedTenantInterceptor implements HttpInterceptor {
  constructor(private readonly tenantService: CurrentTenantService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return from(this.handle(req, next)).pipe(
      take(1),
      switchMap((theTest) => {
        return theTest;
      }),
    );
  }

  async handle(req: HttpRequest<unknown>, next: HttpHandler) {
    const tenantID = this.tenantService?.currentTenant()?._id;
    if (tenantID) {
      return next.handle(
        req.clone({
          headers: req.headers.set('Selected-Tenant-ID', tenantID),
        }),
      );
    }
    return next.handle(req);
  }
}
