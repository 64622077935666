<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <h4 class="header-top-margin">{{ option.filterMethodConfig?.title }}</h4>
  <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="flex-start">
    <ng-container *ngFor="let itemList of checkboxesModel">
      <div
        [fxFlex]="columnFlex"
        fx
        class="list-container"
        fxLayout="column"
        fxLayoutAlign="flex-start stretch"
      >
        <p class="category-checkbox" *ngIf="itemList.name !== 'anonymous'; else noListCheckbox">
          <mat-checkbox
            (change)="categoryChecked($event, itemList)"
            [checked]="itemList.checked"
            [indeterminate]="itemList.indeterminate"
            color="primary"
            >{{ itemList.name }}
          </mat-checkbox>
        </p>
        <ng-template #noListCheckbox>
          <p class="category-checkbox"></p>
        </ng-template>
        <ng-container *ngIf="itemList.items?.length">
          <p class="item-checkbox" *ngFor="let item of itemList.items; let i = index">
            <mat-checkbox
              [checked]="item.checked"
              (change)="itemChecked($event, item, itemList)"
              color="primary"
              >{{ item.name }}
            </mat-checkbox>
          </p>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
