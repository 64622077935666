import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { ITileComponent } from '../../tile-component.interface';
import { LocalChartFilterChange } from '../../../../interfaces/tiles/local-chart-filter-change.interface';
import { DEFAULT_FILTERS_VALUE } from '../../../../constants/default-filters-value.constant';
import { FiltersChannel } from '../../../../../filters/models/filters-channel.model';
import { Filters } from '../../../../../filters/models/filters.model';
import { FilterRule } from '../../../../../../shared/interfaces/filters/filter-rule.type';
import { DataSourceItemsService } from '../../../../../data-source/data-source-items/services/data-source-items.service';
import { IFilterProperty } from '../../../../../../shared/interfaces/filters/filter-property.interface';
import { ProductService, SubscriptionService } from '@suvo-bi-users';
import { IChartTileDefinition } from '../../../../interfaces/tiles/definitions/chart-tile-definition.interface';

@Component({
  selector: 'suvo-bi-chart-tile',
  templateUrl: './chart-tile.component.html',
  styleUrls: ['./chart-tile.component.scss'],
})
export class ChartTileComponent implements ITileComponent, OnInit, OnChanges, OnDestroy {
  @Input() public tileDefinition: IChartTileDefinition;
  @Input() public filtersChannel: FiltersChannel;
  @Input() public editMode: boolean = false;

  public filters: Filters;

  // yearFilter = new FormControl('');
  /*  Chart Inputs
   */

  // Real Colors
  public realColors: string[] = [
    '#0E1035',
    '#104D74',
    '#118AB2',
    '#0CB0A9',
    '#06D6BA',
    '#FFD166',
    '#F78C6B',
    '#EF476F',
    '#C13B8E',
    '#932EAD',
  ];

  // Data
  public isLoading = true;
  public chartData: any[]; // Not inactivated by legend
  public legend: { name: string; inactive?: boolean }[];

  // Settings
  public barPadding;
  public showXAxis;
  public showYAxis;
  public gradient;
  public showLegend = true;
  public legendPosition;
  public showXAxisLabel;
  public xAxisLabel = '';
  public showYAxisLabel;
  public yAxisLabel;
  public colorScheme = {
    domain: this.realColors,
  };

  private finalFilters: Filters;
  private localChartFilters: { [index: string]: FilterRule } = {};
  private chartRealData: any[]; // All
  private isLoadingNewData = true;
  private readonly $unsubscribe = new Subject<void>();

  //TODO : Consider moving this up the inheritance tree
  isTrialing = false;
  hasRestrictions = false;

  constructor(
    private readonly dataService: DataSourceItemsService,
    private readonly breakpointObserver: BreakpointObserver,
    private productService: ProductService,
    private subscriptionService: SubscriptionService
  ) {}

  public async ngOnInit(): Promise<void> {
    Object.assign(this, this.tileDefinition.chartType); //Can we remove this?

    if (this.tileDefinition.chartColors) {
      this.realColors = JSON.parse(JSON.stringify(this.tileDefinition.chartColors));
    }

    if (this.tileDefinition.hideLegend) {
      this.showLegend = false;
    }

    if (this.filtersChannel) {
      this.filtersChannel?.filters
        .pipe(
          takeUntil(this.$unsubscribe),
          filter((filters) => !!filters)
        )
        .subscribe((filters) => {
          this.filters = JSON.parse(JSON.stringify(filters));
          this.finalFilters = JSON.parse(JSON.stringify(filters));

          this.setupChart();
        });
    } else {
      if(this.tileDefinition.recipe.filters) {
        this.filters = JSON.parse(JSON.stringify(this.tileDefinition.recipe.filters));
        this.finalFilters = JSON.parse(JSON.stringify(this.tileDefinition.recipe.filters));
      }

      this.setupChart();
    }

    this.showYAxisLabel = !!this.yAxisLabel;

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(takeUntil(this.$unsubscribe), distinctUntilChanged())
      .subscribe((result) => {
        if (result.matches) {
          this.showXAxis = false;
          this.showXAxisLabel = false;
        } else {
          this.showXAxis = true;
          this.showXAxisLabel = true;
        }
      });

    this.subscriptionService.currentSubscription.pipe(takeUntil(this.$unsubscribe)).subscribe((currentSubscription) => {
      if (currentSubscription && currentSubscription?.status === 'trialing') {
        this.isTrialing = true;
      }
      if (this.editMode) {
        this.hasRestrictions = false;
      } else {
        this.hasRestrictions = this.productService.hasRestrictions('AGGREGATION', [
          'DATASOURCE:' + this.tileDefinition.recipe?.dataSource,
        ]);
      }
    });
  }

  public ngOnChanges(): void {
    // this.setupChart(); BAD
  }

  public ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  public labelFormatting(name: string): string {
    /**
     * Note: 'this' is the chart component confusingly. Not this tile component.
     */

    let chartDataItem = (this as any).data?.find((dataItem) => dataItem.data.name == name);

    if (chartDataItem) {
      return `${name} ${Math.floor(chartDataItem.data.value).toLocaleString()}`;
    } else {
      return name;
    }
  }

  public updateVisibleChartData(useLegend?: boolean): void {
    /*
      Set the correct chartData and colorScheme for the data which has not been legend-disabled.
    */

    let usedColors = [];
    this.chartData = this.chartRealData.filter((data: any, index: number) => {
      if (!useLegend || !this.legend.find((item) => item.name == data.name)?.inactive) {
        usedColors.push(this.realColors[index % this.realColors.length]);
        return true;
      }
      return false;
    });
    this.colorScheme.domain = usedColors;
  }

  public onSelect(data): void {}

  public onActivate(data): void {}

  public onDeactivate(data): void {}

  public onChartLocalFilterChange(filterData: LocalChartFilterChange): void {
    const newRule: FilterRule = {
      key: filterData.localChartFilter.property,
      con: [
        {
          val: filterData.newValue,
          type: filterData.localChartFilter.operator,
        },
      ],
    };

    if (filterData.newValue) {
      this.localChartFilters[newRule.key] = newRule;
    } else {
      delete this.localChartFilters[newRule.key];
    }

    this.setupChart();
  }

  private setLocalFilters(): void {
    if (!this.filters) {
      this.filters = DEFAULT_FILTERS_VALUE;
    }
    this.finalFilters = JSON.parse(JSON.stringify(this.filters));

    const { rules } = this.finalFilters.query;

    Object.entries(this.localChartFilters).forEach(([key, value]: [string, IFilterProperty]) => {
      const existedRule: IFilterProperty = rules.find((rule: IFilterProperty) => rule.key === key) as IFilterProperty;

      if (existedRule) {
        existedRule.con = value.con;
      } else {
        this.finalFilters.query.rules.push(value);
      }
    });
  }

  private setupChart(): void {
    if (this.tileDefinition.neverLoad) {
      return;
    }

    this.barPadding = 16;
    this.showXAxis = true; // this.breakpointObserver.isMatched('(max-width: 600px)');
    this.showYAxis = true;
    this.gradient = false;
    this.legendPosition = 'below';
    this.showXAxisLabel = true;
    this.xAxisLabel = '';
    this.showYAxisLabel = false;

    this.isLoadingNewData = true;

    //const newChartData = this.fakeData;

    this.setLocalFilters();

    const compositeRecipe = { ...this.tileDefinition.recipe };
    if (this.finalFilters) {
      compositeRecipe.filters = this.finalFilters;
    }

    this.dataService.getAggregated(compositeRecipe).then((response) => {
      response = (response as Array<any>).map((item) => {
        if (item.group && compositeRecipe.groupLabelField) {
          return {
            name: item.group[compositeRecipe.groupLabelField],
            value: item.value,
          };
        } else {
          return { name: item._id, value: item.value };
        }
      });

      let chartData = response as any[];

      chartData = chartData.filter((data) => data.value > 0 && data.name);

      const legend = chartData.map((data: any, index: number) => {
        // color uses modulus incase there aren't enough colors.
        return { name: data.name };
      });

      // this.legend = chartData.map((d: any) => d.name)
      this.chartRealData = chartData;
      this.updateVisibleChartData();
      this.legend = legend;
      this.isLoading = false;
      this.isLoadingNewData = false;
    });
  }
}
