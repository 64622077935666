import { NgModule } from '@angular/core';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { TenantSelectedGuard } from './private/guards/tenant-selected.guard';
import { ShopSelectScreenComponent } from './shop-select/shop-select.screen.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then((module) => module.PublicModule),
  },
  {
    path: '',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: () => redirectUnauthorizedTo(['/login']) },
    children: [
      {
        path: 'private',
        canActivate: [TenantSelectedGuard],
        loadChildren: () =>
          import('./private/private.module').then((module) => module.PrivateModule),
      },
      {
        path: 'shops',
        pathMatch: 'full',
        component: ShopSelectScreenComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
