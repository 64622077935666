import { Injectable } from '@angular/core';
import { CommonService, ApiService } from '@suvo-bi-core';
import { PublicOrganisationRoleModel } from '../models/public-organisation-role.model';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PublicOrganisationRoleService extends CommonService<PublicOrganisationRoleModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.usersServiceApi, 'organisation/');
  }

  async getMemberList(organisationId: string): Promise<PublicOrganisationRoleModel[] | PublicOrganisationRoleModel> {
    return this.get(`${organisationId}/members`);
  }
}
