import { Injectable } from '@angular/core';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';

import { TenantRecipeModel } from '../models/tenant-recipe.model';
import { ITenantRecipe } from '../interfaces/tenant-recipe.interface';

@Injectable({
  providedIn: 'root',
})
export class TenantRecipeService extends CommonService<TenantRecipeModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.dataServiceApi, 'tenant-recipe');
  }

  create(tenantRecipe: ITenantRecipe) {
    return this.post('', tenantRecipe);
  }
}
