import { Component, Input, OnInit } from '@angular/core';
import { IBiLink } from '../../../../shared/interfaces/bi-link.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'suvo-bi-toggle-group-navigation',
  templateUrl: './toggle-group-navigation.component.html',
  styleUrls: ['./toggle-group-navigation.component.scss']
})
export class ToggleGroupNavigationComponent implements OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() links: IBiLink[] = [];
  @Input() showIcons = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }


}
