import { Component, OnInit } from '@angular/core';
import { OrganisationRoleModel, SubscriptionService } from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManageBiOrganisationsService } from '../../../services/manage-bi-organisations.service';

@Component({
  selector: 'lib-organisation-subscriptions-view',
  templateUrl: './organisation-subscriptions-view.component.html',
  styleUrls: ['./organisation-subscriptions-view.component.scss']
})
export class OrganisationSubscriptionsViewComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();

  selectedOrganisationRole: OrganisationRoleModel;

  constructor(
    private readonly manageBiOrganisationsService: ManageBiOrganisationsService,
    private subscriptionService: SubscriptionService
    ) { }

  ngOnInit(): void {
    this.manageBiOrganisationsService.selectedSettingsOrgRoleSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((updatedOrgRole) => {
      if (updatedOrgRole) {
        this.selectedOrganisationRole = updatedOrgRole;
      }
    });
  }

  async goToCustomerPortal(): Promise<void> {
    try {
      const newPortalSession = await this.subscriptionService.createCustomerPortalSession(this.selectedOrganisationRole.organisationId, 'organisation');
      if (newPortalSession) {
        window.location.href = newPortalSession.url;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
