import { Component, Input } from '@angular/core';
import { FiltersChannel } from '../../../../filters/models/filters-channel.model';
import { ITileDefinition } from '../../../interfaces/tiles/definition-helpers/tile-definition.interface';

@Component({
  selector: 'suvo-bi-tile-wrapper',
  templateUrl: './tile-wrapper.component.html',
  styleUrls: ['./tile-wrapper.component.scss'],
})
export class TileWrapperComponent {
  @Input() tileDefinition: ITileDefinition;
  @Input() filtersChannel?: FiltersChannel;
  @Input() editMode: boolean = false;
}
