import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'suvo-bi-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
