<mat-dialog-content>
  <div fxLayout="column" class="contact-form-container" fxLayoutAlign="center center">
    <div mat-icon-button class="closeButtonContainer" (click)="close()">
      <mat-icon>close</mat-icon>
    </div>
    <h2>How can we help?</h2>
    <br />
    <p style="max-width: 700px">
      If you need more details about a specific project or fleet, or you have any questions about the rail market, let
      us know and one of our experts will get back to you soon.
    </p>
    <form
      fxFlex="0 1 auto"
      [formGroup]="contactForm"
      class="contact-form"
      name="contact"
      id="contact-form"
      method="post"
      fxLayout="column"
      data-netlify-recaptcha="true"
      data-netlify="true"
      netlify
      netlify-honeypot="bot-field"
      (ngSubmit)="onSubmit()"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p style="display: none">
        <label>
          Don’t fill this out if you're human:
          <input name="bot-field" />
        </label>
      </p>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="senderName" required />
        <mat-error *ngIf="contactForm?.controls?.senderName?.hasError('required')">Please enter your name</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="" formControlName="senderEmail" required />
        <mat-error *ngIf="contactForm?.controls?.senderEmail?.hasError('required')">
          Please enter your email address
        </mat-error>
        <mat-error *ngIf="contactForm?.controls?.senderEmail?.hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Describe what you are looking for</mat-label>
        <textarea matInput placeholder="" formControlName="messageText" required></textarea>
        <mat-error *ngIf="contactForm?.controls?.messageText?.hasError('required')">
          Please describe your query
        </mat-error>
      </mat-form-field>
      <div data-netlify-recaptcha="true"></div>
      <button [disabled]="!contactForm?.valid" mat-flat-button>Send</button>
    </form>
  </div>
</mat-dialog-content>
