import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  contactForm: UntypedFormGroup;
  constructor(
    private contactService: ContactService,
    private matDialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.contactForm = new UntypedFormGroup({
      d: new FormControl('', []),
      senderName: new UntypedFormControl('', Validators.required),
      senderEmail: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      messageText: new UntypedFormControl('', Validators.required),
    });
  }

  async onSubmit() {
    if (this.contactForm.valid) {
      let emailForm = this.contactForm.value;
      await this.contactService.sendEmail(emailForm);

      //this.close();
    } else {
      console.log('your form is not valid');
    }
  }

  close() {
    this.matDialog.closeAll();
  }
}
