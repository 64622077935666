import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';
import { FEATURE_FILTERS_SECTIONS } from '../../../data/feature-filters-sections';
import { FiltersChannel } from '../../../models/filters-channel.model';
import { FiltersChannelsService } from '../../../services/filters-channels.service';

@Component({
  selector: 'suvo-bi-filters-sidenav',
  templateUrl: './filters-sidenav.component.html',
  styleUrls: ['./filters-sidenav.component.scss'],
})
export class FiltersSidenavComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();
  @Input() filtersChannel: FiltersChannel;

  @Input() selectOpen = false;
  @Input() showNoneOption = true;
  @Input() autoApplyFilters = true;

  @Input() insertStyles: any = {};
  @Input() exportDisabled = true;

  @Output() filtersManuallyApplied = new EventEmitter<boolean>();

  selectedFilters: IFilters;
  createEvent = new EventEmitter<boolean>();

  currentExportRoute = null;
  onExportScreen = false;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.currentExportRoute =
      FEATURE_FILTERS_SECTIONS?.[this.filtersChannel?.channelName]?.exportRoute;
    this.activatedRoute.url.pipe(take(1)).subscribe((route) => {
      if (route[0]?.path === 'export') {
        this.onExportScreen = true;
      } else {
        this.onExportScreen = false;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onFiltersManuallyApplied(ev) {
    this.filtersManuallyApplied.next(ev);
  }
}
