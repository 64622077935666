<div fxLayout="column" fxLayoutAlign="flex-start stretch">
  <h4 class="header-top-margin">{{ option.filterMethodConfig.title }}</h4>
  <form [formGroup]="formGroup">
    <mat-form-field fxFlex="1 1">
      <mat-select  formControlName="dropdown">
        <mat-option *ngFor="let option of option.filterMethodConfig.options" [value]="option">{{option.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <!-- <p> {{ formGroup.value | json }}</p> -->
</div>
