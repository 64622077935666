import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { IMarketingEmail } from '../interfaces/marketing-email.interface';

@Injectable({
  providedIn: 'root',
})
export class MarketingEmailService {
  readonly route = `${this.settings.usersServiceApi}marketing/email`;

  constructor(
    private readonly apiService: ApiService,
    private readonly settings: SuvoUsersClientLibSettingsService
  ) {}

  subscribe(emailDTO: IMarketingEmail) {
    return this.apiService.post(this.route, emailDTO);
  }

  unsubscribe(emailDTO: IMarketingEmail) {
    return this.apiService.delete(`${this.route}/${emailDTO.email}`);
  }
}
