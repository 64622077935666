<div fxLayout="row" fxLayoutAlign="flex-start center" class="breadcrumb-container">
  <button
    class="back-btn"
    mat-icon-button
    (click)="goBack()"
    *ngIf="showBackButton && links.length > 1"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>

  <div
    *ngFor="let link of links; let i = index; let first = first; let last = last"
    fxLayoutAlign="center center"
  >
    <ng-container *ngIf="!last; else lastLabel">
      <a
        matRipple
        class="breadcrumb-button"
        [routerLink]="link.route"
        [ngClass]="{ firstPart: first && !showBackButton }"
        ><span>
          {{ link.displayName }}
        </span></a
      >
      <mat-icon *ngIf="!last">chevron_right</mat-icon>
    </ng-container>

    <ng-template #lastLabel>
      <span class="breadcrumb-button" [ngClass]="{ firstPart: first }">{{ link.displayName }}</span>
    </ng-template>
  </div>
</div>
