import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';

@Component({
  standalone: true,
  imports: [HeaderComponent, RouterModule],
  template: `
    <div style="display: flex; flex-flow: column nowrap; min-height: 100vh">
      <app-header />
      <div style="flex-grow: 1">
        <router-outlet />
      </div>
    </div>
  `,
})
export class PrivateComponent {}
