import {Component, Input, OnDestroy} from '@angular/core';
import {ProductService} from '@suvo-bi-users';
import {Subject} from 'rxjs';
import {FiltersChannel} from '../../../../filters/models/filters-channel.model';
import {IFiltersTileDefinition} from '../../../interfaces/tiles/definitions/filters-tile-definition.interface';
import {ITileComponent} from '../tile-component.interface';

@Component({
  selector: 'suvo-bi-filters-tile',
  templateUrl: './filters-tile.component.html',
  styleUrls: ['./filters-tile.component.scss'],
})
export class FiltersTileComponent implements ITileComponent, OnDestroy {
  @Input() tileDefinition: IFiltersTileDefinition;
  @Input() filtersChannel: FiltersChannel;

  public autoApplyFilters: boolean = false;

  private $unsubscribe = new Subject<boolean>();

  allowed = false;
  exportDisabled = false;

  constructor(private productService: ProductService) { }

  ngOnInit() {
    if (this.tileDefinition.autoApplyFilters) {
      this.autoApplyFilters = true;
    }

    if (this.filtersChannel) {
      this.allowed = this.productService.isFeatureEnabled("FINDANDPAGINATE", ['DATASOURCE:' + this.filtersChannel.channelName]);

      this.exportDisabled = !this.productService.isFeatureEnabled('EXPORT', [
        'DATASOURCE:' + this.filtersChannel.channelName,
      ]);
    }
  }

  public ngOnDestroy(): void {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }
}
