<div fxLayout="column" fxLayoutAlign="flex-start stretch">
  <h4 class="header-top-margin">{{ option.filterMethodConfig.title }}</h4>
  <form [formGroup]="formGroup">
    <mat-radio-group color="primary" fxFlex="1 1" formControlName="radio">
      <ng-container *ngFor="let radioOption of option.filterMethodConfig.options">
        <mat-radio-button fxFlex="1 1 50%" [value]="radioOption">{{
          radioOption.name
        }}</mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </form>

  <!-- <p> {{ formGroup.value | json }}</p> -->
</div>
