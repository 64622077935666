import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddNewMfaComponent } from '../add-new-mfa/add-new-mfa.component';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';
import { takeUntil, take } from 'rxjs/operators';
import { RemoveMfaComponent } from '../remove-mfa/remove-mfa.component';

@Component({
  selector: 'lib-manage-mfa',
  templateUrl: './manage-mfa.component.html',
  styleUrls: ['./manage-mfa.component.scss'],
})
export class ManageMfaComponent implements OnInit, OnDestroy {
  activeMfaNumbers = [];
  private readonly unsubscribe$ = new Subject<void>();

  localFirebaseUser: firebase.default.User;

  constructor(
    private dialog: MatDialog,
    private identityService: IdentityService
  ) {}

  ngOnInit(): void {
    this.identityService.firebaseUserSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user: firebase.default.User) => {
        if (user?.uid) {
          this.localFirebaseUser = user;
          this.activeMfaNumbers = user.multiFactor.enrolledFactors;
        }
      });
  }

  addNewMfa(): void {
    const dialogRef = this.dialog.open(AddNewMfaComponent, {
      width: '300px',
      data: {
        firebaseUser: this.localFirebaseUser,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (data) => {
        if (data?.success) {
          this.activeMfaNumbers =
            this.localFirebaseUser.multiFactor.enrolledFactors;
        }
      });
  }

  async removeMfa(mfaOption: any): Promise<void> {
    try {
      await this.localFirebaseUser.multiFactor.unenroll(mfaOption);
    } catch (err) {
      console.log(err);
      if (err.code === 'auth/requires-recent-login') {
        const dialogRef = this.dialog.open(RemoveMfaComponent, {
          width: '300px',
          data: {
            firebaseUser: this.localFirebaseUser,
            targetMfaOption: mfaOption,
          },
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
