import { BehaviorSubject, Subject } from 'rxjs';
import { IFilters } from '../../../shared/interfaces/filters/filters.interface';
import { Filters } from './filters.model';

export class FiltersChannel {
  uuid: string;
  channelName: string;
  loaded = false;
  private unsavedChanges = false;

  selectedFiltersSubject = new Subject<IFilters>();
  selectedFiltersOptionsSubject = new BehaviorSubject<IFilters[]>([]);

  selectedFilters: IFilters;
  selectedFiltersOptions: IFilters[] = [];

  loadedStatus = new BehaviorSubject<boolean>(false);

  channelNameSuffix;

  selectedFilterId;

  filters: BehaviorSubject<Filters> = new BehaviorSubject(null);

  constructor(alias: string, suffix?: string, selectedFilterId?: string) {
    this.uuid = (100 + Math.floor(900 * Math.random())).toString();
    this.channelName = alias;
    this.channelNameSuffix = suffix;

    this.selectedFilterId = selectedFilterId;
  }

  addSavedFilters(savedFilters?: IFilters[], type: string = 'GENERAL') {
    this.selectedFiltersOptions = [];

    savedFilters?.forEach((savedFilter) => {
      if (savedFilter.type == type) {
        this.selectedFiltersOptions.push(savedFilter);
      }
    });

    /**
     *    Select the first filters which are not overwritten by a temporary version.
     */

    if (savedFilters?.length) {
      // if (!this.selectedFilters && savedFilters?.length) {

      let existingFilterId = this.selectedFilterId
        ? this.selectedFilterId
        : sessionStorage.getItem(this.getStorageKey());

      //let existingFilterId = "633fed1fd5ab86b7fe931536";

      let existingFilter = this.selectedFiltersOptions.find(
        (filterOption) => filterOption._id == existingFilterId,
      );

      if (!existingFilter) {
        existingFilter = this.selectedFiltersOptions.find(
          (filterOption) => filterOption.type == type,
        );
      }

      this.switchSelection(existingFilter ? existingFilter : savedFilters[0]);
    } else {
      this.switchSelection(null);
    }

    this.selectedFiltersOptionsSubject.next(this.selectedFiltersOptions);
  }

  removeSavedFilter(savedFilter: IFilters) {
    this.selectedFiltersOptions = this.selectedFiltersOptions.filter(
      (filter) => filter != savedFilter,
    );

    if (this.selectedFilters == savedFilter) {
      this.switchSelection(this.selectedFiltersOptions[0]);
    }

    this.selectedFiltersOptionsSubject.next(this.selectedFiltersOptions);
  }

  setUnsavedChanges(value: boolean): void {
    this.unsavedChanges = value;
  }

  getUnsavedChanges(): boolean {
    return this.unsavedChanges;
  }

  switchSelection(savedFilter?: IFilters) {
    this.selectedFilters = savedFilter;
    this.selectedFiltersSubject.next(savedFilter);

    if (this.selectedFilters) {
      sessionStorage.setItem(this.getStorageKey(), this.selectedFilters._id);
    }
  }

  updateFilters(filters: IFilters) {
    if (this.selectedFilters._id == filters._id) {
      this.selectedFilters.name = filters.name;
      this.selectedFilters.query = filters.query;

      this.selectedFiltersSubject.next(this.selectedFilters);
    }

    let found = false;
    this.selectedFiltersOptions.forEach((option) => {
      if (option._id == filters._id) {
        option.name = filters.name;
        option.query = filters.query;

        found = true;
      }
    });

    if (found) {
      this.selectedFiltersOptionsSubject.next(this.selectedFiltersOptions);
    }
  }

  getStorageKey() {
    let key = `current_filters_${this.channelName}`;

    if (this.channelNameSuffix) {
      key = key + '_' + this.channelNameSuffix;
    }

    return key;
  }
}
