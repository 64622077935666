<div class="card" [style.background]="tileDefinition.background">
  <div class="page-section">
    <div class="page-section-content content-container">
      <div
        class="tile-divider top"
        *ngIf="tileDefinition.isTileDividerEnabled && tileDefinition.tileDividerPosition === 'top'"
        [style.backgroundColor]="tileDefinition.tileDividerColor"
      ></div>
      <div
        class="column-container"
        fxLayout="row wrap"
        fxLayoutGap.md="60px"
        fxLayoutAlign="center flex-start"
      >
        <div class="column" *ngFor="let textColumn of tileDefinition.textColumns">
          <div
            class="column-divider top"
            *ngIf="textColumn.isDividerEnabled && textColumn.dividerPosition === 'top'"
            [style.backgroundColor]="textColumn.dividerColor"
          ></div>

          <p
            [style.color]="textColumn.headingFontColor"
            [style.fontSize]="textColumn.headingFontSize | pxSuffix"
          >
            {{ textColumn.heading }}
          </p>
          <p
            [style.color]="textColumn.textFontColor"
            [style.fontSize]="textColumn.textFontSize | pxSuffix"
            class="text"
          >
            {{ textColumn.text }}
          </p>

          <div
            class="column-divider bottom"
            *ngIf="textColumn.isDividerEnabled && textColumn.dividerPosition === 'bottom'"
            [style.backgroundColor]="textColumn.dividerColor"
          ></div>
        </div>
      </div>
      <div
        class="tile-divider bottom"
        *ngIf="
          tileDefinition.isTileDividerEnabled && tileDefinition.tileDividerPosition === 'bottom'
        "
        [style.backgroundColor]="tileDefinition.tileDividerColor"
      ></div>
    </div>
  </div>
</div>
