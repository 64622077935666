<div *ngIf="links; else noLinks" class="route-toggle-padding">
  <mat-button-toggle-group class="toggle-group" [hideSingleSelectionIndicator]="true">
    <mat-button-toggle
      *ngFor="let item of links"
      class="button-toggle"
      routerLinkActive="active-toggle"
      [routerLink]="item.route"
      fxFlex="1 1"
    >
      <mat-icon *ngIf="showIcons && item.displayIcon" class="toggle-button-icon">{{ item.displayIcon }}</mat-icon>
      {{ item.displayName }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<ng-template #noLinks>
  <suvo-core-error-message title="No Links" message="Unable to show group links."></suvo-core-error-message>
</ng-template>
