import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { IBiLink } from '../../../../shared/interfaces/bi-link.interface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'suvo-bi-list-navigation-item',
  templateUrl: './list-navigation-item.component.html',
  styleUrls: ['./list-navigation-item.component.scss'],
})
export class ListNavigationItemComponent implements OnInit {
  @ViewChild('routerLinkActiveRef') routerLinkActiveRef: RouterLinkActive;

  @Input() routePrefix: string[] | string = [];
  @Input() depth = 0;
  @Input() borderRadius: string = '24px';
  @Input() theme: string;
  @Input() showIcons: boolean = false;
  @Input() link: IBiLink;

  @Output() onLinkClick = new EventEmitter();

  expanded = false;

  $unsubscribe = new Subject<void>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  handleLinkClick() {
    if (this.link.disabled) {
      return;
    }

    if (this.onLinkClick) this.onLinkClick.emit(this.link);
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.$unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/private/' + this.link.route)) {
          this.link.dropDownOpen = true;
        } else {
          this.link.dropDownOpen = false;
        }
      }
    });

    let url = this.router.url;
    let isOpen = url.startsWith('/private/' + this.link.route);
    if (isOpen) {
      this.link.dropDownOpen = true;
    } else {
      this.link.dropDownOpen = false;
    }
  }

  isLoosengeLink() {
    return this.depth !== 0 || (this.depth === 0 && !this.link.subLinks);
  }
}
