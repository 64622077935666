import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BetweenBoxesFilterOptionComponent } from '../between-boxes-filter-option/between-boxes-filter-option.component';
import { FilterConditionType } from '../../../../../shared/interfaces/filters/filter-condition-type.enum';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import {DateTime} from 'luxon';

@Component({
  selector: 'suvo-bi-years-drop-down-option',
  templateUrl: './years-drop-down-option.component.html',
  styleUrls: ['./years-drop-down-option.component.scss']
})
export class YearsDropDownOptionComponent extends BetweenBoxesFilterOptionComponent {

  formGroup: FormGroup;
  yearOptions: number[];

  constructor() {
    super();
  }

  ngOnInit(): void {

    let yearOptions: number[] = [];
    const { min, max } = this.option.filterMethodConfig;

    for (let i = min; i <= max; i++) {
      yearOptions.push(i);
    }

    if(this.option.filterMethodConfig?.reversed) {
      yearOptions = yearOptions.reverse();
    }

    this.yearOptions = yearOptions;

    super.ngOnInit();

  }

  getFilterRules(values: any): FilterRule[] {
    const key = this.option.filterPropertyKeys[0];

    let { lessThan, greaterThan } = values;
    if (!lessThan && !greaterThan) {
      return [];
    }

    const property: IFilterProperty = {
      key,
      con: [],
      type: this.option.filterValueType,
    };
    if (lessThan) {
      if(this.option.filterValueType == 'DATE') {
        lessThan = DateTime.utc(lessThan, 1, 1, 0, 0);
      }

      property.con.push({
        val: lessThan,
        type: FilterConditionType.LessThanEquals,
      });
    }
    if (greaterThan) {
      if(this.option.filterValueType == 'DATE') {
        greaterThan = DateTime.utc(greaterThan, 1, 1, 0, 0);
      }

      property.con.push({
        val: greaterThan,
        type: FilterConditionType.GreaterThanEquals,
      });
    }
    return [property];
  }

  patchRules(rules: FilterRule[]) {
    this.formGroup.controls.lessThan.patchValue(null, { emitEvent: false });
    this.formGroup.controls.greaterThan.patchValue(null, { emitEvent: false });

    rules?.forEach((property: IFilterProperty) => {
      property.con?.forEach((condition) => {
        if (condition.type == FilterConditionType.LessThanEquals) {
          let val = condition.val;

          if(this.option.filterValueType == 'DATE') {
            let d = DateTime.fromISO(val, {'zone': 'utc'});

            val = d.year;
          }

          this.formGroup.controls.lessThan.patchValue(val, {
            emitEvent: false,
          });
        } else if (condition.type == FilterConditionType.GreaterThanEquals) {
          let val = condition.val;

          if(this.option.filterValueType == 'DATE') {
            let d = DateTime.fromISO(val, {'zone': 'utc'});

            val = d.year;
          }

          this.formGroup.controls.greaterThan.patchValue(val, {
            emitEvent: false,
          });
        }
      });
    });
  }
}
