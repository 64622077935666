<mat-dialog-content>
  <div class="form-container">
    <form ngNoForm [formGroup]="changePasswordForm" *ngIf="changePasswordForm">
      <p class="heading">Change Password</p>
      <p>Please choose a new password.</p>

      <mat-form-field appearance="outline">
        <mat-label>New password</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          matInput
          type="password"
          formControlName="newPassword"
          type="password"
          placeholder="**********"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          tabindex="-1"
          type="button"
        >
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>

        <mat-error *ngIf="changePasswordForm.controls.newPassword.hasError('required')">
          Password is
          <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.newPassword.hasError('pattern')">
          Passwords must be 8 characters and contain at least 1 lowercase, uppercase and numeric character.
        </mat-error>
      </mat-form-field>

      <br *ngIf="changePasswordForm.controls.newPassword.hasError('pattern')" />
      <mat-form-field appearance="outline">
        <mat-label>Confirm new password</mat-label>
        <input
          matInput
          [type]="hideConfirmPassword ? 'password' : 'text'"
          formControlName="confirmPassword"
          [errorStateMatcher]="matcher"
          type="password"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hideConfirmPassword = !hideConfirmPassword"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideConfirmPassword"
          tabindex="-1"
          type="button"
        >
          <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm?.hasError('notSame')">Passwords must match!</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" [disabled]="!changePasswordForm.valid" (click)="handleChangePassword()">
        Change password
      </button>
    </form>
  </div>
</mat-dialog-content>
