<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Styles</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="inputs-container">
        <mat-form-field appearance="fill">
          <mat-label>Background</mat-label>
          <input matInput formControlName="background" />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Contacts</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngFor="let contact of contactsControl.controls; let i = index">
        <div [formGroup]="contact" class="contancts-container">
          <div class="inputs-container">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Contact {{ i + 1 }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Full name</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <lib-text-editor
                      [formGroup]="contact"
                      textFormControlName="fullName"
                      fontColorFormControlName="fullNameFontColor"
                      fontSizeFormControlName="fullNameFontSize"
                    ></lib-text-editor>
                  </mat-card-content>
                </mat-card>

                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Role</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <lib-text-editor
                      [formGroup]="contact"
                      textFormControlName="role"
                      fontColorFormControlName="roleFontColor"
                      fontSizeFormControlName="roleFontSize"
                    ></lib-text-editor>
                  </mat-card-content>
                </mat-card>

                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Description</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <lib-text-editor
                      [formGroup]="contact"
                      textFormControlName="text"
                      fontColorFormControlName="textFontColor"
                      fontSizeFormControlName="textFontSize"
                    ></lib-text-editor>
                  </mat-card-content>
                </mat-card>

                <mat-form-field appearance="fill">
                  <mat-label>Phone</mat-label>
                  <input matInput formControlName="phone" />
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email" />
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>Image</mat-label>
                  <input matInput formControlName="img" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Image Position</mat-label>
                  <mat-select formControlName="imgPosition">
                    <mat-option
                      *ngFor="let position of horizontalPositionOptions"
                      [value]="position.key"
                    >
                      {{ position.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-icon class="delete-btn" (click)="removeContact(i)">delete_forever</mat-icon>
        </div>
        <hr />
      </ng-container>

      <button class="add-btn" mat-raised-button color="primary" (click)="addContact()">
        Add Contact
      </button>
    </mat-card-content></mat-card
  >
</form>

<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
