<div
  *ngIf="!isLoading; else loadingElement"
  class="bi-margin"
  fxLayout="column"
  fxLayoutAlign="stretch stretch"
  fxLayoutGap="16px"
>
  <!-- <div *ngIf="context.canEditItem || context.canEditView" fxLayout="row" fxLayoutAlign="flex-start center"
    fxLayoutGap="16px">
    <mat-slide-toggle *ngIf="context.canEditView" color="primary" [(ngModel)]="editGridMode">Edit View
    </mat-slide-toggle>
    <mat-slide-toggle *ngIf="context.canEditItem" color="primary" [checked]="editItemMode"
      (change)="onEditItemToggle($event)">Edit Item
    </mat-slide-toggle>
  </div> -->

  <div fxFlex="1 1" fxLayoutAlign="stretch stretch">
    <suvo-bi-smart-grid
      fxFlex="1 1"
      [settings]="gridSettings"
      [grid]="view"
      [editMode]="editGridMode"
      [saveChangesAsync]="onSaveGrid"
      *ngIf="view && item && definition; else errorSection"
    >
      <ng-template let-tileDefinition>
        <suvo-bi-view-tile-wrapper
          [viewTile]="tileDefinition"
          [item]="item"
          [form]="dataForm"
          [definition]="definition"
          [editItemMode]="editItemMode"
        >
        </suvo-bi-view-tile-wrapper>
      </ng-template>
    </suvo-bi-smart-grid>
    <ng-template #errorSection>
      <div class="error-container">
        <suvo-core-error-message
          title="No data"
          message="Data is missing or there was a problem loading data. You may need to upgrade your subscription to view this item."
        >
        </suvo-core-error-message>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #loadingElement>
  <suvo-core-loading-spinner></suvo-core-loading-spinner>
</ng-template>
