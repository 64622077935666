import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDataSourceDefinition } from '../../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { FiltersOption } from '../../../models/sections/filters-option.model';

@Component({
  selector: 'suvo-bi-section-wrapper',
  templateUrl: './section-wrapper.component.html',
  styleUrls: ['./section-wrapper.component.scss'],
})
export class SectionWrapperComponent {
  @Input() definition: IDataSourceDefinition;
  @Input() option: FiltersOption;
  @Output() rulesChanged: EventEmitter<void> = new EventEmitter();

  innerRulesChanged(option: FiltersOption) {
    this.rulesChanged.emit();
  }
}
