import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { ProductService } from '../../../product/services/product.service';
import { SubscriptionService } from '../../services/subscription.service';
import { RestrictionDialogComponent } from '../../dialogs/restriction-dialog/restriction-dialog.component';

@Component({
  selector: 'suvo-bi-limited-data-banner',
  templateUrl: './limited-data-banner.component.html',
  styleUrls: ['./limited-data-banner.component.scss'],
})
export class LimitedDataBannerComponent {
  unsubscribe$ = new Subject<boolean>();

  @Input() isTrial = false;
  @Input() featureShortCode;
  @Input() tags;

  restrictionText;
  trialDaysRemaining = 0;

  constructor(
    private matDialog: MatDialog,
    private productService: ProductService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    let prettyRestrictions = this.productService.getPrettyRestrictions();

    if (this.featureShortCode.toLowerCase() === 'aggregation') {
      this.featureShortCode = 'Data points';
    }

    for (const [dataSource, restrictions] of Object.entries(
      prettyRestrictions
    )) {
      if (this.tags.includes(dataSource.toLowerCase())) {
        for (const [feature, restrictionMessages] of Object.entries(
          restrictions
        )) {
          if (this.featureShortCode.toLowerCase() === feature.toLowerCase()) {
            this.restrictionText = restrictionMessages.join(', ');
          }
        }
      }
    }

    this.subscriptionService.currentSubscription
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentSubscription) => {
        if (currentSubscription) {
          const currentDate = new Date();
          const trialEnd = new Date(currentSubscription.trial_end * 1000);

          this.trialDaysRemaining = Math.floor(
            (Date.UTC(
              trialEnd.getFullYear(),
              trialEnd.getMonth(),
              trialEnd.getDate()
            ) -
              Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              )) /
              (1000 * 60 * 60 * 24)
          );
        }
      });
  }

  public onInfoButtonClick(): void {
    this.matDialog.open(RestrictionDialogComponent);
  }
}
