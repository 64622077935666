import { IFilters } from '../../../shared/interfaces/filters/filters.interface';

export class Filters implements IFilters {
  _id?;
  name;
  type?;
  query;
  createdAt?: Date;
  updatedAt?: Date;
  constructor(init: any) {
    Object.assign(this, init);
  }
}
