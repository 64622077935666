import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../../product/services/product.service';

@Component({
  selector: 'lib-restriction-dialog',
  templateUrl: './restriction-dialog.component.html',
  styleUrls: ['./restriction-dialog.component.scss'],
})
export class RestrictionDialogComponent implements OnInit {
  prettyRestrictions: Record<string, Record<string, string[]>> = {};

  constructor(private productService: ProductService) { }

  public ngOnInit(): void {
    this.prettyRestrictions = this.productService.getPrettyRestrictions();
  }
}
