import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';
import { FEATURE_FILTERS_SECTIONS } from '../../../data/feature-filters-sections';
import { FiltersChannel } from '../../../models/filters-channel.model';
import { FiltersChannelsService } from '../../../services/filters-channels.service';
import { FiltersSidenavService } from '../../../services/filters-sidenav.service';
import { EditFiltersComponent } from '../edit-filters/edit-filters.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'suvo-bi-filters-content',
  templateUrl: './filters-content.component.html',
  styleUrls: ['./filters-content.component.scss'],
})
export class FiltersContentComponent implements OnInit {
  @ViewChild('editFiltersRef') editFiltersRef: EditFiltersComponent;
  unsubscribe$ = new Subject<boolean>();

  @Input() filtersChannel: FiltersChannel;
  @Input() selectOpen = false;
  @Input() showNoneOption = true;
  @Input() autoApplyFilters = true;
  @Input() showExportButton = true;
  @Input() insertStyles: any = {};
  @Input() filtersChannelSuffiix = '';
  @Input() autoSave = true;
  @Input() isSidenavContent = false;
  @Input() exportDisabled = true;
  @Input() showFilterHeader = true;
  @Input() editFiltersHeading = 'Configure filters';

  @Output() filtersManuallyApplied = new EventEmitter<boolean>();

  selectedFilters: IFilters;
  createEvent = new EventEmitter<boolean>();

  currentExportRoute = null;
  onExportScreen = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public filtersSidenavService: FiltersSidenavService,
    private dialog: MatDialog,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.currentExportRoute =
      FEATURE_FILTERS_SECTIONS?.[this.filtersChannel?.channelName]?.exportRoute;
    this.activatedRoute.url.pipe(take(1)).subscribe((route) => {
      if (route[0]?.path === 'export') {
        this.onExportScreen = true;
      } else {
        this.onExportScreen = false;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public onClose(): void {
    this.filtersSidenavService.close();
  }

  saveTemporaryFilters() {
    this.editFiltersRef.saveTemporaryFilters();
  }

  discardTemporaryFilters() {
    this.editFiltersRef.discardTemporaryFilters();
  }

  applyFiltersClicked() {
    this.editFiltersRef.applyFiltersClicked();
  }

  clearFilters() {
    this.editFiltersRef.clear();
  }
}
