<div class="card">
  <div #firstSection class="page-section lead-boxed" [style.background]="tileDefinition.background">
    <div class="page-section-content" fxLayoutAlign="center center">
      <div class="lead text-center padding-5 margin-t5 slide-right">
        <span
          class="material-icons border-circle lrg-icon light"
          *ngFor="let icon of tileDefinition.icons"
        >
          {{ icon.icon }}
        </span>

        <p
          class="margin-t5 text"
          [style.color]="tileDefinition.textFontColor"
          [style.fontSize]="tileDefinition.textFontSize"
        >
          {{ tileDefinition.text }}
        </p>

        <button
          mat-stroked-button
          [routerLink]="linkButton.routeLink"
          [style.color]="linkButton.buttonFontColor"
          [style.fontSize]="linkButton.buttonFontSize | pxSuffix"
          [style.background]="linkButton.buttonBackgroundColor"
          class="slide-top white-button margin-t5"
          *ngFor="let linkButton of tileDefinition.linkButtons"
        >
          {{ linkButton.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
