import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ITextColumn } from '../../../dashboards/interfaces/tiles/definition-helpers/text-column.interface';
import { ITextColumnsTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/text-columns-tile-definition.interface';
import { VERTICAL_POSITION_OPTIONS } from '../../constants/vertical-position-options.constant';
import { ISelectOption } from '../../interfaces/select-option.interface';
import { TileCreatorHelpersService } from '../../services/tile-creator-helpers.service';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'lib-free-trial-setup-wizard',
  templateUrl: './free-trial-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './free-trial-setup-wizard.component.scss'],
})
export class FreeTrialSetupWizardComponent implements OnInit, OnDestroy {
  tileDefinition: ITextColumnsTileDefinition;

  public formGroup;

  public verticalPositionsOptions: ISelectOption[];
  public fontSizesOptions: string[];

  textColumns;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorHelpersService: TileCreatorHelpersService,
    private readonly tileCreatorService: TileCreatorService,
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSetupForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initSetupForms(): void {
    this.formGroup = this.fb.group({
      background: '',
    });

    if (this.tileDefinition) {
      this.formGroup.patchValue(this.tileDefinition);
    }
  }

  public onCreate() {
    Object.assign(this.tileDefinition, {
      tileType: 'free-trial',
      canView: 'true',
      ...this.formGroup.value,
    });
    this.tileCreatorService.onChange.next();
  }
}
