import { IBiLink } from "../../../shared/interfaces/bi-link.interface";

export const MANAGE_IDENTITY_DATA_SOURCE_ROUTES: IBiLink[] = [
  {
    displayName: 'Projects',
    displayIcon: 'construction',
    route: 'projects',
  },
  {
    displayName: 'Fleets',
    displayIcon: 'train',
    route: 'fleets',
  },
  {
    displayName: 'Tenders',
    displayIcon: 'cases',
    route: 'tenders',
  }
]