import { HttpClient } from '@angular/common/http';
import { ITableDataSource } from '../interfaces/table/table-data-source.interface';
import { ITablePagination } from '../interfaces/table/table-pagination.interface';
import { ITableSort } from '../interfaces/table/table-sort.interface';

export class ApiService<T> {
  constructor(
    protected httpClient: HttpClient,
    protected host: string,
    private route: string
  ) {}

  public async get(url?: string, params?: any): Promise<T[] | T> {
    const requestUrl = await this.getRequestUrl(url);
    return this.httpClient
      .get(requestUrl, { params })
      .toPromise() as unknown as Promise<T[]>;
  }

  public async getPaginated(
    filters: any,
    sort?: ITableSort,
    pagination?: ITablePagination,
    suffixUrl?: string
  ): Promise<ITableDataSource<T>> {
    const tableOptions: any = {};

    if (filters) {
      tableOptions.filters = JSON.stringify(filters);
    }

    if (sort) {
      tableOptions.sort = JSON.stringify(sort);
    }

    if (pagination) {
      tableOptions.pagination = JSON.stringify(pagination);
    }

    return this.httpClient
      .get(await this.getRequestUrl(suffixUrl), {
        params: tableOptions,
      })
      .toPromise() as unknown as Promise<ITableDataSource<T>>;
  }

  public async patch(
    id?: number | string,
    body?: T,
    url?: string
  ): Promise<object> {
    let additonalUrl;
    if (id) {
      additonalUrl = `/${id}`;
      if (url) {
        additonalUrl += url;
      }
    }
    return this.httpClient
      .patch(await this.getRequestUrl(additonalUrl), body)
      .toPromise();
  }

  public async put(url?: string, body?: T): Promise<object> {
    return this.httpClient.put(await this.getRequestUrl(url), body).toPromise();
  }

  public async post(body?: T | any, additionalUrl?: string): Promise<object> {
    return this.httpClient
      .post(await this.getRequestUrl(additionalUrl), body)
      .toPromise();
  }

  public async delete(body?: T | any, additionalUrl?: string): Promise<object> {
    const url = await this.getRequestUrl(additionalUrl);
    return this.httpClient.delete(url, { body }).toPromise();
  }

  public async getRequestUrl(additionalUrl?: string): Promise<string> {
    const routeUrl = await this.getRouteUrl();
    if (additionalUrl) {
      return `${routeUrl}${additionalUrl}`;
    }
    return routeUrl;
  }

  public async getRouteUrl(params?: any): Promise<string> {
    return `${this.host}${this.route}`;
  }
}
