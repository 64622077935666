import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageSubscriptionComponent } from '../components/manage-subscription/manage-subscription.component';
// import { SubscriptionService } from '../services/subscription.service';
import { SharedModule } from '../../../shared/modules/shared/shared.module';
import { NgxStripeModule } from 'ngx-stripe';
import { UpgradeSubscriptionDialogComponent } from '../dialogs/upgrade-subscription-dialog/upgrade-subscription-dialog.component';
import { LimitedDataBannerComponent } from '../components/limited-data-banner/limited-data-banner.component';
import { RouterModule } from '@angular/router';
import { RestrictionDialogComponent } from '../dialogs/restriction-dialog/restriction-dialog.component';

@NgModule({
  declarations: [
    ManageSubscriptionComponent,
    UpgradeSubscriptionDialogComponent,
    LimitedDataBannerComponent,
    RestrictionDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxStripeModule,
    RouterModule
  ],
  providers: [
    // SubscriptionService
  ],
  exports: [
    ManageSubscriptionComponent,
    UpgradeSubscriptionDialogComponent,
    LimitedDataBannerComponent
  ]
})
export class SubscriptionModule { }
