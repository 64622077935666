import { Component, Input, OnInit } from '@angular/core';
import { ListNavigationComponent } from '../../../list-navigation/components/list-navigation/list-navigation.component';

@Component({
  selector: 'suvo-bi-route-selector',
  templateUrl: './route-selector.component.html',
  styleUrls: ['./route-selector.component.scss']
})
export class RouteSelectorComponent extends ListNavigationComponent {

}
