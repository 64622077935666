import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { CONTINENT_NAME_SWAPS } from '../../../data/country-continents';
import { ToggleGroup } from '../../../models/utility/toggle-group.model';
import { FiltersUtilityService } from '../../../services/filters-utility.service';
import { FilterOptionComponent } from '../filter-option/filter-option.component';
import { NestedCheckboxesDialogComponent } from '../../dialogs/nested-checkboxes-dialog/nested-checkboxes-dialog.component';

@Component({
  selector: 'suvo-bi-combined-nested-checkboxes-filter-option',
  templateUrl: './combined-nested-checkboxes-filter-option.component.html',
  styleUrls: ['./combined-nested-checkboxes-filter-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinedNestedCheckboxesFilterOptionComponent extends FilterOptionComponent {
  title: string = 'Options';
  showChildrenLabel = 'Show sub-options';
  formGroup!: FormGroup;

  continentNameSwaps = CONTINENT_NAME_SWAPS;

  activeDialog: MatDialogRef<unknown>;
  toggleGroups: ToggleGroup[];

  childValueFormat = 'name';

  childFilterCount: number;

  parentKeyName: string;
  childKeyName: string;

  optionsColumns;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly filtersUtilityService: FiltersUtilityService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = this.option.filterMethodConfig.title;
    this.showChildrenLabel = this.option.filterMethodConfig.showChildrenLabel;

    let parentData = this.option.filterMethodConfig.parentData;
    let childData = this.option.filterMethodConfig.childData;

    this.parentKeyName = this.option.filterMethodConfig.parentKeyName;
    this.childKeyName = this.option.filterMethodConfig.childKeyName;

    this.optionsColumns = this.option.filterMethodConfig.optionsColumns;

    const response = this.filtersUtilityService.createToggleGroupsFormGroup(parentData, childData, this.unsubscribe$);
    this.toggleGroups = response.toggleGroups;
    this.formGroup = response.formGroup;

    this.childValueFormat = this.option.filterMethodConfig.childValueFormat || 'name';

    this.hookForm(this.formGroup);

    super.ngOnInit();

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.activeDialog && this.activeDialog.close();
    super.ngOnDestroy();
  }

  getFilterRules(values: any): FilterRule[] {
    this.changeDetectorRef.detectChanges();

    const filterRules = this.filtersUtilityService.getToggleGroupsFilterRules(
      this.toggleGroups,
      this.parentKeyName,
      this.childKeyName,
      this.option.filterMethodConfig.toggleGroupParentKey,
      this.option.filterMethodConfig.toggleGroupChildKey
    );

    return filterRules;
  }

  openChildrenDialog(): void {
    this.activeDialog && this.activeDialog.close();

    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
    this.matDialog
      .open(NestedCheckboxesDialogComponent, {
        data: {
          title: this.title,
          //  locationFormGroup: this.locationFormGroup,
          toggleGroups: this.toggleGroups,
          onFilterApprove: () => {
            this.toggleGroups.forEach((toggleGroup) => toggleGroup.recalculateValues());
            this.option.filterRules = this.getFilterRules(null);

            this.updateChildFilterCount();

            this.rulesChanged.emit();
          },
        },
        width: '90vw',
      })
      .beforeClosed()
      .subscribe(() => {
        this.resetSubscriptions();
        this.hookForm(this.formGroup);
      });
  }

  patchRules(rules: FilterRule[]) {
    // TODO: countries property
    this.filtersUtilityService.patchToggleGroupsFilterRules(
      this.toggleGroups,
      rules,
      this.parentKeyName,
      this.childKeyName,
      this.option.filterMethodConfig.toggleGroupParentKey,
      this.option.filterMethodConfig.toggleGroupChildKey
    );
    this.updateChildFilterCount();
    this.changeDetectorRef.detectChanges();
  }

  private updateChildFilterCount() {
    this.childFilterCount = 0;
    for (const toggleGroup of this.toggleGroups) {
      for (const child of Object.getOwnPropertyNames(toggleGroup.formGroup.controls)) {
        if (toggleGroup.formGroup.controls[child].value) this.childFilterCount++;
      }
    }
  }

  public onCheckboxChange(toggleGroup, checked) {
    toggleGroup.setChecked(checked);

    this.updateChildFilterCount();
  }
}
