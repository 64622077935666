import { CommonModel } from '@suvo-bi-core';
import { ICustomDomain } from '../interfaces/custom-domain.interface';

export class CustomDomainModel
  extends CommonModel<CustomDomainModel>
  implements ICustomDomain
{
  constructor() {
    super(CustomDomainModel);
  }
  url: string;
  firebaseTenantId: string;
}
