import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { BehaviorSubject } from 'rxjs';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { ProductService } from '../../product/services/product.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends ApiService {
  currentSubscription: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    httpClient: HttpClient,
    private readonly clientSettingsService: SuvoUsersClientLibSettingsService,
    private readonly productService: ProductService
  ) {
    super(httpClient);
  }

  createCustomer(body: {
    organisationId: string;
    organisationName: string;
    tenantId: string;
    ownerEmail: string;
  }) {
    return this.post(
      `${this.clientSettingsService.subscriptionServiceApi}subscription/customer`,
      body
    );
  }

  getOrganisationCustomer(organisationId: string): Promise<any> {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}subscription/customer/${organisationId}`);
  }

  getProductGroups() {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}tenant/products`);
  }

  getExtendedProductGroups() {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}tenant/products/extended`);
  }

  getPublicProductGroups(tenantId: string) {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}tenant/${tenantId}/products`);
  }

  getFeatures() {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}tenant/features`);
  }

  getTierFeatures(productTierId: string) {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}tier/${productTierId}/features`)
  }

  getAllStripeProducts(organisationId: string): Promise<any> {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}subscription/stripe/products/${organisationId}`);
  }

  async getActiveCustomerSubscriptions(organisationId: string): Promise<any> {
    const subs = await this.get(`${this.clientSettingsService.subscriptionServiceApi}subscription/active-subscriptions/${organisationId}`);

    // Assuming user will only ever have one subscription
    this.currentSubscription.next(subs?.data?.[0]);
    return subs;
  }

  async prepareSetupIntent(organisationId: string): Promise<any> {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}subscription/customer/${organisationId}/setup-intent`);
  }

  async createSubscription(priceIds: string[], organisationId: string, seats: number): Promise<any> {
    const newSub = await this.post(`${this.clientSettingsService.subscriptionServiceApi}subscription/create-subscription/${organisationId}`,
      { priceIds, organisationId, seats });
    await this.productService.fetchAllOwned();
    return newSub;
  }

  async createTrialSubscription(organisationId: string, seats: number): Promise<any> {
    const newSub = await this.post(`${this.clientSettingsService.subscriptionServiceApi}subscription/create-trial-subscription/${organisationId}`,
      { organisationId, seats });
    await this.productService.fetchAllOwned();
    return newSub;
  }

  async updateSubscription(priceIds: string[], organisationId: string, seats: number): Promise<any> {
    const updatedSub = await this.put(
      `${this.clientSettingsService.subscriptionServiceApi}subscription/update-subscription/${organisationId}`,
      { priceIds, organisationId, seats });
    await this.productService.fetchAllOwned();
    return updatedSub;
  }

  async cancelSubscription(organisationId: string, cancelNow: boolean): Promise<any> {
    const cancelledSub = await this.post(`${this.clientSettingsService.subscriptionServiceApi}subscription/cancel-subscription/${organisationId}`, { cancelNow });
    await this.productService.fetchAllOwned();
    return cancelledSub;
  }

  createCustomerPortalSession(organisationId: string, redirectType: 'personal' | 'organisation' = 'personal'): Promise<any> {
    return this.get(`${this.clientSettingsService.subscriptionServiceApi}subscription/customer-portal/${organisationId}?redirect_type=${redirectType}`);
  }
}
