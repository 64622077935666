import { Component, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  FormArray,
  Validators,
} from '@angular/forms';
import { ILinkButton } from '../../../dashboards/interfaces/tiles/definition-helpers/link-button.interface';
import { IBannerTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/banner-tile-definition.interface';
import { TILE_REQUEST } from '../../tokens/tile-request.token';
import { TileCreatorService } from '../../services/tile-creator.service';

@Component({
  selector: 'app-banner-setup-wizard',
  templateUrl: './banner-setup-wizard.component.html',
  styleUrls: ['./banner-setup-wizard.component.scss'],
})
export class BannerSetupWizardComponent implements OnInit {
  tileDefinition: IBannerTileDefinition;

  public firstStepSetupForm: FormGroup;
  public secondStepSetupForm: FormGroup;

  public dataSourceObject;

  public get headingControl(): AbstractControl {
    return this.firstStepSetupForm.get('heading') as AbstractControl;
  }

  public get displayFeatureTotalControl(): AbstractControl {
    return this.firstStepSetupForm.get(
      'displayFeatureTotal'
    ) as AbstractControl;
  }

  public get subHeadingControl(): AbstractControl {
    return this.firstStepSetupForm.get('subHeading') as AbstractControl;
  }

  public get headingIconControl(): AbstractControl {
    return this.firstStepSetupForm.get('headingIcon') as AbstractControl;
  }

  public get imgUriControl(): AbstractControl {
    return this.firstStepSetupForm.get('imgUri') as AbstractControl;
  }

  public get linkButtonsControl(): FormArray {
    return this.secondStepSetupForm.get('linkButtons') as FormArray;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorService: TileCreatorService
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSetupForms();
  }

  public initSetupForms(): void {
    this.firstStepSetupForm = this.fb.group({
      heading: ['', [Validators.required]],
      subHeading: ['', [Validators.required]],
      displayFeatureTotal: ['', [Validators.required]],
      headingIcon: ['', [Validators.required]],
      imgUri: ['', [Validators.required]],
    });

    this.secondStepSetupForm = this.fb.group({
      linkButtons: this.fb.array([]),
    });

    if (this.tileDefinition) {
      this.firstStepSetupForm.patchValue(this.tileDefinition);
      this.secondStepSetupForm.patchValue(this.tileDefinition);

      this.tileDefinition.linkButtons?.forEach((linkButton: ILinkButton) => {
        this.addLinkButton(linkButton);
      });
    }
  }

  public addLinkButton(linkButton?: ILinkButton): void {
    const linkButtonFormGroup = this.fb.group({
      buttonText: ['', [Validators.required]],
      buttonIcon: ['', [Validators.required]],
      buttonColorTheme: ['', [Validators.required]],
      routeLink: ['', [Validators.required]],
    });

    linkButtonFormGroup.patchValue(linkButton);

    this.linkButtonsControl.push(linkButtonFormGroup);
  }

  public removeLinkButton(linkButtonIndex: number) {
    this.linkButtonsControl.removeAt(linkButtonIndex);
  }

  public initDataSource(dataSource: any): void {
    this.dataSourceObject = dataSource;
  }

  public onCreate(): void {
    Object.assign(this.tileDefinition, {
      tileType: 'banner',
      heading: this.headingControl.value,
      headingIcon: this.headingIconControl.value,
      displayFeatureTotal: this.displayFeatureTotalControl.value,
      subHeading: this.subHeadingControl.value,
      imgUri: this.imgUriControl.value,
      customHeight: '25vh',
      linkButtons: this.linkButtonsControl.value,
      canView: 'true',
    });
    this.tileCreatorService.onChange.next();
  }
}
