import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterConditionType } from '../../../../../shared/interfaces/filters/filter-condition-type.enum';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FilterOptionComponent } from '../filter-option/filter-option.component';

@Component({
  selector: 'suvo-bi-between-boxes-filter-option',
  templateUrl: './between-boxes-filter-option.component.html',
  styleUrls: ['./between-boxes-filter-option.component.scss'],
})
export class BetweenBoxesFilterOptionComponent
  extends FilterOptionComponent
  implements OnInit
{
  formGroup: FormGroup;

  constructor() {
    super();

    this.formGroup = new FormGroup({
      greaterThan: new FormControl(),
      lessThan: new FormControl(),
    });
    this.hookForm(this.formGroup);
  }

  getFilterRules(values: any): FilterRule[] {
    const key = this.option.filterPropertyKeys[0];

    const { lessThan, greaterThan } = values;
    if (!lessThan && !greaterThan) {
      return [];
    }

    const property: IFilterProperty = {
      key,
      con: [],
    };
    if (lessThan) {
      property.con.push({
        val: lessThan,
        type: FilterConditionType.LessThanEquals,
      });
    }
    if (greaterThan) {
      property.con.push({
        val: greaterThan,
        type: FilterConditionType.GreaterThanEquals,
      });
    }
    return [property];
  }

  patchRules(rules: FilterRule[]) {
    this.formGroup.controls.lessThan.patchValue(null, { emitEvent: false });
    this.formGroup.controls.greaterThan.patchValue(null, { emitEvent: false });

    rules?.forEach((property: IFilterProperty) => {
      property.con?.forEach((condition) => {
        if (condition.type == FilterConditionType.LessThanEquals) {
          this.formGroup.controls.lessThan.patchValue(condition.val, {
            emitEvent: false,
          });
        } else if (condition.type == FilterConditionType.GreaterThanEquals) {
          this.formGroup.controls.greaterThan.patchValue(condition.val, {
            emitEvent: false,
          });
        }
      });
    });
  }
}
