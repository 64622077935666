import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule, PipesModule } from '@suvo-bi-core';
import { AngularMaterialModule } from './modules/angular-material/angular-material.module';
import { GeneralPromptSaveDialogComponent } from './general-prompt-save-dialog/general-prompt-save-dialog.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation.component';

@NgModule({
  declarations: [GeneralPromptSaveDialogComponent, ConfirmationDialogComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    CommonComponentsModule,
  ],
  exports: [
    AngularMaterialModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    CommonComponentsModule,
    ConfirmationDialogComponent
  ],
})
export class SharedModule {}
