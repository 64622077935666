<!-- TODO: Nice way to center the UI on desktop but preserve mobile styles. -->
<div class="login-container" *ngIf="loginStage === 1 && !showForgotPassword">
  <p *ngIf="token">Please login to join the organization that you've been invited to</p>
  <h2 *ngIf="loginComponentOptions?.titleText && hideChildTitles">{{loginComponentOptions.titleText}}</h2>
  <form ngNoForm [formGroup]="loginForm" *ngIf="loginForm" (keyup.enter)="submitLogin()">
    <mat-form-field appearance="outline">
      <mat-label> Email Address </mat-label>
      <input matInput formControlName="email" type="email">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> Password </mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" type="password"
        (keydown.enter)="$event.preventDefault(); submitLogin()">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex="-1" type="button">
        <mat-icon> {{hide ? 'visibility_off' : 'visibility'}} </mat-icon>
      </button>
    </mat-form-field>
  </form>
  <p *ngIf="loginErr" class="submit-error">{{loginErr}}</p>
  <p *ngIf="loginHelp" class="submit-info">{{loginHelp}}</p>
  <button color="primary" mat-flat-button (click)="submitLogin()" type="submit"> Log in </button>
  <p (click)="toggleShowForgotPassword()" class="forgot-pass-link">Forgot password?</p>
  <div class="register-container" *ngIf="loginComponentOptions?.showRegister">
    <hr>
    <button class="register-btn" mat-flat-button (click)="goToRegister()"> Sign up </button>
  </div>
</div>

<div class="login-container" *ngIf="loginStage === 2 && mfaResolver && !showForgotPassword">
  <lib-verify-mfa [mfaResolver]="mfaResolver"></lib-verify-mfa>
</div>

<!-- Forgot password -->
<div class="login-container" *ngIf="showForgotPassword">
  <h2 *ngIf="loginComponentOptions?.titleText && hideChildTitles">{{loginComponentOptions.titleText}}</h2>
  <ng-container *ngIf="!forgotPasswordRequestSent">
    <p>Please enter your email address to reset your password</p>
    <form ngNoForm [formGroup]="forgotPasswordForm" *ngIf="forgotPasswordForm">
      <mat-form-field appearance="outline">
        <mat-label> Email Address</mat-label>
        <input matInput formControlName="email" type="email">
      </mat-form-field>
    </form>
    <button color="primary" mat-flat-button (click)="sendForgotPassword()"
      [disabled]="forgotPasswordRequestSent || !forgotPasswordForm.value.email"> Submit </button>
    <br>
    <button mat-stroked-button (click)="toggleShowForgotPassword()"> Cancel </button>
    <p class="submit-error" *ngIf="forgotPasswordError">{{forgotPasswordError}}</p>
  </ng-container>
  <p class="forgot-pass-confirm" *ngIf="forgotPasswordRequestSent">A password reset email has been sent to {{forgotPasswordForm.value.email}}. Please click the link in that email to set a new password.</p>
</div>
