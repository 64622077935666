import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSourceDefinitionsService } from '../services/data-source-definitions.service';
import { DataSourcePropertiesService } from '../services/data-source-properties.service';
import { SharedModule } from '../../../../shared/shared.module';
import { NewDataSourceDefinitionsService } from '../services/new-data-source-definitions.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    DataSourceDefinitionsService,
    NewDataSourceDefinitionsService,
    DataSourcePropertiesService
  ]
})
export class DataSourceDefinitionsModule { }
