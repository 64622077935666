import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { IContact } from '../../../dashboards/interfaces/tiles/definition-helpers/contact.interface';
import { IContactsTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/contacts-tile-definition.interface';
import { CONTENT_ALIGNMENT_OPTIONS } from '../../constants/content-alignment-options.constant';
import { HORIZONTAL_POSITION_OPTIONS } from '../../constants/horizontal-position-options.constant';
import { VERTICAL_POSITION_OPTIONS } from '../../constants/vertical-position-options.constant';
import { ISelectOption } from '../../interfaces/select-option.interface';
import { TileCreatorHelpersService } from '../../services/tile-creator-helpers.service';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'lib-contacts-setup-wizard',
  templateUrl: './contacts-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './contacts-setup-wizard.component.scss'],
})
export class ContactsSetupWizardComponent implements OnInit, OnDestroy {
  tileDefinition: IContactsTileDefinition;

  public formGroup: FormGroup;

  public get contactsControl(): FormArray<FormGroup> {
    return this.formGroup.get('contacts') as FormArray<FormGroup>;
  }

  public contentAlignmentOptions: ISelectOption[];
  public positionsOptions: ISelectOption[];
  public horizontalPositionOptions: ISelectOption[];
  public fontSizesOptions: string[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorHelpersService: TileCreatorHelpersService,
    private readonly tileCreatorService: TileCreatorService,
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSelectOptions();
    this.initSetupForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initSelectOptions(): void {
    this.contentAlignmentOptions = CONTENT_ALIGNMENT_OPTIONS;
    this.positionsOptions = VERTICAL_POSITION_OPTIONS;
    this.horizontalPositionOptions = HORIZONTAL_POSITION_OPTIONS;
    this.fontSizesOptions = this.tileCreatorHelpersService.generateFontSize();
  }

  public initSetupForms(): void {
    this.formGroup = this.fb.group({
      contacts: this.fb.array([]),
      background: [''],
    });

    if (this.tileDefinition) {
      this.formGroup.patchValue(this.tileDefinition);

      this.tileDefinition.contacts?.forEach((contact: IContact) => {
        this.addContact(contact);
      });
    } else {
      this.addContact();
    }
  }

  public addContact(contact?: IContact): void {
    const contactFormGroup = this.fb.group({
      fullName: [''],
      fullNameFontSize: ['6px'],
      fullNameFontColor: ['#000000'],
      role: [''],
      roleFontSize: ['6px'],
      roleFontColor: ['#000000'],
      text: [''],
      textFontSize: ['6px'],
      textFontColor: ['#000000'],
      phone: [''],
      email: [''],
      img: [''],
      imgPosition: ['start'],
    });

    contactFormGroup.patchValue(contact);

    this.contactsControl.push(contactFormGroup);
  }

  public removeContact(contactIndex: number) {
    this.contactsControl.removeAt(contactIndex);
  }

  public onCreate() {
    Object.assign(this.tileDefinition, {
      tileType: 'contacts',
      canView: 'true',
      ...this.formGroup.value,
    });
    this.tileCreatorService.onChange.next();
  }
}
