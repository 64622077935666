import { NgModule } from "@angular/core";
import { UploadComponent } from "./componets/upload/upload.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UploadControl } from "./controls/upload/upload.control";
import { MediaService } from "./services/media.service";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    UploadComponent,
    UploadControl,
  ],
  providers: [
    MediaService,
  ],
  exports: [
    UploadComponent,
    UploadControl,
  ]
})
export class MediaModule { }
