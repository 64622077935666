<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch stretch">

  <div fxFlex="1 1" [fxLayout]="isVertical ? 'row' : 'column'"
    [fxLayoutAlign]="isVertical ? 'space-between flex-end' : 'space-between flex-start'">

    <ng-container *ngFor="let barHeight of barHeights">

      <div [ngStyle]="{
        'height': (isVertical ? barHeight : barWidth),
        'width': (isVertical ? barWidth : barHeight)
      }">

        <ngx-skeleton-loader [theme]="{
          height: '100%',
          width: '100%'
        }"></ngx-skeleton-loader>

      </div>

    </ng-container>

  </div>

</div>
