import { Component, Inject, InjectionToken } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ChecklistComponent, ChecklistItem } from '@suvo-bi-core';
import * as Items from './items';

const SHOP_SETUP_CHECKLIST_ITEMS = new InjectionToken<ChecklistItem[]>(
  'Shop setup checklist entries',
);

@Component({
  standalone: true,
  imports: [ChecklistComponent, MatCardModule],
  providers: Object.values(Items).map((useClass) => ({
    multi: true,
    provide: SHOP_SETUP_CHECKLIST_ITEMS,
    useClass,
  })),
  selector: 'app-shop-setup-checklist',
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title>Setup Checklist</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <suvo-core-checklist [items]="items" />
      </mat-card-content>
    </mat-card>
  `,
})
export class ShopSetupChecklistComponent {
  constructor(@Inject(SHOP_SETUP_CHECKLIST_ITEMS) readonly items: ChecklistItem[]) {}
}
