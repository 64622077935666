<div class="outer-container">
  <div class="card slim-margin">
    <mat-expansion-panel
      [expanded]="true"
      class="expansion transition"
      [ngClass]="{ 'primary-background': !expansionOpen }"
      (opened)="expansionOpened()"
      (closed)="expansionClosed()"
      (afterExpand)="expansionFinished()"
      #expansionPanel
      [hideToggle]="!enableAccordionClose"
    >
      <mat-expansion-panel-header
        [ngClass]="{ disabled: !enableAccordionClose }"
      >
        <!-- <mat-panel-title class=" transition">
        <mat-icon class="icon">filter_alt</mat-icon> Custom Filters
      </mat-panel-title> -->
        <mat-panel-description class="transition description">
          <!-- {{ filtersSidenav.selectedFilters?.name || 'No Custom Filters.' }} -->
          <!-- Search {{ filtersSidenav.filtersChannel?.channelName | titlecase }} -->
          Refine your selection using the filters below
        </mat-panel-description>
      </mat-expansion-panel-header>

      <!-- <suvo-bi-edit-filters #editFiltersRef fxFlex="1 1"></suvo-bi-edit-filters> -->

      <div
        class="container container-height"
        fxFlex="1 1"
        fxLayout="column"
        fxLayoutAlign="stretch stretch"
      >
        <mat-divider></mat-divider>
        <suvo-bi-filters-content
          [filtersChannel]="filtersChannel"
          [insertStyles]="{
            position: 'relative'
          }"
          [autoApplyFilters]="autoApplyFilters"
          (filtersManuallyApplied)="onFiltersApplied()"
          [exportDisabled]="exportDisabled"
          [showFilterHeader]="false"
        >
        </suvo-bi-filters-content>
      </div>
      <!--  s-->

      <!--
    <mat-action-row>
      <button mat-button color="primary">
        <mat-icon>filter_alt</mat-icon>Saved Filters
      </button>
      <button mat-button color="primary">
        <mat-icon>search</mat-icon>Search
      </button>
    </mat-action-row> -->
    </mat-expansion-panel>
  </div>
</div>
