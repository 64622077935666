<h2>What will you be using the platform for?</h2>

<small>Select all that apply. This information helps us to personalise your experience and get you started.</small>

<app-step-multiple-choice
  [none]="{
    name: 'unsure',
    label: 'I\'m not sure yet.'
  }"
  [checkboxes]="checkboxes"
  [parentFormGroup]="parentFormGroup"
  [formGroup]="formGroup"
  [controlName]="controlName"
></app-step-multiple-choice>
