<ng-container *ngIf="editItemMode && control; else view">

  <div fxFlex="1 1" class="field-container">
    <mat-form-field fxFlex="1 1">
      <!-- <mat-label>{{ property.displayName }}</mat-label> -->

      <ng-container [ngSwitch]="property.type">

        <ng-container *ngSwitchCase="'INT'">
          <input fxFlex="nogrow" type="number" matInput [formControl]="control" [placeholder]="property.displayName"
            class="property-value">
          <span *ngIf="property.displayOptions?.unit" class="property-units">{{ property.displayOptions.unit }}</span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <textarea cdkTextareaAutosize matInput [formControl]="control" [placeholder]="property.displayName"
            class="property-value"></textarea>
        </ng-container>

        <ng-container *ngSwitchCase="'REFERENCE'">

          <mat-select [formControl]="control">

            <mat-select-trigger fxLayoutAlign="center center" style="padding-left: 18px">
              <ng-container *ngTemplateOutlet="display; context: { value: control.value }">
              </ng-container>
            </mat-select-trigger>

            <ng-container *ngFor="let option of referenceOptions; trackBy: trackObject">
              <mat-option [value]="option">
                <ng-container *ngTemplateOutlet="display; context: { value: option }">
                </ng-container>
              </mat-option>
            </ng-container>

          </mat-select>

        </ng-container>

      </ng-container>


    </mat-form-field>

  </div>

</ng-container>



<ng-template #view>
  <ng-container *ngTemplateOutlet="display; context: { value }"></ng-container>
</ng-template>

<ng-template #display let-value="value">
  <ng-container *ngTemplateOutlet="isReference ? reference : notReference; context: { value }">
  </ng-container>
  <!-- <ng-container *ngTemplateOutlet="property.displayOptions?.isChippable ? chip : basicValue; context: { value }">
  </ng-container> -->
</ng-template>
<ng-template #notReference let-value="value">
  <ng-container *ngTemplateOutlet="isChippable ? chip : config?.raw ? rawValue : basicValue; context: { value }">
  </ng-container>
</ng-template>
<ng-template #reference let-value="value">
  <ng-container *ngIf="isReference && property.referenceOptions.type == 'one_to_one'">
    <ng-container *ngTemplateOutlet="notReference; context: { value: value[referenceDisplayProperty] }">
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isReference && property.referenceOptions.type == 'one_to_many'">
    <ng-container *ngFor="let item of value">
      <ng-container *ngTemplateOutlet="notReference; context: { value: item[referenceDisplayProperty] }">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #chip let-value="value">
  <div fxFlex="nogrow" class="chip" [style]="getChipStyles(value, property)" fxLayoutAlign="center center">
    <span>{{ value }}</span>
  </div>
</ng-template>

<ng-template #basicValue let-value="value">
  <div fxLayout="row" *ngIf="value">
    <p class="property-value">{{ value }}</p>
    <span *ngIf="property.displayOptions?.unit" class="property-units">{{ property.displayOptions.unit }}</span>
  </div>
</ng-template>

<ng-template #rawValue let-value="value">
  <!-- <mat-icon class="property-value">link</mat-icon> -->
  <div class="property-value" [innerHTML]="value"></div>
</ng-template>