import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IBiLink } from '../../../../shared/interfaces/bi-link.interface';
import { MANAGE_IDENTITY_DATA_SOURCE_ROUTES } from '../../data/data-source-routes.constant';

@Component({
  selector: 'suvo-bi-select-data-source-screen',
  templateUrl: './select-data-source-screen.component.html',
  styleUrls: ['./select-data-source-screen.component.scss']
})
export class SelectDataSourceScreenComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();

  selectLinks = MANAGE_IDENTITY_DATA_SOURCE_ROUTES;

  heading: string;
  intro: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    let allowedAliases = this.activatedRoute.snapshot.parent.data?.allowedAliases;

    if(allowedAliases) {
      this.selectLinks = this.selectLinks.filter((selectLink) => {
        return allowedAliases.find((allowedAlias) => {
          return allowedAlias == selectLink.route;
        });
      });
    }

    this.router.events.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.inferHeading();
      }
    })

    this.inferHeading();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  inferHeading(): void {

    this.heading = this.activatedRoute.snapshot.parent.data?.breadcrumb;
    this.intro = this.activatedRoute.snapshot.parent.data?.intro;

  }

}
