import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditProfileModule, SubscriptionModule } from '@suvo-bi-users';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../../shared/shared.module';
import { DataSourceFavouritesModule } from '../data-source/data-source-favourites/data-source-favourites.module';
import { FiltersModule } from '../filters/filters.module';
import { ListNavigationModule } from '../list-navigation/list-navigation.module';
import { ManageBiSharedModule } from '../manage-bi-shared/manage-bi-shared.module';
import { OrganisationViewComponent } from './components/screen-parts/organisation-view/organisation-view.component';
import { BillingDetailsComponent } from './components/screen-parts/billing-details/billing-details.component';
import { EmailAlertLibModule } from '../email-alert/email-alert.module';
import { ToggleGroupNavigationModule } from '../toggle-group-navigation/toggle-group-navigation.module';

@NgModule({
  declarations: [
    OrganisationViewComponent,
    BillingDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ManageBiSharedModule,
    FiltersModule,
    EditProfileModule,
    SubscriptionModule,
    ListNavigationModule,
    DataSourceFavouritesModule,
    EmailAlertLibModule,
    NgxSkeletonLoaderModule,
    ToggleGroupNavigationModule,
  ]
})
export class ManageBiIdentityModule { }
