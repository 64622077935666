<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <span class="header">{{ option.filterMethodConfig?.title || 'Between Boxes' }}</span>

  <div fxFlex="0 1" fxLayout="row" fxLayoutGap="8px" [formGroup]="formGroup">
    <div fxFlex="1 1 calc(50% - 4px)">
      <!-- Greater Than -->
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Minimum</mat-label>
        <input
          #greaterThanInput
          type="number"
          formControlName="greaterThan"
          matInput
          placeholder="0"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <div fxFlex="1 1 calc(50% - 4px)">
      <!-- Less Than -->
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Maximum</mat-label>
        <input
          #lessThanInput
          type="number"
          formControlName="lessThan"
          matInput
          placeholder="0"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
  </div>
</div>
