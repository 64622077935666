import { Component, OnDestroy, inject } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { IChartTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/chart-tile-definition.interface';
import { CHART_TYPES } from '../../constants/chart-types.constant';
import { HARDCODED_METRICS } from '../../constants/hardcoded-metrics.constant';
import { SORT_ORDERS } from '../../constants/sort-orders.constant';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'app-chart-setup-wizard',
  templateUrl: './chart-setup-wizard.component.html',
  styleUrls: ['./chart-setup-wizard.component.scss'],
})
export class ChartSetupWizardComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly tileDefinition: IChartTileDefinition;

  readonly formGroup = new FormGroup({
    title: new FormControl(),
    dataSource: new FormControl(),
    chartType: new FormControl(),
    groupBy: new FormControl(),
    metric: new FormControl(),
    sortOrder: new FormControl(),
  });

  public dataSourceObject;

  public chartTypes = CHART_TYPES;
  public sortOrders = SORT_ORDERS;
  public availableDataSourceGroups = [];
  public availableDataSourceMetrics = [];

  public get titleControl(): AbstractControl {
    return this.formGroup.get('title') as AbstractControl;
  }

  public get dataSourceControl(): AbstractControl {
    return this.formGroup.get('dataSource') as AbstractControl;
  }

  public get chartTypeControl(): AbstractControl {
    return this.formGroup.get('chartType') as AbstractControl;
  }

  public get groupByControl(): AbstractControl {
    return this.formGroup.get('groupBy') as AbstractControl;
  }

  public get metricControl(): AbstractControl {
    return this.formGroup.get('metric') as AbstractControl;
  }

  public get sortOrderControl(): AbstractControl {
    return this.formGroup.get('sortOrder') as AbstractControl;
  }

  constructor(private readonly tileCreatorService: TileCreatorService) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;

    if (this.tileDefinition) {
      this.formGroup.patchValue({
        title: this.tileDefinition.title,
        dataSource: this.tileDefinition.recipe?.dataSource,
        chartType: this.tileDefinition.chartType?.value,
        groupBy: this.tileDefinition.recipe?.groupBy,
        metric: this.tileDefinition.recipe?.metric,
        sortOrder: this.tileDefinition.recipe?.sortOrder,
      });
    }

    this.dataSourceControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.formGroup.patchValue({
        groupBy: '',
        metric: '',
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initDataSource(dataSource: any): void {
    this.dataSourceObject = dataSource;
    this.availableDataSourceGroups = dataSource.properties;
    this.availableDataSourceMetrics = dataSource.properties.filter((property) => {
      return property.type === 'INT';
    });
    this.availableDataSourceMetrics.unshift(...HARDCODED_METRICS);
  }

  public onCreate() {
    Object.assign(this.tileDefinition, {
      tileType: 'chart',
      title: this.titleControl.value,
      subTitle: '',
      recipe: {
        dataSource: this.dataSourceControl.value,
        metric: this.metricControl.value,
        groupBy: this.groupByControl.value,
        sortOrder: this.sortOrderControl.value,
        maxGroups: 16,
        includeOtherGroup: true,
      },
      chartType: {
        name: 'Line Chart',
        icon: 'show_chart',
        value: this.chartTypeControl.value,
        labels: true,
        trimLabels: false,
        showLegend: false,
        formatLabels: true,
      },
      canView: 'true',
      backgroundColor: 'white',
    });
    this.tileCreatorService.onChange.next();
  }
}
