import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrganisationInvitationDialogComponent, PublicOrganisationRoleModel, PublicOrganisationRoleService, UsersDialogService } from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManageBiOrganisationsService } from '../../../services/manage-bi-organisations.service';

@Component({
  selector: 'suvo-bi-organisation-members-view',
  templateUrl: './organisation-members-view.component.html',
  styleUrls: ['./organisation-members-view.component.scss']
})
export class OrganisationMembersViewComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  memberListLoaded: 'loading' | 'loaded' | 'none' = 'none';

  selectedOrganisation = new FormControl();
  selectedOrganisationMembers: PublicOrganisationRoleModel[];

  constructor(
    private publicOrganisationRoleService: PublicOrganisationRoleService,
    private readonly manageBiOrganisationsService: ManageBiOrganisationsService,
    private readonly usersDialogService: UsersDialogService

  ) { }

  ngOnInit(): void {
    this.manageBiOrganisationsService.selectedSettingsOrgRoleSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((updatedOrgRole) => {
      if (updatedOrgRole) {
        this.selectedOrganisation.setValue(updatedOrgRole);
        this.loadMembers(updatedOrgRole.organisationId);
      }
    })
  }

  async loadMembers(organisationId: string): Promise<void> {
    this.memberListLoaded = 'loading';
    this.selectedOrganisationMembers = await this.publicOrganisationRoleService.getMemberList(organisationId) as PublicOrganisationRoleModel[];
    this.memberListLoaded = 'loaded';
  }

  openInvitationDialog(): void {
    this.usersDialogService.open(OrganisationInvitationDialogComponent, {
      organisationId: this.selectedOrganisation.value?.organisationId
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
