import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { IContactConfig } from '../interfaces/contact-config.interface';
import { CONTACT_CONFIG } from '../providers/contact_config_provider.token';
import { DataSourceSettingsRef } from '../../data-source/interfaces/data-source-settings.token';
import { IDataSourceSettings } from '../../data-source/interfaces/data-source-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
    @Inject(CONTACT_CONFIG) private config: IContactConfig
  ) {}

  getDialogParams() {
    const isMobile = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);

    if (isMobile) {
      return {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
      };
    } else {
      return {};
    }
  }

  sendEmail(emailForm: any) {
    let url = this.config.contactEndpoint + 'messages';
    const body = new HttpParams()
      .set('form-name', 'contact')
      .append('senderName', emailForm.senderName)
      .append('senderEmail', emailForm.senderEmail)
      .append('messageText', emailForm.messageText);
    this.http
      .post(url, body.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'text',
      })
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.snackBar.open(`Your message has been sent!`, 'Ok', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['successCssSnackBar'],
          });

          this.matDialog.closeAll();
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  private handleError(err: HttpErrorResponse) {
    this.snackBar.open(
      `Something went wrong when sending your message.`,
      'Ok',
      {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['failureCssSnackBar'],
      }
    );

    throw Error(err.message);
  }
}
