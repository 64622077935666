import { Injectable } from '@angular/core';
import { parse } from 'csv-parse/browser/esm';
import { DEFAULT_CSV_PARSE_OPTIONS } from '../constants/csv-parse-defaults.constant';
import { CsvParseOptions } from '../interfaces/csv-parse-options.interface';
import { CsvParseResult } from '../interfaces/csv-parse-result.interface';

@Injectable()
export class CsvParseBrowserService {
  private convert(input: string | ArrayBuffer | Buffer): string | Buffer {
    if (input instanceof ArrayBuffer) {
      const output = Buffer.alloc(input.byteLength);
      const view = new Uint8Array(input);
      for (const index in input) {
        input[index] = view[index];
      }
      return output;
    }

    return input;
  }

  parse(
    csv: string | ArrayBuffer | Buffer,
    parseOptions: CsvParseOptions = {}
  ): Promise<CsvParseResult> {
    return new Promise<CsvParseResult>((resolve, reject) => {
      const input = this.convert(csv);
      const options = {
        ...DEFAULT_CSV_PARSE_OPTIONS,
        ...parseOptions,
        columns: false,
      };

      if (!input.includes(options.delimiter)) {
        reject(Error('Invalid column delimiter'));
      }

      try {
        parse(input, options, (error, records, info) => {
          if (error) {
            reject(error);
          } else {
            resolve({ records, info });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
