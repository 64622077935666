import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartGridComponent } from './components/smart-grid/smart-grid.component';
import { TimestampsToolbarComponent } from './components/timestamps-toolbar/timestamps-toolbar.component';
import { TileJsonDialogComponent } from './components/tile-json-dialog/tile-json-dialog.component';
import { SkeletonGridComponent } from './components/skeleton-grid/skeleton-grid.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../../shared/shared.module';
import { CommonComponentsModule } from '@suvo-bi-core';
import { TileCreatorModule } from '../tile-creator/tile-creator.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SmartGridComponent,
    TimestampsToolbarComponent,
    TileJsonDialogComponent,
    SkeletonGridComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    CommonComponentsModule,
    TileCreatorModule,
    MatTooltipModule,
  ],
  exports: [SmartGridComponent, SkeletonGridComponent, TimestampsToolbarComponent],
})
export class SmartGridModule {}
