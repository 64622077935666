<div class="outlet-container" fxLayout="column" fxLayoutAlign="flex-start center">

  <div class="sidenav-container-position">
    <suvo-bi-list-navigation [showIcons]="true" [links]="manageOrganisationsLinks" class="sidenav-position" fxShow fxHide.lt-md>
    </suvo-bi-list-navigation>
  </div>

  <div [ngClass]="{'outlet-padding': !viewingSubscriptionsScreen, 'subscription-outlet-padding': viewingSubscriptionsScreen}">
    <suvo-core-route-toggle-group fxShow.lt-md fxHide></suvo-core-route-toggle-group>

    <router-outlet></router-outlet>
  </div>
</div>