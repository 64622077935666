import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'lib-verify-email-confirmation',
  templateUrl: './verify-email-confirmation.component.html',
  styleUrls: ['./verify-email-confirmation.component.scss']
})
export class VerifyEmailConfirmationComponent implements OnInit {

  response!: string;
  successfulResponse!: boolean;
  redirectCountdown = 5;
  redirectUrl = '/login';

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private angularFirebaseAuth: AngularFireAuth,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (params.mode === 'verifyEmail' && (params.oobCode || params.verifyLink)) {
        if(params.oobCode){
          this.handleVerifyEmailWithOOBCode(params.oobCode);
        } else if(params.verifyLink) {
          let decodedLink = atob(decodeURIComponent(params.verifyLink));

          // this.handleVerifyEmailWithLink(decodedLink);

          let searchParams = new URLSearchParams(decodedLink);

          let oobCode = searchParams.get("oobCode");

          this.handleVerifyEmailWithOOBCode(oobCode);
        }
      }
      
      else {
        this.response = 'Something went wrong';
        this.successfulResponse = false;
      }
    });
  }

  handleVerifyEmailWithOOBCode(actionCode: string): void {
    this.angularFirebaseAuth.applyActionCode(actionCode).then((res) => {
      this.response = ""; //'Your email has been verified successfully';
      this.successfulResponse = true;
      this.updateRedirectCountdown();
    }).catch((e) => {
      console.log(e);
      this.response = 'Unfortunately we were unable to verify your account. The link you used may have expired, please try logging in again to request a new verification code.';
      this.successfulResponse = false;
    });
  }

  handleVerifyEmailWithLink(link: string): void {
    this.http.get(link).toPromise().then((res) => {
      this.response = ""; //'Your email has been verified successfully';
      this.successfulResponse = true;
      this.updateRedirectCountdown();
    }).catch((e) => {
      console.log(e);
      this.response = 'Unfortunately we were unable to verify your account. The link you used may have expired, please try logging in again to request a new verification code.';
      this.successfulResponse = false;
    });
  }

  updateRedirectCountdown() {
    if (this.redirectCountdown > 0) {
      --this.redirectCountdown;
      setTimeout(() => {
        this.updateRedirectCountdown();
      }, 1000);
    }
    else {
      this.router.navigateByUrl(this.redirectUrl);
    }
  }
}
