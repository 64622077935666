import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersChannel } from '../../../../../filters/models/filters-channel.model';
import { ITileDefinition } from '../../../../interfaces/tiles/definition-helpers/tile-definition.interface';

@Component({
  selector: 'suvo-bi-filters-info-tile',
  templateUrl: './filters-info-tile.component.html',
  styleUrls: ['./filters-info-tile.component.scss'],
})
export class FiltersInfoTileComponent implements OnInit, OnDestroy {
  @Input() tileDefinition: ITileDefinition;
  @Input() filtersChannel: FiltersChannel;

  public filtersJson: string;

  private unsubscribe$ = new Subject<boolean>();

  public ngOnInit(): void {
    this.filtersChannel
      ?.filters.pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters) => {
        this.filtersJson = JSON.stringify(filters, null, 4);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
