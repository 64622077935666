import { Inject, Injectable } from '@angular/core';

@Injectable()
export class DashboardSettingsService {
  usersServiceApi: string;
  dataServiceApi: string;
  filtersButtonTour: boolean;
  canEditDashboards: boolean;

  constructor(@Inject(Object) public config: any) {}
}
