import { Component, Input } from '@angular/core';
import { ILinkButton } from '../../../../dashboards/interfaces/tiles/definition-helpers/link-button.interface';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

const IntegerValidator: ValidatorFn = (control) => {
  return Number.isInteger(control.value) ? null : { notInteger: true };
};

class FontSizeFormControl extends FormControl<number> {
  constructor(defaultValue?: number) {
    super(defaultValue, [Validators.min(6), Validators.max(64), IntegerValidator]);
  }
}

@Component({
  selector: 'lib-links-editor',
  templateUrl: './links-editor.component.html',
  styleUrl: './links-editor.component.scss',
})
export class LinksEditorComponent {
  @Input() linksFormArray;
  @Input() tileDefinition;

  ngOnInit() {
    this.tileDefinition?.linkButtons?.forEach((button) => {
      this.addButton(button);
      if (button.buttonFontSize) {
        this.linksFormArray
          .at(-1)
          .controls.buttonFontSize.setValue(parseInt(button.buttonFontSize.slice(0, -2)));
      }
    });
  }

  addButton(value?: ILinkButton) {
    this.linksFormArray.push(
      new FormGroup({
        buttonBackgroundColor: new FormControl(value?.buttonBackgroundColor ?? '#000000'),
        buttonFontColor: new FormControl(value?.buttonFontColor ?? '#000000'),
        buttonFontSize: new FontSizeFormControl(value.buttonFontSize ? value.buttonFontSize : 12),
        buttonIcon: new FormControl(value.buttonIcon),
        buttonText: new FormControl(value.buttonText),
        routeLink: new FormControl(value.routeLink),
      }),
    );
  }

  removeButton(index: number) {
    this.linksFormArray.removeAt(index);
  }
}
