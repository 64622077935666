import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() public logoTheme: 'navy' | 'white';
  @Input() public route?: string;
  @Input() public logoUrl;

  constructor(private readonly router: Router) {}

  public navigate(): void {
    this.router.navigate([this.route ? this.route : '']);
  }
}
