import { Component } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FilterOptionComponent } from '../filter-option/filter-option.component';

@Component({
  selector: 'suvo-bi-keywords-filter-option',
  templateUrl: './keywords-filter-option.component.html',
  styleUrls: ['./keywords-filter-option.component.scss'],
})
export class KeywordsFilterOptionComponent extends FilterOptionComponent {
  formGroup!: FormGroup;

  constructor() {
    super();

    this.formGroup = new FormGroup({
      keywords: new FormControl(),
    });
    this.hookForm(this.formGroup);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  hookForm(formGroup: FormGroup): void {
    formGroup.valueChanges.pipe(debounceTime(50), takeUntil(this.unsubscribe$)).subscribe((values: any) => {
      this.option.filterRules = this.getFilterRules(values);
      this.rulesChanged.emit();
    });
  }

  getFilterRules(values: { keywords: string }): FilterRule[] {
    const { keywords } = values;

    if (!keywords) {
      return [];
    }

    const key = this.option.filterPropertyKeys[0];

    const property: IFilterProperty = {
      key,
      in: keywords || '',
      useForSearch: this.option.filterMethodConfig.useForSearch,
    };

    return [property];
  }

  async patchRules(rules: FilterRule[]): Promise<void> {
    if (rules?.length) {
      const property = rules[0] as IFilterProperty;
      this.formGroup.controls.keywords.patchValue(property.in, {
        emitEvent: false,
      });
    } else {
      this.formGroup.controls.keywords.patchValue(null, { emitEvent: false });
    }
  }
}
