import { computed, Injectable } from '@angular/core';
import { AuthService } from '@suvo-bi-core';
import { CurrentTenantService } from './current-tenant.service';

@Injectable({ providedIn: 'root' })
export class CurrentTenantRoleService {
  constructor(
    private readonly authService: AuthService,
    private readonly currentTenantService: CurrentTenantService,
  ) {}

  readonly currentTenantRole = computed(async () => {
    const currentTenantId = this.currentTenantService.currentTenant()?._id;
    const currentTenantRoles = await this.authService.currentUserTenantRoles();

    if (currentTenantId && currentTenantRoles) {
      for (const [tenantId, role] of Object.entries(currentTenantRoles)) {
        if (tenantId && currentTenantId === tenantId) {
          return role;
        }
      }
    }

    return 'NONE';
  });
}
