import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBiLink } from '@suvo-bi-lib';
import { TenantRoleService } from '@suvo-bi-users';
import { Observable, Subject } from 'rxjs';
import { EDITING_NAV_LINKS } from '../../data/editing-nav-links';
import { NAV_LINKS } from '../../data/nav-links';
import { MAJOR_NAV_LINKS } from '../../data/major-nav-links';
import { SETTINGS_NAV_LINKS } from '../../data/settings-nav-links';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  majorLinks = MAJOR_NAV_LINKS;
  dataLinks$: Observable<IBiLink[]>;
  adminLinks = NAV_LINKS;
  editingLinks = EDITING_NAV_LINKS;
  settingsLinks = SETTINGS_NAV_LINKS;

  currentRole;

  constructor(
    private navigationService: NavigationService,
    private tenantRoleService: TenantRoleService,
  ) {}

  async ngOnInit() {
    this.dataLinks$ = this.navigationService.createDataLinksObservable(this.unsubscribe$);

    await this.tenantRoleService.loadRole();

    this.currentRole = (await this.tenantRoleService.getCurrentRole()).role;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  hasRoles(roles) {
    return this.currentRole && roles.includes(this.currentRole);
  }

  handleLogout() {}
}
