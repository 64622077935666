import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {AngularMaterialModule} from '../modules/angular-material/angular-material.module';
import {NiceCardComponent} from './components/nice-card/nice-card.component';
import { BasicStatComponent } from './components/basic-stat/basic-stat.component';

@NgModule({
  declarations: [NiceCardComponent, BasicStatComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [NiceCardComponent, BasicStatComponent],
})
export class VisualizationsModule {}
