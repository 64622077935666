import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IDataSourceDefinitionProperty } from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { IDataSourceItem } from '../../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { ITableSortDirection } from '../../../../../../shared/interfaces/table/table-sort.interface';
import { NewDataSourceDefinitionsService } from '../../../../data-source-definitions/services/new-data-source-definitions.service';
import { IChipStyles } from '../../../../data-source-items/interfaces/chip-styles.interface';
import { DataSourceItemChipsService } from '../../../../data-source-items/services/data-source-item-chips.service';
import { NewDataSourceItemsService } from '../../../../data-source-items/services/new-data-source-items.service';

@Component({
  selector: 'suvo-bi-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
})
export class PropertyComponent implements OnInit {
  @Input() property: IDataSourceDefinitionProperty;
  @Input() item: IDataSourceItem;
  @Input() control: FormControl;
  @Input() editItemMode: boolean;
  @Input() config: any = {};

  value: any;
  touched = false;

  referenceOptions: IDataSourceItem[];
  referencesLoading = false;

  isChippable = false;
  isReference = false;
  referenceDisplayProperty: string;

  constructor(
    private dataSourceItemChipsService: DataSourceItemChipsService,
    private dataSourceDefinitionsService: NewDataSourceDefinitionsService,
    private dataSourceItemService: NewDataSourceItemsService
  ) {}

  ngOnInit(): void {
    this.isChippable = !!this.property.displayOptions?.isChippable;

    if (this.property.referenceOptions?.formDefinitionId) {
      this.referenceDisplayProperty =
        this.property.referenceOptions.displayProperty;
      this.isReference = true;
    }

    this.setValue();
    this.loadReferenceData();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.setValue();

    if (simpleChanges.control) {
      this.loadReferenceData();
    }
  }

  setValue(): void {
    const key = this.property.name;
    this.value = this.control?.value || this.item?.data[key];
    this.touched = this.control && this.control.value !== this.item.data[key];
  }

  trackObject(index: number, object: IDataSourceItem): string {
    return object._id.toString();
  }

  public getChipStyles(
    value: any,
    property: IDataSourceDefinitionProperty
  ): IChipStyles {
    return this.dataSourceItemChipsService.getChipStylesFromValue(
      value,
      property
    );
  }

  async loadReferenceData(): Promise<void> {
    if (!this.control) return;
    if (this.referencesLoading) return;
    this.referencesLoading = true;

    if (!this.property.referenceOptions?.formDefinitionId) {
      return;
    }

    const referenceOptions = this.property.referenceOptions;
    const value = this.value;

    const definition = await this.dataSourceDefinitionsService.getOne(
      null,
      referenceOptions.formDefinitionId
    );
    const referenceDataSource = await this.dataSourceItemService.getPaginated(
      definition.alias,
      {
        sort: {
          property: `data.${this.referenceDisplayProperty}`,
          direction: ITableSortDirection.Ascending,
        },
      }
    );

    this.referenceOptions = referenceDataSource.data;
    if (value)
      this.control.patchValue(
        this.referenceOptions.find((d) => value._id === d._id)
      );
  }
}
