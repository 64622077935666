// DEPLOYED DEV
const usersServiceApi = 'https://users.api.suvo-services-dev.com/api/v1/';
const dataServiceApi = 'https://data.api.suvo-bi-develop.com/api/v1/';
const subscriptionServiceApi = 'https://subscriptions.api.suvo-bi-develop.com/api/v1/';
const mediaServiceApi = 'https://media.api.suvo-services-dev.com/api/v1';
const mediaStaticAssets = 'https://suvo-services-dev-media.s3.eu-west-1.amazonaws.com/suvo_bi';
const canEditDashboards = true;

export const environment = {
  usersServiceApi,
  dataServiceApi,
  subscriptionServiceApi,
  mediaServiceApi,
  mediaStaticAssets,
  title: 'IRJ BI',
  showRegisterDoBField: false,
  production: false,
  canEditDashboards,
  stripeClientId: 'ca_NDsYwaUPKnBzcqHWpZocewoN6LNADVKJ', //test
  stripePublicKey:
    'pk_test_51NUqdBJhaXb1PZs16kJG8tiAAKZHmvPVbIN9HsiI39gTLLO9qrSm9e3k6rNdO5pVGDEC2obiQyFElhlZEstN6pQ900vEztbx30',
  firebaseAuthDomain: 'suvo-bi-users-dev.firebaseapp.com',
  firebaseApiKey: 'AIzaSyBd26S29cSHejJmKiLPb4vRMonrHjgb8vc',
  platformFirebaseTenantID: 'suvo-tenant-admins-hewfi',
  platformId: 'suvo_bi',
  stripeTestMode: true,
};

export const suvoUsersClientLibConfig = {
  usersServiceApi,
  dataServiceApi,
  title: 'IRJ BI',
  showRegisterDoBField: false,
};
