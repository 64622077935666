import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProductService } from '@suvo-bi-users';
import { FiltersChannelsService } from '../../../filters/services/filters-channels.service';
import { EmailAlertService } from '../../services/email-alert.service';
import { SnackBarService } from '../../../../shared/services/snack/snack-bar.service';
import { IFilters } from '../../../../shared/interfaces/filters/filters.interface';
import { FiltersChannel } from '../../../filters/models/filters-channel.model';

@Component({
  selector: 'lib-manage-email-alerts',
  templateUrl: './manage-email-alerts.component.html',
  styleUrls: ['./manage-email-alerts.component.scss'],
})
export class ManageEmailAlertsComponent implements OnChanges {
  @Input() alias: string;

  unsubscribe$ = new Subject<boolean>();

  filtersChannelSuffix = 'email_alerts';
  loading = true;
  alertsEnabled = false;
  emailsAllowed = false;
  activeFilter: IFilters;
  filtersChannel: FiltersChannel;

  constructor(
    private filtersChannelsService: FiltersChannelsService,
    private emailAlertService: EmailAlertService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    private productService: ProductService
  ) {}

  ngOnChanges(values: SimpleChanges) {
    if (values.alias.previousValue !== values.alias.currentValue) this.loadFilters();
  }

  async loadFilters(): Promise<void> {
    this.loading = true;

    this.emailsAllowed = this.productService.isFeatureEnabled('EMAIL_ALERTS', ['DATASOURCE:' + this.alias]);

    if (this.emailsAllowed) {
      const existingSelectedFilter = await this.emailAlertService.getSelectedEmailFilter(this.alias);

      if (existingSelectedFilter) {
        this.alertsEnabled = true;

        this.loadFiltersChannel(existingSelectedFilter?._id);
      } else {
        this.alertsEnabled = false;
      }
    }

    this.loading = false;
  }

  loadFiltersChannel(filterId) {
    this.filtersChannel = this.filtersChannelsService.getFiltersChannel(
      this.alias,
      this.filtersChannelSuffix,
      filterId,
      'EMAIL'
    );

    // this.filtersChannel.selectedFiltersSubject
    //   .pipe(skip(1), takeUntil(this.unsubscribe$), distinctUntilChanged())
    //   .subscribe((filters) => {
    //     this.setActiveFilters(filters);
    //   });
  }

  // async setActiveFilters(filters: IFilters) {
  //   if (filters) {
  //     this.activeFilter = filters;

  //     if (this.alertsEnabled) {
  //       this.emailAlertService.postSelectedEmailFilter(this.alias, filters._id);
  //     }
  //   } else {
  //     this.activeFilter = null;

  //     this.emailAlertService.deleteSelectedEmailFilter(this.alias);
  //   }
  // }

  async onAlertsEnabledChange(ev) {
    let willEnable = !this.alertsEnabled;

    if (willEnable) {
      let upsertResponse = await this.emailAlertService.postSelectedEmailFilter(this.alias, null); // this.activeFilter?._id

      this.loadFiltersChannel(upsertResponse?.upsertedId);
    } else {
      await this.emailAlertService.deleteSelectedEmailFilter(this.alias);
    }

    this.alertsEnabled = willEnable;

    this.changeDetectorRef.markForCheck();

    let message = `Email alerts ${this.alertsEnabled ? 'enabled' : 'disabled'}.`;

    this.snackBarService.open(message, { verticalPosition: 'top' });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
  }
}
