import { Injectable, signal } from '@angular/core';
import { ChecklistItem } from '@suvo-bi-core';

@Injectable()
export class PrivateDashboardsChecklistItem implements ChecklistItem {
  readonly title = 'Create dashboards';
  readonly routerLink = '/private/pages';

  readonly isChecked = signal(false);

  // TODO: check if tenant has any private dashboards
  readonly skippable = true;
}
