<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>Image</mat-label>
        <input matInput formControlName="background" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>

<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
