<div class="screen-container" fxLayout="column" fxLayoutAlign="flex-start stretch">

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch" class="top-margin">
    <h4 class="margin">Saved Filters</h4>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <div class="padding">
        <p>Manage your saved filters here.</p>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <h4 class="margin">Email Preferences</h4>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <div class="padding">
        <p>Manage your email notification preferences here.</p>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <h4 class="margin">Favorite Data</h4>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <div class="padding">
        <p>Manage your starred data here.</p>
      </div>
    </div>
  </div>
</div>