<div [formGroup]="setupForm">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Heading 1</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor
        [formGroup]="setupForm"
        textFormControlName="heading1"
        fontColorFormControlName="heading1FontColor"
        fontSizeFormControlName="heading1FontSize"
      ></lib-text-editor>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Heading 2</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor
        [formGroup]="setupForm"
        textFormControlName="heading2"
        fontColorFormControlName="heading2FontColor"
        fontSizeFormControlName="heading2FontSize"
      ></lib-text-editor>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Introduction</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor [formGroup]="setupForm" textFormControlName="text"></lib-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Feature Image</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Image URL</mat-label>
        <input matInput formControlName="contentImg" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Background Image</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-image-editor
        [formGroup]="setupForm"
        imageUrlFormControlName="background"
        imageOffsetTopFormControlName="backgroundImageTopOffset"
        imageOffsetLeftFormControlName="backgroundImageLeftOffset"
        imageOffsetBottomFormControlName="backgroundImageBottomOffset"
        imageOffsetRightFormControlName="backgroundImageRightOffset"
      ></lib-image-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Buttons</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [formGroup]="setupForm">
        <div>Buttons</div>
        <lib-links-editor
          [linksFormArray]="setupForm.controls.linkButtons"
          [tileDefinition]="tileDefinition"
        ></lib-links-editor>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Icons</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [formGroup]="setupForm">
        <ng-container *ngFor="let icon of iconsControl.controls; let i = index">
          <div [formGroup]="icon" class="icons-container">
            <div class="inputs-container">
              <mat-form-field appearance="fill">
                <mat-label>Icon</mat-label>
                <input matInput formControlName="icon" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Icon Style</mat-label>
                <mat-select formControlName="iconStyle">
                  <mat-option *ngFor="let iconStyle of iconStylesOptions" [value]="iconStyle.key">
                    {{ iconStyle.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" class="color-input">
                <mat-label>Icon Color</mat-label>
                <input type="color" matInput formControlName="iconColor" />
              </mat-form-field>

              <mat-form-field appearance="fill" class="color-input">
                <mat-label>Icon Outline Color</mat-label>
                <input type="color" matInput formControlName="iconOutlineColor" />
              </mat-form-field>

              <mat-form-field appearance="fill" class="color-input">
                <mat-label>Icon Background Color</mat-label>
                <input type="color" matInput formControlName="iconBackgroundColor" />
              </mat-form-field>
            </div>

            <mat-icon class="delete-btn" (click)="removeIcon(i)">delete_forever</mat-icon>
          </div>
        </ng-container>

        <button class="add-btn" mat-raised-button color="primary" (click)="addIcon()">Add Icon Column</button>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="footer">
    <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
  </div>
</div>
