<div class="input-group" [formGroup]="formGroup">
  <div *ngIf="label">
    {{ label }}
  </div>
  <div class="modifier-input-group">
    <mat-form-field appearance="outline" style="width: 80px" *ngIf="fontSizeFormControlName">
      <mat-label floatLabel="always">Size</mat-label>
      <input matInput [formControlName]="fontSizeFormControlName" type="number" />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="color-input"
      style="width: 60px"
      *ngIf="fontColorFormControlName"
    >
      <mat-label floatLabel="always">Color</mat-label>
      <input
        type="color"
        matInput
        [formControlName]="fontColorFormControlName"
        style="width: 25px; height: 27px"
      />
    </mat-form-field>
  </div>
  <div class="input-group">
    <mat-form-field appearance="outline">
      <mat-label>Text</mat-label>
      <input matInput [formControlName]="textFormControlName" />
    </mat-form-field>
  </div>
</div>
