import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ITableDataSource } from '../../../../../../shared/interfaces/table/table-data-source.interface';

@Component({
  selector: 'app-cluster-click-preview-dialog',
  templateUrl: './cluster-click-preview-dialog.component.html',
  styleUrls: ['./cluster-click-preview-dialog.component.scss'],
})
export class ClusterClickPreviewDialogComponent implements OnInit {
  public resolvedData: ITableDataSource<any>;

  constructor(
    public dialogRef: MatDialogRef<ClusterClickPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this.resolvedData = await this.data.fetchDataPromise;
    this.data.alias =
      this.data.alias.charAt(0).toUpperCase() + this.data.alias.slice(1);
    console.log(this.resolvedData);
  }

  public itemClicked(dataSourceItem): void {
    if (dataSourceItem?._id) {
      this.router.navigate([...this.data.prefixes, dataSourceItem._id]);
      this.dialogRef.close();
    }
  }
}
