import { NgModule } from '@angular/core';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { TENANT_ID } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxStripeModule, StripeService } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivateModule } from './private/private.module';
import { PublicModule } from './public/public.module';
import { DashboardsModule } from '@suvo-bi-lib';

export function initTenantId(stripeService: StripeService) {
  stripeService.changeKey(environment.stripePublicKey);
  return environment.platformFirebaseTenantID;
}

export function initFirebase() {
  return {
    authDomain: environment.firebaseAuthDomain,
    apiKey: environment.firebaseApiKey,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    PublicModule,
    PrivateModule,
    AngularFireModule,
    NgxStripeModule.forRoot(''),
    DashboardsModule.forRoot(environment),
  ],
  providers: [
    { provide: TENANT_ID, useFactory: initTenantId, deps: [StripeService] },
    { provide: FIREBASE_OPTIONS, useFactory: initFirebase },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
