import { ITenantTheme } from '../../../shared/interfaces/tenant-theme.interface';

export interface ITenant {
  _id?: string;
  createdAt: string;
  firebaseTenantId: string;
  primaryWebUrl: string;
  tenantEmail: string;
  tenantName: string;
  updatedAt: string;
  webUrls: string[];
  trackingId?: string;
  theme?: ITenantTheme;

  /** The Informally feature set that the tenant uses. */
  plan?: 'FREE' | 'FULL';
}
