import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { IIcon } from '../../../dashboards/interfaces/tiles/definition-helpers/icon.interface';
import { ILinkButton } from '../../../dashboards/interfaces/tiles/definition-helpers/link-button.interface';
import { IHeroBannerTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/hero-banner-tile-definition.interface';
import { CONTENT_ALIGNMENT_OPTIONS } from '../../constants/content-alignment-options.constant';
import { HORIZONTAL_POSITION_OPTIONS } from '../../constants/horizontal-position-options.constant';
import { ICON_STYLES_OPTIONS } from '../../constants/icon-styles.constant';
import { VERTICAL_POSITION_OPTIONS } from '../../constants/vertical-position-options.constant';
import { ISelectOption } from '../../interfaces/select-option.interface';
import { TileCreatorHelpersService } from '../../services/tile-creator-helpers.service';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'lib-hero-banner-setup-wizard',
  templateUrl: './hero-banner-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './hero-banner-setup-wizard.component.scss'],
})
export class HeroBannerSetupWizardComponent implements OnInit, OnDestroy {
  tileDefinition: IHeroBannerTileDefinition;

  public color: any;

  public setupForm: FormGroup;

  public contentAlignmentOptions: ISelectOption[];
  public allPositionOptions: ISelectOption[];
  public iconStylesOptions: ISelectOption[];
  public fontSizesOptions: string[];

  private destroy$: Subject<void> = new Subject<void>();

  linkButtonsControl;
  iconsControl;

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorHelpersService: TileCreatorHelpersService,
    private readonly tileCreatorService: TileCreatorService,
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSelectOptions();
    this.initSetupForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initSelectOptions(): void {
    this.contentAlignmentOptions = CONTENT_ALIGNMENT_OPTIONS;
    this.allPositionOptions = [...HORIZONTAL_POSITION_OPTIONS, ...VERTICAL_POSITION_OPTIONS];
    this.iconStylesOptions = ICON_STYLES_OPTIONS;
    this.fontSizesOptions = this.tileCreatorHelpersService.generateFontSize();
  }

  public initSetupForms(): void {
    this.setupForm = this.fb.group({
      heading1: [''],
      heading2: [''],
      text: [''],
      contentImg: [''],
      background: [''],
      heading1FontSize: ['6px'],
      heading2FontSize: ['6px'],
      heading1FontColor: ['#000000'],
      heading2FontColor: ['#000000'],
      textFontSize: ['6px'],
      textFontColor: ['#000000'],
      backgroundImageTopOffset: [''],
      backgroundImageRightOffset: [''],
      backgroundImageBottomOffset: [''],
      backgroundImageLeftOffset: [''],
      minHeight: [''],
      alignContent: ['start'],
      contentImgPosition: ['start'],
      linkButtons: this.fb.array([]),
      icons: this.fb.array([]),
    });

    this.linkButtonsControl = this.setupForm.controls.linkButtons as FormArray;
    this.iconsControl = this.setupForm.controls.icons as FormArray;

    if (this.tileDefinition) {
      this.setupForm.patchValue(this.tileDefinition);

      this.tileDefinition.icons?.forEach((icon: IIcon) => {
        this.addIcon(icon);
      });
    }
  }

  public addLinkButton(linkButton?: ILinkButton): void {
    const linkButtonFormGroup = this.fb.group({
      buttonText: [''],
      buttonIcon: [''],
      routeLink: [''],
      buttonFontSize: [6],
      buttonFontColor: ['#000000'],
      buttonBackgroundColor: ['#000000'],
    });

    linkButtonFormGroup.patchValue(linkButton);

    this.linkButtonsControl.push(linkButtonFormGroup);
  }

  public removeLinkButton(linkButtonIndex: number): void {
    this.linkButtonsControl.removeAt(linkButtonIndex);
  }

  public addIcon(icon?: IIcon): void {
    const iconFormGroup = this.fb.group({
      icon: [''],
      iconStyle: ['basic'],
      iconColor: ['#000000'],
      iconOutlineColor: ['#000000'],
      iconBackgroundColor: ['#000000'],
    });

    iconFormGroup.patchValue(icon);

    this.iconsControl.push(iconFormGroup);
  }

  public removeIcon(iconColumnIndex: number): void {
    this.iconsControl.removeAt(iconColumnIndex);
  }

  public onCreate(): void {
    Object.assign(this.tileDefinition, {
      tileType: 'hero-banner',
      canView: 'true',
      ...this.setupForm.value,
    });
    this.tileCreatorService.onChange.next();
  }
}
