import { OverlayModule } from '@angular/cdk/overlay';
import { Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EventType, Router } from '@angular/router';
import { ShopSetupChecklistComponent } from '../../modules/shop-setup-checklist/shop-setup-checklist.component';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, OverlayModule, ShopSetupChecklistComponent],
  selector: 'app-checklist-widget',
  template: `
    <button
      mat-stroked-button
      color="primary"
      (click)="isOpen = !isOpen"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <mat-icon>checklist</mat-icon>
      Checklist
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (overlayOutsideClick)="isOpen = !isOpen"
    >
      <app-shop-setup-checklist />
    </ng-template>
  `,
})
export class ChecklistWidgetComponent {
  isOpen = false;

  private readonly routerEvents = toSignal(inject(Router).events);

  private readonly effect = effect(() => {
    if (this.routerEvents().type === EventType.NavigationStart) {
      this.isOpen = false;
    }
  });

  ngOnDestroy() {
    this.effect.destroy();
  }
}
