import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@suvo-bi-lib';
import { NewShopComponent } from '../components/new-shop/new-shop.component';
import { MultipleChoiceComponent } from '../components/multiple-choice/multiple-choice.component';

import {
  // Step components
  PriorExperienceStepComponent,
  RequirementsStepComponent,
  ShopDetailsStepComponent
} from '../components/steps';

@NgModule({
  declarations: [
    NewShopComponent,
    MultipleChoiceComponent,

    // Step components
    PriorExperienceStepComponent,
    RequirementsStepComponent,
    ShopDetailsStepComponent
  ],
  imports: [CommonModule, SharedModule]
})
export class NewShopModule {}
