<mat-dialog-content>
  <div class="manage-sub-container">
    <div class="manage-sub-wrapper" *ngIf="selectedOrganisation">
      <div class="manage-header">
        <h4>{{ selectedOrganisation.organisationName }} Subscription</h4>
        <button mat-icon-button class="close-icon" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div *ngIf="loading" class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>

      <p class="may-take-a-moment" *ngIf="mayTakeAMoment">This may take a few moments...</p>

      <ng-container *ngIf="!loading && !enteringPaymentInfo && isOrganisationAdmin && !subscriptionIncomplete">
        <p>Select the products you wish to subscribe to:</p>
        <br />
        <div class="products-container">
          <ng-container *ngFor="let productGroup of productGroups; let last = last">
            <div class="product">
              <div *ngIf="productGroup.icon">
                <span class="material-icons border-circle">{{ productGroup.icon }}</span>
              </div>
              <br />
              <h3>{{ productGroup.name }}</h3>
              <ng-container *ngIf="!enabledOrgManagement">
                <p class="product-price-line">
                  <!-- TODO: this is going to need updating to support multiple tiers -->
                  <!-- This is fine for IRJ - they always have a free tier and a paid tier -->
                  <strong>
                    {{
                      getPaidProduct(productGroup).price / 100
                        | currency : getPaidProduct(productGroup).currency.toUpperCase() : 'symbol' : '1.0-0'
                    }}
                    / year
                  </strong>
                </p>
              </ng-container>
              <mat-checkbox
                [(ngModel)]="productGroup.isSelected"
                (ngModelChange)="reCalculatePrice(); checkIfDirty()"
                color="primary"
              >
                Subscribe to plan
              </mat-checkbox>
            </div>
            <div *ngIf="!last" class="vertical-line"></div>
          </ng-container>
        </div>
        <div>
          <br />
          <hr />
          <br />
          <div>
            <ng-container *ngIf="suvoUsersClientLibSettingsService.tempTenantConfig.showOrganisationFunctionality">
              <div class="seats-row-container">
                <div>
                  <h4>Seats</h4>
                  <p class="seats-subtext">
                    Add more seats to invite more users to your organisation, and take
                    <br />
                    advantage of multi-seat discounts!
                  </p>
                </div>
                <div class="seats-container">
                  <p>Seats</p>
                  <div>
                    <mat-form-field appearance="outline" class="seats-field">
                      <input matInput type="number" [formControl]="seats" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
            </ng-container>
            <div>
              <div class="price-line-container">
                <ng-container *ngFor="let productGroup of productGroups">
                  <div *ngIf="productGroup.isSelected">
                    <p class="price-line">
                      {{ productGroup.name }} x {{ seats.value }} =
                      {{
                        (getPaidProduct(productGroup).price * seats.value) / 100
                          | currency : getPaidProduct(productGroup).currency.toUpperCase()
                      }}
                    </p>
                  </div>
                  <p *ngIf="!productGroup.isSelected">&nbsp;</p>
                </ng-container>
              </div>
              <br />
              <div *ngIf="productGroups?.length">
                <ng-container *ngIf="!discountPercentage">
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </ng-container>
                <ng-container *ngIf="discountPercentage">
                  <p class="price-line">
                    <strong>SUBTOTAL:</strong>
                    {{ previewSubtotal / 100 | currency : getPaidProduct(productGroups[0]).currency.toUpperCase() }} /
                    year
                  </p>
                  <p class="price-line">
                    <strong>{{ selectedProducts }} PRODUCTS DISCOUNT:</strong>
                    -{{
                      (previewSubtotal - previewSubtotal * ((100 - discountPercentage) / 100)) / 100
                        | currency : getPaidProduct(productGroups[0]).currency.toUpperCase()
                    }}
                  </p>
                </ng-container>
                <p class="price-line">
                  <strong>TOTAL:</strong>
                  {{
                    (previewSubtotal * ((100 - discountPercentage) / 100)) / 100
                      | currency : getPaidProduct(productGroups[0]).currency.toUpperCase()
                  }}
                  / year
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <p *ngIf="!isOrganisationAdmin">
        You must be the admin of the selected organisation to change the manage subscriptions.
      </p>
    </div>

    <ng-container *ngIf="!loading && !enteringPaymentInfo && isOrganisationAdmin">
      <p *ngIf="currentError" class="error-msg">{{ currentError.message }}</p>
      <p *ngIf="currentSuccessMessage" class="success-msg">{{ currentSuccessMessage }}</p>

      <ng-container *ngIf="!subscriptionIncomplete">
        <div class="stripe-elements-container">
          <button
            button
            mat-raised-button
            color="primary"
            class="confirm-payment"
            [disabled]="!productSelectedCheck()"
            (click)="prepareSetupIntent()"
          >
            Confirm Product Choices
          </button>
        </div>
        <br />
        <p>
          <strong>For offline payment,</strong>
          please contact
          <a href="mailto:jchalon@sbpub.com?subject=Offline Payments">jchalon&#64;sbpub.com</a>
          and supply your phone number and email address.
        </p>
      </ng-container>
    </ng-container>

    <div
      class="stripe-elements-container stripe-elements-card-container"
      *ngIf="clientSecret && enteringPaymentInfo && isOrganisationAdmin"
    >
      <p class="payment-subheading">Please enter your card details to confirm your subscription.</p>
      <br />
      <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
      <br />
      <ng-container *ngIf="currentError">
        <p class="error-msg">{{ currentError.message }}</p>
      </ng-container>
      <button
        button
        mat-raised-button
        color="primary"
        class="confirm-payment"
        [disabled]="loading"
        (click)="confirmCardSetup()"
      >
        Confirm Payment
      </button>
    </div>
  </div>
</mat-dialog-content>
