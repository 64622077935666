import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IdentityService, UsersSnackbarService, OrganisationInvitationService } from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'suvo-bi-organisation-invite-list',
  templateUrl: './organisation-invite-list.component.html',
  styleUrls: ['./organisation-invite-list.component.scss']
})
export class OrganisationInviteListComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  selectedInvitation = new FormControl();
  invitationsList: any[];

  currentInvitation: any;
  currentInvitationErr: string;

  constructor(
    private organisationInvitationService: OrganisationInvitationService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private usersSnackbarService: UsersSnackbarService,
    private identityService: IdentityService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.pluckParamsFromRouting();
    await this.getMyOrganisations();
  }

  getMyOrganisations(): void {
    this.organisationInvitationService.get('invitations-list').then((res: any[]) => {
      this.invitationsList = res;
      if (this.currentInvitation) {
        this.currentInvitation.name = (this.invitationsList.filter(invitation =>
          invitation._id === this.currentInvitation.organisationId
        ))[0].name;
      }
      this.invitationsList = this.invitationsList.filter(invitation => invitation._id !== this.currentInvitation?.organisationId);
    });
  }

  async getCurrentInvitation(token): Promise<void> {
    try {
      this.currentInvitation = await this.organisationInvitationService.get(`current-invitation/${token}`);
      this.checkUserAuthorisation(this.currentInvitation);
    } catch (err) {
      this.currentInvitationErr = 'The invitation link you followed is invalid. Please request another one from the sender.';
      console.log(err);
    }
  }

  checkUserAuthorisation(currentInvitation): void {
    const { email } = currentInvitation;
    this.identityService.firebaseUserSubject.pipe(take(1)).subscribe(user => {
      if (user?.email && user.email !== email) {
        this.currentInvitationErr = 'The invitation link you followed is invalid. Please request another one from the sender.';
      }
    });
  }

  pluckParamsFromRouting(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activateRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params: any) => {
        const { token } = params;
        if (token) {
          await this.getCurrentInvitation(token);
        }
        resolve();
      });
    });
  }

  navigateToDashboard(): void {
    this.router.navigateByUrl(`/private/login-referrer`);
  }

  handleAction(accepted: boolean, organisationId: string): void {
    if (accepted) {
      this.acceptInvitation(organisationId);
    } else {
      this.declineInvitation(organisationId);
    }
  }

  acceptInvitation(organisationId): void {
    this.organisationInvitationService.post('invitation/accept', { organisationId }).then(async (res: any) => {
      if (this.currentInvitation?.organisationId === organisationId) {
        // Reset query params & refresh
        await this.router.navigate([], {
          queryParams: {
            token: null,
          },
          queryParamsHandling: 'merge'
        });
        this.currentInvitation = null;
      }
      await this.pluckParamsFromRouting();
      await this.getMyOrganisations();
      this.usersSnackbarService.open(res.response);
    }).catch(err => {
      console.log(err);
      this.usersSnackbarService.open(err);
    });
  }

  declineInvitation(organisationId): void {
    this.organisationInvitationService.post('invitation/decline', { organisationId }).then(async (res: any) => {
      if (this.currentInvitation?.organisationId === organisationId) {
        // Reset query params & refresh
        await this.router.navigate([], {
          queryParams: {
            token: null,
          },
          queryParamsHandling: 'merge'
        });
        this.currentInvitation = null;
      }
      await this.pluckParamsFromRouting();
      await this.getMyOrganisations();
      this.usersSnackbarService.open(res.response);
    }).catch(err => {
      console.log(err);
      this.usersSnackbarService.open(err);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
