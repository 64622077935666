import { Component, Input, OnInit } from '@angular/core';
import { FiltersChannel } from '../../../models/filters-channel.model';

@Component({
  selector: 'suvo-bi-channel-debug-label',
  templateUrl: './channel-debug-label.component.html',
  styleUrls: ['./channel-debug-label.component.scss']
})
export class ChannelDebugLabelComponent implements OnInit {

  @Input() filtersChannel: FiltersChannel;

  constructor() { }

  ngOnInit(): void {
  }

}
