<div fxLayout="column" fxLayoutAlign="flex-start stretch" [formGroup]="formGroup">
  <div class="top-margin" fxLayoutAlign="flex-start center">
    <span class="header">{{ option.filterMethodConfig?.title || 'Keywords' }}</span>
  </div>

  <mat-form-field class="form-field" appearance="outline">
    <mat-label>{{ option.filterMethodConfig?.label || 'Search...' }}</mat-label>
    <input matInput formControlName="keywords" placeholder="Search ..." autocomplete="off" />
  </mat-form-field>

  <!-- <p>{{ option.filterProperties | json }}</p> -->
</div>
