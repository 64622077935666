import { Injectable } from '@angular/core';
import { IDataSourceDefinitionProperty } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { IDataSourceItem } from '../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { IChipStyles } from '../interfaces/chip-styles.interface';

@Injectable({
  providedIn: 'root',
})
export class DataSourceItemChipsService {
  private convertOrDefault(chipColors: any): IChipStyles {
    return chipColors
      ? {
          'background-color': chipColors.primary,
          color: chipColors.accent,
        }
      : {
          'background-color': '#e0e0e0',
          color: 'rgba(0, 0, 0, 0.87)',
        };
  }

  public getChipStylesFromValue(
    value: any,
    property: IDataSourceDefinitionProperty
  ): IChipStyles {
    const chipColors = property.displayOptions?.enumColourSet?.find(
      (set) => set.key === value
    );
    return this.convertOrDefault(chipColors);
  }

  public getChipStyles(
    item: IDataSourceItem,
    property: IDataSourceDefinitionProperty
  ): IChipStyles {
    const chipColors = property.displayOptions?.enumColourSet?.find(
      (set) => set.key === item.data[property.name]
    );
    return this.convertOrDefault(chipColors);
  }
}
