import { Component, Input, OnInit } from '@angular/core';
import { IError } from '../../interfaces/error.interface';

@Component({
  selector: 'suvo-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnInit {
  @Input() errors: Array<IError>;

  constructor() {}

  ngOnInit(): void {}
}
