import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NewShopModule } from './new-shop';
import { PublicRoutingModule } from './public-routing.module';

@NgModule({
  imports: [CommonModule, NewShopModule, PublicRoutingModule, SharedModule],
})
export class PublicModule {}
