import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'suvo-bi-edit-filters-name-dialog',
  templateUrl: './edit-filters-name-dialog.component.html',
  styleUrls: ['./edit-filters-name-dialog.component.scss']
})
export class EditFiltersNameDialogComponent implements OnInit {

  maxLength = 28;

  formControl!: FormControl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string, actionText?: string, actionIcon?: string, prefillName?: string }
  ) { }

  ngOnInit(): void {
    this.formControl = new FormControl(this.data.prefillName ? this.data.prefillName : '', [Validators.required, Validators.maxLength(this.maxLength)])
  }

}
