import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationDialogComponent {
  constructor(
    private readonly matDialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { confirmationText: string }
  ) {}

  public onConfirm(): void {
    this.matDialogRef.close({ confirmed: true });
  }

  public onDecline(): void {
    this.matDialogRef.close({ confirmed: false });
  }
}
