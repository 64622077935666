import { Injectable, signal } from '@angular/core';
import { ChecklistItem } from '@suvo-bi-core';
import { SubscriptionService } from '@suvo-bi-users';

@Injectable()
export class PricingChecklistItem implements ChecklistItem {
  readonly title = 'Create pricing plans';
  readonly routerLink = '/private/subscriptions/products';

  readonly isChecked = signal(false);

  constructor(subscriptionService: SubscriptionService) {
    // TODO(fix): make reactive
    subscriptionService.getProductGroups().then((groups) => this.isChecked.set(groups?.length > 0));
  }
}
