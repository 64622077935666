<mat-toolbar class="toolbar">
  <a class="logo" routerLink="/private/login-referrer">{{title}}</a>
  <span class="menu" *ngIf="user">
    <button mat-button [matMenuTriggerFor]="menu"> Hi {{ user.firstName}}</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/private/my-account/edit-profile">Edit Profile</button>
      <button mat-menu-item (click)="logout()">Log out</button>
    </mat-menu>
  </span>

</mat-toolbar>