import { Component, EventEmitter, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { environment } from '@environments/environment';
import { SharedModule } from '@informally/admin/shared/shared.module';
import { VerifyService } from '@suvo-bi-users';

@Component({
  standalone: true,
  imports: [MatCardModule, SharedModule],
  template: `
    <mat-card style="margin: 48px auto; max-width: 600px">
      <mat-card-header>
        <mat-card-title>Welcome back to Informally</mat-card-title>
        <mat-card-subtitle
          >Please log in to continue managing your Informally site.</mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content style="margin-top: 48px">
        <lib-login
          [loginComponentOptions]="loginComponentOptions"
          (loginStatusChange)="onLoginStatusChange($event)"
        />
        @if (message) {
          <p style="text-align: center">{{ message }}</p>
        }
      </mat-card-content>
    </mat-card>
  `,
})
export class LoginPageComponent {
  @Output() loginStatusChange: EventEmitter<void> = new EventEmitter<void>();

  loginComponentOptions = {
    titleText: 'Log In',
    showRegister: false,
    skipVerifyStep: true,
    pwResetFirebaseTenantId: environment.platformFirebaseTenantID,
  };

  message: string;

  constructor(private readonly verifyService: VerifyService) {}

  async onLoginStatusChange(status) {
    if (status === 'USER_UNVERIFIED') {
      await this.verifyService.sendVerificationEmail();
      this.message = 'A verification link has been sent to your email address.';
    }
    this.loginStatusChange.next(status);
  }
}
