<div
  fxLayoutAlign="center stretch"
  class="banner-container"
  [ngClass]="{
    'card banner': !tileDefinition.noCard,
    'primary-background': !tileDefinition.white,
    'white-background': tileDefinition.white
  }"
  [ngStyle]="{ color: tileDefinition.white ? 'black' : '#ffffff', maxHeight: tileDefinition.customHeight || '100%' }"
  fxLayout="row"
  fxLayout.xs="column"
  fxFlex="1 1"
>
  <div
    class="banner-title-container"
    fxFlexOrder="1"
    fxFlex="1 1 50%"
    fxFlex.xs="1 1 100%"
    fxLayoutAlign="center center"
  >
    <div fxFlex="0 1 80%" fxLayout="row" class="flex-container">
      <!-- <h3 *ngIf="tileDefinition.headingIcon" style="margin-right: 8px; margin-top: -4px; margin-left: -24px">
        <mat-icon class="border-circle light">{{ tileDefinition.headingIcon }}</mat-icon>
      </h3> -->

      <div fxLayout="column" fxLayoutAlign="center flex-start" class="content-container">
        <h3>
          <mat-icon *ngIf="tileDefinition.headingIcon" class="border-circle light">
            {{ tileDefinition.headingIcon }}
          </mat-icon>
          {{ tileDefinition.heading }}
          <b [ngClass]="{ loaded: displayNumberLoaded }" class="total" *ngIf="displayNumber">
            {{ displayNumber | number }}
          </b>
        </h3>
        <p ngClass="secondary-lead" fxHide.xs fxShow>{{ tileDefinition.subHeading }}</p>
        <div fxFlex="1 1" class="buttons-container" fxLayoutGap="16px" fxLayoutAlign="stretch center">
          <button
            *ngFor="let button of tileDefinition.linkButtons"
            fxFlex="1 0"
            [routerLink]="button.routeLink"
            class="link-button"
            mat-stroked-button
          >
            <mat-icon *ngIf="button.buttonIcon">{{ button.buttonIcon }}</mat-icon>
            {{ button.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="banner-image-container"
    fxFlex="1 1 50%"
    fxFlex.xs="1 1 196px"
    fxFlexOrder="2"
    fxFlexOrder.xs="-1"
    fxLayoutAlign="center center"
  >
    <img fxFlex="nogrow" class="banner-image" [src]="contentImgPath" />
  </div>
</div>
