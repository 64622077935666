import { ISectionDefinition } from "../interfaces/section-definition.interface";
import { RowDefinition } from "./row-definition";

export class SectionDefinition implements ISectionDefinition {
  rows: RowDefinition[];

  constructor(init: ISectionDefinition) {
    this.rows = init.rows.map(row => new RowDefinition(row));
  }

  toJSObj() {
    return JSON.parse(JSON.stringify(this));
  }
}