import {Injectable} from "@angular/core";
import {Event as NavigationEvent, NavigationStart} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public async getSessionStoreItem(alias) {
        try {
            return JSON.parse(await sessionStorage.getItem(alias));
        } catch(e) {
            return null;
        }
    }

    public async setSessionStoreItem(alias, value) {
        await sessionStorage.setItem(alias, JSON.stringify(value));
    }

    public async removeSessionStoreItem(userId, routeUrl, alias) {
        return;
    }

    public handleRouteChange(userId: string, event: NavigationEvent) {
        if (userId && event instanceof NavigationStart && event.navigationTrigger != "popstate") {
            let sessionStorageKeys = Object.keys(sessionStorage);

            for (let key of sessionStorageKeys) {
                const routeKeyPrefix = `${userId}_${event.url}`;
                if (key.startsWith(routeKeyPrefix)) {
                    sessionStorage.removeItem(key);
                }
            }
        }
    }
}
