<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <span class="header">
    {{ option.filterMethodConfig?.title || 'Between Dates' }}
  </span>

  <div fxFlex="0 1" fxLayout="row" fxLayoutGap="8px">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [formGroup]="formGroup" [rangePicker]="rangePicker" #dateRangeInput>
        <input matStartDate formControlName="start" placeholder="From" #startDateInput />
        <input matEndDate formControlName="end" placeholder="To" #endDateInput />
      </mat-date-range-input>
      <suvo-bi-clear-control-button
        matSuffix
        [clearFormGroup]="formGroup"
      ></suvo-bi-clear-control-button>
      <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #rangePicker>
        <mat-date-range-picker-actions>
          <button mat-button matDateRangePickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
