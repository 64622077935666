import { Injectable } from '@angular/core';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { SubdomainModel } from '../models/subdomain.model';

@Injectable({
  providedIn: 'root',
})
export class SubdomainService extends CommonService<SubdomainModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.usersServiceApi, 'subdomain');
  }

  getOne(subdomain: string) {
    return super.getOne(subdomain);
  }
}
