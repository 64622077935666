<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-checkbox class="example-margin" formControlName="isTileDividerEnabled"
        >Tile Divider</mat-checkbox
      >

      <mat-form-field
        appearance="fill"
        *ngIf="formGroup?.controls?.isTileDividerEnabledControl?.value"
        class="color-input"
      >
        <mat-label>Tile Divider Color</mat-label>
        <input type="color" matInput formControlName="tileDividerColor" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        *ngIf="formGroup?.controls?.isTileDividerEnabledControl?.value"
      >
        <mat-label>Tile Divider Position</mat-label>
        <mat-select formControlName="tileDividerPosition">
          <mat-option
            *ngFor="let positionOption of verticalPositionsOptions"
            [value]="positionOption.key"
          >
            {{ positionOption.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="inputs-container">
        <mat-form-field appearance="fill">
          <mat-label>Background</mat-label>
          <input matInput formControlName="background" />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Text Columns</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div
        *ngFor="let textColumn of textColumns.controls; let i = index"
        [formGroup]="textColumn"
        class="text-columns-container"
      >
        <div class="inputs-container">
          <mat-form-field appearance="fill">
            <mat-label>Heading</mat-label>
            <input matInput formControlName="heading" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Heading Font Size</mat-label>
            <mat-select formControlName="headingFontSize">
              <mat-option *ngFor="let sizeOption of fontSizesOptions" [value]="sizeOption">
                {{ sizeOption }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="color-input">
            <mat-label>Heading Font Color</mat-label>
            <input type="color" matInput formControlName="headingFontColor" />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Text</mat-label>
            <input matInput formControlName="text" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Text Font Size</mat-label>
            <mat-select formControlName="textFontSize">
              <mat-option *ngFor="let sizeOption of fontSizesOptions" [value]="sizeOption">
                {{ sizeOption }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="color-input">
            <mat-label>Text Font Color</mat-label>
            <input type="color" matInput formControlName="textFontColor" />
          </mat-form-field>

          <mat-checkbox class="example-margin" formControlName="isDividerEnabled"
            >Divider</mat-checkbox
          >

          <mat-form-field
            appearance="fill"
            *ngIf="textColumn.get('isDividerEnabled').value"
            class="color-input"
          >
            <mat-label>Divider Color</mat-label>
            <input type="color" matInput formControlName="dividerColor" />
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="textColumn.get('isDividerEnabled').value">
            <mat-label>Divider Position</mat-label>
            <mat-select formControlName="dividerPosition">
              <mat-option
                *ngFor="let positionOption of verticalPositionsOptions"
                [value]="positionOption.key"
              >
                {{ positionOption.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-icon class="delete-btn" (click)="removeTextColumn(i)">delete_forever</mat-icon>
        <hr />
      </div>
      <button class="add-btn" mat-raised-button color="primary" (click)="addTextColumn()">
        Add Text Column
      </button>
    </mat-card-content>
  </mat-card>
</form>

<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
