import { CommonModel } from '@suvo-bi-core';
import { ISubdomain } from '../interfaces/subdomain.interface';

export class SubdomainModel
  extends CommonModel<SubdomainModel>
  implements ISubdomain
{
  constructor() {
    super(SubdomainModel);
  }
  subdomain: string;
  firebaseTenantId?: string;
  disabled?: boolean;
  redirectsTo?: string;
}
