<mat-toolbar class="toolbar">
  <a class="home-link" href="/">
    <img class="logo" src="/assets/logo-informally.svg" />
  </a>
  <div class="action-buttons">
    <button class="login-btn" mat-stroked-button routerLink="/login">Log in</button>
    <button class="sign-up-button" mat-flat-button color="primary" routerLink="/new-shop">Start free trial</button>
  </div>
</mat-toolbar>
<router-outlet></router-outlet>
