<div class="component-container" [class.blur]="!loading && isTenantAdmin">
  <ng-container *ngIf="selectedOrganisation?.value">
    <header>
      <h4>{{ selectedOrganisation.value.organisationName }} Subscription</h4>

      <!-- <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="more-button"
        *ngIf=" true || (!loading && !isEnteringPaymentInfo && isOrganisationAdmin && selectedOrganisationCustomerSubscriptions?.length && !subscriptionIncomplete)"
      >
        <mat-icon>more_vert</mat-icon>
      </button> -->

      <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="cancelSubscription()">
          Cancel Subscription
        </button>
      </mat-menu> -->
    </header>

    <mat-form-field *ngIf="navType === 'multiple'">
      <mat-label>Select your organisation</mat-label>
      <mat-select
        [disabled]="isEnteringPaymentInfo"
        [formControl]="selectedOrganisation"
        (selectionChange)="selectOrganisation()"
      >
        <mat-option *ngFor="let organisation of organisationsList" [value]="organisation">
          {{ organisation.organisationName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spinner-container" *ngIf="loading && !isEnteringPaymentInfo">
      <mat-spinner></mat-spinner>
    </div>

    <ng-container *ngIf="!loading && !isEnteringPaymentInfo && isOrganisationAdmin && !subscriptionIncomplete">
      <div class="trial-banner" *ngIf="selectedOrganisationCustomerSubscriptions?.[0]?.status === 'trialing'">
        <p>
          You are currently on a free trial. Your trial will
          <b>end</b>
          in
          <b>{{ trialDaysRemaining }} days.</b>
        </p>
      </div>

      <div class="products-container">
        <ng-container *ngFor="let group of productGroups; let last = last">
          <div class="product">
            <div *ngIf="group.icon">
              <span class="material-icons border-circle">
                {{ group.icon }}
              </span>
            </div>

            <h3>{{ group.name }}</h3>

            <p>Annual {{ group.name }} Subscription</p>

            <p class="product-price-line">
              <strong>
                {{
                  getPaidProduct(group).price / 100 | currency : getPaidProduct(group).currency : 'symbol' : '1.0-0'
                }}
                / year
              </strong>
            </p>

            <div class="owned-check-container">
              <mat-icon [style.color]="group.active?.access === 'full' ? 'green' : '#cf3d3d'">
                {{ group.active?.access === 'full' ? 'check_circle_outline' : 'remove_circle_outline' }}
              </mat-icon>
              <p>
                {{ group.active ? (group.active.access === 'full' ? 'Full' : 'Limited') : 'No' }}
                Access
              </p>
            </div>

            <div class="trial-banner" *ngIf="!group.active">
              <p><b>Your trial period has ended.</b></p>
            </div>
          </div>

          <div *ngIf="!last" class="vertical-line"></div>
        </ng-container>
      </div>

      <div>
        <br />
        <hr />
        <br />
        <div>
          <ng-container *ngIf="suvoUsersClientLibSettingsService.tempTenantConfig.showOrganisationFunctionality">
            <div class="seats-row-container">
              <div>
                <h4>Seats</h4>
                <p class="seats-subtext">
                  Add more seats to invite more users to your organisation, and take
                  <br />
                  advantage of multi-seat discounts!
                </p>
              </div>
              <div class="seats-container">
                <p>Seats</p>
                <div>
                  <mat-form-field appearance="outline" class="seats-field">
                    <input matInput type="number" [formControl]="seats" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </ng-container>
        </div>
      </div>
    </ng-container>

    <p *ngIf="!isOrganisationAdmin">
      You must be the admin of the selected organisation to change the manage subscriptions.
    </p>

    <!-- TODO: Shouldn't be needed anymore, ensure there's not way to make this pop-up again. -->
    <ng-container *ngIf="subscriptionIncomplete && !loading && isOrganisationAdmin">
      <p>
        Your organisation appears to have an incomplete subscription. This typically means that the page was refreshed
        mid-checkout.
      </p>

      <p>To resolve this issue, you must do one of the following:</p>
      <ul>
        <li>
          Pay the outstanding invoice via the Stripe customer portal. You can access the portal by clicking the
          <strong>Manage Billing Details</strong>
          button below.
        </li>
        <li>
          Cancel the outstanding subscription via the button below. You will not be charged for cancelling, but you will
          need to create a new subscription to access content.
        </li>
        <li>Contact support for assistance with this issue.</li>
      </ul>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!loading && !isEnteringPaymentInfo && isOrganisationAdmin">
    <p *ngIf="currentError" class="error-msg">{{ currentError.message }}</p>
    <p *ngIf="currentSuccessMessage" class="success-msg">
      {{ currentSuccessMessage }}
    </p>

    <ng-container *ngIf="!subscriptionIncomplete">
      <div
        class="stripe-elements-container"
        *ngIf="!selectedOrganisationCustomerSubscriptions?.length || selectedOrganisationCustomerSubscriptions?.[0]?.status === 'trialing'; else hasPlan"
      >
        <button button mat-raised-button color="primary" (click)="upgradeFromTrial()">Choose Your Plan</button>
      </div>
      <ng-template #hasPlan>
        <div>
          <p>To make changes to your current subscription, please contact sales.</p>
        </div>
      </ng-template>
      <br />
      <p>
        <strong>For offline payment,</strong>
        please contact
        <a href="mailto:jchalon@sbpub.com?subject=Offline Payments">jchalon&#64;sbpub.com</a>
        and supply your phone number and email address.
      </p>
      <br />
    </ng-container>

    <ng-container *ngIf="subscriptionIncomplete">
      <div class="stripe-elements-container" *ngIf="selectedOrganisationCustomerSubscriptions?.length">
        <button button mat-raised-button color="primary" (click)="cancelSubscription()">
          Cancel Incomplete Subscription
        </button>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="stripe-elements-container stripe-elements-card-container"
    *ngIf="clientSecret && isEnteringPaymentInfo && isOrganisationAdmin"
  >
    <p class="payment-subheading">Please enter your card details to confirm your subscription.</p>
    <br />
    <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
    <br />
    <button button mat-raised-button color="primary" [disabled]="loading" (click)="confirmCardPayment()">
      Confirm Payment
    </button>
  </div>
</div>
