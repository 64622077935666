<!-- v1 -->

<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Heading 1</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor
        [formGroup]="formGroup"
        textFormControlName="heading1"
        fontColorFormControlName="heading1FontColor"
        fontSizeFormControlName="heading1FontSize"
      ></lib-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Heading 2</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor
        [formGroup]="formGroup"
        textFormControlName="heading2"
        fontColorFormControlName="heading2FontColor"
        fontSizeFormControlName="heading2FontSize"
      ></lib-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Introduction</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor
        [formGroup]="formGroup"
        textFormControlName="text"
        fontColorFormControlName="textFontColor"
        fontSizeFormControlName="textFontSize"
      ></lib-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Image</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>Image</mat-label>
        <input matInput formControlName="contentImg" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Image Position</mat-label>
        <mat-select formControlName="contentImgPosition">
          <mat-option value="start">Left</mat-option>
          <mat-option value="end">Right</mat-option>
          <mat-option value="top">Top</mat-option>
          <mat-option value="bottom">Bottom</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Background Image</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <lib-image-editor
        [formGroup]="formGroup"
        imageUrlFormControlName="background"
        imageOffsetTopFormControlName="backgroundImageTopOffset"
        imageOffsetLeftFormControlName="backgroundImageLeftOffset"
        imageOffsetBottomFormControlName="backgroundImageBottomOffset"
        imageOffsetRightFormControlName="backgroundImageRightOffset"
      ></lib-image-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Buttons</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <lib-links-editor
        [linksFormArray]="formGroup.controls.linkButtons"
        [tileDefinition]="tileDefinition"
      ></lib-links-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Icons</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div
        *ngFor="let icon of formGroup.controls.icons.controls; let i = index"
        [formGroup]="icon"
        class="array-entry"
      >
        <div class="array-entry-header">
          <h3>Button {{ i + 1 }}</h3>
          <button mat-icon-button (click)="removeIcon(i)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Icon</mat-label>
          <input matInput formControlName="icon" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Icon Style</mat-label>
          <mat-select formControlName="iconStyle">
            <mat-option value="basic">Basic</mat-option>
            <mat-option value="outline">Outline</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="color-input">
          <mat-label>Icon Colour</mat-label>
          <input type="color" matInput formControlName="iconColor" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="color-input">
          <mat-label>Icon Outline Colour</mat-label>
          <input type="color" matInput formControlName="iconOutlineColor" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="color-input">
          <mat-label>Icon Background Colour</mat-label>
          <input type="color" matInput formControlName="iconBackgroundColor" />
        </mat-form-field>
      </div>

      <div style="text-align: center">
        <button mat-flat-button color="primary" (click)="addIcon()">Add Icon</button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Layout</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>Min Height</mat-label>
        <input matInput formControlName="minHeight" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Content Alignment</mat-label>
        <mat-select formControlName="alignContent">
          <mat-option value="start">Left</mat-option>
          <mat-option value="center">Center</mat-option>
          <mat-option value="end">Right</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>

<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
