import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { CurrentTenantRoleService } from '@suvo-bi-users';
import { AccountWidgetSelectorComponent } from '../account-widget/account-widget.component';
import { ChecklistWidgetComponent } from '../checklist-widget/checklist-widget.component';
// import { ShopSelectWidgetComponent } from '../shop-select-widget/shop-select-widget.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    AccountWidgetSelectorComponent,
    ChecklistWidgetComponent,
    // ShopSelectWidgetComponent,
  ],
  selector: 'app-header',
  template: `
    <header>
      <img class="logo" src="/assets/logo-informally.svg" />
      <mat-nav-list>
        <a routerLink="pages" routerLinkActive="active" [class.disabled]="simpleMode() | async">
          Site Pages
        </a>
        <a
          routerLink="data-sources"
          routerLinkActive="active"
          [class.disabled]="simpleMode() | async"
          >Data Sources</a
        >
        <a routerLink="subscriptions" routerLinkActive="active">Subscriptions</a>
        <a routerLink="settings" routerLinkActive="active" [class.disabled]="simpleMode() | async">
          Settings
        </a>
      </mat-nav-list>
      <div class="section">
        <!-- <app-shop-select-widget /> -->
        <app-checklist-widget />
        <app-account-widget />
      </div>
    </header>
  `,
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  readonly currentTenantRole = inject(CurrentTenantRoleService).currentTenantRole;

  readonly simpleMode = computed(async () => {
    const role = await this.currentTenantRole();
    return role === 'USER_ADMIN';
  });
}
