import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../shared/services/api.service';
import { IDataSourceSettings } from '../../data-source/interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../data-source/interfaces/data-source-settings.token';

@Injectable({ providedIn: 'root' })
export class UserSettingsService extends ApiService<any> {
  constructor(
    httpClient: HttpClient,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings
  ) {
    super(httpClient, dataSourceSettings.dataServiceApi, 'user-settings');
  }
}
