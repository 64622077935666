<div *ngIf="(successfulResponse || response) else loadingVerify">
  <div class="heading">
    <h4><b>{{successfulResponse ? 'Verification Successful' : 'Unable to verify your email'}}</b></h4>
  </div>
  <div class="response-container">
    <br>
    <div class="response" [ngClass]="{'successful': successfulResponse , 'unsuccessful' : !successfulResponse}"> {{
      response }} </div>
    <p class="redirect-countdown" *ngIf="successfulResponse">You will be automatically redirected to the login screen in {{
      redirectCountdown }} second{{redirectCountdown != 1 ? 's' : ''}}.</p>
    <br>
    <a mat-stroked-button [routerLink]="redirectUrl">Back to login</a>
  </div>
</div>
<ng-template #loadingVerify>
  <!-- <span> {{ filtersChannel?.channelName || 'none' }} </span> -->
  <div class="loading-verify" fxLayout="column" fxLayoutAlign="center center">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>