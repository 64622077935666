import { Injectable } from '@angular/core';
import { HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '@suvo-bi-core';
import { tap } from 'rxjs/operators';

/**
 * This {@linkcode HttpInterceptor} listens for the `Refresh-Token` response header
 * and quietly refreshes the auth token in the background.
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(async (event) => {
        if (
          event.type === HttpEventType.Response &&
          event.headers.get('refresh-token') === 'true'
        ) {
          await this.auth.currentUser()?.getIdToken(true);
        }
      }),
    );
  }
}
