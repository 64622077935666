<div class="card view-margin" fxFlex="1 1" fxLayout="column">


  <ng-container *ngFor="let display of displays; trackBy: trackDisplay; let last=last">

    <span class="property-name">{{ display.property?.displayName || '?' }}</span>
    <mat-divider></mat-divider>
    <suvo-bi-property class="paragraph-padding" [property]="display.property" [item]="item" [control]="display.control"
      [editItemMode]="editItemMode">
    </suvo-bi-property>
    <mat-divider *ngIf="!last"></mat-divider>

  </ng-container>

</div>