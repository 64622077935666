import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'suvo-bi-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent {
  @Input() public legend: { name: string, inactive?: boolean }[];
  @Input() public colors: string[];

  @Output() public itemToggled: EventEmitter<any> = new EventEmitter<any>();

  public toggleItem(item): void {
    item.inactive = !item.inactive;
    this.itemToggled.emit();
  }
}
