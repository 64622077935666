<div class="outer-container" *ngIf="allowed; else notAllowed">
  <div class="card" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div class="lookup-container">
      <div fxLayout="row" fxLayoutAlign="flex-start center" class="flex-container">
        <div class="description">Tender notice number lookup:</div>
        <div class="input-container">
          <input mat-input type="text" [formControl]="referenceId" class="lookupField" />
          <button
            fxFlex="1 1"
            mat-flat-button
            color="primary"
            (click)="lookupItem()"
            [disabled]="!referenceId.value || referenceId.value.length == 0"
            class="save-button"
          >
            <mat-icon>search</mat-icon>
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #notAllowed>
  <!-- <div fxFlex="1 1" fxLayoutAlign="space-between center" class="card not-allowed-message">
        <div>
            <b>This feature is available for paid {{tileDefinition.dataSourceAlias}} subscribers.</b>
        </div>

        <button color="primary" mat-stroked-button routerLink="/private/my-account/subscription">View upgrade
            options</button>
    </div> -->
</ng-template>
