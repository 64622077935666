import {Injectable} from '@angular/core';
import {ApiService} from '@suvo-bi-core';
import {BehaviorSubject} from 'rxjs';
import {SuvoUsersClientLibSettingsService} from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import {ProductDashboardModel} from '../models/product-for-dashboard.model';

const FEATURE_PRETTY_NAME = {
  "AGGREGATION" : 'Analysis',
  "EMAIL_ALERTS" : 'Email alerts'
};

const RESTRICTIONS_MESSAGES = {
  countLimit: 'most recent',
  enabled: 'included'
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  ownedProductsList: BehaviorSubject<ProductDashboardModel[]> = new BehaviorSubject<ProductDashboardModel[]>(null);

  constructor(
    private settings: SuvoUsersClientLibSettingsService,
    private apiService: ApiService
  ) { }

  async fetchAllOwned(): Promise<ProductDashboardModel[]> {
    try {
      this.ownedProductsList.next(await this.apiService.get(`${this.settings.subscriptionServiceApi}product/owned`));
      return this.ownedProductsList.value;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  isFeatureEnabled(featureShortcode, tags) {
    let enabled = false;

    let ownedProducts = this.ownedProductsList.getValue();

    ownedProducts.forEach((ownedProduct) => {
      if (ownedProduct.featureShortcode == featureShortcode) {
        let tagsMatch = false;
        tags.forEach((t) => {
          tagsMatch = ownedProduct.tags.includes(t.toUpperCase());
        });

        if (tagsMatch && ownedProduct.enabled) {
          enabled = true;
        }
      }
    });

    return enabled;
  }

  hasRestrictions(featureShortcode, tags) {
    let hasRestrictions = false;

    let ownedProducts = this.ownedProductsList.getValue();

    ownedProducts.forEach((ownedProduct) => {
      if (ownedProduct.featureShortcode == featureShortcode) {
        let tagsMatch = false;
        tags.forEach((t) => {
          tagsMatch = ownedProduct.tags.includes(t.toUpperCase());
        });

        if (tagsMatch && ownedProduct.restrictions) {
          hasRestrictions = true;
        }
      }
    });

    return hasRestrictions;
  }

  getPrettyRestrictions() {
    const prettyRestrictions: Record<string, Record<string, string[]>> = {};

    this.ownedProductsList.getValue().forEach(ownedFeature => {
      const featureName = (() => {
        switch (ownedFeature.featureShortcode.toUpperCase()) {
          case 'FINDANDPAGINATE': return 'Data points';
          // case 'AGGREGATION': return 'Charts';
          // case 'GEOLOCATION': return 'Geolocation';
          // case 'FETCH_SINGLE': return 'Single item';
          case 'EXPORT': return 'Exports';
          case 'EMAIL_ALERTS': return 'Email alerts';
          // case 'PUBLIC_COUNT': return 'Item counts';
          default: return null; // skip it
        }
      })();

      let restrictionMessages: string[] = [];

      if (ownedFeature.enabled) {
        if (ownedFeature.restrictions) {
          // Enabled but restricted
          // loop over restrictions
          for (const [key, value] of Object.entries(ownedFeature.restrictions)) {
            if (!value) continue;
            switch (key) {
              case 'countLimit':
                restrictionMessages.push(`${value} most recent`);
                break;
              case 'maxUpdatedDaysAgo':
                restrictionMessages.push(`last ${value} days`);
                break;
            }
          }
        }
      } else {
        restrictionMessages = ["not included"];
      }

      if (restrictionMessages.length) {
        for (const tag of ownedFeature.tags) {

          const tagSplit = tag.split(':');

          if (tagSplit.length < 2 || tagSplit[0] !== 'DATASOURCE') {
            continue;
          }

          const dataSourceName = tagSplit[1].charAt(0).toUpperCase() + tagSplit[1].slice(1).toLowerCase();

          if (!prettyRestrictions[dataSourceName]) {
            prettyRestrictions[dataSourceName] = {};
          }

          if (featureName) {
            prettyRestrictions[dataSourceName][featureName] = restrictionMessages;
          }
        }
      }

    });

    return prettyRestrictions;
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}
