import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';

@Injectable({ providedIn: 'root' })
export class VerifyService {
  private readonly http = inject(HttpClient);
  private readonly settings = inject(SuvoUsersClientLibSettingsService);

  sendVerificationEmail(legacy = false) {
    return lastValueFrom(
      this.http.post(this.settings.usersServiceApi + 'verify', null, { params: { legacy } }),
    );
  }
}
