<div class="input-group" [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>Background</mat-label>
    <input matInput formControlName="background" />
  </mat-form-field>

  <div class="offset-input-group">
    <mat-form-field appearance="outline" *ngIf="imageOffsetTopFormControlName">
      <mat-label>Top Offset</mat-label>
      <input matInput [formControlName]="imageOffsetTopFormControlName" />
    </mat-form-field>

    <div style="display: flex; justify-content: space-between">
      <mat-form-field appearance="outline" *ngIf="imageOffsetLeftFormControlName">
        <mat-label>Left Offset</mat-label>
        <input matInput [formControlName]="imageOffsetLeftFormControlName" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="imageOffsetRightFormControlName">
        <mat-label>Right Offset</mat-label>
        <input matInput [formControlName]="imageOffsetRightFormControlName" />
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" *ngIf="imageOffsetBottomFormControlName">
      <mat-label>Bottom Offset</mat-label>
      <input matInput [formControlName]="imageOffsetBottomFormControlName" />
    </mat-form-field>
  </div>
</div>
