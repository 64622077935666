import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { exampleTour } from '../data/example-tour';
import { ITourProgress } from '../interfaces/tour-progress.interface';
import { ITourStep } from '../interfaces/tour-step.interface';
import { ITour } from '../interfaces/tour.interface';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  progressSubject$: BehaviorSubject<ITourProgress> = new BehaviorSubject<ITourProgress>(null);

  currentTour: ITour;
  currentTourStep: ITourStep;
  currentStepIndex: number;

  constructor(
    private readonly router: Router,
  ) { }

  startTestTour() {
    this.startTour(exampleTour);
  }

  startTour(tour: ITour) {
    if (tour.steps.length) {

      this.currentTour = tour;
      this.currentStepIndex = 0;
      this.pushStep();
    }
  }

  private pushStep() {

    this.currentTourStep = this.currentTour.steps[this.currentStepIndex];
    if (this.currentTourStep.route) {
      this.router.navigate(this.currentTourStep.route);
    }
    const currentProgress: ITourProgress = {
      currentIndex: this.currentStepIndex,
      totalSteps: this.currentTour.steps.length,
      step: this.currentTourStep,
    }
    this.progressSubject$.next(currentProgress);

  }

  nextStep() {
    this.currentStepIndex += 1;
    if (this.currentStepIndex == this.currentTour.steps.length) {
      this.endTour();
    } else {
      this.pushStep();
    }
  }

  previousStep() {

    /*  Back-track to the last route if we reach a route change step.
    */
    if (this.currentTourStep.route) {
      for (let index = this.currentStepIndex; index--; index > 0) {
        if (this.currentTour.steps[index].route) {
          this.router.navigate(this.currentTour.steps[index].route, {});

          break;
        }
      }
    }
    if (this.currentStepIndex != 0) {
      this.currentStepIndex -= 1;
    }
    this.pushStep();
  }

  endTour() {
    this.currentTour = null;
    this.currentStepIndex = null;
    this.progressSubject$.next(null);
  }



}
