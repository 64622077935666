<div [formGroup]="formGroup">
  <div class="checkbox-container">
    <div class="checkbox" *ngFor="let checkbox of checkboxes" (click)="onClick($event, checkbox.name)">
      <div class="checkbox-flex">
        <mat-checkbox color="primary" [formControlName]="checkbox.name">{{ checkbox.label }}</mat-checkbox>
        <mat-icon *ngIf="checkbox.matIcon">{{ checkbox.matIcon }}</mat-icon>
      </div>
    </div>

    <div *ngIf="none" class="checkbox" (click)="onClick($event, none.name)">
      <mat-checkbox color="primary" [formControlName]="none.name">{{ none.label }}</mat-checkbox>
    </div>
  </div>
</div>
