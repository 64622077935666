import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductService, CurrentTenantService } from '@suvo-bi-users';
import { ApiService } from '../../../shared/services/api.service';
import { ILatestDraftsPaginationResponse } from '../interfaces/latest-drafts-pagination-response.interface';
import { Dashboard } from '../models/dashboard.model';
import { DashboardSettingsService } from './dashboards-settings.service';
import * as featureMapping from './feature-mapping.json';
import { IDashboard } from '../interfaces/dashboard.interface';
import { SitemapTree } from '../../../shared/sitemap/models/sitemap-tree.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ApiService<Dashboard> {
  private dashboards: Dashboard[];
  private allLatestDrafts: Dashboard[];
  private cachedDashboards = new Map<string, Dashboard>();
  private cachedPublicDashboards = new Map<string, Dashboard>();
  private publicDashboards = new Map<string, Dashboard>();

  constructor(
    private readonly http: HttpClient,
    private readonly productService: ProductService,
    private readonly dashboardSettingsService: DashboardSettingsService,
    private readonly tenantService: CurrentTenantService,
  ) {
    super(http, dashboardSettingsService.dataServiceApi, 'dashboards');
  }

  public async getPredefinedDashboards(): Promise<Dashboard[]> {
    if (!this.dashboards) {
      this.dashboards = (await super.get()) as Dashboard[];
    }
    return this.dashboards;
  }

  public async getAllLatestDrafts(): Promise<Dashboard[]> {
    if (!this.allLatestDrafts) {
      this.allLatestDrafts = (await super.get(
        `/latestDrafts/${this.tenantService.currentTenantData._id}`,
      )) as Dashboard[];
    }
    return this.allLatestDrafts;
  }

  public async getLatestDashboardByBaseId(
    baseId: string,
    includeUnpublished = false,
  ): Promise<Dashboard> {
    const cachedPublicDashboard = this.cachedPublicDashboards.get(baseId);

    if (cachedPublicDashboard) {
      return JSON.parse(JSON.stringify(cachedPublicDashboard));
    }

    const prefix = includeUnpublished ? '/admin' : '';

    const dashboard = (await super.get(`${prefix}/getHistory/latest/${baseId}`))[0] as Dashboard;

    this.cachedPublicDashboards.set(baseId, dashboard);

    return JSON.parse(JSON.stringify(dashboard));
  }

  public async getPaginatedLatestDrafts(paginationOptions: {
    pageSize: number;
    pageIndex: number;
  }): Promise<ILatestDraftsPaginationResponse> {
    return super.get(
      `/latestDrafts/paginated/${this.tenantService.currentTenantData._id}?pagination=${JSON.stringify(
        paginationOptions,
      )}`,
    ) as any;
  }

  public async getDashboardsByBaseIds(baseIds: string[]): Promise<void> {
    const qs = '?dashboardBaseIds=' + baseIds.join(',');

    const tenantId = this.tenantService.currentTenantData?._id;

    let publicDashboards = (await this.httpClient
      .get(`${this.host}public-dashboards/${tenantId}${qs}`)
      .toPromise()) as any;

    this.cachedPublicDashboards = new Map(
      publicDashboards.map((dashboard) => [dashboard.baseId, dashboard]),
    );
  }

  // public async getDashboard(dashboardId: string): Promise<Dashboard> {
  //   const cachedDashboard = this.cachedDashboards.get(dashboardId);

  //   if (cachedDashboard) {
  //     return JSON.parse(JSON.stringify(cachedDashboard));
  //   }

  //   const dashboard = (await super.get(`/${dashboardId}`)) as Dashboard;

  //   this.cachedDashboards.set(dashboardId, dashboard);

  //   return JSON.parse(JSON.stringify(dashboard));
  // }

  public async processTilesAgainstSubs(dashboard: Dashboard): Promise<void> {
    // TODO: Put in some kind of utility service
    function toEntries<T>(a: T[]): (readonly [number, T])[] {
      return a.map((value, index) => [index, value] as const);
    }

    const theDashboard: any = dashboard;
    theDashboard.canView = true;
    let foundPaidTile = false;

    for (const [i, section] of toEntries(dashboard.sections)) {
      if (section?.rows) {
        for (const [x, row] of toEntries(section.rows)) {
          if (row?.columns) {
            for (const [y, column] of toEntries(row.columns)) {
              const theColumn = column as any;
              if (theColumn?.tileDefinition && theColumn?.tileDefinition?.recipe) {
                if (!foundPaidTile) {
                  theDashboard.canView = false;
                  foundPaidTile = true;
                }
                const tag =
                  'DATASOURCE:' + theColumn.tileDefinition.recipe.dataSource.toUpperCase();
                theColumn.tileDefinition.canView = false;

                const ownedProducts = this.productService.ownedProductsList.getValue();

                for (const ownedProduct of ownedProducts) {
                  if (
                    ownedProduct.tags.includes(tag) &&
                    featureMapping[theColumn.tileDefinition.tileType] ===
                      ownedProduct.featureShortcode
                  ) {
                    theColumn.tileDefinition.canView = true;
                    theDashboard.canView = true;
                  }
                }
              } else {
                if (theColumn.tileDefinition) theColumn.tileDefinition.canView = true;
              }
            }
          }
        }
      }
    }
  }

  public async saveDashboard(dashboard: Dashboard) {
    //ensure not published until deliberately published
    dashboard.published = false;

    return this.post(dashboard, `/drafts`);
  }

  public async createDashboard(dashboard: Dashboard) {
    dashboard.tenantId = this.tenantService.currentTenantData._id;
    await this.post(dashboard);

    return dashboard.baseId;
  }

  public async publishDraft(dashboard: IDashboard) {
    dashboard.version++;

    let url = `${await this.getRequestUrl()}/published/${dashboard.baseId}?version=${dashboard.version}`;

    return await this.http.patch(url, {}).toPromise();
  }

  public async deleteDraft(id: string): Promise<void> {
    super.delete(null, `/${id}`);
  }

  public async deleteAllDrafts(baseId: string): Promise<void> {
    super.delete(null, `/drafts/${baseId}`);
  }

  async init(sitemap) {
    this.loadPublicDashboards(sitemap);
  }

  getDashboardBaseIds(routes) {
    let baseIds = [];
    for (let route of routes) {
      baseIds.push(route.dashboardId);

      if (route.children.length) {
        let childDashboardIds = this.getDashboardBaseIds(route?.children);

        baseIds = baseIds.concat(childDashboardIds);
      }
    }

    return baseIds;
  }

  async loadPublicDashboards(siteMap: SitemapTree) {
    let publicSitemap = siteMap.routes.find((route) => {
      return route.name == 'Public';
    });

    let baseIds = this.getDashboardBaseIds(publicSitemap.children);

    let dashboards = await this.http
      .get<
        IDashboard[]
      >(`${this.dashboardSettingsService.dataServiceApi}public-dashboards/${this.tenantService.currentTenantData._id}/?dashboardBaseIds=${baseIds}`)
      .toPromise();

    if (dashboards.length) {
      for (let dashboard of dashboards) {
        this.cachedPublicDashboards.set((dashboard as any).baseId, dashboard as Dashboard);
      }
    }
  }

  async getPublicDashboardById(id) {
    return await this.http
      .get<IDashboard>(
        `${this.dashboardSettingsService.dataServiceApi}public-dashboards/${this.tenantService.currentTenantData._id}/?dashboardBaseIds=${id}`,
      )
      .toPromise();
  }

  public async getLatestPublicDashboardByBaseId(baseId: string): Promise<Dashboard> {
    const cachedPublicDashboard = this.cachedPublicDashboards.get(baseId);

    if (cachedPublicDashboard) {
      return JSON.parse(JSON.stringify(cachedPublicDashboard));
    }

    let dashboards = await this.http
      .get<IDashboard>(
        `${this.dashboardSettingsService.dataServiceApi}public-dashboards/${this.tenantService.currentTenantData._id}/?dashboardBaseIds=${baseId}`,
      )
      .toPromise();

    this.cachedPublicDashboards.set(baseId, dashboards[0] as Dashboard);

    return JSON.parse(JSON.stringify(dashboards[0]));
  }
}
