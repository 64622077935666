import { Component, OnInit } from '@angular/core';
import { PropertiesViewTileComponent } from '../properties-view-tile/properties-view-tile.component';

@Component({
  selector: 'suvo-bi-major-properties-view-tile',
  templateUrl: './major-properties-view-tile.component.html',
  styleUrls: ['./major-properties-view-tile.component.scss']
})
export class MajorPropertiesViewTileComponent extends PropertiesViewTileComponent {

}
