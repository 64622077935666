import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IDataSourceItem } from '../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { TableApiService } from '../../../tables/services/table-api.service';
import { IDataSourceSettings } from '../../interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../interfaces/data-source-settings.token';
import {IReferenceProvider} from '../../../references/interfaces/reference-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class NewDataSourceItemsService extends TableApiService<IDataSourceItem> implements IReferenceProvider {
  constructor(
    protected httpClient: HttpClient,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings
  ) {
    super(httpClient, `${dataSourceSettings.dataServiceApi}`);
  }
}
