import { Component } from '@angular/core';

@Component({
  selector: 'lib-marketing-statement',
  templateUrl: './marketing-statement.component.html',
  styleUrls: ['./marketing-statement.component.scss'],
})
export class MarketingStatementComponent {
  constructor() {}
}
