<div [ngClass]="{'active': tourActive}" class="animated tour-outlet">

  <div class="tour-container">

    <div class="animated window-container" [style.top.px]="windowRect.top" [style.left.px]="windowRect.left"
      [style.height.px]="windowRect.height" [style.width.px]="windowRect.width">
      <suvo-bi-tour-window>
      </suvo-bi-tour-window>
    </div>
    <div class="card-container" fxLayout="row" fxLayoutAlign="center center">
      <suvo-bi-tour-card [currentProgress]="cardProgress">
      </suvo-bi-tour-card>
    </div>

  </div>

</div>