import { computed, Injectable } from '@angular/core';
import { ChecklistItem } from '@suvo-bi-core';
import { CurrentTenantService } from '@suvo-bi-users';

@Injectable()
export class ThemeChecklistItem implements ChecklistItem {
  readonly title = 'Customise your theme';
  readonly routerLink = '/private/settings/theme';

  readonly isChecked = computed(() => !!this.currentTenantService.currentTenant()?.theme);

  constructor(private readonly currentTenantService: CurrentTenantService) {}
}
