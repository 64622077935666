import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  OrganisationInvitationDialogComponent,
  UsersSnackbarService,
  UsersDialogService,
  OrganisationService,
  CreateOrganisationComponent,
  OrganisationRoleService,
  OrganisationRoleModel,
  PublicOrganisationRoleService,
  PublicOrganisationRoleModel,
} from '@suvo-bi-users';
import { take } from 'rxjs/operators';

@Component({
  selector: 'suvo-bi-organisation-view',
  templateUrl: './organisation-view.component.html',
  styleUrls: ['./organisation-view.component.scss'],
})
export class OrganisationViewComponent implements OnInit {
  organisationListLoaded: 'loading' | 'loaded' | 'none' = 'none';
  memberListLoaded: 'loading' | 'loaded' | 'none' = 'none';

  organisationsList: OrganisationRoleModel[] = [];

  selectedOrganisation = new FormControl();
  selectedOrganisationName = new FormControl();
  selectedOrganisationMembers: PublicOrganisationRoleModel[];

  constructor(
    private organisationRoleService: OrganisationRoleService,
    private publicOrganisationRoleService: PublicOrganisationRoleService,
    private organisationService: OrganisationService,
    private usersSnackbarService: UsersSnackbarService,
    private readonly usersDialogService: UsersDialogService
  ) {}

  ngOnInit(): void {
    this.organisationListLoaded = 'loading';

    this.organisationRoleService
      .get('')
      .then((res: OrganisationRoleModel[]) => {
        this.organisationsList = res;
        this.selectedOrganisation.setValue(res[0]);
        this.selectedOrganisationName.setValue(res[0].organisationName);
        this.organisationListLoaded = 'loaded';
        this.loadMembers(this.selectedOrganisation.value.organisationId);
      });
  }

  async selectOrganisation(): Promise<void> {
    await this.organisationRoleService.setCurrentRole(
      this.selectedOrganisation.value
    );
    this.selectedOrganisationName.setValue(
      this.selectedOrganisation.value.organisationName
    );
    this.loadMembers(this.selectedOrganisation.value.organisationId);
  }

  createOrganisation(): void {
    const dialogRef = this.usersDialogService.open(CreateOrganisationComponent);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (data) => {
        if (data) {
          this.usersSnackbarService.open('Organisation Created');
          this.refreshAndMapSelected();
        }
      });
  }

  async updateOrganisation(): Promise<void> {
    await this.organisationService.updateOrganisation(
      this.selectedOrganisation.value.organisationId,
      {
        name: this.selectedOrganisationName.value,
      }
    );
    this.usersSnackbarService.open('Organisation name updated');
    this.refreshAndMapSelected();
  }

  async refreshAndMapSelected(): Promise<void> {
    this.organisationRoleService.get('').then((res: any[]) => {
      this.organisationsList = res;
      let selectedIndex = this.organisationsList.findIndex((orgRole) => {
        return (
          orgRole.organisationId ===
          this.selectedOrganisation.value.organisationId
        );
      });

      selectedIndex = selectedIndex < 0 ? 0 : selectedIndex;
      this.selectedOrganisation.setValue(res[selectedIndex]);
      this.selectedOrganisationName.setValue(
        res[selectedIndex].organisationName
      );
      this.loadMembers(this.selectedOrganisation.value.organisationId);
    });
  }

  async loadMembers(organisationId: string): Promise<void> {
    this.memberListLoaded = 'loading';
    this.selectedOrganisationMembers =
      (await this.publicOrganisationRoleService.getMemberList(
        organisationId
      )) as PublicOrganisationRoleModel[];
    this.memberListLoaded = 'loaded';
  }

  openInvitationDialog(): void {
    this.usersDialogService.open(OrganisationInvitationDialogComponent, {
      organisationId: this.selectedOrganisation.value?.organisationId,
    });
  }
}
