<div fxLayoutAlign="center center">

  <div class="non-full-export-card card padding" fxLayout="column" fxLayoutAlign="center center">
    <h4>Export <b>{{ tileDefinition?.exportDisplayName }}</b>
      <mat-icon *ngIf="numberOfFilters" [matBadge]="numberOfFilters" matBadgeColor="warn">filter_alt</mat-icon>
    </h4>

    <form *ngIf="optionsForm" [formGroup]="optionsForm">

      <div fxFlex="1 1" fxLayout="row" fxLayoutAlign="stretch flex-start">

        <mat-form-field appearance="outline" aria-label="File Name">
          <mat-label>File Name</mat-label>
          <input matInput formControlName="fileName">
          <span matSuffix class="file-extension">.csv</span>
          <mat-error *ngIf="fileNameControl.invalid">
            {{ getFileNameError() }}
          </mat-error>
        </mat-form-field>

        <!-- <mat-button-toggle-group>
          <mat-button-toggle>.csv</mat-button-toggle>
          <mat-button-toggle>.pdf</mat-button-toggle>
        </mat-button-toggle-group> -->

      </div>


    </form>



  </div>

</div>