import { Injectable } from '@angular/core';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { TenantModel } from '../model/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends CommonService<TenantModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.usersServiceApi, '');
  }

  /**
   * @returns An array of tenants that the signed in user has a role for.
   */
  getMyTenants() {
    return this.get('me/tenants') as Promise<TenantModel[]>;
  }
}
