import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  ValidationErrors,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';
import { UsersDialogService } from '../../../../../shared/services/users-dialog/users-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take, takeUntil } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import firebase from 'firebase/compat/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyMfaDialogComponent } from '../../public/verify-mfa-dialog/verify-mfa-dialog.component';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      control &&
      control.dirty &&
      control.parent.get('newPassword').value !==
        control.parent.get('confirmPassword').value
    );
  }
}

@Component({
  selector: 'suvo-users-change-password',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  actionCode: string | any;
  matcher: MyErrorStateMatcher | any;
  private readonly unsubscribe$ = new Subject<void>();
  passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  hide = true;
  hideConfirmPassword = true;

  localFirebaseUser: firebase.User;

  constructor(
    private usersSnackbarService: UsersSnackbarService,
    private activatedRoute: ActivatedRoute,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private readonly usersDialogService: UsersDialogService,
    private readonly dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.angularFireAuth.authState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user) => {
        if (user) {
          this.localFirebaseUser = user;
        }
      });

    this.changePasswordForm = new FormGroup(
      {
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passRegex),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      {
        validators: this.checkPasswordsMatch,
      }
    );
    this.matcher = new MyErrorStateMatcher();
  }

  checkPasswordsMatch = (control: AbstractControl): ValidationErrors | null => {
    if (this.changePasswordForm) {
      return this.changePasswordForm.get('newPassword').value ===
        this.changePasswordForm.get('confirmPassword').value
        ? null
        : { notSame: true };
    }
    return null;
  };

  async handleChangePassword(): Promise<void> {
    // TODO: will need re-authentication
    try {
      await this.localFirebaseUser.updatePassword(
        this.changePasswordForm.controls.confirmPassword.value
      );
      this.angularFireAuth
        .signOut()
        .then(() => {
          this.dialogRef.close();
          this.router.navigateByUrl('login');
          this.usersSnackbarService.open(
            'Password change successful. Please login again.'
          );
        })
        .catch((err) => {
          console.log(err);
          this.usersSnackbarService.open('Something went wrong');
        });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        const dialogRef = this.usersDialogService.open(
          VerifyMfaDialogComponent,
          {
            title: 'Verify Identity',
          }
        );

        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(async (data: any) => {
            if (data?.success) {
              await this.handleChangePassword();
            }
          });
      } else {
        console.log(error);
        this.usersSnackbarService.open(
          `Your Password could not be changed. Please contact support.`
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
