import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  OrganisationRoleModel,
  OrganisationRoleService,
  OrganisationService,
  UsersSnackbarService,
} from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManageBiOrganisationsService } from '../../../services/manage-bi-organisations.service';

@Component({
  selector: 'lib-organisation-profile',
  templateUrl: './organisation-profile.component.html',
  styleUrls: ['./organisation-profile.component.scss'],
})
export class OrganisationProfileComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  selectedOrganisation = new FormControl();
  selectedOrganisationName = new FormControl();

  constructor(
    private readonly manageBiOrganisationsService: ManageBiOrganisationsService,
    private organisationService: OrganisationService,
    private usersSnackbarService: UsersSnackbarService,
    private readonly organisationRoleService: OrganisationRoleService
  ) {}

  ngOnInit(): void {
    this.manageBiOrganisationsService.selectedSettingsOrgRoleSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orgRole) => {
        if (orgRole) {
          this.selectedOrganisation.setValue(orgRole);
          this.selectedOrganisationName.setValue(orgRole.organisationName);
        }
      });
  }

  async updateOrganisation(): Promise<void> {
    await this.organisationService.updateOrganisation(
      this.selectedOrganisation.value.organisationId,
      {
        name: this.selectedOrganisationName.value,
      }
    );
    this.usersSnackbarService.open('Organisation name updated');
    const updatedOrgRole = (await this.organisationRoleService.getRole(
      this.selectedOrganisation.value._id
    )) as OrganisationRoleModel;
    this.manageBiOrganisationsService.selectedSettingsOrgRoleSubject.next(
      updatedOrgRole
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
