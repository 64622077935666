import { Component, Input } from '@angular/core';
import { ITileDefinition } from '../../../../interfaces/tiles/definition-helpers/tile-definition.interface';
import { ITileComponent } from '../../tile-component.interface';

@Component({
  selector: 'suvo-bi-invalid-tile',
  templateUrl: './invalid-tile.component.html',
  styleUrls: ['./invalid-tile.component.scss']
})
export class InvalidTileComponent implements ITileComponent {
  @Input() public tileDefinition: ITileDefinition;
}
