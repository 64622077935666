import { IRowDefinition } from "../interfaces/row-definition.interface";
import { ColumnDefinition } from "./column-definition";

export class RowDefinition implements IRowDefinition {

    columns: ColumnDefinition[];

    constructor(init: IRowDefinition) {
        this.columns = init.columns.map(column => new ColumnDefinition(column));
    }

    toJSObj() {
        return JSON.parse(JSON.stringify(this));
    }
}
