import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { IIcon } from '../../../dashboards/interfaces/tiles/definition-helpers/icon.interface';
import { ILinkButton } from '../../../dashboards/interfaces/tiles/definition-helpers/link-button.interface';
import { IPresentationTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/presentation-tile-definition.interface';
import { ICON_STYLES_OPTIONS } from '../../constants/icon-styles.constant';
import { ISelectOption } from '../../interfaces/select-option.interface';
import { TileCreatorHelpersService } from '../../services/tile-creator-helpers.service';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

const IntegerValidator: ValidatorFn = (control) => {
  return Number.isInteger(control.value) ? null : { notInteger: true };
};

class FontSizeFormControl extends FormControl<number> {
  constructor(defaultValue?: number) {
    super(defaultValue, [Validators.min(6), Validators.max(64), IntegerValidator]);
  }
}

@Component({
  selector: 'lib-presentation-setup-wizard',
  templateUrl: './presentation-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './presentation-setup-wizard.component.scss'],
})
export class PresentationSetupWizardComponent implements OnInit, OnDestroy {
  tileDefinition: IPresentationTileDefinition;

  readonly formGroup = new FormGroup({
    text: new FormControl(),
    background: new FormControl(),
    textFontSize: new FontSizeFormControl(6),
    height: new FormControl(),
    textFontColor: new FormControl('#000000'),

    // Buttons
    linkButtons: new FormArray<FormGroup>([]),

    // Icons
    icons: new FormArray<FormGroup>([]),
  });

  public iconStylesOptions: ISelectOption[];
  public fontSizesOptions: string[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorHelpersService: TileCreatorHelpersService,
    private readonly tileCreatorService: TileCreatorService,
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSelectOptions();
    this.initSetupForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initSelectOptions(): void {
    this.iconStylesOptions = ICON_STYLES_OPTIONS;
    this.fontSizesOptions = this.tileCreatorHelpersService.generateFontSize();
  }

  public initSetupForms(): void {
    if (this.tileDefinition) {
      this.formGroup.patchValue(this.tileDefinition);

      this.tileDefinition.icons?.forEach((icon: IIcon) => {
        this.addIcon(icon);
      });

      //TODO : Replace this workaround - ideally handle this in editor widgets
      for (const field of ['textFontSize']) {
        if (this.tileDefinition[field]) {
          let originalValue = this.tileDefinition[field];
          if (originalValue) {
            let value = originalValue;
            if (!isNaN(originalValue)) {
              let valueStr = originalValue.slice(0, -2);
              value = parseInt(valueStr);
            }
            this.formGroup.controls[field].setValue(value);
          }
        }
      }
    }
  }

  public addIcon(icon?: IIcon): void {
    const iconFormGroup = this.fb.group({
      icon: [''],
      iconStyle: ['basic'],
      iconColor: ['#000000'],
      iconOutlineColor: ['#000000'],
      iconBackgroundColor: ['#000000'],
    });

    iconFormGroup.patchValue(icon);

    (this.formGroup.controls.icons as FormArray).push(iconFormGroup);
  }

  public removeIcon(iconIndex: number): void {
    (this.formGroup.controls.icons as FormArray).removeAt(iconIndex);
  }

  public onCreate(): void {
    Object.assign(this.tileDefinition, {
      tileType: 'presentation',
      canView: 'true',
      ...this.formGroup.value,
    });
    this.tileCreatorService.onChange.next();
  }
}
