import { FilterOperatorType } from '../../../shared/interfaces/filters/filter-operator-type.enum';
import { IFiltersSections } from '../interfaces/sections/filters-sections.interface';
import { ALL_CONTINENTS, CONTINENT_COUNTRIES } from './country-continents';

export const FLEETS_FILTERS_SECTIONS: IFiltersSections = {
  name: 'fleets',
  shortCode: 'fleets',
  exportRoute: 'fleets/export',
  sections: [
    {
      options: [
        {
          filterMethod: 'keywords',
          filterPropertyKeys: ['search'],
          filterMethodConfig: {
            useForSearch: true,
            title: 'Search',
            label: 'Any keywords or fleet IDs',
          },
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'combined-nested-checkboxes',
          filterMethodConfig: {
            title: 'Location',
            showChildrenLabel: 'Show countries',
            enableShowChildren: true,
            parentData: ALL_CONTINENTS,
            parentKeyName: 'continent',
            childData: CONTINENT_COUNTRIES,
            childKeyName: 'country',
            optionsColumns: 3,
          },
          filterPropertyKeys: ['continent', 'country'],
        },
      ],
      filterGroup: FilterOperatorType.Or,
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'categories',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['category.id'],
          definitionAlias: 'fleets',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'sub-categories',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['sub-category.id'],
          definitionAlias: 'fleets',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'operators',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['operator/city.id'],
          definitionAlias: 'fleets',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'suppliers',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['supplier.id'],
          definitionAlias: 'fleets',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'between-boxes',
          filterMethodConfig: {
            title: 'Train Set Quantity',
            minValue: 0,
            maxValue: 500,
          },
          filterPropertyKeys: ['trainSetQuantity'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'years-drop-down',
          filterMethodConfig: {
            title: 'Delivery Start',
            min: 2000,
            max: 2040,
          },
          filterPropertyKeys: ['deliveryStart'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'years-drop-down',
          filterMethodConfig: {
            title: 'Delivery End',
            min: 2000,
            max: 2040,
          },
          filterPropertyKeys: ['deliveryEnd'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'between-boxes',
          filterMethodConfig: {
            title: 'Number of Vehicles',
          },
          filterPropertyKeys: ['vehicleQuantity'],
        },
      ],
    },
    // {
    //   option: {
    //     filterMethod: 'simple',
    //     filterPropertyKeys: ['drink'],
    //   }
    // },
  ],
};
