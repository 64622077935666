import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService, SitemapTree } from '@suvo-bi-lib';
import { CurrentTenantService } from '@suvo-bi-users';

@Injectable({
  providedIn: 'root'
})
export class SitemapApiService extends ApiService<SitemapTree> {
  constructor(http: HttpClient, private readonly tenantService: CurrentTenantService) {
    super(http, environment.dataServiceApi, 'sitemap');
  }

  public async getCurrentTenantSitemap(): Promise<SitemapTree> {
    return (await super.get(`/${this.tenantService.currentTenantData._id}`)) as SitemapTree;
  }

  public async updateSitemap(sitemapId: string, sitemap: any): Promise<SitemapTree> {
    return (await super.patch(sitemapId, sitemap)) as SitemapTree;
  }
}
