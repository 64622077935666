<h4 mat-dialog-title><b>Unsaved Changes to Filters</b></h4>
<div mat-dialog-content>
  <!-- <p>Are you sure you'd like to leave filters?</p> -->
  <!-- <p>Choose what to do with {{ data.filters.name }}.</p> -->
  <p>Choose what to do with your changes.</p>

</div>
<div class="actions" fxLayout="row" fxLayoutAlign="stretch stretch" mat-dialog-actions>
  <button mat-stroked-button [matDialogClose]="{ 'leave': false, 'save': false }">Stay on this page</button>
  <button mat-stroked-button [matDialogClose]="{ 'leave': true, 'save': false }">
    <mat-icon>undo</mat-icon>
    Revert
  </button>
  <button mat-flat-button [matDialogClose]="{ 'leave': true, 'save': true }" class="save-button">
    <mat-icon>check</mat-icon>
    Save
  </button>
</div>