import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'suvo-bi-manage-bi-organisations',
  templateUrl: './manage-bi-organisations.component.html',
  styleUrls: ['./manage-bi-organisations.component.scss']
})
export class ManageBiOrganisationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
