import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IExportDialogConfig } from '../../../interfaces/dialogs/export-dialog-config.interface';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {
  public activeFiltersName: string;
  public exportOptionsForm: FormGroup;
  public fileFormat = '.csv';

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: IExportDialogConfig
  ) { }

  public ngOnInit(): void {
    const filtersChannel = this.config.filtersChannel;
    const activeFilters = filtersChannel.selectedFilters;

    this.activeFiltersName = activeFilters?.name || null;

    this.exportOptionsForm = new FormGroup({
      // fileFormat: new FormControl('csv', [Validators.required]),
      fileName: new FormControl('export', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(64),
      ]),
      applyFilters: new FormControl(!!activeFilters, Validators.required),
    });
  }
}
