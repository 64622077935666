import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CurrentTenantService } from '@suvo-bi-users';

@Injectable()
export class TenantSelectedGuard {
  constructor(
    private readonly currentTenantService: CurrentTenantService,
    private readonly router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Is current tenant already set?
    if (this.currentTenantService.currentTenant()) {
      return true;
    }

    let options: NavigationExtras = {
      queryParams: {
        redirectUrl: encodeURIComponent(state.url),
      },
    };

    await this.router.navigate(['shops'], options);
    return false;
  }
}
