<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <span class="header">{{ option.filterMethodConfig?.title || 'Years Drop Down' }}</span>

  <div *ngIf="formGroup" fxFlex="0 1" fxLayout="row" fxLayoutGap="8px" [formGroup]="formGroup">
    <div fxFlex="1 1 calc(50% - 4px)">
      <!-- Greater Than -->
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Minimum</mat-label>
        <mat-select formControlName="greaterThan">
          <mat-option>No Minimum</mat-option>
          <mat-option *ngFor="let year of yearOptions" [value]="year">{{ year }}</mat-option>
        </mat-select>

        <suvo-bi-clear-control-button
          matSuffix
          [clearFormGroup]="formGroup"
          clearFormControlName="greaterThan"
        >
        </suvo-bi-clear-control-button>
      </mat-form-field>
    </div>
    <div fxFlex="1 1 calc(50% - 4px)">
      <!-- Less Than -->
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Maximum</mat-label>
        <mat-select formControlName="lessThan">
          <mat-option>No Maximum</mat-option>
          <mat-option *ngFor="let year of yearOptions" [value]="year">{{ year }}</mat-option>
        </mat-select>

        <suvo-bi-clear-control-button
          matSuffix
          [clearFormGroup]="formGroup"
          clearFormControlName="lessThan"
        >
        </suvo-bi-clear-control-button>
      </mat-form-field>
    </div>
  </div>
</div>
