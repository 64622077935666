import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { DataSourceModule, FiltersModule } from '@suvo-bi-lib';
import { FEATURE_FILTERS_SECTIONS } from 'submodules/SUVO-FRONTEND-FRAMEWORK/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/filters/data/feature-filters-sections';
import { SharedModule } from '../shared/shared.module';
import { TenantSelectedGuard } from './guards/tenant-selected.guard';
import { NavigationModule } from './modules/navigation/navigation.module';
import { ReportsModule } from './modules/reports/reports.module';
import { PrivateRoutingModule } from './private-routing.module';

@NgModule({
  imports: [
    DataSourceModule.forRoot({
      dataServiceApi: environment.dataServiceApi,
    }),
    FiltersModule.forRoot({
      dataServiceApi: environment.dataServiceApi,
      filtersSections: FEATURE_FILTERS_SECTIONS,
      reverseUrlParts: false,
    }),
    NavigationModule,
    PrivateRoutingModule,
    CommonModule,
    ReportsModule,
    SharedModule,
    OverlayModule,
  ],
  providers: [TenantSelectedGuard],
})
export class PrivateModule {}
