import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';
import { ISaveDialogResponse } from '../../../interfaces/save-dialog-response.interface';
import { PromptSaveDialogComponent } from '../../dialogs/prompt-save-dialog/prompt-save-dialog.component';
import { EditFiltersComponent } from '../edit-filters/edit-filters.component';

@Component({
  selector: 'suvo-bi-filters-title',
  templateUrl: './filters-title.component.html',
  styleUrls: ['./filters-title.component.scss'],
})
export class FiltersTitleComponent implements OnInit {
  @Output() titleClicked = new EventEmitter();
  @Output() saveFilters = new EventEmitter<boolean>();
  @Input() filters: IFilters;
  @Input() editFiltersRef: EditFiltersComponent;
  @Input() autoSave = true;

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {}

  titlePressed(): void {
    console.log('title pressed, this.filters:', this.filters);

    if (!this.filters || this.filters._id) {
      this.titleClicked.emit();
    } else {
      const filters = this.filters;
      this.matDialog
        .open(PromptSaveDialogComponent, { data: { filters } })
        .afterClosed()
        .toPromise()
        .then((response: ISaveDialogResponse) => {
          this.saveFilters.emit(response.save);
          if (response.leave) this.titleClicked.emit();
        });
    }
  }
}
