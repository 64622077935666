import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromptSaveDialogComponent } from '../components/dialogs/prompt-save-dialog/prompt-save-dialog.component';
import { FiltersChannel } from '../models/filters-channel.model';
import { FiltersApiService } from './filters-api.service';
import { Filters } from '../models/filters.model';
import {
  ITableSort,
  ITableSortDirection,
} from '../../../shared/interfaces/table/table-sort.interface';
import { FiltersUtilityService } from './filters-utility.service';

@Injectable({ providedIn: 'root' })
export class FiltersChannelsService {
  constructor(
    private readonly filtersApiService: FiltersApiService,
    private readonly filtersUtilityService: FiltersUtilityService,
    private readonly matDialog: MatDialog,
  ) {}

  public getFiltersChannel(
    filtersChannelName: string,
    suffix: string,
    selectedFilterId?: string,
    type?: string,
  ): FiltersChannel {
    let filtersChannel = new FiltersChannel(filtersChannelName, suffix, selectedFilterId);

    filtersChannel.loadedStatus.next(false);

    let sortOptions: ITableSort = {
      active: 'updatedAt',
      direction: ITableSortDirection.Descending,
    };

    // Filters Channel Name matches alias for now. ('projects' etc.)
    this.filtersApiService
      .getPaginatedFilters(filtersChannelName, null, sortOptions, null)
      .then(async (savedFilters) => {
        const sessionSelectedFilterId = sessionStorage.getItem(
          `current_filters_${filtersChannelName}`,
        );

        if (savedFilters.data.length == 0) {
          const filtersName = this.filtersUtilityService.generateDefaultFiltersName(
            filtersChannelName,
            savedFilters.data,
          );

          let newFilters = new Filters({
            name: filtersName,
            type: 'GENERAL',
            entity: filtersChannelName,
            query: {
              op: 'AND',
              rules: [],
            },
          });

          await this.filtersApiService.createFilters(filtersChannelName, newFilters);

          savedFilters.data.unshift(newFilters);

          filtersChannel.selectedFilterId = newFilters._id;
        } else if (sessionSelectedFilterId) {
          filtersChannel.selectedFilterId = sessionSelectedFilterId;
        } else {
          let latestFilter = savedFilters.data?.sort((a, b) => {
            return a.updatedAt < b.updatedAt ? 1 : -1;
          })[0];

          filtersChannel.selectedFilterId = latestFilter._id;
        }

        filtersChannel.addSavedFilters(savedFilters.data, type);
        filtersChannel.loaded = true;
        filtersChannel.loadedStatus.next(true);

        filtersChannel.filters.next(filtersChannel.selectedFilters);
      });

    return filtersChannel;
  }

  async canDeactivate(filtersChannel): Promise<boolean> {
    if (!filtersChannel) {
      return true;
    }

    const channelName = filtersChannel.channelName;
    const filters = filtersChannel.selectedFilters;

    console.log('Unsaved changes? :', filtersChannel.getUnsavedChanges());

    if (filtersChannel.getUnsavedChanges()) {
      const response = await this.matDialog
        .open(PromptSaveDialogComponent, { data: { filters } })
        .afterClosed()
        .toPromise();

      if (!response.leave) {
        return false;
      }

      filtersChannel.setUnsavedChanges(false);

      if (response.save) {
        await this.filtersApiService.patchFilters(channelName, filters);
        return true;
      }

      return true;
    }

    return true;
  }
}
