<div class="card view-margin" fxFlex="1 1">

  <div fxFlex="1 1" fxLayout="column" fxLayoutAlign="flex-start stretch">

    <ng-container *ngFor="let display of displays; trackBy: trackDisplay; let last=last">
      <div [fxFlex]="last ? '1 1 0' : '0 0 0'" fxLayout="row" fxLayoutAlign="flex-start stretch">
        <!-- <mat-icon class="property-icon">{{ display.displayIcon }}</mat-icon> -->

        <div [fxFlex]="'0 0 ' + (viewTile?.titleWidth ? viewTile.titleWidth : '37.5%')" class="property-name-box">
          <span class="property-name">{{ display.property?.displayName || '?' }}</span>
        </div>

        <div class="property-value-box">
          <suvo-bi-property [config]="display.config" [property]="display.property" [item]="item" [control]="display.control"
            [editItemMode]="editItemMode">
          </suvo-bi-property>
        </div>

      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>


  </div>

</div>