<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource$"
    class="table"
    matSort
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="listDropped($event)"
    [ngClass]="{ loaded: !isLoading }"
  >
    <ng-container *ngFor="let property of propertyColumns; index as i" [matColumnDef]="property.name">
      <th
        mat-header-cell
        cdkDrag
        [mat-sort-header]="canSort ? property.name : null"
        *matHeaderCellDef
        [disabled]="canSort ? false : true"
        [style.width]="isLoading ? columnWidths[i] + '%' : 'auto'"
      >
        {{ property.displayName }}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="property.displayOptions?.isChippable; else basicCell">
          <div fxLayoutAlign="center center" class="chip" [ngStyle]="getChipStyles(row, property)">
            <span fxFlex="nogrow">{{ row.data[property.name] }}</span>
          </div>
        </ng-container>
        <ng-template #basicCell>
          <span [class.index-value]="property.name === 'index'">{{ row.data[property.name] }}</span>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="propertyColumnNames"></tr>
    <tr
      mat-row
      class="row"
      [class.hidden]="isLoading"
      *matRowDef="let row; columns: propertyColumnNames"
      [routerLink]="getRowRouterLink(row)"
    ></tr>
  </table>
</div>
<ng-container *ngIf="isLoading">
  <div *ngIf="options.loadingType == 'spinner'" class="loading-spinner-container">
    <suvo-core-loading-spinner [message]="'Searching'"></suvo-core-loading-spinner>
  </div>
  <ng-container *ngIf="options.loadingType != 'spinner'">
    <suvo-bi-skeleton-table-rows
      class="skeleton"
      [rowCount]="pageSize"
      [columnWidths]="columnWidths"
    ></suvo-bi-skeleton-table-rows>
  </ng-container>
  <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"></div>
</ng-container>
<ng-container *ngIf="!isLoading && noData">
  <div class="no-data-container">
    <!--[style.height.px]="(matPaginator?.pageSize || pageSize) * 48"-->
    <ng-content select="[noDataContent]"></ng-content>
  </div>
</ng-container>

<!-- <div fxLayout="column" fxLayoutGap="16px" style="margin-left: 16px; font-weight: 700; opacity: 0.7">
  <span>Subjects</span>
  <span>Pagination: {{ (paginationSubject | async) | json }}</span>
  <span>Sort: {{ (sortSubject | async) | json }}</span>
  <span>Filters: {{ (filtersSubject | async)?.dataSourceFilters?.name }}</span>
</div> -->

<mat-paginator
  *ngIf="options.showPageControls"
  [pageSize]="pageSize"
  [hidePageSize]="!options.showPageSizeOptions"
  [pageSizeOptions]="options.showPageSizeOptions && pageSizeOptions"
  showFirstLastButtons
></mat-paginator>
