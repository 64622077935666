<div class="banner">
  <p>
    <ng-container *ngIf="isTrial">
      <span>
        You are on a
        <b>free trial</b>
      </span>
      <!-- <span *ngIf="restrictionText">
        and are limited to the {{ restrictionText }} data points.
      </span> -->
      <!-- <span *ngIf="!restrictionText"> -->
      <span>&nbsp;based on a small sample dataset.</span>
    </ng-container>
    <span *ngIf="!isTrial">The below is based on a small sample dataset only.</span>
    <span *ngIf="tags?.length">
      Subscribe to
      <b style="text-transform: capitalize">{{ tags[0] }}</b>
      for access to the full dataset.
    </span>
  </p>
  <mat-icon (click)="onInfoButtonClick()">info_outline</mat-icon>
</div>
