import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { ActiveFiltersWidgetComponent } from "./active-filters-widget/active-filters-widget.component";


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [ActiveFiltersWidgetComponent],
  exports: [ActiveFiltersWidgetComponent],
})
export class FilterWidgetsModule { }