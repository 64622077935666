import { Injectable, signal } from '@angular/core';
import { ChecklistItem } from '@suvo-bi-core';
import { DataSourceDefinitionsService } from '@suvo-bi-lib';

@Injectable()
export class ConnectDataChecklistItem implements ChecklistItem {
  readonly title = 'Connect data';
  readonly routerLink = '/private/data-sources';

  readonly isChecked = signal(false);

  constructor(dataSourceDefinitionService: DataSourceDefinitionsService) {
    // TODO(fix): make reactive
    dataSourceDefinitionService.getAllDefinitions().then((definitions) => {
      this.isChecked.set(!!definitions.find((definition) => definition.primaryOptions?.isPrimary));
    });
  }
}
