<div class="screen-container" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <h4 class="margin">Subscription</h4>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin padding card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch" *ngIf="selectedOrganisationRole?.organisationId">
      <lib-manage-subscription [navType]="'single'" [organisationId]="selectedOrganisationRole.organisationId"></lib-manage-subscription>
    </div>
  </div>

  <!-- TODO: MERGE INTO ONE COMPONENT -->
  <div class="top-margin" fxLayout="row wrap" fxLayoutAlign="flex-start stretch" *ngIf="selectedOrganisationRole?.role === 'ADMIN'">
    <h4 class="margin">Billing Details</h4>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch" *ngIf="selectedOrganisationRole?.role === 'ADMIN'">
    <div class="margin padding card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <p>To manage your existing payment methods, click the button to go to the Stripe™ Customer Portal.</p>
      <br>
      <button button mat-raised-button color="primary" class="billing-portal-btn" (click)="goToCustomerPortal()">
        Manage Billing Details
      </button>
    </div>
  </div>
</div>