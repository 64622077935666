import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pxSuffix',
})
export class PxSuffixPipe implements PipeTransform {
  transform(fontSize: string | number): string {
    if (typeof fontSize === 'number') {
      return fontSize + 'px';
    }

    const hasSuffix = /[a-zA-Z%]+$/.test(fontSize);
    return hasSuffix ? fontSize : fontSize + 'px';
  }
}
