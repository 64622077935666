import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DataSourceItemsService } from '../../../../../data-source/data-source-items/services/data-source-items.service';
import { FiltersChannel } from '../../../../../filters/models/filters-channel.model';
import { Filters } from '../../../../../filters/models/filters.model';
import { IExportButtonTileDefinition } from '../../../../interfaces/tiles/definitions/export-button-tile-definition.interface';

@Component({
  selector: 'suvo-bi-export-button-tile',
  templateUrl: './export-button-tile.component.html',
  styleUrls: ['./export-button-tile.component.scss'],
})
export class ExportButtonTileComponent implements OnDestroy {
  @Input() public tileDefinition: IExportButtonTileDefinition;
  @Input() protected filtersChannel: FiltersChannel;

  public isExporting = false;

  protected unsubscribe$ = new Subject<boolean>();

  constructor(
    protected readonly dataSourceItemsService: DataSourceItemsService
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public async export(): Promise<void> {
    if (this.isExporting) {
      return;
    }

    const dataSourceAlias = this.tileDefinition.dataSource;
    const filters = this.filtersChannel.filters.getValue();
    const dateString = (new Date()).toLocaleDateString();
    const fileName = `${dataSourceAlias}_${filters.name}_${dateString}`
      .replace(/[^\w-_]/g, '');

    this.exportDataSourceItems(dataSourceAlias, filters, fileName);
  }

  protected async exportDataSourceItems(
    dataSourceAlias: string,
    filters: Filters,
    fileName: string
  ): Promise<void> {
    this.isExporting = true;

    this.dataSourceItemsService
      .getCsvString(
        dataSourceAlias,
        {
          filters,
        },
        {
          fileFormat: 'CSV',
          // maxItems: 500,
        }
      )
      .then((csvString) => {
        if (this.unsubscribe$.closed) {
          return;
        }

        const blob = new Blob([csvString], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;
        link.click();

        this.isExporting = false;
      })
      .catch((error) => {
        console.error(error);
        this.isExporting = false;
      });
  }
}
