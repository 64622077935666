import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StripeCardComponent } from 'ngx-stripe';
import { UpgradeSubscriptionDialogComponent } from '../../dialogs/upgrade-subscription-dialog/upgrade-subscription-dialog.component';
import { OrganisationRoleService } from '../../../organisation-role/services/organisation-role.service';
import { SubscriptionService } from '../../services/subscription.service';

import { StripeCardElementOptions } from '@stripe/stripe-js';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { UsersSnackbarService } from '../../../../shared/services/users-snackbar/users-snackbar.service';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { IUser } from '../../../authentication/interfaces/user.interface';
import { UsersService } from '../../../users/services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductService } from '../../../product/services/product.service';

type Access = 'none' | 'limited' | 'full';

interface ProductData {
  name: string;
  stripeProductId: string;
  isAutoTrial: boolean;
  price: number;
  currency: string;
  features: any[];
}

@Component({
  selector: 'lib-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss'],
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  @Input() navType?: 'single' | 'multiple' | 'personal' = 'multiple';
  @Input() organisationId?: string;
  @Input() isTenantAdmin = false;

  organisationList = [];

  selectedOrganisation = new FormControl();
  selectedOrgCustomer;
  selectedOrganisationCustomerSubscriptions;

  tenantStripeProducts = [];

  clientSecret: string;
  clientSecretType: string = null;

  loading = true;

  unsubscribe$ = new Subject<boolean>();
  localUser: IUser;
  isEnteringPaymentInfo = false;
  dirtyCheckObj = {
    originalValues: {
      selectedProducts: [],
      seats: 0,
    },
    isDirty: false,
  };

  seats = new FormControl(1);
  seatsValueSub: Subscription;

  selectedProducts = 0;
  discountPercentage = 0;
  previewSubtotal = 0;
  previewTotal = 0;
  subscriptionIncomplete = false;
  trialDaysRemaining = 0;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  isOrganisationAdmin = true;
  currentError = null;
  currentSuccessMessage = '';

  productGroups: {
    name: string;
    icon: string;
    active: ProductData;
    products: ProductData[];
  }[] = [];

  constructor(
    private organisationRoleService: OrganisationRoleService,
    private subscriptionService: SubscriptionService,
    private usersSnackbarService: UsersSnackbarService,

    // REQUIRED BY COMPONENT HTML
    private suvoUsersClientLibSettingsService: SuvoUsersClientLibSettingsService,

    private usersService: UsersService,
    private readonly matDialog: MatDialog,
    private productService: ProductService
  ) {}

  async ngOnInit() {
    this.usersService.userSubject.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe((newUser) => {
      this.localUser = newUser;
    });

    this.organisationList = (await this.organisationRoleService.get('')) as any[];

    if (this.navType !== 'personal' && this.organisationId) {
      for (const organisationRole of this.organisationList) {
        if (this.organisationId === organisationRole.organisationId) {
          this.selectedOrganisation.setValue(organisationRole);
        }
      }
    } else {
      this.selectedOrganisation.setValue(this.organisationList[0]);
    }

    try {
      this.selectedOrgCustomer = await this.subscriptionService.getOrganisationCustomer(
        this.selectedOrganisation.value.organisationId
      );
      await this.refreshProductsAndSubs();
      this.reCalculatePrice();
      this.seatsValueSub = this.seats.valueChanges.subscribe((value) => {
        if (value < 1) {
          this.seats.setValue(1);
        } else if (value > 9999999) {
          this.seats.setValue(9999999);
        }
        this.isOrganisationAdmin = true;
        this.reCalculatePrice();
      });
    } catch (error) {
      if (error.status === 403 && (error?.statusText === 'Forbidden' || error?.error?.error === 'Forbidden')) {
        this.loading = false;
        this.isOrganisationAdmin = false;
      }
    }
  }

  async selectOrganisation(): Promise<void> {
    this.loading = true;
    this.isOrganisationAdmin = true;
    await this.organisationRoleService.setCurrentRole(this.selectedOrganisation.value);

    try {
      this.selectedOrgCustomer = await this.subscriptionService.getOrganisationCustomer(
        this.selectedOrganisation.value.organisationId
      );
      await this.refreshProductsAndSubs();
    } catch (error) {
      if (error.status === 403 && (error?.statusText === 'Forbidden' || error?.error?.error === 'Forbidden')) {
        this.loading = false;
        this.isOrganisationAdmin = false;
      }
    }
  }

  upgradeFromTrial(): void {
    const dialog = this.matDialog.open(UpgradeSubscriptionDialogComponent, {
      data: {
        productGroups: this.productGroups,
        selectedOrgCustomer: this.selectedOrgCustomer,
        selectedOrgCustomerSubs: this.selectedOrganisationCustomerSubscriptions,
        selectedOrganisation: this.selectedOrganisation.value,
        localUser: this.localUser,
      },
      width: '690px',
      height: '90vh',
    });

    if (window.innerWidth < 576) {
      dialog.updateSize('100%', '100%');
    }

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (data) => {
        if (data?.success) {
          this.usersSnackbarService.open('Your subscription has been upgraded');
          this.loading = true;
          this.selectedOrgCustomer = await this.subscriptionService.getOrganisationCustomer(
            this.selectedOrganisation.value.organisationId
          );
          await this.refreshProductsAndSubs();
        } else if (data?.errorMsg) {
          this.loading = true;
          this.selectedOrgCustomer = await this.subscriptionService.getOrganisationCustomer(
            this.selectedOrganisation.value.organisationId
          );
          await this.refreshProductsAndSubs(false);
          this.currentError = { message: data.errorMsg };
        }
      });
  }

  async cancelSubscription(cancelNow = false, clearErr = true): Promise<void> {
    if (clearErr) {
      this.currentError = null;
    }
    this.loading = true;

    try {
      // TODO: BAD, assuming user cannot have more than 1 subscription
      await this.subscriptionService.cancelSubscription(this.selectedOrganisation.value.organisationId, cancelNow);
      // this.usersSnackbarService.open('Your subscription has been cancelled');
      await this.refreshProductsAndSubs(clearErr);
      // this.updateSuccessMessage('Your subscription has been cancelled');
    } catch (error) {
      console.log(error);
      if (error?.error?.code !== null && error?.error?.code !== undefined) {
        this.currentError = error.error;
      } else {
        // this.currentError = { message: 'There was an issue cancelling your subscription, please contact support.' };
      }
    } finally {
      this.loading = false;
    }
  }

  async refreshProductsAndSubs(clearErr = true): Promise<void> {
    this.loading = true;
    this.subscriptionIncomplete = false;
    if (clearErr) this.currentError = null;

    let tempSeats = 1;
    this.productGroups = [];

    if (this.selectedOrgCustomer?.id) {
      // let tenantDataSources; // TODO
      let tenantProducts;
      let tenantFeatures;

      // Await requests asynchronously for speed
      await Promise.all([
        // Tenant products and features
        // (async () => (tenantDataSources = await this.subscriptionService.getDataSources()))(), // TODO
        (async () => (tenantProducts = await this.subscriptionService.getExtendedProductGroups()))(),
        (async () => (tenantFeatures = await this.subscriptionService.getFeatures()))(),
        // Org's active subscriptions
        (async () =>
          (this.selectedOrganisationCustomerSubscriptions = (
            await this.subscriptionService.getActiveCustomerSubscriptions(
              this.selectedOrganisation.value.organisationId
            )
          ).data))(),
        // Available tenant Stripe products
        (async () =>
          (this.tenantStripeProducts = await this.subscriptionService.getAllStripeProducts(
            this.selectedOrganisation.value.organisationId
          )))(),
      ]);

      {
        // TODO: old code
        if (this.tenantStripeProducts.length) {
          this.dirtyCheckObj.originalValues.selectedProducts = [];
          this.dirtyCheckObj.isDirty = false;
        }

        if (this.selectedOrganisationCustomerSubscriptions.length) {
          // TODO: Not the best but this value should *never* be null
          if (this.selectedOrganisationCustomerSubscriptions[0].status === 'incomplete') {
            this.subscriptionIncomplete = true;
            await this.cancelSubscription(true, false);
            return;
          }

          tempSeats = this.selectedOrganisationCustomerSubscriptions[0].items.data[0].quantity;

          if (this.selectedOrganisationCustomerSubscriptions[0]?.status === 'trialing') {
            const currentDate = new Date();
            const trialEnd = new Date(this.selectedOrganisationCustomerSubscriptions[0].trial_end * 1000);
            this.trialDaysRemaining = Math.floor(
              (Date.UTC(trialEnd.getFullYear(), trialEnd.getMonth(), trialEnd.getDate()) -
                Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) /
                (1000 * 60 * 60 * 24)
            );
          }
        }
      }

      await Promise.all(
        tenantProducts.map((productGroup) =>
          (async () => {
            let active: ProductData;
            let products: ProductData[] = [];

            await Promise.all(
              productGroup.products.map((tier) =>
                (async () => {
                  const foundStripeProduct = this.tenantStripeProducts.find(
                    (stripeProduct) => stripeProduct.id === tier.stripeProductId
                  );

                  const price = foundStripeProduct.default_price?.tiers?.length
                    ? foundStripeProduct.default_price.tiers[0].unit_amount
                    : foundStripeProduct.default_price?.unit_amount;

                  const stripePriceId = foundStripeProduct.default_price.id;
                  const featureAccesses = new Set<Access>();

                  // And curate the information that the component needs
                  // TODO dataSources
                  const features = tenantFeatures.map((tenantFeature) => {
                    const tierFeature = tier.features.find(
                      (tierFeature) => tenantFeature._id === tierFeature.featureId
                    );

                    let access: Access;

                    if (!tierFeature?.enabled) {
                      access = 'none';
                    } else if (tierFeature.restrictions && Object.keys(tierFeature.restrictions).length) {
                      access = 'limited';
                    } else {
                      access = 'full';
                    }

                    featureAccesses.add(access);

                    return {
                      name: tenantFeature.name,
                      access,
                    };
                  });

                  let access: Access;

                  if (featureAccesses.size == 0) {
                    access = 'none';
                  } else if (featureAccesses.size == 1) {
                    access = [...featureAccesses][0];
                  } else {
                    access = 'limited';
                  }

                  const productData = {
                    name: tier.name,
                    stripeProductId: tier.stripeProductId,
                    isAutoTrial: tier.isAutoTrial,
                    price,
                    currency: foundStripeProduct.default_price?.currency?.toUpperCase(),
                    stripePriceId,
                    features,
                    access,
                  };

                  // TODO: find out if user has it in subscription
                  if (
                    this.selectedOrganisationCustomerSubscriptions.find((subscription) =>
                      subscription.items.data.find(
                        (subscriptionItem) => subscriptionItem.price.product === tier.stripeProductId
                      )
                    )
                  ) {
                    active = productData;
                  }

                  products.push(productData);
                })()
              )
            );

            this.productGroups.push({
              name: productGroup.name,
              icon: productGroup.icon,
              active,
              products,
            });
          })()
        )
      );

      {
        // TODO: old code
        this.seats.setValue(tempSeats);
        this.dirtyCheckObj.originalValues.seats = this.seats.value;
        // TODO; Shouldn't need this extra call, but the HTML calls the method BEFORE the seats value changes....
        this.reCalculatePrice();
        this.loading = false;
      }
    }
  }

  // TODO: Lags on high numbers, capped seat count for the time being
  reCalculatePrice(): void {
    let tempPrice = 0;
    this.selectedProducts = 0;
    this.discountPercentage = 0;
    this.tenantStripeProducts.forEach((product) => {
      if (product.suvoChecked) {
        if (product.default_price.tiers.length) {
          this.selectedProducts++;
          if (product.default_price.tiers_mode) {
            tempPrice += this.calcProductTiersPrice(product.default_price.tiers, this.seats.value);
          } else {
            tempPrice += product.default_price.unit_amount * this.seats.value;
          }
        } else {
          tempPrice += product.default_price.unit_amount * this.seats.value;
        }
      }
    });
    this.previewSubtotal = tempPrice;
    switch (this.selectedProducts) {
      case 2:
        this.discountPercentage = 5;
        break;
      case 3:
        this.discountPercentage = 7;
        break;
    }
  }

  calcProductTiersPrice(tiers, startingSeats): number {
    let seatsLeft = startingSeats;
    let sum = 0;
    let previousUpTo = 0;

    for (const tier of tiers) {
      if (tier.up_to == null) {
        // We're at the end, just add the remaining seats
        sum += tier.unit_amount * seatsLeft;
      } else if (tier.up_to < startingSeats) {
        // How many seats we're adding from this chunk
        const amount = tier.up_to - previousUpTo;

        // Update the sum by the correct amount
        sum += tier.unit_amount * amount;
        seatsLeft -= amount;
      } else {
        // If we go in here, we arent at the end (up_to == null) but we are out of seats
        sum += tier.unit_amount * seatsLeft;
        break;
      }
      // Use this to track how many seats are in a tier
      previousUpTo = tier.up_to;
    }
    return sum;
  }

  ngOnDestroy(): void {
    if (this.seatsValueSub) {
      this.seatsValueSub.unsubscribe();
    }
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  updateSuccessMessage(message: string): void {
    this.currentSuccessMessage = message;
    setTimeout(() => {
      this.currentSuccessMessage = '';
    }, 5000);
  }

  getPaidProduct(productGroup) {
    return productGroup.products.find((tier) => !tier.isAutoTrial);
  }
}
