import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SubscriptionService, OrganisationRoleService } from '../../../../../../../../../../SUVO-USERS-CLIENT-LIB/projects/suvo-users-client-lib/src/public-api';

@Component({
  selector: 'suvo-bi-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {

  @Input() selectedOrganisation;

  constructor(
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit(): void { }

  async goToCustomerPortal(): Promise<void> {
    try {
      const newPortalSession = await this.subscriptionService.createCustomerPortalSession(this.selectedOrganisation.organisationId, 'personal');
      if (newPortalSession) {
        window.location.href = newPortalSession.url;
      }
      console.log(newPortalSession);
    } catch (error) {
      console.log(error);
    }
  }

}
