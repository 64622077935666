import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared/shared.module';
import { VerifyModule } from '../../verify';
import { CreateUserComponent } from '../components/create-user/create-user.component';

@NgModule({
  imports: [CommonModule, SharedModule, VerifyModule],
  declarations: [CreateUserComponent],
  exports: [CreateUserComponent],
})
export class UsersModule {}
