import { Component, Input, OnInit } from '@angular/core';
import { DataSourceItemsService } from '../../../../../data-source/data-source-items/services/data-source-items.service';
import { FAKE_TOTALS } from '../../../../data/fake-totals';
import { IBannerTileDefinition } from '../../../../interfaces/tiles/definitions/banner-tile-definition.interface';
import { ITileComponent } from '../../tile-component.interface';
import { CurrentTenantService, TenantService } from '@suvo-bi-users';

@Component({
  selector: 'suvo-bi-banner-tile',
  templateUrl: './banner-tile.component.html',
  styleUrls: ['./banner-tile.component.scss'],
})
export class BannerTileComponent implements ITileComponent, OnInit {
  @Input() public tileDefinition: IBannerTileDefinition;

  public displayNumber: number;
  public displayNumberLoaded = false;

  constructor(private dataSourceItemsService: DataSourceItemsService, private tenantService: CurrentTenantService) {}

  async ngOnInit(): Promise<void> {
    if (this.tileDefinition.displayFeatureTotal) {
      // TODO: Workaround until we can count Tenders properly
      if (this.tileDefinition.displayFeatureTotal === 'tenders') {
        this.displayNumber = FAKE_TOTALS[this.tileDefinition.displayFeatureTotal];
      } else {
        this.displayNumber = await this.dataSourceItemsService.getCount(this.tileDefinition.displayFeatureTotal);
      }
    }
  }

  ngAfterViewInit(): void {
    this.displayNumberLoaded = true;
  }

  public get contentImgPath(): string {
    return `${this.tenantService.currentTenantData.primaryWebUrl}/${this.tileDefinition.imgUri}`;
  }
}
