<div
  fxFlex="1 1 100%"
  class="card margin top-margin outer-container"
  fxLayout="column"
  fxLayoutAlign="flex-start stretch"
>
  <ng-container *ngIf="!loading; else loadingSpinner">
    <ng-container *ngIf="emailsAllowed; else emailsDisallowed">
      <h4>Set up email alerts</h4>
      <div class="intro-container">
        <div class="content-container">
          <p>
            Email alerts allow you to be kept up to date as new data is added or updated that meets your criteria. A
            daily email will be sent to your inbox with the data included in a concise, easy-to-read format.
          </p>
        </div>
      </div>
      <div>
        <div class="content-container" fxLayout="row" fxLayoutAlign="flex-start center">
          <div class="toggle-label">Enable daily email alerts for {{ alias }}?</div>
          <mat-button-toggle-group
            [ngModel]="alertsEnabled"
            (ngModelChange)="onAlertsEnabledChange($event)"
            class="toggle-group"
          >
            <mat-button-toggle [value]="false" color="primary">Disable</mat-button-toggle>
            <mat-button-toggle [value]="true" color="primary">Enable</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="filters-container" fxLayout="column" *ngIf="alertsEnabled">
        <suvo-bi-filters-content
          [showNoneOption]="false"
          [selectOpen]="false"
          fxFlex="1 1"
          [insertStyles]="{
            position: 'relative'
          }"
          [showExportButton]="false"
          [autoSave]="false"
          [filtersChannel]="filtersChannel"
          [showFilterHeader]="false"
          editFiltersHeading="Choose the filters to use for your email alerts:"
        ></suvo-bi-filters-content>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loadingSpinner>
    <div fxFlex="1 1" fxLayoutAlign="center center" class="loading-container">
      <suvo-core-loading-spinner></suvo-core-loading-spinner>
    </div>
  </ng-template>
  <ng-template #emailsDisallowed>
    <div fxFlex="1 1" fxLayoutAlign="space-between center" class="card not-allowed-message">
      <div>
        <b>Email alerts are available for paid subscribers to {{ alias }}.</b>
      </div>
      <button color="primary" mat-stroked-button routerLink="/private/my-account/subscription">
        View upgrade options
      </button>
    </div>
  </ng-template>
</div>
