export interface IFAQ {
    title?: string;
    question:string;
    answer: string;
}

export interface IFAQCategory {
    name: string;
    items: Array<IFAQ>;
}

export interface IFAQLibrary {
    categories: Array<IFAQCategory>;
}
