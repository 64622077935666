import { Injectable } from '@angular/core';
import { IBiLink } from '@suvo-bi-lib';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NewDataSourceDefinitionsService } from 'submodules/SUVO-FRONTEND-FRAMEWORK/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/data-source/data-source-definitions/services/new-data-source-definitions.service';
import { DATA_NAV_LINKS } from '../data/data-nav-links';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private readonly dataSourceDefinitionService: NewDataSourceDefinitionsService) {}

  createDataLinksObservable(unsubscribeSubject: Subject<boolean>): Observable<IBiLink[]> {
    this.dataSourceDefinitionService.getAllLocal();

    return this.dataSourceDefinitionService.definitions$.pipe(
      map((definitions) => {
        const links = [...DATA_NAV_LINKS];
        const index = links.findIndex((link) => link.displayName === 'All Items');
        const itemsLink = links[index];

        definitions
          .filter((d) => d.type !== 'tenders-iinfo')
          .sort((a, b) => (a.displayOptions?.order || 128) - (b.displayOptions?.order || 128))
          .forEach((definition) => {
            const link: IBiLink = {
              displayName: definition.name
            };
            if (definition.primaryOptions?.isPrimary) {
              link.displayIcon = definition.primaryOptions.iconName;
              link.route = ['items', definition.alias];
              links.splice(index, 0, link);
            } else {
              link.route = ['items', 'all', definition.alias];
              itemsLink.subLinks.push(link);
            }
          });

        console.log({ links });
        return links;
      }),
      takeUntil(unsubscribeSubject)
    );
  }

  // async loadMenuItems(): Promise<void> {

  //   const sidenavLinks: IBiLink[] = [...NAVIGATION_LINKS];

  //   const definitions = await this.dataSourceDefinitionService.getPaginated(null);
  //   localStorage.setItem('menu', JSON.stringify(itemsDatasource));

  //   const items = itemsDatasource.data as { name: string, alias: string }[];

  //   const itemsSidenavLink = sidenavLinks.find(link => link.displayName.indexOf('Items') !== -1)
  //   itemsSidenavLink.subLinks = items
  //     .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  //     .map(item => { return { displayName: item.name, route: ['items', item.alias] } })

  //   this.sidenavLinks = sidenavLinks;
  //   this.sidenavLinksSubject.next(this.sidenavLinks);

  //   console.log({ sidenavLinks });

  // }
}
