<div class="container padding" fxLayout="row" [ngClass]="{'focused': isFocused }">

  <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="flex-start center">
    <p class="entry">{{ name }}</p>
  </div>

  <!-- Invite Buttons -->
  <div class="entry-buttons-container" fxLayout="row" fxLayoutAlign="flex-end center">
    <button mat-icon-button class="edit-entry" (click)="action(true)">
      <mat-icon>check</mat-icon>
    </button>
    <button mat-icon-button class="edit-entry" (click)="action(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

</div>