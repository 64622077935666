<!-- <suvo-bi-channel-debug-label [filtersChannel]="filtersChannel"></suvo-bi-channel-debug-label> -->

<!-- <div class="debug-info-box">
  <p>{{ filters | json }}</p>
</div> -->

<!-- <p>{{ debugNumber }} , {{ filtersChannel?.channelName }} , {{ filtersChannel?.selectedFilters?.name }} , {{ selectedFilters?.name }}</p> -->

<div
  *ngIf="filters"
  class="container"
  [ngClass]="{ 'controls-disabled': controlsDisabled }"
  fxFlex="1 1"
  fxLayout="column"
  fxLayoutAlign="flex-start stretch"
>
  <div
    fxFlex="0 1"
    class="edit-section"
    fxLayout="column"
    fxLayoutAlign="flex-start stretch"
  >
    <!-- Sections -->
    <div fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch stretch">
      <ng-container
        *ngFor="let section of filtersSections.sections; let lastSection = last"
      >
        <div
          fxFlex="1 1"
          [fxLayout]="!verticalOnly && section.asRow ? 'row' : 'column'"
          fxLayoutAlign="stretch stretch"
        >
          <ng-container
            *ngFor="let option of section.columns; let lastOption = last"
          >
            <suvo-bi-section-wrapper
              class="section-wrapper"
              fxFlex="1 1"
              [option]="option"
              (rulesChanged)="rulesChanged()"
            >
            </suvo-bi-section-wrapper>
            <!-- <mat-divider [vertical]="true" *ngIf="!lastOption"></mat-divider> -->
          </ng-container>
        </div>
        <!-- <mat-divider *ngIf="!lastSection"></mat-divider> -->
      </ng-container>
    </div>
  </div>
</div>
