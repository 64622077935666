import { Component, Input } from '@angular/core';
import { IContactsTileDefinition } from '../../../../interfaces/tiles/definitions/contacts-tile-definition.interface';

@Component({
  selector: 'suvo-bi-contacts-tile',
  templateUrl: './contacts-tile.component.html',
  styleUrls: ['./contacts-tile.component.scss'],
})
export class ContactsTileComponent {
  @Input() public tileDefinition: IContactsTileDefinition;
}
