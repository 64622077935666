<div
  matTooltip="Upgrade to export data"
  [matTooltipDisabled]="!disabled"
  matTooltipPosition="above"
  matTooltipClass="tooltip"
>
  <button
    mat-stroked-button
    class="export-button"
    (click)="export()"
    [disabled]="disabled || isExporting"
    color="primary"
  >
    <mat-icon>download</mat-icon>
    Export
    <mat-progress-bar class="progress-bar" mode="indeterminate" *ngIf="isExporting"></mat-progress-bar>
  </button>
</div>
