import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DataSourceDefinitionsModule } from './data-source-definitions/modules/data-source-definitions.module';
import { DataSourceFavouritesModule } from './data-source-favourites/data-source-favourites.module';
import { DataSourceItemViewsModule } from './data-source-item-views/data-source-item-views.module';
import { DataSourceItemsModule } from './data-source-items/modules/data-source-items.module';
import { IDataSourceSettings } from './interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from './interfaces/data-source-settings.token';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DataSourceItemsModule,
    DataSourceDefinitionsModule,
    DataSourceFavouritesModule,
    DataSourceItemViewsModule,
  ],
  exports: [
  ],
  declarations: [
  ]
})
export class DataSourceModule {
  static forRoot(config: IDataSourceSettings): ModuleWithProviders<DataSourceModule> {
    return {
      ngModule: DataSourceModule,
      providers: [
        {
          provide: DataSourceSettingsRef,
          useValue: config,
        }
      ]
    };
  }
}
