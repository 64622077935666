import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class ToggleGroup {
  checked: boolean;
  indeterminate: boolean;

  name: string;
  value: any;
  valueIsList: boolean;
  formGroup: FormGroup;
  children: any[];
  isSecondary = false;
  alsoFilterByChildren = false;

  constructor(
    name: string,
    value: any,
    valueIsList: boolean = false,
    formGroup: FormGroup,
    children: any[],
    isSecondary = false,
    alsoFilterByChildren = false,
    unsubscribe$: Subject<boolean>
  ) {
    this.name = name;
    this.value = value;
    this.valueIsList = valueIsList;
    this.formGroup = formGroup;
    this.children = children;

    this.recalculateValues();
    this.formGroup.valueChanges
      .pipe(takeUntil(unsubscribe$))
      .subscribe(() => this.recalculateValues());

    this.isSecondary = isSecondary;
    this.alsoFilterByChildren = alsoFilterByChildren;
  }

  recalculateValues() {
    if (!this.children || this.children.length == 0) return;

    let allChecked = true;
    let noneChecked = true;

    // At least one checked but not all, indeterminate.
    for (let child of this.children) {
      if (this.formGroup.value[child.name]) {
        noneChecked = false;
        if (!allChecked) {
          break;
        }
      } else {
        allChecked = false;
        if (!noneChecked) {
          break;
        }
      }
    }

    this.indeterminate = !noneChecked && !allChecked;
    this.checked = allChecked;
  }

  setChecked(checked: boolean, options?: Object) {
    this.children.forEach((child) => {
      this.formGroup.controls[child.name].setValue(checked, options);
    });
    this.checked = checked;
  }

  getControl(name: string): FormControl {
    const control = this.formGroup.controls[name];
    if (!control) {
      console.log(name, 'no control', this.name);
    }
    return control as FormControl;
  }
}
