import { IColumnDefinition } from "../interfaces/column-definition.interface";
import { TileDefinition } from "./tiles/tile-definition.model";

export class ColumnDefinition implements IColumnDefinition {
    tileDefinition: TileDefinition;

    constructor(column: IColumnDefinition) {
        this.tileDefinition = column.tileDefinition; // TODO: Need to use factory?
    }

    toJSObj() {
        return JSON.parse(JSON.stringify(this));
    }
}
