import { IBiLink } from '@suvo-bi-lib';

export const EDITING_NAV_LINKS: IBiLink[] = [
  {
    route: 'dashboards',
    displayName: 'Dashboards',
    displayIcon: 'construction'
  },
  {
    route: 'routing',
    displayName: 'Routing',
    displayIcon: 'account_tree'
  },
  {
    route: 'theme',
    displayName: 'Theme',
    displayIcon: 'color_lens'
  },
  // {
  //   route: 'external-data-sources',
  //   displayName: 'External Sources',
  //   displayIcon: 'cable'
  // }
];
