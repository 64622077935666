import { CommonModel } from '@suvo-bi-core';
import { ITenant } from '../interfaces/tenant.interface';

export class TenantModel extends CommonModel<TenantModel> implements ITenant {
  createdAt: string;
  firebaseTenantId: string;
  primaryWebUrl: string;
  tenantEmail: string;
  tenantName: string;
  updatedAt: string;
  webUrls: string[];
  trackingId?: string;
  theme?: {
    headingFont: string;
    standardFont: string;
    primaryColor: string;
    primaryContrast: 'light' | 'dark';
    secondaryColor: string;
    secondaryContrast: 'light' | 'dark';
    tertiaryColor: string;
    tertiaryContrast: 'light' | 'dark';
  };
  constructor() {
    super(TenantModel);
  }
}
