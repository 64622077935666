import { IFiltersSections } from "../../interfaces/sections/filters-sections.interface";
import { FiltersSection } from "./filters-section.model";

export class FiltersSections implements IFiltersSections {
  name: string;
  shortCode: string;
  sections: FiltersSection[];

  constructor(init: IFiltersSections) {
    this.sections = init.sections.map(section => new FiltersSection(section));
    this.name = init.name;
    this.shortCode = init.shortCode;
  }
}