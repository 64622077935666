import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ITextColumn } from '../../../dashboards/interfaces/tiles/definition-helpers/text-column.interface';
import { ITextColumnsTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/text-columns-tile-definition.interface';
import { VERTICAL_POSITION_OPTIONS } from '../../constants/vertical-position-options.constant';
import { ISelectOption } from '../../interfaces/select-option.interface';
import { TileCreatorHelpersService } from '../../services/tile-creator-helpers.service';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'lib-text-columns-setup-wizard',
  templateUrl: './text-columns-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './text-columns-setup-wizard.component.scss'],
})
export class TextColumnsSetupWizardComponent implements OnInit, OnDestroy {
  tileDefinition: ITextColumnsTileDefinition;

  public formGroup;

  public verticalPositionsOptions: ISelectOption[];
  public fontSizesOptions: string[];

  textColumns;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorHelpersService: TileCreatorHelpersService,
    private readonly tileCreatorService: TileCreatorService,
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSelectOptions();
    this.initSetupForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initSelectOptions(): void {
    this.verticalPositionsOptions = VERTICAL_POSITION_OPTIONS;
    this.fontSizesOptions = this.tileCreatorHelpersService.generateFontSize();
  }

  public initSetupForms(): void {
    this.formGroup = this.fb.group({
      isTileDividerEnabled: [false],
      tileDividerColor: ['#000000'],
      tileDividerPosition: ['bottom'],
      textColumns: this.fb.array([]),
      background: [''],
    });

    this.textColumns = this.formGroup.controls.textColumns as FormArray;

    if (this.tileDefinition) {
      this.formGroup.patchValue(this.tileDefinition);

      this.tileDefinition.textColumns?.forEach((textColumn: ITextColumn) => {
        this.addTextColumn(textColumn);
      });
    } else {
      this.addTextColumn();
    }
  }

  public addTextColumn(textColumn?: ITextColumn): void {
    const textColumnFormGroup = this.fb.group({
      heading: [''],
      headingFontSize: ['6px'],
      headingFontColor: ['#000000'],
      text: [''],
      textFontSize: ['6px'],
      textFontColor: ['#000000'],
      isDividerEnabled: [false],
      dividerColor: ['#000000'],
      dividerPosition: ['top'],
    });

    textColumnFormGroup.patchValue(textColumn);

    (this.formGroup.controls.textColumns as FormArray).push(textColumnFormGroup);
  }

  public removeTextColumn(textColumnIndex: number) {
    (this.formGroup.controls.textColumns as FormArray).removeAt(textColumnIndex);
  }

  public onCreate() {
    Object.assign(this.tileDefinition, {
      tileType: 'text-columns',
      canView: 'true',
      ...this.formGroup.value,
    });
    this.tileCreatorService.onChange.next();
  }
}
