import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { ITableSortDirection } from '../../../../../shared/interfaces/table/table-sort.interface';
import { ReferenceLookupService } from '../../../../references/services/reference-lookup-service.service';
import { FilterOptionComponent } from '../filter-option/filter-option.component';
import { IEntity } from './entity.interface';

@Component({
  selector: 'suvo-bi-reference-autocomplete-option',
  templateUrl: './reference-autocomplete-option.component.html',
  styleUrls: ['./reference-autocomplete-option.component.scss'],
})
export class ReferenceAutocompleteOptionComponent extends FilterOptionComponent {
  separatorKeyCodes: number[] = [ENTER, COMMA];

  @ViewChild('entityInput') entityInput: ElementRef<HTMLInputElement>;
  @ViewChild('entityAutocomplete') entityAutocomplet: MatAutocomplete;
  entityInputControl: FormControl;

  chippableOptions: { key: string; primary: string; accent: string }[];

  entityOptions: IEntity[] = [];
  entityOptionsUnloaded = true;
  entityOptionsReceived = new Subject<IEntity[]>();

  selectedEntities: IEntity[] = [];
  unselectedEntities: IEntity[] = [];

  filteredEntitiesSubject = new Subject<IEntity[]>();

  entityDefinition;
  subjectDefinition;

  loadingEntities = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLookupService: ReferenceLookupService,
  ) {
    super();
  }

  async ngOnInit() {
    this.entityInputControl = new FormControl();

    this.entityInputControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((text) => this.filterEntityOptions(text));

    this.entityDefinition = await this.referenceLookupService.getDefinition(
      this.option.filterMethodConfig.entityDefinitionAlias,
    );

    console.log('test');
    console.log(this.entityDefinition);

    this.subjectDefinition = await this.referenceLookupService.getDefinition(
      this.option.definitionAlias,
    );

    this.generateChippableOptions();

    super.ngOnInit();

    this.changeDetectorRef.detectChanges();
  }

  generateChippableOptions() {
    const propertyName = this.option.filterPropertyKeys[0];
    if (!propertyName) {
      return;
    }
    const propertyDefinition = this.subjectDefinition?.properties?.find(
      (p) => p.name === propertyName,
    );
    if (!propertyDefinition) {
      return;
    }

    const chippableOptions = propertyDefinition.displayOptions?.enumColourSet;
    this.chippableOptions = chippableOptions;

    this.changeDetectorRef.markForCheck();
  }

  filterEntityOptions(text: string | null): void {
    this.fetchOptions(text);
  }

  async fetchEntityOptions(search: string): Promise<void> {
    let prefix = this.option.filterMethodConfig?.metaDataPrefix
      ? this.option.filterMethodConfig?.metaDataPrefix + '.'
      : null;

    let options: any = {
      pagination: {
        pageIndex: 0,
        pageSize: 40,
      },
      search,
    };

    const defaultSort = this.entityDefinition?.tableOptions?.defaultSortOption;
    if (defaultSort) {
      options.sort = {
        active: prefix + defaultSort.property,
        direction:
          defaultSort.direction == 'ASCENDING'
            ? ITableSortDirection.Ascending
            : ITableSortDirection.Descending,
      };
    }

    this.loadingEntities = true;

    let entities = await this.referenceLookupService.getPaginated(
      this.option.filterMethodConfig.entityDefinitionAlias,
      options,
      this.entityDefinition?.alias,
    );

    this.loadingEntities = false;

    this.entityOptions = [];

    entities?.data.forEach((entity: any) => {
      this.entityOptions.push(entity as IEntity);
    });

    this.unselectedEntities = [...this.entityOptions];
    this.entityOptionsReceived.next(this.entityOptions);
    this.filteredEntitiesSubject.next(this.entityOptions);
  }

  /**
   *  Option Property
   */

  emitPropertyChanges() {
    this.option.filterRules = this.getFilterRules();
    this.rulesChanged.emit();
  }

  getFilterRules(): FilterRule[] {
    if (this.selectedEntities.length) {
      let key = this.option.filterPropertyKeys[0];

      const inValues = this.selectedEntities.map((entity) => entity._id);

      return [
        {
          key,
          in: inValues.join(','),
          type: this.option.filterValueType,
        },
      ];
    } else {
      return [];
    }
  }

  checkRulesMatch(rules): boolean {
    let entitiesChanged = false;

    if (rules?.length) {
      const property = rules[0] as IFilterProperty;
      property.in?.split(',').forEach((value) => {
        const existingEntity = this.selectedEntities.find((entity) => entity._id === value);

        if (!existingEntity) {
          entitiesChanged = true;
        }
      });

      this.selectedEntities.forEach((entity) => {
        const existingEntity = property.in?.split(',').find((value) => entity._id === value);

        if (!existingEntity) {
          entitiesChanged = true;
        }
      });
    } else if (this.selectedEntities.length) {
      //NOTE: Must have changed since the rules length == 0, yet we have selected entities
      entitiesChanged = true;
    }

    return !entitiesChanged;
  }

  async patchRules(rules: FilterRule[]): Promise<void> {
    let rulesMatch = this.checkRulesMatch(rules);

    if (rulesMatch) {
      return;
    }

    // const propertyName = this.option.filterPropertyKeys[0];
    // console.log({ propertyName, rules })
    this.selectedEntities.forEach((entity) => this.removeEntity(entity, true));

    if (rules?.length) {
      if (!this.entityOptions.length) {
        await this.fetchOptions(null);
      }

      const property = rules[0] as IFilterProperty;

      let propertyInList = property.in?.split(',');

      for (let value of propertyInList) {
        let entity = this.entityOptions.find((entity) => {
          return entity._id === value;
        });

        if (!entity) {
          entity = await this.referenceLookupService.getOne(
            this.option.filterMethodConfig.entityDefinitionAlias,
            value,
          );
        }

        if (entity) {
          this.addEntity(entity, true);
        }
      }
    }

    this.emitPropertyChanges();
  }

  /**
   * Input focussed
   */

  async fetchOptions(search: string): Promise<void> {
    this.entityOptionsUnloaded = false;
    await this.fetchEntityOptions(search);
  }

  async onInputFocus(): Promise<void> {
    this.fetchOptions(null);
  }

  /**
   * Entity Adding and Removing
   */

  assignEntityChipColor(entity: IEntity): void {
    if (entity.chipColor) {
      return;
    }

    const chipColors = this.chippableOptions?.find((colors) => colors.key === entity.data.name);

    entity.chipColor = chipColors?.primary || '#1a78cf';
    entity.chipAccent = chipColors?.accent || '#fff';
  }

  addEntity(entity: IEntity, dontEmitChanges?: boolean): void {
    const index = this.unselectedEntities.indexOf(entity);

    if (index >= 0) {
      this.unselectedEntities.splice(index, 1);
    }

    this.assignEntityChipColor(entity);
    this.selectedEntities.push(entity);

    this.filteredEntitiesSubject.next(this.unselectedEntities);

    if (!dontEmitChanges) {
      this.emitPropertyChanges();
    }
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    this.entityInput.nativeElement.value = '';
    this.entityInputControl.setValue(null);

    const entity: IEntity = event.option.value;
    this.addEntity(entity);

    this.entityInput.nativeElement.focus();
  }

  removeEntity(entity: IEntity, dontEmitChanges: boolean = false): void {
    const index = this.selectedEntities.indexOf(entity);
    if ((index) => 0) {
      this.unselectedEntities.push(entity);
      this.selectedEntities.splice(index, 1);

      this.filteredEntitiesSubject.next(this.unselectedEntities);

      if (!dontEmitChanges) {
        this.emitPropertyChanges();
      }
    }
  }

  getEntityLabel(entity: IEntity) {
    console.log(entity);
    console.log(this.entityDefinition.referenceLabelPropertyKey);
    console.log(entity[this.entityDefinition.referenceLabelPropertyKey]);
    if (this.entityDefinition.referenceLabelPropertyKey) {
      if (this.entityDefinition.referenceLabelPropertyKey.split('.').length == 2) {
        let keyParts = this.entityDefinition.referenceLabelPropertyKey.split('.');

        return entity[keyParts[0]][keyParts[1]];
      } else {
        return entity[this.entityDefinition.referenceLabelPropertyKey];
      }
    } else {
      return entity?.data?.name;
    }
  }
}
