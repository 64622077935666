<!-- Metadata toolbar -->
<div class="metadata-toolbar" fxLayout="row" fxLayoutGap="12px">
  <p class="edit-date-label" matTooltip="Object _Id">
    <mat-icon>fingerprint</mat-icon> {{ mongoObject._id }}
  </p>
  <p class="edit-date-label" [matTooltip]="mongoObject.createdAt | date:'long'">
    <mat-icon>add</mat-icon> {{ mongoObject.createdAt | date }}
  </p>
  <p class="edit-date-label" [matTooltip]="mongoObject.updatedAt | date:'long'">
    <mat-icon>update</mat-icon> {{ mongoObject.updatedAt | date }}
  </p>
</div>