<div fxLayout="column" fxLayoutAlign="flex-start stretch" class="option-container">
  <span class="header">{{ title }}</span>

  <form *ngIf="formGroup" [formGroup]="formGroup">
    <div *ngIf="toggleGroups" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <ng-container *ngFor="let toggleGroup of toggleGroups">
        <p class="option" [ngClass]="{ 'option-columns-3': optionsColumns == 3 }" *ngIf="!toggleGroup.isSecondary">
          <mat-checkbox
            [checked]="toggleGroup.checked"
            [indeterminate]="toggleGroup.indeterminate"
            (change)="onCheckboxChange(toggleGroup, $event.checked)"
            color="primary"
          >
            {{ continentNameSwaps[toggleGroup.name] || toggleGroup.name }}
          </mat-checkbox>
        </p>
      </ng-container>
    </div>
  </form>

  <button
    *ngIf="option.filterMethodConfig?.enableShowChildren"
    mat-stroked-button
    (click)="openChildrenDialog()"
    class="showAllButton"
  >
    <!-- <mat-icon>travel_explore</mat-icon> -->
    <div class="show-children-label">
      <span>{{ showChildrenLabel }}</span>
      <div class="number-badge" *ngIf="childFilterCount > 0">
        <div class="number-badge-inner">
          <span>{{ childFilterCount }}</span>
        </div>
      </div>
    </div>
  </button>
</div>
