import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {IBiLink} from '../../../../../public-api';
import {MANAGE_IDENTITY_DATA_SOURCE_ROUTES} from '../../data/data-source-routes.constant';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'suvo-bi-data-source-route-select',
  templateUrl: './data-source-route-select.component.html',
  styleUrls: ['./data-source-route-select.component.scss']
})
export class DataSourceRouteSelectComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();

  @Input() allowedAliases: Array<string>;

  selectLinks = MANAGE_IDENTITY_DATA_SOURCE_ROUTES;
  selectedLink: IBiLink;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.allowedAliases) {
      this.selectLinks = this.selectLinks.filter((selectLink) => {
        return this.allowedAliases.find((allowedAlias) => {
          return allowedAlias == selectLink.route;
        });
      });
    }

    this.activatedRoute.url.pipe(takeUntil(this.unsubscribe$)).subscribe(url => {
      const route = url[url.length - 1].toString();
      this.selectedLink = this.selectLinks.find(l => l.route === route);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
