<mat-stepper linear #stepper>
  <mat-step [stepControl]="firstStepSetupForm" [formGroup]="firstStepSetupForm" [editable]="true">
    <ng-template matStepLabel>Content</ng-template>

    <div class="inputs-container">
      <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
        <mat-error *ngIf="titleControl.hasError('required')">Title is required</mat-error>
      </mat-form-field>

      <app-data-source-selector
        formControlName="dataSource"
        (onDataSourceChange)="initDataSource($event)"
      ></app-data-source-selector>
    </div>

    <div>
      <button mat-button matStepperNext (click)="firstStepSetupForm.markAllAsTouched()">Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirm</ng-template>
    <p>Title: {{ titleControl.value }}</p>
    <p>Data Source: {{ dataSourceObject?.name }}</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="onCreate()">Apply</button>
    </div>
  </mat-step>
</mat-stepper>
