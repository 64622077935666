<div class="faq-container">
<mat-accordion multi *ngIf="faqLibrary?.categories">
    <div *ngFor="let category of faqLibrary.categories" class="faq-category">
        <h4>{{category.name}}</h4>
        <mat-expansion-panel *ngFor="let item of category.items">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{item.question}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="item.answer">
            </div>
        </mat-expansion-panel>
    </div>
</mat-accordion>
</div>