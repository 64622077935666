<mat-stepper linear #stepper>
  <mat-step [stepControl]="firstStepSetupForm" [formGroup]="firstStepSetupForm" [editable]="true">
    <ng-template matStepLabel>Content</ng-template>

    <div class="inputs-container">
      <mat-form-field appearance="fill">
        <mat-label>Heading</mat-label>
        <input matInput formControlName="heading" />
        <mat-error *ngIf="headingControl.hasError('required')">Heading is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Sub Heading</mat-label>
        <input matInput formControlName="subHeading" />
        <mat-error *ngIf="subHeadingControl.hasError('required')">Sub Heading is required</mat-error>
      </mat-form-field>

      <app-data-source-selector
        formControlName="displayFeatureTotal"
        (onDataSourceChange)="initDataSource($event)"
      ></app-data-source-selector>

      <mat-form-field appearance="fill">
        <mat-label>Heading Icon</mat-label>
        <input matInput formControlName="headingIcon" />
        <mat-error *ngIf="headingIconControl.hasError('required')">Heading Icon is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Image URI</mat-label>
        <input matInput formControlName="imgUri" />
        <mat-error *ngIf="imgUriControl.hasError('required')">Image URI is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Link Text</mat-label>
        <input matInput formControlName="linkText" />
        <mat-error *ngIf="linkTextControl.hasError('required')">Link Text is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Route Link</mat-label>
        <input matInput formControlName="routeLink" />
        <mat-error *ngIf="routeLinkControl.hasError('required')">Route Link is required</mat-error>
      </mat-form-field>
    </div>

    <div>
      <button mat-button matStepperNext (click)="firstStepSetupForm.markAllAsTouched()">Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirm</ng-template>
    <p>Title: {{ headingControl.value }}</p>
    <p>Sub Heading: {{ subHeadingControl.value }}</p>
    <p>Data Source: {{ dataSourceObject?.name }}</p>
    <p>Heading Icon: {{ headingIconControl.value }}</p>
    <p>Image URI: {{ imgUriControl.value }}</p>
    <p>Link Text: {{ linkTextControl.value }}</p>
    <p>Route Link: {{ routeLinkControl.value }}</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="onCreate()">Apply</button>
    </div>
  </mat-step>
</mat-stepper>
