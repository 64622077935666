<h4 mat-dialog-title>
  Export
  <b>{{ config.dataSourceAlias | titlecase }}</b>
</h4>

<div mat-dialog-content>
  <mat-stepper #stepper>
    <mat-step [editable]="false" label="Options" [stepControl]="exportOptionsForm">
      <form [formGroup]="exportOptionsForm" fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <mat-form-field class="margin-top" appearance="outline" aria-label="File Name">
          <mat-label>File Name</mat-label>
          <input matInput formControlName="fileName" />
          <span matSuffix>{{ fileFormat }}</span>
        </mat-form-field>

        <mat-checkbox class="margin-bottom" *ngIf="activeFiltersName" formControlName="applyFilters" color="primary">
          Apply '
          <b>{{ activeFiltersName }}</b>
          ' Custom Filters
        </mat-checkbox>

        <div class="margin-top">
          <button mat-flat-button color="accent" class="export-button" matStepperNext>
            <mat-icon>download</mat-icon>
            Export
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Export"></mat-step>
  </mat-stepper>

  <div fxLayout="column"></div>
</div>
