import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ITour } from '../../tour/interfaces/tour.interface';
import { TourService } from '../../tour/services/tour.service';
import { CurrentTenantService, TenantService } from '@suvo-bi-users';

@Injectable({
  providedIn: 'root',
})
export class FilterDemoTourService {
  TOUR_STORAGE_KEY = 'has-completed-filter-tour';

  FILTERS_TOUR: ITour = {
    tourId: 'filters-demo-tour',
    steps: [
      {
        title: 'Data Views',
        content: 'Here you can view table, chart and map visualisations of the data.',
      },
      {
        title: 'Filters',
        content:
          'On the right of the screen are the filter controls, these allow you to filter the data according to the criteria you are interested in.',
        selector: '.active-filters-widget',
      },
    ],
  };

  emailAlertsStep = {
    title: 'Email Alerts',
    content: 'Click here to set up your email alerts to be kept informed of new and updated data.',
    selector: '.email-alerts-setup-button-container',
  };

  constructor(
    private readonly tourService: TourService,
    private readonly router: Router,
    private currentTenantService: CurrentTenantService,
  ) {
    if (this.currentTenantService?.currentTenantData.redirectToEmailAlerts) {
      this.FILTERS_TOUR.steps.push(this.emailAlertsStep);
    }
  }

  public async startFiltersDemoTour() {
    const completedFiltersTour = localStorage.getItem(this.TOUR_STORAGE_KEY);

    //TODO : Restore at some point
    // if (!completedFiltersTour) {

    //   localStorage.setItem(this.TOUR_STORAGE_KEY, 'true');
    //   this.tourService.startTour(this.FILTERS_TOUR);
    // }
  }

  public async forceTour() {
    const completedFiltersTour = localStorage.getItem(this.TOUR_STORAGE_KEY);

    this.router.navigateByUrl('/private/projects/all/lists');

    if (completedFiltersTour) {
      /**
       * If the tour won't start, we'll start it here.
       */
      this.tourService.startTour(this.FILTERS_TOUR);
    }
  }
}
