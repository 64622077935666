import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProductService, SubscriptionService, UsersService } from '@suvo-bi-users';
import { IItemsTableOptions } from '../../../../../data-source/data-source-items/interfaces/items-table-options.interface';
import { FiltersChannel } from '../../../../../filters/models/filters-channel.model';
import { ITableTileDefinition } from '../../../../interfaces/tiles/definitions/table-tile-definition.interface';

@Component({
  selector: 'suvo-bi-table-tile',
  templateUrl: './table-tile.component.html',
  styleUrls: ['./table-tile.component.scss'],
})
export class TableTileComponent implements OnInit {
  @Input() public tileDefinition: ITableTileDefinition;
  @Input() public filtersChannel: FiltersChannel;
  @Input() public editMode: boolean = false;

  showExportButton = true;
  exportDisabled = false;

  public dataSourceAlias: string;
  public options: IItemsTableOptions;

  public tableAlias = '';

  allowed = false;

  isTrialing = false;
  hasRestrictions = false;

  currentTileDefinitionString;

  private $unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly productService: ProductService,
    private readonly subscriptionService: SubscriptionService,
    private readonly usersService: UsersService,
  ) {}

  public ngOnInit(): void {}

  public ngOnChanges(changes) {
    console.log('table tile ngonchanges');
    if (changes.tileDefinition) {
      let newDefinitionString = JSON.stringify(changes.tileDefinition);
      if (this.currentTileDefinitionString != newDefinitionString) {
        this.initialise();

        this.currentTileDefinitionString = newDefinitionString;
      }
    }
  }

  public initialise() {
    let userId = this.usersService?.userSubject?.value?._id;

    //TODO : Potentially add tile specific alias
    this.tableAlias = `${userId}_${this.router.url.toString()}_table-tile`;

    this.dataSourceAlias = this.tileDefinition.recipe?.dataSource;

    const itemRoutePrefixes = /* this.tileDefinition.itemRoutePrefixes || */ [
      '/',
      'private',
      'item',
      this.tileDefinition.recipe?.dataSource,
    ];
    const defaultOptions: IItemsTableOptions = {
      columns: this.tileDefinition.showColumns?.map((name: string) => {
        return { key: name };
      }),
      defaultPageSize: this.tileDefinition.defaultPageSize,
      showPageControls: this.tileDefinition.showPageControls,
      showPageSizeOptions: this.tileDefinition.showPageSizeOptions,
      itemRoutePrefixes,
      canSort: true,
    };

    this.options = {};

    Object.assign(this.options, defaultOptions);
    Object.assign(this.options, this.tileDefinition.tableOptions);

    if (this.editMode) {
      this.allowed = true;
    } else if (this.tileDefinition?.recipe?.dataSource) {
      this.allowed = this.productService.isFeatureEnabled('FINDANDPAGINATE', [
        'DATASOURCE:' + this.tileDefinition?.recipe?.dataSource,
      ]);
    }

    this.getSubscriptionStatus();

    this.exportDisabled =
      this.editMode ||
      !this.productService.isFeatureEnabled('EXPORT', [
        'DATASOURCE:' + this.filtersChannel?.channelName,
      ]);

    if (this.editMode) {
      this.showExportButton = false;
    }
  }

  private getSubscriptionStatus(): void {
    this.subscriptionService.currentSubscription
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((currentSubscription) => {
        if (currentSubscription && currentSubscription?.status === 'trialing') {
          this.isTrialing = true;
        }

        if (this.editMode) {
          this.hasRestrictions = false;
        } else {
          this.hasRestrictions = this.productService.hasRestrictions('FINDANDPAGINATE', [
            'DATASOURCE:' + this.tileDefinition.recipe?.dataSource,
          ]);
        }
      });
  }
}
