import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss',
})
export class TextEditorComponent {
  @Input() label;
  @Input() formGroup;
  @Input() textFormControlName;
  @Input() fontSizeFormControlName;
  @Input() fontColorFormControlName;
}
