import { Component, inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { IIcon } from '../../../dashboards/interfaces/tiles/definition-helpers/icon.interface';
import { IHeroBannerTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/hero-banner-tile-definition.interface';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';
import { ILinkButton } from '../../../dashboards/interfaces/tiles/definition-helpers/link-button.interface';

const IntegerValidator: ValidatorFn = (control) => {
  return Number.isInteger(control.value) ? null : { notInteger: true };
};

class FontSizeFormControl extends FormControl<number> {
  constructor(defaultValue?: number) {
    super(defaultValue, [Validators.min(6), Validators.max(64), IntegerValidator]);
  }
}

@Component({
  selector: 'lib-hero-banner-v3-setup-wizard',
  templateUrl: './hero-banner-v3-setup-wizard.component.html',
  styleUrls: ['../shared-tile-creator-styles.scss', './hero-banner-v3-setup-wizard.component.scss'],
})
export class HeroBannerV3SetupWizardComponent {
  protected tileDefinition: IHeroBannerTileDefinition;

  readonly formGroup = new FormGroup({
    // Header
    heading1: new FormControl(),
    heading1FontColor: new FormControl('#000000'),
    heading1FontSize: new FontSizeFormControl(46),
    heading2: new FormControl(),
    heading2FontColor: new FormControl('#000000'),
    heading2FontSize: new FontSizeFormControl(46),

    // Text
    text: new FormControl(),
    textFontSize: new FontSizeFormControl(12),
    textFontColor: new FormControl('#000000'),

    // Image
    contentImg: new FormControl(),
    contentImgPosition: new FormControl('start'),

    // Background
    background: new FormControl(),
    backgroundImageTopOffset: new FormControl(),
    backgroundImageRightOffset: new FormControl(),
    backgroundImageBottomOffset: new FormControl(),
    backgroundImageLeftOffset: new FormControl(),

    // Buttons
    linkButtons: new FormArray<FormGroup>([]),

    // Icons
    icons: new FormArray<FormGroup>([]),

    // Layout
    minHeight: new FormControl(),
    alignContent: new FormControl('start'),
  });

  constructor(private readonly tileCreatorService: TileCreatorService) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  ngOnInit() {
    this.formGroup.patchValue(this.tileDefinition as never);

    this.tileDefinition?.icons?.forEach((icon) => {
      this.addIcon(icon);
    });
  }

  addIcon(value?: IIcon) {
    this.formGroup.controls.icons.push(
      new FormGroup({
        icon: new FormControl(value?.icon),
        iconBackgroundColor: new FormControl(value?.iconBackgroundColor ?? '#000000'),
        iconColor: new FormControl(value?.iconColor ?? '#000000'),
        iconOutlineColor: new FormControl(value?.iconOutlineColor ?? '#000000'),
        iconStyle: new FormControl(value?.iconStyle ?? 'basic'),
      }),
    );
  }

  removeIcon(index: number) {
    this.formGroup.controls.icons.removeAt(index);
  }

  onCreate() {
    Object.assign(
      this.tileDefinition,
      {
        tileType: 'hero-banner-v3',
        canView: 'true',
      },
      this.formGroup.value,
    );
    this.tileCreatorService.onChange.next();
  }
}
