import { Component, Input, OnInit } from '@angular/core';
import * as featureMapping from '../../../../dashboards/services/feature-mapping.json';

@Component({
  selector: 'suvo-bi-no-subscription',
  templateUrl: './no-subscription.component.html',
  styleUrls: ['./no-subscription.component.scss']
})
export class NoSubscriptionComponent implements OnInit {
  @Input() public tileDefinition: any;

  public dataSource: string;
  public feature: string;

  public ngOnInit(): void {
    this.feature = featureMapping[this.tileDefinition.tileType];
    this.dataSource = this.tileDefinition?.recipe?.dataSource;
  }
}
