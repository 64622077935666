import { Injectable } from '@angular/core';
import { BranchRoleModel } from '../models/branch-role.model';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { BehaviorSubject } from 'rxjs';
import { CommonService, ApiService } from '@suvo-bi-core';

@Injectable({
  providedIn: 'root'
})
export class BranchRoleService extends CommonService<BranchRoleModel> {
  currentBranchRoleSubject: BehaviorSubject<BranchRoleModel> = new BehaviorSubject<BranchRoleModel>(null);

  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.usersServiceApi, 'me/branches/roles');
  }

  setCurrentRole(branchRole: BranchRoleModel): void {
    this.currentBranchRoleSubject.next(branchRole);
  }

  async getCurrentRole(): Promise<BranchRoleModel> {
    return this.currentBranchRoleSubject.value;
  }
}
