import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'suvo-bi-clear-control-button',
  templateUrl: './clear-control-button.component.html',
  styleUrls: ['./clear-control-button.component.scss']
})
export class ClearControlButtonComponent implements OnInit {

  @Input() clearFormGroup: FormGroup;
  @Input() clearFormControlName?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
