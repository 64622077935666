<!-- TODO: MERGE INTO ONE COMPONENT -->
<div class="top-margin" fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
  <h4 class="margin">Your Billing Details</h4>
</div>

<div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">

  <div class="margin card" fxFlex="1 1" fxLayout="row" fxLayoutAlign="stretch stretch">

    <div class="padding" fxFlex="0 1 40%" fxLayout="column" fxLayoutAlign="flex-start stretch">
      <p>We use <b><a>Stripe™</a></b> to manage your payment methods.</p>
      <br>

    </div>

    <mat-divider [vertical]="true"></mat-divider>

    <div class="padding" fxflex="0 1 60%" fxLayout="column" fxLayoutAlign="flex-start stretch">
      <p>To manage your existing payment methods, click the button to go to the Stripe™ Customer Portal.</p>

      <button button mat-flat-button color="primary" class="billing-portal-btn" (click)="goToCustomerPortal()">
        <mat-icon>link</mat-icon>
        Manage Billing Details
      </button>
    </div>


  </div>

</div>
