<div class="container" fxLayout="column" fxLayoutAlign="flex-start stretch">

  <div class="logo-padding" fxFlex="0 0 0px" fxLayout="column" fxLayoutAlign="flex-start stretch">
    <logo logoTheme="navy" [route]="'private/overview'"></logo>
    <div class="logo-tagline">ADMIN</div>
  </div>

  <div fxLayout="column" fxFlex="1 1" fxLayoutAlign="flex-start stretch">

    <suvo-bi-list-navigation class="links-sidenav" [links]="majorLinks" showIcons=true [borderRadius]="'0 24px 24px 0'">
    </suvo-bi-list-navigation>

    <mat-divider class="links-divider"></mat-divider>

    <suvo-bi-list-navigation *ngIf="(dataLinks$ | async)" class="links-sidenav" [links]="dataLinks$ | async" showIcons=true
      [borderRadius]="'0 24px 24px 0'" [ngClass]="{'disabled': !hasRoles('ADMIN')}">
    </suvo-bi-list-navigation>

    <mat-divider class="links-divider"></mat-divider>

    <suvo-bi-list-navigation *ngIf="editingLinks" class="links-sidenav" [links]="editingLinks" showIcons=true
      [borderRadius]="'0 24px 24px 0'"  [ngClass]="{'disabled': !hasRoles('ADMIN')}">
    </suvo-bi-list-navigation>

    <mat-divider class="links-divider"></mat-divider>

    <suvo-bi-list-navigation class="links-sidenav" [links]="adminLinks" showIcons=true [borderRadius]="'0 24px 24px 0'">
    </suvo-bi-list-navigation>

    <mat-divider class="links-divider"></mat-divider>

    <suvo-bi-list-navigation class="links-sidenav" [links]="settingsLinks" showIcons=true [borderRadius]="'0 24px 24px 0'" [ngClass]="{'disabled': !hasRoles('ADMIN')}">
    </suvo-bi-list-navigation>

  </div>


  <mat-nav-list fxFlex="0 0 256px" fxFlex.xs="0 0 25vh" class="account-sidenav">
    <suvo-bi-account-sidenav-widget [logoutRoute]="['/', 'login']" [loadOrganisation]="false" [tenantSelectorLink]="['/', 'shops']" (onLogout)="handleLogout()"></suvo-bi-account-sidenav-widget>
  </mat-nav-list>

</div>
