import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VisualizationsModule } from '@suvo-bi-lib';
import { SharedModule } from '../../../shared/shared.module';
import { NavigationModule } from '../navigation/navigation.module';
import { ReportsSummaryComponent } from './components/reports-summary/reports-summary.component';

@NgModule({
  declarations: [ReportsSummaryComponent],
  imports: [CommonModule, SharedModule, VisualizationsModule, NavigationModule],
  exports: [ReportsSummaryComponent]
})
export class ReportsModule {}
