import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '@suvo-bi-core';
import { AccountSidenavWidgetModule, FiltersModule, ListNavigationModule } from '@suvo-bi-lib';
import { SharedModule } from '../../../shared/shared.module';
import { NavigationOutletModule } from '../navigation-outlet/navigation-outlet.module';
import { OutletComponent } from './components/outlet/outlet.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { NavigationService } from './services/navigation.service';

@NgModule({
  declarations: [OutletComponent, SidenavComponent],
  imports: [
    CommonModule,
    SharedModule,
    ListNavigationModule.forRoot({}),
    BreadcrumbsModule,
    NavigationOutletModule,
    AccountSidenavWidgetModule,
    FiltersModule,
  ],
  exports: [OutletComponent, ListNavigationModule],
  providers: [NavigationService],
})
export class NavigationModule {}
