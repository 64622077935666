<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Text</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <lib-text-editor
        [formGroup]="formGroup"
        textFormControlName="text"
        fontColorFormControlName="textFontColor"
        fontSizeFormControlName="textFontSize"
      ></lib-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Background</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="fill">
        <mat-label>Background</mat-label>
        <input matInput formControlName="background" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Icons</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div
        *ngFor="let icon of formGroup.controls.icons.controls; let i = index"
        [formGroup]="icon"
        class="array-entry"
      >
        <div class="array-entry-header">
          <h3>Button {{ i + 1 }}</h3>
          <button mat-icon-button (click)="removeIcon(i)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Icon</mat-label>
          <input matInput formControlName="icon" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Icon Style</mat-label>
          <mat-select formControlName="iconStyle">
            <mat-option value="basic">Basic</mat-option>
            <mat-option value="outline">Outline</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="color-input">
          <mat-label>Icon Colour</mat-label>
          <input type="color" matInput formControlName="iconColor" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="color-input">
          <mat-label>Icon Outline Colour</mat-label>
          <input type="color" matInput formControlName="iconOutlineColor" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="color-input">
          <mat-label>Icon Background Colour</mat-label>
          <input type="color" matInput formControlName="iconBackgroundColor" />
        </mat-form-field>
      </div>

      <div style="text-align: center">
        <button mat-flat-button color="primary" (click)="addIcon()">Add Icon</button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Buttons</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <lib-links-editor
        [linksFormArray]="formGroup.controls.linkButtons"
        [tileDefinition]="tileDefinition"
      ></lib-links-editor>
    </mat-card-content>
  </mat-card>
</form>

<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
