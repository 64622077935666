<div *ngFor="let rowRandomBasis of rowRandomBasisValues; let last = last">

    <div class="cell-container">

        <div class="cell" *ngFor="let columnBasis of columnBasisValues" [style.flexBasis]="columnBasis + '%'">
          <ngx-skeleton-loader [theme]="{
            'height': '18px',
            'width': rowRandomBasis + '%',
            'border-radius': '22px'
          }"></ngx-skeleton-loader>
        </div>

    </div>

    <mat-divider *ngIf="!last"></mat-divider>

</div>
