import {
  CdkDrag,
  CdkDragDrop,
  DropListOrientation,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { SnackBarService } from '../../../../shared/services/snack/snack-bar.service';
import { Dashboard } from '../../../dashboards/models/dashboard.model';
import { TileCreatorService } from '../../../tile-creator/services/tile-creator.service';
import { IGridColumn } from '../../interfaces/grid-column.interface';
import { IGridRow } from '../../interfaces/grid-row.interface';
import { IGridSettings } from '../../interfaces/grid-settings.interfaces';
import { IGrid } from '../../interfaces/grid.interface';
import { TileJsonDialogComponent } from '../tile-json-dialog/tile-json-dialog.component';

@Component({
  selector: 'suvo-bi-smart-grid',
  templateUrl: './smart-grid.component.html',
  styleUrls: ['./smart-grid.component.scss'],
})
export class SmartGridComponent implements OnInit, OnDestroy {
  @Input() public editMode: boolean;
  @Input() public grid: IGrid | any;
  @Input() public settings: IGridSettings;
  @Input() public saveChangesAsync: (grid: IGrid, publish: boolean) => Promise<any>;
  @Input() public isCreation: boolean = false;
  @Input() public saveMode: string = 'draftsAndPublish';
  @Input() public scrollEnabled = false;
  @Input() displayNameLabel: string;

  @Output() public onCreate: EventEmitter<Dashboard> = new EventEmitter<Dashboard>();

  @Output() public contentChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChild(TemplateRef) public tileOutlet: TemplateRef<any>;

  public changesUnsaved = false;

  public sectionConnectedListIds: string[] = [];
  public rowConnectedListIds: string[] = [];
  public rowDropListOrientation: DropListOrientation = 'horizontal';

  public gridForm: FormGroup;

  public get displayNameControl(): AbstractControl {
    return this.gridForm.get('displayName') as AbstractControl;
  }

  public get shortCodeControl(): AbstractControl {
    return this.gridForm.get('shortCode') as AbstractControl;
  }

  private lastSavedGrid: IGrid;

  private activeDialogRef: MatDialogRef<TileJsonDialogComponent>;

  private unsubscribe$ = new Subject<boolean>();

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly snackBarService: SnackBarService,
    private readonly matDialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly tileCreatorService: TileCreatorService,
    private changeDetectorRef: ChangeDetectorRef,
    private applicationRef: ApplicationRef,
  ) {}

  public ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((result) => {
        this.rowDropListOrientation = result.matches ? 'vertical' : 'horizontal';
      });

    this.recreateConnectedListIds();
    this.setDeepCopySave();
    this.initForm();
    this.initOnChangedSubscription();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes) {
    if (changes.grid) {
      this.changesUnsaved = false;

      this.setDeepCopySave();
    }

    this.initForm();
  }

  /*  Edit Add/Remove Actions
   */

  public addColumnToRow(sectionIndex: number, rowIndex: number): void {
    const section = this.grid.sections[sectionIndex];
    const row = section.rows[rowIndex];

    if (row.columns.length < this.settings.maxColumnCount) {
      const newColumn: IGridColumn = {
        tileDefinition: {
          tileType: 'new',
          canView: true,
        },
      };
      row.columns.push(newColumn);
    }

    this.onChanged(true);
  }

  public addRow(sectionIndex: number, rowIndex?: number): void {
    const section = this.grid.sections[sectionIndex];
    const newRow: IGridRow = {
      columns: [
        {
          tileDefinition: {
            tileType: 'new',
            canView: true,
          },
        },
      ],
    };

    if (rowIndex) {
      section.rows.splice(rowIndex, 0, newRow);
    } else {
      section.rows.push(newRow);
    }

    this.recreateConnectedListIds();
    this.onChanged(true);
  }

  public removeRow(sectionIndex: number, rowIndex: number): void {
    const section = this.grid.sections[sectionIndex];

    section.rows.splice(rowIndex, 1);

    this.recreateConnectedListIds();
    this.onChanged(true);
  }

  public removeColumnFromRow(sectionIndex: number, rowIndex: number, columnIndex: number): void {
    const section = this.grid.sections[sectionIndex];
    const row = section.rows[rowIndex];

    row.columns.splice(columnIndex, 1);

    if (!row.columns.length) {
      this.removeRow(sectionIndex, rowIndex);
    }

    this.onChanged(true);
  }

  private setEditMode(editMode: boolean): void {
    this.editMode = editMode;
  }

  /*  Drag and Drop
   */

  public sectionDropped = (event: CdkDragDrop<any[]>) =>
    this.dragDropRearrange(event) && this.onChanged(true);

  public rowDropped = (event: CdkDragDrop<any[]>) =>
    this.dragDropRearrange(event) && this.onChanged(true);

  public tileDropped = (event: CdkDragDrop<any[]>) =>
    this.dragDropRearrange(event) && this.onChanged(true);

  tileEnterPredicate(dragItem: CdkDrag<IGridColumn>, dropList: any): boolean {
    return dropList.data.length < 3;
  }

  private recreateConnectedListIds(): void {
    //  Nested cdkLists don't work and so the rows are connected by a list of ids.
    //  These ids have to be reassigned when the number of rows changes.

    let sectionConnectedListIds = [];
    let rowConnectedListIds = [];
    let runningRows = 0;
    let runningSections = 0;

    this.grid?.sections?.forEach((section) => {
      sectionConnectedListIds.push(`Section${runningSections}`);
      runningSections += 1;

      section.rows.forEach((row) => {
        rowConnectedListIds.push(`Row${runningRows}`);
        runningRows += 1;
      });
    });

    this.sectionConnectedListIds = sectionConnectedListIds;
    this.rowConnectedListIds = rowConnectedListIds;
  }

  private dragDropRearrange(event: CdkDragDrop<any[]>): boolean {
    if (event.previousContainer === event.container) {
      if (event.previousIndex == event.currentIndex) {
        return false; // No Changes.
      }
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    return true;
  }

  /*  Save and Changes
   */

  public setDeepCopySave(): void {
    this.lastSavedGrid = JSON.parse(JSON.stringify(this.grid));
  }

  public discardChanges(): void {
    this.grid = this.lastSavedGrid;
    this.setDeepCopySave();
    this.onChanged(true);
  }

  public async saveChanges(publish): Promise<void> {
    this.changesUnsaved = false;
    this.saveChangesAsync(this.grid, publish).then(
      (onSuccess) => {
        this.setDeepCopySave();
        this.onChanged(true);
        this.snackBarService.open('Saved.');
      },
      (onRejected) => {
        this.onChanged(true);
        this.snackBarService.open('Something went wrong. Unable to save.');
      },
    );
  }

  private onChanged(refreshGrid = false): void {
    if (this.grid) {
      let stringifiedGrid = JSON.stringify(this.grid);

      this.changesUnsaved = stringifiedGrid !== JSON.stringify(this.lastSavedGrid);

      this.contentChanged.next(this.changesUnsaved);

      if (refreshGrid) {
        //Trigger change detection on grid - maybe find better solution - problems with getting changes to be noticed through template ref
        //this.applicationRef.tick();

        this.grid = JSON.parse(stringifiedGrid);
      }

      this.initForm();
    }
  }

  public toggleColumnDisplayProperty(
    propertyName: string,
    sectionIndex: number,
    rowIndex: number,
    columnIndex: number,
  ): void {
    const section = this.grid.sections[sectionIndex];
    const row = section.rows[rowIndex];
    const column = row.columns[columnIndex];

    this.toggleDisplayProperty(propertyName, column.tileDefinition);
  }

  public toggleDisplayProperty(propertyName: string, definition: any): void {
    if (!definition.displayProperties) definition.displayProperties = {};

    definition.displayProperties[propertyName] = !definition.displayProperties?.[propertyName];

    this.onChanged(true);
  }

  public toggleDashboardProperty(propertyName: string): void {
    this.grid[propertyName] = !this.grid[propertyName];
    this.onChanged(true);
  }

  public openColumnEditDialog(sectionIndex: number, rowIndex: number, columnIndex: number): void {
    const section = this.grid.sections[sectionIndex];
    const row = section.rows[rowIndex];
    const column = row.columns[columnIndex];

    this.tileCreatorService.requestTileUpdate(column.tileDefinition);
  }

  /*  Json Editting
   */

  public editColumnJson(sectionIndex: number, rowIndex: number, columnIndex: number): void {
    const section = this.grid.sections[sectionIndex];
    const row = section.rows[rowIndex];
    const column = row.columns[columnIndex];
    const inputJson = JSON.stringify(column.tileDefinition, null, 4);
    const jsonDialog = this.createJsonDialog('Edit Tile Json', inputJson);

    jsonDialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          column.tileDefinition = JSON.parse(result);
          this.onChanged(true);
        }
      });
  }

  public editJson(): void {
    const { sections } = this.grid;
    const inputJson = JSON.stringify(sections, null, 4);
    const jsonDialog = this.createJsonDialog('Edit Json', inputJson);

    jsonDialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.grid.sections = JSON.parse(result);
          this.onChanged(true);
        }
      });
  }

  private createJsonDialog(
    title: string,
    inputJson: string,
  ): MatDialogRef<TileJsonDialogComponent> {
    this.activeDialogRef?.close();

    const jsonDialog = this.matDialog.open(TileJsonDialogComponent, {
      width: '768px',
      height: '768px',
      data: { title, json: inputJson },
    });

    this.activeDialogRef = jsonDialog;

    return jsonDialog;
  }

  public onCreateClick(): void {
    this.onCreate.emit(this.grid as any);
  }

  private initForm(): void {
    if (!this.gridForm) {
      this.gridForm = this.fb.group({
        displayName: [this.grid.displayName, [Validators.required]],
        shortCode: [this.grid.shortCode, [Validators.required]],
      });

      this.displayNameControl.valueChanges.pipe(debounceTime(250)).subscribe((displayName) => {
        if (this.grid.displayName !== displayName) {
          this.grid.displayName = displayName;
          this.grid.shortCode = this.slugify(displayName);
          this.onChanged(false);
        }
      });

      this.shortCodeControl.valueChanges.pipe(debounceTime(250)).subscribe((shortCode) => {
        if (this.grid.shortCode !== shortCode) {
          this.grid.shortCode = shortCode;
          this.onChanged(false);
        }
      });
    } else {
      this.gridForm.controls.displayName.setValue(this.grid.displayName);
      this.gridForm.controls.shortCode.setValue(this.grid.shortCode);
    }
  }

  public slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  private initOnChangedSubscription(): void {
    this.tileCreatorService.onChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onChanged(true);
    });
  }

  getInvalidFields() {
    let invalids = [];
    Object.keys(this.gridForm.controls).forEach((field) => {
      const control = this.gridForm.get(field);
      if (control.invalid) {
        invalids.push(`${field} is invalid`);
      }
    });

    return invalids;
  }
}
