import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';
import { IUser } from '../../../features/authentication/interfaces/user.interface';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  public userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  public firebaseUserSubject: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);

  constructor(
    private angularFireAuth: AngularFireAuth
  ) {
    this.angularFireAuth.user.subscribe((newUser: any) => {
      this.firebaseUserSubject.next(newUser);
    });
  }

  async requestMfaVerify(phoneInfoOptions: any, reCaptchaObj: firebase.auth.RecaptchaVerifier): Promise<string> {
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, reCaptchaObj);
  }

  createMfaAssertion(localVerifyId: string, smsCode: string): firebase.auth.PhoneMultiFactorAssertion {
    const credential = firebase.auth.PhoneAuthProvider.credential(localVerifyId, smsCode);
    return firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
  }
}
