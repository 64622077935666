import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UserSettingsService } from './services/user-settings.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [UserSettingsService],
})
export class UserSettingsLibModule {}
