import { ISitemapRoute } from '../interfaces/sitemap-route.interface';

export class SitemapRoute implements ISitemapRoute {
  children: SitemapRoute[];
  name: string;
  isLocked: boolean;
  routeType?: string;
  dashboardId?: string;
  filterGroup?: string;
  navigationLinkIcon?: string;
  hideRightHandFilters?: boolean;
  uuid?: string;
}
