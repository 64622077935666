<div class="outlet-container" fxLayout="column" fxLayoutAlign="flex-start center">

  <div class="outlet-padding">
    <!--  -->
    <div class="screen-container" fxLayout="column" fxLayoutAlign="flex-start stretch">

      <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">

        <h4 class="margin">Invitations</h4>

      </div>

      <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">

        <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
          <suvo-bi-organisation-invite-list></suvo-bi-organisation-invite-list>
        </div>

      </div>

    </div>
    <!--  -->
  </div>
</div>