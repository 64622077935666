import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

const defaultConfig: MatSnackBarConfig = {
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  duration: 3000,
};

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private readonly matSnackBar: MatSnackBar) {}

  public open(
    message: string,
    config: MatSnackBarConfig = {}
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.matSnackBar.open(message, null, {...defaultConfig, ...config});
  }
}
