<div
  *ngIf="currentDashboard"
  class="dashboard-container"
  fxLayout="column"
  fxLayoutAlign="flex-start stretch"
>
  <suvo-bi-smart-grid
    [settings]="gridSettings"
    [grid]="currentDashboard"
    [editMode]="editMode"
    [displayNameLabel]="displayNameLabel"
    [saveChangesAsync]="saveChangesAsync"
    [isCreation]="isCreation"
    (onCreate)="createDashboard($event)"
    (contentChanged)="gridContentChanged($event)"
  >
    <ng-template let-tileDefinition>
      <suvo-bi-tile-wrapper
        fxFlex="1 1"
        class="dashboard-tile"
        [tileDefinition]="tileDefinition"
        (change)="onTileChange($event, column)"
        [filtersChannel]="filtersChannel"
        [editMode]="editMode"
      ></suvo-bi-tile-wrapper>
    </ng-template>
  </suvo-bi-smart-grid>
</div>
