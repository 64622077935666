import { Component, OnInit } from '@angular/core';
import { PropertiesViewTileComponent } from '../properties-view-tile/properties-view-tile.component';

@Component({
  selector: 'suvo-bi-paragraph-view-tile',
  templateUrl: './paragraph-view-tile.component.html',
  styleUrls: ['./paragraph-view-tile.component.scss']
})
export class ParagraphViewTileComponent extends PropertiesViewTileComponent {


}
