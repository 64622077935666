import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DataSourceItemsService } from '../../../data-source/data-source-items/services/data-source-items.service';
import { FiltersChannel } from '../../models/filters-channel.model';
import { Filters } from '../../models/filters.model';

@Component({
  selector: 'suvo-bi-export-with-filters',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  @Input() public dataSourceAlias: any;
  @Input() filtersChannel: FiltersChannel;
  @Input() disabled: boolean = false;

  public isExporting = false;

  constructor(
    protected readonly dataSourceItemsService: DataSourceItemsService
  ) {}

  ngOnInit(): void {}

  public async export(): Promise<void> {
    if (this.isExporting) {
      return;
    }

    const filters = this.filtersChannel.filters.getValue();
    const dateString = (new Date()).toLocaleDateString();
    const fileName = `IRJPRO_${this.dataSourceAlias}_${filters.name}_${dateString}`
      .replace(/[^\w-_]/g, '');

    this.exportDataSourceItems(this.dataSourceAlias, filters, fileName);
  }

  protected async exportDataSourceItems(
    dataSourceAlias: string,
    filters: Filters,
    fileName: string
  ): Promise<void> {
    this.isExporting = true;

    this.dataSourceItemsService
      .getCsvString(
        dataSourceAlias,
        {
          filters,
        },
        {
          fileFormat: 'CSV',
          // maxItems: 500,
        }
      )
      .then((csvString) => {
        if (this.unsubscribe$.closed) {
          return;
        }

        const blob = new Blob([csvString], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;
        link.click();

        this.isExporting = false;
      })
      .catch((error) => {
        console.error(error);
        this.isExporting = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
