import { IBiLink } from '@suvo-bi-lib';

export const DATA_NAV_LINKS: IBiLink[] = [
  {
    route: ['items', 'all'],
    displayName: 'All Items',
    displayIcon: 'work',
    subLinks: [], // This will be populated with definitions.
  },
  {
    route: 'definitions',
    displayName: 'Definitions',
    displayIcon: 'square_foot',
  },
];