import { ITendersCheckboxItemList } from '../interfaces/tenders-info/tenders-checkbox-item-list.interface';

export const tendersRegions: ITendersCheckboxItemList[] = [
  {
    name: 'Asia',
    value: '03',
    checked: false,
    items: [
      { name: 'Central Asia', value: '0301', checked: false },
      { name: 'Eastern Asia', value: '0302', checked: false },
      { name: 'SAARC', value: '0307', checked: false },
      { name: 'South Asia', value: '0304', checked: false },
      { name: 'South East Asia', value: '0305', checked: false },
      { name: 'Western Asia', value: '0306', checked: false },
      { name: 'GCC Countries', value: '0308', checked: false },
      { name: 'Gulf Countries', value: '0309', checked: false },
    ],
  },
  {
    name: 'Africa',
    value: '0100',
    checked: false,
    items: [
      { name: 'Central Africa', value: '0101', checked: false },
      { name: 'East Africa', value: '0102', checked: false },
      { name: 'North Africa', value: '0103', checked: false },
      { name: 'Sub-Saharan Africa', value: '0106', checked: false },
      { name: 'Southern Africa', value: '0104', checked: false },
      { name: 'West Africa', value: '0105', checked: false },
    ],
  },
  {
    name: 'Americas',
    value: '02',
    checked: false,
    items: [
      { name: 'Caribbean', value: '0201', checked: false },
      { name: 'Central America', value: '0202', checked: false },
      { name: 'North America', value: '0203', checked: false },
      { name: 'South America', value: '0204', checked: false },
      { name: 'Latin America', value: '0205', checked: false },
    ],
  },
  {
    name: 'Australasia',
    value: '0400',
    checked: false,
    items: [
      { name: 'Australia and New Zealand', value: '0401', checked: false },
      { name: 'Melanesia', value: '0402', checked: false },
      { name: 'Micronesia', value: '0403', checked: false },
      { name: 'Polynesia', value: '0404', checked: false },
    ],
  },
  {
    name: 'Europe',
    value: '0500',
    checked: false,
    items: [
      { name: 'Eastern Europe', value: '0501', checked: false },
      { name: 'Northern Europe', value: '0502', checked: false },
      { name: 'Western Europe', value: '0504', checked: false },
      { name: 'Southern Europe', value: '0503', checked: false },
      { name: 'Central Europe', value: '0505', checked: false },
      { name: 'Baltic', value: '0506', checked: false },
    ],
  },
];
