import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'suvo-bi-skeleton-table-rows',
  templateUrl: './skeleton-table-rows.component.html',
  styleUrls: ['./skeleton-table-rows.component.scss']
})
export class SkeletonTableRowsComponent {

  @Input() rowCount = 7;
  @Input() columnCount = 1;

  /**
   * Array of column width integers that should not sum to greater than 100.
   */
  @Input() columnWidths: number[];

  rowRandomBasisValues: number[];
  columnBasisValues: number[];

  ngOnChanges(changes: SimpleChanges): void {

    // Assign randomness to each row
    if (changes.rowCount) {
      this.rowRandomBasisValues = Array.from({
        length: changes.rowCount.currentValue as number
      }, () => {
        return Math.floor(Math.random() * 50 + 40);
      });
    }

    // Get column basis percentages from provided values
    if (changes.columnWidths) {
      this.columnBasisValues = (changes.columnWidths.currentValue as number[]);
    }

    // Otherwise make each column equal width
    else if (changes.columnCount) {
      const equalBasis = 100 / (changes.columnCount.currentValue as number);
      this.columnBasisValues = Array.from({
        length: changes.columnCount.currentValue as number
      }, () => {
        return equalBasis;
      });
    }
  }
}
