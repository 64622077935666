<!-- <div class="margin">
  <div fxFlex="1 1">
    <mat-form-field fxFlex="1 1" appearance="fill">

      <mat-select panelClass="select-box" [(value)]="selectedFilters" (selectionChange)="selectionChanged($event)" placeholder="No Filters"
        #select>
        <mat-select-trigger>
          <h4 class="title">{{ select.value?.name }}</h4>

          <p *ngIf="select.value?.temporaryForId" class="unsaved-tag">Unsaved</p>
        </mat-select-trigger>
        <mat-option class="no-filters-selected">No Filters</mat-option>
        <mat-option class="filters-select-option" *ngFor="let option of selectedFiltersOptions" [value]="option">
          {{ option.name }}
          <p *ngIf="option.temporaryForId" class="unsaved-tag">Unsaved</p>
        </mat-option>
      </mat-select>
      <button class="final-form-button" matSuffix mat-icon-button [disabled]="!selectedFilters?.temporaryForId">
        <mat-icon>save</mat-icon>
      </button>

    </mat-form-field>
  </div>
</div> -->
<!-- <suvo-bi-channel-debug-label [filtersChannel]="filtersChannel"></suvo-bi-channel-debug-label> -->

<div
  fxLayout="column"
  fxLayoutAlign="flex-start stretch"
  class="options full-width"
>
  <div
    class="title-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <button
        mat-icon-button
        (click)="goBack()"
        aria-label="Back to the filter"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <span class="manage-title">All filters</span>
        <!-- <span class="feature-title">{{ filtersChannel?.channelName || '' | titlecase }}</span> -->
      </div>
    </div>

    <button
      mat-button
      class="export-button"
      (click)="createFilters(filtersChannel.channelName)"
    >
      <mat-icon>add</mat-icon> New
    </button>
  </div>

  <mat-nav-list
    class="full-width filter-list"
    fxLayout="column"
    fxLayoutAlign="flex-start"
  >
    <!-- No Filters -->
    <mat-list-item *ngIf="showNoneOption" (click)="selectNone()">
      <div
        class="full-width"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <p class="option-text no-filters-option-text">No Filters</p>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </mat-list-item>

    <!-- Saved Filters -->
    <ng-container
      *ngFor="let option of selectedFiltersOptions; let first = first"
    >
      <mat-divider></mat-divider>

      <mat-list-item
        (click)="selected(option)"
        [class.active-option]="option === selectedFilters"
      >
        <div
          class="full-width"
          fxlayout="row"
          fxLayoutAlign="space-between center"
        >
          <div fxLayoutAlign="flex-start center" fxLayout="row">
            <!-- <mat-icon class="active-circle">circle</mat-icon> -->
            <div class="option-text">
              <div>{{ option.name }}</div>
              <div>
                <span class="filter-date">{{
                  option.updatedAt | date : "medium"
                }}</span>
              </div>
            </div>
            <div
              class="chip active-chip"
              *ngIf="option === selectedFilters"
              fxLayoutAlign="center center"
            >
              <span>ACTIVE</span>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutGap="8px"
            fxLayoutAlign="flex-start center"
          >
            <!-- <p *ngIf="!option._id" class="unsaved-tag tag-extra-styles">Unsaved</p> -->
            <button
              (click)="$event.stopPropagation(); (false)"
              mat-icon-button
              [matMenuTriggerFor]="editOptionMenu"
              [matMenuTriggerData]="{option}"
              aria-label="Filter menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <!-- <mat-icon>chevron_right</mat-icon> -->
          </div>
        </div>
      </mat-list-item>
    </ng-container>

    <ng-container *ngIf="!showNoneOption && !selectedFiltersOptions.length">
      <suvo-core-icon-message
        icon="filter_alt"
        title="No Custom Filters"
        message="No filters to choose from."
      ></suvo-core-icon-message>
    </ng-container>

    <mat-divider></mat-divider>

    <!-- <mat-list-item (click)="selectNone()" class="full-width option-text no-filters-option-text" fxFlex="1 1" fxLayout="row"
      fxLayoutAlign="space-between center">
      No Filters
      <mat-icon>chevron_right</mat-icon>
    </mat-list-item>

    <mat-list-item class="full-width option-text" fxFlex="1 1" fxLayout="row" fxLayoutAlign="space-between center"
      *ngFor="let option of selectedFiltersOptions" (click)="selected(option)">

      {{ option.name }}

      <mat-icon>chevron_right</mat-icon>

      <p *ngIf="option.temporaryForId" class="unsaved-tag tag-extra-styles">Unsaved</p>

      <button mat-icon-button [matMenuTriggerFor]="editOptionMenu" [matMenuTriggerData]="{option}">
        <mat-icon>more_vert</mat-icon>
      </button>
    </mat-list-item> -->

    <!-- <app-navigation-menu [parentMenuItems]="parentMenuItems"></app-navigation-menu> -->
  </mat-nav-list>

  <!-- Options in debug box -->

  <!-- <div class="margin padding" style="border-radius: 12px 12px 12px 12px; color: white; background-color: black;">
    <p *ngFor="let option of selectedFiltersOptions">{{ option.name }}</p>
  </div> -->
  <!--
  <div class="margin" fxLayout="row" fxLayoutAlign="flex-start stretch" fxLayoutGap="12px">

    <button (click)="createFilters()" fxFlex="1 1" mat-flat-button color="primary">
      <mat-icon>add</mat-icon> New Custom Filters
    </button>

  </div> -->
</div>

<!-- <div *ngIf="!selectedFilters" class="padding" fxLayout="column" fxLayoutAlign="flex-start center">
  <suvo-core-icon-message icon="add" title="No Active Filters" message="Create new filters."></suvo-core-icon-message>
  <button (click)="createFilters()" fxFlex="1 1" mat-flat-button color="primary">
    <mat-icon>add</mat-icon> Create New Filters
  </button>
</div> -->

<mat-menu #editOptionMenu="matMenu">
  <ng-template matMenuContent let-editOption="option">
    <button (click)="renameFilters(editOption)" mat-menu-item>Rename</button>
    <button (click)="duplicateFilters(editOption)" mat-menu-item>
      Duplicate
    </button>
    <button
      (click)="deleteFilters(editOption)"
      mat-menu-item
      [disabled]="selectedFiltersOptions.length == 1"
    >
      Delete
    </button>
  </ng-template>
</mat-menu>
