import { Injectable, signal } from '@angular/core';
import { ChecklistItem } from '@suvo-bi-core';
import { SitemapApiService } from '../../sitemap/services/sitemap-api.service';

@Injectable()
export class PagesChecklistItem implements ChecklistItem {
  readonly title = 'Create pages for your site.';
  readonly routerLink = '/private/pages';

  readonly isChecked = signal(false);

  constructor(subscriptionService: SitemapApiService) {
    // TODO(fix): make reactive
    subscriptionService.getCurrentTenantSitemap().then((sitemap) => {
      for (const route of sitemap?.routes) {
        if (route.children?.length) {
          this.isChecked.set(true);
        }
      }
    });
  }
}
