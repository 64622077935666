import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { NewShopModule } from './new-shop';
import { PublicComponent } from './public.component';
import { PublicRoutingModule } from './public-routing.module';
import { RegisterPageComponent } from './register/register-page.component';

@NgModule({
  imports: [CommonModule, NewShopModule, PublicRoutingModule, SharedModule],
  declarations: [LoginComponent, PublicComponent, RegisterPageComponent],
})
export class PublicModule {}
