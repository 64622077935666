import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';

@Component({
  selector: 'suvo-bi-prompt-save-dialog',
  templateUrl: './prompt-save-dialog.component.html',
  styleUrls: ['./prompt-save-dialog.component.scss'],
})
export class PromptSaveDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { filters: IFilters }
  ) {}
}
