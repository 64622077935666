import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [MatButtonModule, RouterModule],
  template: `
    <div style="margin: 8px 12px">
      <div class="fx" style="margin: auto; max-width: 1200px">
        <a routerLink="/" style="margin-top: 8px">
          <img src="/assets/logo-informally.svg" />
        </a>
        <div class="fx">
          <button mat-stroked-button routerLink="/login">Log in</button>
          <button mat-flat-button color="primary" routerLink="/new-shop">Start free trial</button>
        </div>
      </div>
    </div>
    <router-outlet />
  `,
  styles: `
    .fx {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
  `,
})
export class PublicComponent {}
