<div fxLayout="column">
  <mat-nav-list fxFlex="1 0">
    <suvo-bi-list-navigation-item
      *ngFor="let link of links"
      [link]="link"
      [showIcons]="showIcons"
      [borderRadius]="borderRadius"
      [theme]="theme"
      (onLinkClick)="onLinkClick.emit($event)"
    ></suvo-bi-list-navigation-item>
    <ng-content></ng-content>
  </mat-nav-list>
  <a
    *ngIf="showAskAQuestion"
    class="request-info-box"
    (click)="openRequestInfo()"
    fxLayout="row"
    fxLayoutAlign="flex-start center"
  >
    <mat-icon>help_outline</mat-icon>
    &nbsp;
    <span>Ask us a question</span>
  </a>
</div>
