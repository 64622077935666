import { IFilterGroup } from './filter-group.interface';

export interface IFilters {
  _id?: string;
  name: string;
  type?: string;
  query: IFilterGroup;
  createdAt?: Date;
  updatedAt?: Date;
}
