import { computed, Injectable, signal } from '@angular/core';
import type { User } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export interface ITokenContext {
  tenantRoles?: { [tenantId: string]: 'USER' | 'USER_ADMIN' | 'ADMIN' };
}

@Injectable({ providedIn: 'root' })
export class AuthService<T = unknown> {
  readonly currentUser = signal<User>(undefined);

  readonly currentUserContext = computed(
    async () => (await this.currentUser()?.getIdTokenResult())?.claims.context as T & ITokenContext,
  );

  readonly currentUserTenantRoles = computed(async () => (await this.currentUserContext())?.tenantRoles);

  constructor(auth: AngularFireAuth) {
    auth.user.subscribe((user) => {
      this.currentUser.set(user);
    });
  }
}
