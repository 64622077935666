<div class="screen-container" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <h4 class="margin">Organisation Profile</h4>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
    <div class="margin card" fxFlex="1 1 " fxLayout="column" fxLayoutAlign="stretch stretch">
      <div class="padding">
        <suvo-users-card-entry label="Organisation Name" [entryControl]="selectedOrganisationName" (entrySaved)="updateOrganisation()" [forbidEdit]="selectedOrganisation?.value?.role !== 'ADMIN'">
        </suvo-users-card-entry>
      </div>
    </div>
  </div>
</div>