import { FilterOperatorType } from '../../../shared/interfaces/filters/filter-operator-type.enum';
import { IFiltersSections } from '../interfaces/sections/filters-sections.interface';
import { ALL_CONTINENTS, CONTINENT_COUNTRIES } from './country-continents';

export const PROJECTS_FILTERS_SECTIONS: IFiltersSections = {
  name: 'projects',
  shortCode: 'projects',
  exportRoute: 'projects/export',
  sections: [
    {
      options: [
        {
          filterMethod: 'keywords',
          filterPropertyKeys: ['search'],
          filterMethodConfig: {
            useForSearch: true,
            title: 'Search',
            label: 'Any keywords or projects IDs',
          },
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'combined-nested-checkboxes',
          filterMethodConfig: {
            title: 'Location',
            showChildrenLabel: 'Show countries',
            enableShowChildren: true,
            parentData: ALL_CONTINENTS,
            parentKeyName: 'continent',
            childData: CONTINENT_COUNTRIES,
            childKeyName: 'country',
            optionsColumns: 3,
          },
          filterPropertyKeys: ['continent', 'country'],
        },
      ],
      filterGroup: FilterOperatorType.Or,
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionId: '62052402c2a7c78cc8490364',
            entityDefinitionAlias: 'cities',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['city.id'],
          definitionAlias: 'projects',
        },
      ],
      filterGroup: FilterOperatorType.Or,
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionId: '62019e7637cce15768b64e4a',
            entityDefinitionAlias: 'types',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['type.id'],
          definitionAlias: 'projects',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionId: '62019e3337cce15768b64e32',
            entityDefinitionAlias: 'project-categories',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['projectType.id'],
          definitionAlias: 'projects',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionId: '62019e5237cce15768b64e3b',
            entityDefinitionAlias: 'project-statuses',
            metaDataPrefix: 'data',
          },
          filterPropertyKeys: ['status.id'],
          definitionAlias: 'projects',
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'years-drop-down',
          filterMethodConfig: {
            title: 'Construction Start',
            min: 2000,
            max: 2040,
          },
          filterPropertyKeys: ['constructionStart'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'years-drop-down',
          filterMethodConfig: {
            title: 'Completion',
            min: 2000,
            max: 2040,
          },
          filterPropertyKeys: ['completion'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'between-boxes',
          filterMethodConfig: {
            title: 'Number of Stations',
          },
          filterPropertyKeys: ['stations'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'between-boxes',
          filterMethodConfig: {
            title: 'Rail Length (km)',
            minValue: 0,
            maxValue: 500,
          },
          filterPropertyKeys: ['length'],
        },
      ],
    },

    // {
    //   option: {
    //     filterMethod: 'simple',
    //     filterPropertyKeys: ['drink'],
    //   }
    // },
  ],
};
