<button
  *ngIf="visible"
  mat-flat-button
  class="favourite-button"
  [ngClass]="{ favourited: favourited, debounce: debounce }"
  fxLayout="row"
  fxLayoutAlign="flex-start center"
  (click)="clicked($event)"
>
  <mat-icon class="favourite-icon">{{ favourited ? 'star' : 'star_border' }}</mat-icon>
  <div class="button-text">{{ favourited ? 'Remove from Favourites' : 'Add to Favourites' }}</div>
</button>
<p *ngIf="debugDisplayString">{{ debugDisplayString }}</p>
