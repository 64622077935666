import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ManageBiOrganisationsService } from '../../../services/manage-bi-organisations.service';
import { MANAGE_ORGANISATIONS_LINK } from '../../../data/manage-organisations-links.constant';
import { OrganisationRoleModel, OrganisationRoleService } from '@suvo-bi-users';
import { BreadcrumbsService } from '@suvo-bi-core';

@Component({
  selector: 'lib-single-organisation-view',
  templateUrl: './single-organisation-view.component.html',
  styleUrls: ['./single-organisation-view.component.scss']
})
export class SingleOrganisationViewComponent implements OnInit, OnDestroy {
  $unsubscribe: Subject<boolean> = new Subject<boolean>();

  manageOrganisationsLinks = MANAGE_ORGANISATIONS_LINK;

  selectedOrgRoleId: string;
  selectedOrganisationRole: OrganisationRoleModel;

  // TODO: Kinda hacky, better way to make dynamic width?
  viewingSubscriptionsScreen = false;

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly organisationRoleService: OrganisationRoleService,
    private readonly manageBiOrganisationsService: ManageBiOrganisationsService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      takeUntil(this.$unsubscribe),
      distinctUntilChanged(),
    ).subscribe((params: Params) => {
      this.setViewByParams(params);
    });

    this.manageBiOrganisationsService.selectedSettingsOrgRoleSubject.pipe(takeUntil(this.$unsubscribe)).subscribe((updatedOrgRole) => {
      if (updatedOrgRole) {
        this.breadcrumbsService.setDynamicNameSwap('organisation-role-id', updatedOrgRole.organisationName);
      }
    });

    this.router.events.pipe(takeUntil(this.$unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd && event?.url === `/private/my-organisations/organisations/${this.selectedOrgRoleId}/subscription`) {
        this.viewingSubscriptionsScreen = true;
      } else {
        this.viewingSubscriptionsScreen = false;
      }
    });
  }

  async setViewByParams(params: Params): Promise<void> {
    this.selectedOrgRoleId = params.orgRoleId

    if (this.selectedOrgRoleId) {
      try {
        if (this.router.url === `/private/my-organisations/organisations/${this.selectedOrgRoleId}/subscription`) {
          this.viewingSubscriptionsScreen = true;
        }
        this.selectedOrganisationRole = await this.organisationRoleService.getRole(this.selectedOrgRoleId) as OrganisationRoleModel;
        this.manageBiOrganisationsService.selectedSettingsOrgRoleSubject.next(this.selectedOrganisationRole);
      } catch (error) {
        throw error;
      }
    }


  }

  ngOnDestroy(): void {
    this.breadcrumbsService.removeDynamicNameSwap('organisation-role-id');
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

}
