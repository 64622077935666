import { CommonModel } from '@suvo-bi-core';
import { ITenantRecipe } from '../interfaces/tenant-recipe.interface';

export class TenantRecipeModel
  extends CommonModel<TenantRecipeModel>
  implements ITenantRecipe
{
  email?: string;
  shopDetails: {
    name: string;
    domain: string;
    location: string;
  };
  questionnaire?: Record<string, Record<string, boolean>>;
  constructor() {
    super(TenantRecipeModel);
  }
}
