<!-- Link Button -->
<div [ngClass]="{ disabled: link.disabled }">
  <a
    #item
    mat-list-item
    class="link"
    [ngClass]="{
    inverted: theme === 'inverted',
    losenge: isLoosengeLink(),
    rootLink: !isLoosengeLink(),
  }"
    [ngStyle]="{ 'border-radius': borderRadius }"
    [style.font-size.px]="16 - 2 * depth"
    (click)="handleLinkClick()"
    [routerLink]="link.route"
    routerLinkActive="inactive-link active-link"
    #routerLinkActiveRef
  >
    <span class="link-text" [style.margin-left.px]="depth * 36" fxLayout="row" fxLayoutAlign="flex-start center">
      <mat-icon
        [style.opacity]="depth > 0 ? 0.25 : 1"
        *ngIf="showIcons && link.displayIcon"
        [matBadge]="link.badge"
        matBadgeSize="small"
        matBadgeColor="warn"
      >
        {{ link.displayIcon }}
      </mat-icon>
      {{ link.displayName }}
    </span>

    <!-- Expand Button -->
    <button *ngIf="link.subLinks" mat-icon-button class="expand-button">
      <mat-icon class="expand-icon">{{ link.dropDownOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </a>

  <!-- Sub Links -->
  <ng-container *ngIf="link.subLinks && link.dropDownOpen">
    <div *ngFor="let subLink of link.subLinks">
      <suvo-bi-list-navigation-item
        [depth]="depth + 1"
        [link]="subLink"
        [showIcons]="showIcons"
        [borderRadius]="borderRadius"
        [theme]="theme"
        (onLinkClick)="onLinkClick.emit($event)"
      ></suvo-bi-list-navigation-item>
    </div>
  </ng-container>

  <!-- [routerLinkActiveOptions]="{exact: !!link.subLinks}" -->
</div>
