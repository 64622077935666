import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CurrentTenantService } from '../../../tenant/services/current-tenant.service';
import { OrganisationModel } from '../../../organisation/models/organisation-model';
import { SubscriptionService } from '../../../subscription/services/subscription.service';
import { UserModel } from '../../models/user-model';
import { UsersService } from '../../services/users.service';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl): boolean {
    return (
      control &&
      control.dirty &&
      control.parent?.get('password')?.value !==
        control.parent?.get('confirmPassword')?.value
    );
  }
}

@Component({
  selector: 'lib-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent {
  newUserForm: FormGroup;

  hidePass = true;
  hideConfirmPass = true;

  matcher: MyErrorStateMatcher;

  passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

  creatingUser = false;

  generalError;

  constructor(
    private readonly dialogRef: MatDialogRef<CreateUserComponent>,
    private readonly fb: FormBuilder,
    private readonly subscriptionService: SubscriptionService,
    private readonly tenantService: CurrentTenantService,
    private readonly usersService: UsersService
  ) {
    this.newUserForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passRegex),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  async createUser() {
    try {
      if (this.newUserForm.valid) {
        this.creatingUser = true;
        this.generalError = null;

        const userResult = (await this.usersService.post('all-users', {
          firstName: this.newUserForm.value.firstName,
          lastName: this.newUserForm.value.lastName,
          email: this.newUserForm.value.email,
          password: this.newUserForm.value.password,
          tenantId: this.tenantService.currentTenantData?.firebaseTenantId,
        })) as UserModel & { createdOrganisation: OrganisationModel };

        const subscription = await this.subscriptionService.createCustomer({
          ownerEmail: this.newUserForm.value.email,
          organisationId: userResult.createdOrganisation._id,
          organisationName: userResult.createdOrganisation.name,
          tenantId: this.tenantService.currentTenantData?._id,
        });

        this.dialogRef.close({
          ...userResult,
          createdSubscription: subscription,
        });

        this.creatingUser = false;
      }
    } catch (error) {
      this.creatingUser = false;
      error?.error && (this.generalError = error.error);
      throw error;
    }
  }

  checkPasswordsMatch = (control: AbstractControl): ValidationErrors | null => {
    if (this.newUserForm) {
      return this.newUserForm.get('password')?.value ===
        this.newUserForm.get('confirmPassword')?.value
        ? null
        : { notSame: true };
    }
    return null;
  };
}
