import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DataSourceItemsService} from '../../../../../data-source/data-source-items/services/data-source-items.service';
import { FAKE_TOTALS } from '../../../../data/fake-totals';
import { ILinkBannerTileDefinition } from '../../../../interfaces/tiles/definitions/link-banner-tile-definition.interface';
import { ITileComponent } from '../../tile-component.interface';

@Component({
  selector: 'suvo-bi-link-banner-tile',
  templateUrl: './link-banner-tile.component.html',
  styleUrls: ['./link-banner-tile.component.scss']
})
export class LinkBannerTileComponent implements ITileComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() public tileDefinition: ILinkBannerTileDefinition;

  public displayNumber: number;
  public ticking = false;

  private targetNumber: number;
  private unsubscribe$ = new Subject<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dataSourceItemsService: DataSourceItemsService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.displayNumber = 0;

    // TODO: Workaround until we can count Tenders properly
    if (this.tileDefinition.displayFeatureTotal === 'tenders') {
      this.targetNumber = null;
    } else {
      this.targetNumber = await this.dataSourceItemsService.getCount(this.tileDefinition.displayFeatureTotal);
    }

    interval(10).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.displayNumber = this.displayNumber + (this.targetNumber - this.displayNumber) * 0.04
    })
  }

  public ngAfterViewInit(): void {
    this.ticking = true;
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
