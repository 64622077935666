import { Component, Input, OnInit } from '@angular/core';
import { IStatDefinition } from '../interfaces/stat-definition.interface';
import { DataSourceItemsService } from '../../../../data-source/data-source-items/services/data-source-items.service';

@Component({
  selector: 'suvo-bi-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
})
export class StatComponent implements OnInit {
  constructor(private dataService: DataSourceItemsService) {}

  statValue;

  @Input() statDefinition: IStatDefinition;

  ngOnInit(): void {
    this.fetchStat();
  }

  private fetchStat(): void {
    this.dataService.getAggregated(this.statDefinition.recipe).then((response) => {
      response = (response as Array<any>).map((item) => {
        return { name: item._id, value: item.value };
      });

      if (response[0]) {
        this.statValue = response[0].value;
      } else {
        this.statValue = 0;
      }
    });
  }
}
