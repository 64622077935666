import { Injectable } from '@angular/core';
import { IDataSourceDefinitionProperty } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';

@Injectable({
  providedIn: 'root',
})
export class DataSourcePropertiesService {
  prepare(property: IDataSourceDefinitionProperty) {
    switch (property.type.toLowerCase()) {
      case 'reference':
        // load foreign entities for form
        break;
      case 'int':
        break;
      case 'string':
        break;
      case 'boolean':
        break;
    }
  }
}
