import { IDashboard } from '../interfaces/dashboard.interface';
import { IDisplayProperties } from '../interfaces/tiles/definition-helpers/display-properties.interface';
import { SectionDefinition } from './section-definition';

export class Dashboard implements IDashboard {
    _id: string;
    id?: string;
    shortCode: string;
    displayName: string;
    displayIcon: string;
    sections: SectionDefinition[];
    baseId: string;
    version: number;
    published: boolean;
    tenantId: string;
    createdAt?: any;
    displayProperties?: IDisplayProperties;
    public: boolean = false;

    constructor(dashboard: IDashboard) {

        const { sections, ...restOfDashboard } = dashboard;

        Object.assign(this, restOfDashboard);

        this.sections = dashboard.sections.map((sectionData) => new SectionDefinition(sectionData));
    }

    toJSObj() {
        return JSON.parse(JSON.stringify(this));
    }
}
