import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IDataSourceDefinition } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { IDataSourceDefinitionProperty } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { TableApiService } from '../../../tables/services/table-api.service';
import { IDataSourceSettings } from '../../interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../interfaces/data-source-settings.token';
import { IDefinitionRelations } from '../interfaces/definition-relations.interface';

@Injectable({
  providedIn: 'root',
})
export class NewDataSourceDefinitionsService extends TableApiService<IDataSourceDefinition> {
  private definitions: IDataSourceDefinition[];
  private definitionsSubject = new ReplaySubject<IDataSourceDefinition[]>(1);
  public definitions$ = this.definitionsSubject.asObservable();

  private relations: IDefinitionRelations[];
  private relationsSubject = new ReplaySubject<IDefinitionRelations[]>(1);
  public relations$ = this.relationsSubject.asObservable();

  // private relationsSubject = new ReplaySubject<IDefinitionRelations[]>(1);
  // public relations$ = this.relationsSubject.asObservable();

  constructor(
    protected httpClient: HttpClient,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings
  ) {
    super(httpClient, `${dataSourceSettings.dataServiceApi}definitions/`);
  }

  determineRelations(): void {
    const definitions = this.definitions;
    const relations: IDefinitionRelations[] = definitions.map((definition) => ({
      definition,
      referencedBy: [],
      references: [],
    }));

    definitions.forEach((definition) => {
      const definitionRelations = relations.find(
        (r) => r.definition._id === definition._id
      );
      definition.properties.forEach((property) => {
        if (
          property.type === 'REFERENCE' &&
          property.referenceOptions?.formDefinitionId
        ) {
          const referenceDefinition = definitions.find(
            (d) => d._id === property.referenceOptions.formDefinitionId
          );
          if (referenceDefinition) {
            relations
              .find((r) => r.definition === referenceDefinition)
              .referencedBy.push({ definition, property });
            definitionRelations.references.push({
              definition: referenceDefinition,
              property,
            });
          }
        }
      });
    });

    this.relations = relations;
    this.relationsSubject.next(relations);
    // console.log(relations.filter(r => (r.references.length !== 0 || r.referencedBy.length !== 0)))
  }

  async getAllLocal(): Promise<IDataSourceDefinition[]> {
    let definitions = this.definitions;
    if (!definitions) {
      definitions = await this.getAll();
      this.determineRelations();
    }
    return definitions;
  }

  // async getOneLocal(id: string): Promise<IDataSourceDefinition> {

  //   return this.definitions.find(definition => definition._id === id)
  //     || this.getOne(id);

  // }

  async getOneLocalByAlias(alias: string): Promise<IDataSourceDefinition> {
    return (await this.getAllLocal()).find(
      (definition) => definition.alias === alias
    );
  }

  async getPropertyByAliasAndName(
    alias: string,
    name: string
  ): Promise<IDataSourceDefinitionProperty> {
    const definitions = await this.getAllLocal();
    const definition = definitions.find(
      (definition) => definition.alias === alias
    );
    const property = definition.properties.find(
      (property) => property.name === name
    );
    return property;
  }

  async getAll(): Promise<IDataSourceDefinition[]> {
    const { data: definitions } = await this.getPaginated(null, null);
    this.definitions = definitions;
    this.definitionsSubject.next(definitions);
    return definitions;
  }

  async postOne(
    prefixes: string | string[] = '',
    body: IDataSourceDefinition
  ): Promise<IDataSourceDefinition> {
    const item = await super.postOne(prefixes, body);
    (await this.getAllLocal()).push(item);
    this.definitionsSubject.next(this.definitions);
    return item;
  }

  async patchOne(
    prefixes: string | string[] = '',
    id: string,
    body: IDataSourceDefinition
  ): Promise<Object> {
    const index = this.definitions.findIndex((d) => d._id === id);
    if (index !== -1) {
      this.definitions[index] = body;
      this.definitionsSubject.next(this.definitions);
      this.determineRelations();
    }

    return super.patchOne(prefixes, id, body);
  }
}
