<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Heading 1</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="inputs-container">
        <mat-form-field appearance="fill">
          <mat-label>Background</mat-label>
          <input matInput formControlName="background" />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Heading 1</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div
        *ngFor="let textRow of textRows.controls; let i = index"
        [formGroup]="textRow"
        class="text-rows-container"
      >
        <!-- {{ textRow | json }} -->
        <div class="inputs-container">
          <mat-form-field appearance="fill">
            <mat-label>Heading</mat-label>
            <input matInput formControlName="heading" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Heading Font Size</mat-label>
            <mat-select formControlName="headingFontSize">
              <mat-option *ngFor="let sizeOption of fontSizesOptions" [value]="sizeOption">
                {{ sizeOption }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="color-input">
            <mat-label>Heading Font Color</mat-label>
            <input type="color" matInput formControlName="headingFontColor" />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Text</mat-label>
            <input matInput formControlName="text" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Text Font Size</mat-label>
            <mat-select formControlName="textFontSize">
              <mat-option *ngFor="let sizeOption of fontSizesOptions" [value]="sizeOption">
                {{ sizeOption }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="color-input">
            <mat-label>Text Font Color</mat-label>
            <input type="color" matInput formControlName="textFontColor" />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Image</mat-label>
            <input matInput formControlName="img" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Image Position</mat-label>
            <mat-select formControlName="imgPosition">
              <mat-option *ngFor="let position of horizontalPositionOptions" [value]="position.key">
                {{ position.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Buttons</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <lib-links-editor
                [linksFormArray]="getLinkButtonsControls(i)"
                [tileDefinition]="tileDefinition.textRows[i]"
              ></lib-links-editor>
            </mat-card-content>
          </mat-card>
        </div>
        <mat-icon class="delete-btn" (click)="removeTextRow(i)">delete_forever</mat-icon>
      </div>

      <button class="add-btn" mat-raised-button color="primary" (click)="addTextRow()">
        Add Text Row
      </button>
    </mat-card-content>
  </mat-card>
</form>
<div class="footer">
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
