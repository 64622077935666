import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CurrentTenantService,
  IUser,
  OrganisationRoleModel,
  OrganisationRoleService,
  SubscriptionService,
  TenantRoleService,
  UsersService,
} from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IBiLink } from '../../../../shared/interfaces/bi-link.interface';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormComponent } from '../../../contact/components/contact-form/contact-form.component';

@Component({
  selector: 'suvo-bi-account-sidenav-widget',
  templateUrl: './account-sidenav-widget.component.html',
  styleUrls: ['./account-sidenav-widget.component.scss'],
})
export class AccountSidenavWidgetComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();
  showOrgSelector = false; // TODO: Wire this into tenant configuration
  @Input() tenantSelectorLink: string[];

  @Input() loadOrganisation: boolean = false;
  @Input() showProfileLink: boolean = true;
  @Input() disableProfileLink: boolean = false;
  @Input() accountRoute: string[] | string = [
    '/',
    'private',
    'my-account',
    'profile',
  ];
  @Input() logoutRoute: string[] | string = ['/', 'public', 'login'];
  @Input() subscriptionRoute: string[] | string = [
    '/',
    'private',
    'my-account',
    'subscription',
  ];
  user!: IUser;
  @Input() additionalLinks: IBiLink[];

  @Output() onLogout = new EventEmitter();

  localOrganisationsList: OrganisationRoleModel[] = [];
  selectedOrganisation = new FormControl();

  activeSubscription;
  trialDaysRemaining = 0;

  isTenantAdmin = false;

  constructor(
    private usersService: UsersService,
    private organisationRoleService: OrganisationRoleService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private tenantRoleService: TenantRoleService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.usersService.loadUser(this.loadOrganisation);
    this.usersService.userSubject
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((newUser) => {
        this.user = newUser;
      });
    this.organisationRoleService.currentOrganisationRoleSubject
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((updatedRole) => {
        this.updateLocalSelectedOrgRole();
      });
    this.organisationRoleService.allOrganisationRolesSubject
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((organisationRoles) => {
        this.localOrganisationsList = organisationRoles;
        this.updateLocalSelectedOrgRole();
      });
    this.subscriptionService.currentSubscription
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentSubscription) => {
        this.activeSubscription = currentSubscription;
        if (this.activeSubscription) {
          const currentDate = new Date();
          const trialEnd = new Date(this.activeSubscription.trial_end * 1000);

          this.trialDaysRemaining = Math.floor(
            (Date.UTC(
              trialEnd.getFullYear(),
              trialEnd.getMonth(),
              trialEnd.getDate()
            ) -
              Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              )) /
              (1000 * 60 * 60 * 24)
          );
        }
      });
    this.checkIfTenantAdmin();
  }

  async selectOrganisation(): Promise<void> {
    if (this.selectedOrganisation.value?.organisationId) {
      localStorage.setItem(
        'activeOrganisationRoleID',
        this.selectedOrganisation.value.organisationId
      );
      await this.organisationRoleService.setCurrentRole(
        this.selectedOrganisation.value
      );
    }
  }

  resetSelectedOrg(): void {
    localStorage.setItem(
      'activeOrganisationRoleID',
      this.localOrganisationsList[0].organisationId
    );
    this.selectedOrganisation.setValue(this.localOrganisationsList[0]);
    this.refreshLocalSubscription(
      this.selectedOrganisation.value.organisationId
    );
  }

  updateLocalSelectedOrgRole(): void {
    if (
      this.localOrganisationsList &&
      this.localOrganisationsList.length &&
      this.organisationRoleService.currentOrganisationRoleSubject.value
    ) {
      const persistentOrgRoleId = localStorage.getItem(
        'activeOrganisationRoleID'
      );

      if (persistentOrgRoleId) {
        let found = false;
        this.localOrganisationsList.forEach((org, i) => {
          if (org.organisationId === persistentOrgRoleId) {
            found = true;
            this.selectedOrganisation.setValue(this.localOrganisationsList[i]);
            this.refreshLocalSubscription(
              this.selectedOrganisation.value.organisationId
            );
          }
        });
        if (!found) {
          this.resetSelectedOrg();
        }
      } else {
        this.resetSelectedOrg();
      }
    }
  }

  async logout(): Promise<void> {
    this.angularFireAuth
      .signOut()
      .then(() => {
        if (this.onLogout) this.onLogout.emit();
        this.router.navigate(this.logoutRoute as any[] | any);
      })
      .catch(() => {});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async refreshLocalSubscription(organisationId: string): Promise<void> {
    await this.subscriptionService.getActiveCustomerSubscriptions(
      organisationId
    );
  }

  private async checkIfTenantAdmin(): Promise<void> {
    await this.tenantRoleService.loadRole();

    this.isTenantAdmin =
      (await this.tenantRoleService.getCurrentRole())?.role === 'ADMIN';
  }
}
