import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/modules/shared/shared.module';
import { ResetPasswordConfirmationComponent } from '../../components/public/reset-password-confirmation/reset-password-confirmation.component';
import { AddNewMfaComponent } from '../../components/private/add-new-mfa/add-new-mfa.component';
import { ManageMfaComponent } from '../../components/private/manage-mfa/manage-mfa.component';
import { LoginComponent } from '../../components/public/login/login.component';
import { RegisterComponent } from '../../components/public/register/register.component';
import { VerifyEmailConfirmationComponent } from '../../components/public/verify-email-confirmation/verify-email-confirmation.component';
import { VerifyEmergencyRemovalMfaComponent } from '../../components/public/verify-emergency-removal-mfa/verify-emergency-removal-mfa.component';
import { VerifyMfaComponent } from '../../components/public/verify-mfa/verify-mfa.component';
import { DashboardHeaderComponent } from '../../../dashboard/dashboard-header.component';
import { RemoveMfaComponent } from '../../components/private/remove-mfa/remove-mfa.component';
import { EmailVerificationMessageComponent } from '../../components/public/email-verification-message/email-verification-message.component';
import { VerifyMfaDialogComponent } from '../../../authentication/components/public/verify-mfa-dialog/verify-mfa-dialog.component';
import { PasswordFieldComponent } from '../../../edit-profile/components/common/password-field/password-field.component';
import { ChangePasswordDialogComponent } from '../../components/private/change-password/change-password-dialog.component';
import { MarketingStatementComponent } from '../../components/public/marketing-statement/marketing-statement.component';
import { MarketingCheckboxComponent } from '../../components/public/marketing-checkbox/marketing-checkbox.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    ResetPasswordConfirmationComponent,
    AddNewMfaComponent,
    ManageMfaComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordConfirmationComponent,
    VerifyEmailConfirmationComponent,
    VerifyEmergencyRemovalMfaComponent,
    VerifyMfaComponent,
    DashboardHeaderComponent,
    AddNewMfaComponent,
    RemoveMfaComponent,
    ManageMfaComponent,
    EmailVerificationMessageComponent,
    VerifyMfaDialogComponent,
    PasswordFieldComponent,
    ChangePasswordDialogComponent,
    MarketingStatementComponent,
    MarketingCheckboxComponent,
  ],
  exports: [
    ResetPasswordConfirmationComponent,
    AddNewMfaComponent,
    ManageMfaComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordConfirmationComponent,
    VerifyEmailConfirmationComponent,
    VerifyEmergencyRemovalMfaComponent,
    VerifyMfaComponent,
    DashboardHeaderComponent,
    AddNewMfaComponent,
    RemoveMfaComponent,
    ManageMfaComponent,
    EmailVerificationMessageComponent,
    VerifyMfaDialogComponent,
    PasswordFieldComponent,
    ChangePasswordDialogComponent,
    MarketingStatementComponent,
    MarketingCheckboxComponent,
  ],
})
export class AuthenticationModule {}
