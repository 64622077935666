import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CommonService, ApiService } from '@suvo-bi-core';
import { BehaviorSubject } from 'rxjs';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { IUser } from '../../authentication/interfaces/user.interface';
import { OrganisationModel } from '../../organisation/models/organisation-model';
import { OrganisationRoleService } from '../../organisation-role/services/organisation-role.service';
import { CurrentTenantService } from '../../tenant/services/current-tenant.service';
import { VerifyService } from '../../verify/services/verify.service';
import { IRegistrationStats } from '../interfaces/registration-stats.interface';
import { UserModel } from '../models/user-model';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends CommonService<UserModel> {
  public userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService,
    private readonly angularFireAuth: AngularFireAuth,
    private readonly organisationRoleService: OrganisationRoleService,
    private readonly tenantService: CurrentTenantService,
    private readonly verifyService: VerifyService,
  ) {
    super(apiService, settings.usersServiceApi, '');
  }

  async registerUser(
    route: 'public/register',
    userInfo: any,
  ): Promise<{
    endpointResponseBody: UserModel & {
      createdOrganisation: OrganisationModel;
    };
    firebaseUser: firebase.default.auth.UserCredential;
  }>;
  async registerUser<T>(
    route: string,
    userInfo: any,
  ): Promise<{
    endpointResponseBody: T;
    firebaseUser: firebase.default.auth.UserCredential;
  }>;
  async registerUser<T>(
    route: string,
    userInfo: any,
  ): Promise<{
    endpointResponseBody: T;
    firebaseUser: firebase.default.auth.UserCredential;
  }> {
    userInfo.tenantId = await this.angularFireAuth.tenantId;
    const endpointResponseBody = (await this.post(route, userInfo)) as unknown as T;
    const user = await this.angularFireAuth.signInWithEmailAndPassword(
      userInfo.email,
      userInfo.password,
    );
    if (!user.user.emailVerified) {
      await this.verifyService.sendVerificationEmail(true);
      await this.angularFireAuth.signOut();
    }
    return { endpointResponseBody, firebaseUser: user };
  }

  async loadUser(loadOrganisation: boolean = false): Promise<IUser> {
    const user = await this.getOne('me/profile/');
    this.userSubject.next(user);

    if (loadOrganisation) {
      await this.organisationRoleService.getRoles();
    }

    return user;
  }

  async sendPasswordReset(email: string, firebaseTenantId?: string): Promise<void> {
    try {
      return (await this.post('public/send-password-reset', {
        email,
        tenantId: firebaseTenantId ?? this.tenantService.currentTenantData.firebaseTenantId,
      })) as any; // TODO: bad practice!
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getLatestMemberRegistrations(): Promise<IRegistrationStats> {
    return this.apiService.get(
      (await this.getRouteUrl()) + 'user-stats/member-registrations/latest',
    );
  }

  async getTotalMemberRegistrations(): Promise<IRegistrationStats> {
    return this.apiService.get(
      (await this.getRouteUrl()) + 'user-stats/member-registrations/total',
    );
  }

  async deleteUser(userId: string): Promise<UserModel> {
    return this.delete(`all-users/${userId}`);
  }

  async updateUser(userId: string, userUpdateData: any): Promise<UserModel> {
    return this.patch(`all-users/${userId}`, userUpdateData);
  }

  async getUserLatestLoginDate(userId: string): Promise<any> {
    return this.get(`all-users/lastLogin/${userId}`);
  }
}
