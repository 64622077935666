import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';
import { UsersService } from '../../../../users/services/users.service';
import { VerifyService } from '../../../../verify';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() titleTextChange: EventEmitter<any> = new EventEmitter();
  @Output() loginStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() resetPasswordStatusChange: EventEmitter<any> = new EventEmitter();
  private readonly unsubscribe$ = new Subject<void>();
  loginErr = '';
  loginHelp = '';
  loginForm!: FormGroup;

  loginStage = 1;
  mfaCode = '';

  // TODO: Correct type is "firebase.default.auth.MultiFactorResolver", but phone number doesn't always exist in the hints here?
  mfaResolver: any;

  hide = true;
  showForgotPassword = false;
  forgotPasswordRequestSent = false;
  forgotPasswordError = '';
  forgotPasswordForm: FormGroup;
  token: string;

  @Input() loginComponentOptions: {
    titleText: string;
    showRegister: boolean;
    skipVerifyStep: boolean;
    pwResetFirebaseTenantId?: string;
  };

  firstTimeCheck = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly angularFireAuth: AngularFireAuth,
    private readonly identityService: IdentityService,
    private readonly router: Router,
    private readonly usersService: UsersService,
    private readonly usersSnackbarService: UsersSnackbarService,
    private readonly verifyService: VerifyService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.createLoginForm();
    this.pluckParamsFromRouting();

    if (!this.showForgotPassword) {
      this.tryAutoLogin();
    }

    this.createForgotPasswordForm();

    this.loginStatusChange.next('PASSWORD_RESET_ATTEMPT_NOT_STARTED');
    this.resetPasswordStatusChange.next('PASSWORD_RESET_ATTEMPT_NOT_STARTED');
  }

  async tryAutoLogin() {
    if (await this.angularFireAuth.currentUser) {
      this.loginStatusChange.next('LOGIN_ATTEMPT_PENDING');
    }
    this.identityService.firebaseUserSubject?.pipe(takeUntil(this.unsubscribe$)).subscribe(
      async (user: any) => {
        if (user?.emailVerified) {
          if (this.token) {
            await this.router.navigateByUrl(`private/invitation-list?token=${this.token}`);
            this.loginStatusChange.next('LOGIN_ATTEMPT_COMPLETE');
          } else {
            await this.router.navigate(['private', 'login-referrer']);
            this.loginStatusChange.next('LOGIN_ATTEMPT_COMPLETE');
          }
        } else if (user) {
          try {
            if (this.firstTimeCheck) {
              if (this.loginComponentOptions.skipVerifyStep) {
                this.loginStatusChange.next('USER_UNVERIFIED');
              } else {
                await this.verifyService.sendVerificationEmail(true);
                this.loginErr = '';
                this.loginHelp = `A verification link has been sent to ${user.email}`;
                await this.angularFireAuth.signOut();
              }
            }
          } catch (err) {
            this.loginStatusChange.next('LOGIN_ATTEMPT_FAILED');
            if (err.code === 'auth/too-many-requests') {
              this.loginHelp = '';
              // this.loginErr = err?.message;
              this.loginErr =
                'There was an issue signing into your account.\nIf your account is not yet verified, please check for a verification email and click the link within the email.';
            } else {
              console.log(err);
              this.usersSnackbarService.open(`Something went wrong: ${err.message}`);
            }
            await this.angularFireAuth.signOut();
          }
        }
        this.firstTimeCheck = false;
      },
      (err: any) => {
        this.loginStatusChange.next('LOGIN_ATTEMPT_FAILED');
        console.log(err);
        this.usersSnackbarService.open(`Something went wrong: ${err.message}`);
      },
    );
  }

  pluckParamsFromRouting() {
    console.log(this.activatedRoute);

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((event) => {
        this.testRoute();

        const token = null;
        // const token = paramMap.get('token');
        if (token) {
          this.token = token;
          this.loginForm.addControl('token', new FormControl(this.token, Validators.required));
        }
      });

    this.testRoute();
  }

  testRoute() {
    if (this.router.url.indexOf('forgotPassword') !== -1) {
      this.showForgotPassword = true;
    } else {
      this.showForgotPassword = false;
    }
    // console.log({ forgotPassword })
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    });
  }

  createForgotPasswordForm() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(''),
    });
  }

  async sendForgotPassword(): Promise<void> {
    try {
      this.resetPasswordStatusChange.next('PASSWORD_RESET_ATTEMPT_PENDING');
      await this.usersService.sendPasswordReset(
        this.forgotPasswordForm.value.email,
        this.loginComponentOptions.pwResetFirebaseTenantId,
      );
      this.forgotPasswordRequestSent = true;
      this.forgotPasswordError = '';
      await this.usersSnackbarService.open('Reset Email sent successfully.');
      this.resetPasswordStatusChange.next('PASSWORD_RESET_ATTEMPT_COMPLETE');
    } catch (err) {
      this.resetPasswordStatusChange.next('PASSWORD_RESET_ATTEMPT_FAILED');
      this.forgotPasswordError = err?.error?.message;
    }
  }

  toggleShowForgotPassword(): void {
    const queryParams: any = {};
    let newTitleText = 'Login';
    if (!this.showForgotPassword) {
      queryParams.forgotPassword = true;
      newTitleText = 'Forgot password';
    }
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams });
    this.titleTextChange.emit(newTitleText);

    // this.showForgotPassword = !this.showForgotPassword;
    this.loginErr = '';
    this.loginHelp = '';
    this.forgotPasswordError = '';
    this.forgotPasswordRequestSent = false;
  }

  async submitLogin(): Promise<void> {
    this.loginErr = '';
    this.loginHelp = '';
    this.loginStatusChange.next('LOGIN_ATTEMPT_PENDING');
    try {
      const loginRes = await this.angularFireAuth.signInWithEmailAndPassword(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value,
      );
      if (loginRes.user?.emailVerified) {
        if (this.token) {
          this.router.navigateByUrl(`private/invitation-list?token=${this.token}`);
        } else {
          this.router.navigate(['private', 'login-referrer']);
        }
      } else if (this.loginComponentOptions.skipVerifyStep) {
        this.loginStatusChange.next('USER_UNVERIFIED');
      } else {
        await this.verifyService.sendVerificationEmail(true);
        this.loginHelp = `A verification link has been sent to ${loginRes.user.email}`;
        await this.angularFireAuth.signOut();
        this.loginStatusChange.next('LOGIN_ATTEMPT_FAILED');
      }
    } catch (err) {
      this.loginStatusChange.next('LOGIN_ATTEMPT_FAILED');
      console.log(err);
      switch (err.code) {
        case 'auth/multi-factor-auth-required':
          this.loginStage = 2;
          this.loginErr = '';
          this.loginHelp = '';
          this.mfaResolver = err.resolver;
          break;
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          console.log('user not found');
          this.loginHelp = '';
          this.loginErr = 'Your username or password is invalid.';
          break;
        default:
          this.usersSnackbarService.open(`Something went wrong: ${err.message}`);
          this.loginHelp = '';
          this.loginErr = err.message;
          console.log(this.loginErr);
          break;
      }
    }
  }

  goToRegister() {
    this.loginErr = '';
    this.loginHelp = '';

    this.router.navigate(['register']);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
