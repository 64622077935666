<div class="tile-wrapper" fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch stretch">
  <ng-container [ngSwitch]="viewTile.tileType">

    <ng-container *ngSwitchCase="'major-properties'">
      <suvo-bi-major-properties-view-tile fxFlex="1 1" [viewTile]="viewTile" [definition]="definition" [item]="item" [form]="form"
        [editItemMode]="editItemMode">
      </suvo-bi-major-properties-view-tile>
    </ng-container>

    <ng-container *ngSwitchCase="'paragraph'">
      <suvo-bi-paragraph-view-tile fxFlex="1 1" [viewTile]="viewTile" [definition]="definition" [item]="item" [form]="form"
        [editItemMode]="editItemMode">
      </suvo-bi-paragraph-view-tile>
    </ng-container>


    <ng-container *ngSwitchCase="'rail-map'">
      <suvo-bi-rail-map-view-tile fxFlex="1 1" [viewTile]="viewTile" [definition]="definition" [item]="item" [form]="form"
        [editItemMode]="editItemMode">
      </suvo-bi-rail-map-view-tile>
    </ng-container>

    <ng-container *ngSwitchCase="'properties'">
      <suvo-bi-properties-view-tile fxFlex="1 1" [viewTile]="viewTile" [definition]="definition" [item]="item" [form]="form"
        [editItemMode]="editItemMode">
      </suvo-bi-properties-view-tile>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span>Default.</span>
    </ng-container>

  </ng-container>
</div>