import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FilterConditionType } from '../../../../../shared/interfaces/filters/filter-condition-type.enum';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FilterOptionComponent } from '../filter-option/filter-option.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'suvo-bi-between-dates-filter-option',
  templateUrl: './between-dates-filter-option.component.html',
  styleUrls: ['./between-dates-filter-option.component.scss'],
})
export class BetweenDatesFilterOptionComponent extends FilterOptionComponent {
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @ViewChild('dateRangeInput') dateRangeInput: ElementRef;

  private emitEvents = true;

  constructor() {
    super();

    this.formGroup = new FormGroup({
      start: new FormControl(''),
      end: new FormControl(''),
    });

    this.hookForm(this.formGroup);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  formatDateTime(date: DateTime): string {
    return date.toFormat('dd-MM-yyyy');
  }

  parseDate(dateTimeString: string): Date {
    const [date, month, fullYear] = dateTimeString.split('-').map((x) => +x);
    if (date >= 0 && month >= 0 && fullYear >= 0) {
      return DateTime.fromObject({
        year: fullYear,
        month: month,
        day: date,
      });
    }
    return null;
  }

  hookForm(formGroup: FormGroup): void {
    formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((values: any) => {
        if (this.emitEvents) {
          if (this.areDateInputsValid()) {
            this.option.filterRules = this.getFilterRules(values);
            this.rulesChanged.emit();
          }
        }
      });
  }

  areDateInputsValid() {
    if (!this.startDateInput || !this.endDateInput) return false;

    let startDateInputValue = this.startDateInput.nativeElement.value;
    let endDateInputValue = this.endDateInput.nativeElement.value;

    if (startDateInputValue?.length) {
      let parts = startDateInputValue?.split('/');
      if (parts.length != 3) return false;
      if (parts[2].length != 4) return false;
    }
    if (endDateInputValue?.length) {
      let parts = endDateInputValue?.split('/');
      if (parts.length != 3) return false;
      if (parts[2].length != 4) return false;
    }

    return true;
  }

  getFilterRules(values: { start: DateTime; end: DateTime }): FilterRule[] {
    const key = this.option.filterPropertyKeys[0];
    const { start, end } = values;
    if (!start && !end) {
      return [];
    }

    const property: IFilterProperty = {
      key,
      con: [],
    };
    if (start && start.isValid) {
      property.con.push({
        val: this.formatDateTime(start),
        type: FilterConditionType.GreaterThanEquals,
      });
    }
    if (end && end.isValid) {
      property.con.push({
        val: this.formatDateTime(end),
        type: FilterConditionType.LessThanEquals,
      });
    }

    return [property];
  }

  patchRules(rules: FilterRule[]) {
    /**
     *    emitEvents: false doesn't work with date range inputs >:(
     *
     */

    this.emitEvents = false;

    this.formGroup.controls.end.patchValue(null, { emitEvent: false });
    this.formGroup.controls.start.patchValue(null, { emitEvent: false });

    rules?.forEach((property: IFilterProperty) => {
      property.con?.forEach((condition) => {
        if (condition.type == FilterConditionType.LessThanEquals) {
          this.formGroup.controls.end.patchValue(
            this.parseDate(condition.val),
            { emitEvent: false }
          );
        } else if (condition.type == FilterConditionType.GreaterThanEquals) {
          this.formGroup.controls.start.patchValue(
            this.parseDate(condition.val),
            { emitEvent: false }
          );
        }
      });
    });

    this.emitEvents = true;
  }
}
