import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationModule } from '@suvo-bi-users';

@Component({
  standalone: true,
  imports: [AuthenticationModule],
  template: `
    @switch (mode) {
      @case ('verifyEmail') {
        <lib-verify-email-confirmation />
      }
      @case ('resetPassword') {
        <lib-reset-password-confirmation />
      }
      @case ('revertSecondFactorAddition') {
        <lib-verify-emergency-removal-mfa />
      }
    }
  `,
})
export class VerifyPageComponent {
  mode: string;

  constructor(activateRoute: ActivatedRoute, router: Router) {
    switch ((this.mode = activateRoute.snapshot.queryParams.mode)) {
      case 'verifyEmail':
      case 'resetPassword':
      case 'revertSecondFactorAddition':
        break;
      default:
        router.navigate(['login']);
    }
  }
}
