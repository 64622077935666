<ng-container *ngIf="filtersChannel?.loadedStatus | async; else loadingFilters">
  <div
    class="sliding-container"
    [style.right]="selectOpen ? '100%' : '0%'"
    fxLayout="row"
    fxLayoutAlign="stretch flex-start"
  >
    <div fxFlex="0 0 50%" class="edit-section" fxLayout="column" fxLayoutAlign="stretch stretch">
      <div class="header-section-outer" *ngIf="showFilterHeader">
        <div class="header-section">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="header-section-inner">
            <h4>
              <mat-icon class="filters-icon">filter_alt</mat-icon>
              <span>{{ editFiltersRef?.filters?.name }}</span>
            </h4>
            <!-- <button
              fxFlex="1 1 44px"
              mat-icon-button
              class="switch-filters-button"
              (click)="selectOpen = true"
            >
              <mat-icon>menu</mat-icon>
            </button> -->
          </div>
          <h5 class="active-filter-heading">
            <span
              *ngIf="editFiltersRef?.filters && !editFiltersRef?.filtersSaved && !autoSave"
              class="unsaved-changes"
            >
              (Unsaved changes)
            </span>
          </h5>

          <button mat-icon-button class="close-btn" (click)="onClose()" *ngIf="isSidenavContent">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
          <suvo-bi-filters-title
            fxFlex="1 1"
            *ngIf="!!editFiltersRef.filters; else loadingFilters"
            [filters]="editFiltersRef.filters"
            (titleClicked)="selectOpen = true"
            [editFiltersRef]="editFiltersRef"
            (saveFilters)="$event ? saveTemporaryFilters() : discardTemporaryFilters()"
            [autoSave]="autoSave"
          >
          </suvo-bi-filters-title>
        </div> -->
        <mat-divider></mat-divider>
      </div>
      <div
        *ngIf="!autoSave"
        class="filters-toolbar"
        fxLayout="row"
        fxLayoutAlign="space-between flex-start"
        [ngClass]="{ disabled: !!editFiltersRef?.filtersSaved }"
      >
        <h4 style="margin-left: 4px; margin-top: 0">{{ editFiltersHeading }}</h4>
        <div fxLayout="row" fxLayoutAlign="stretch flex-start" fxLayoutGap="10px">
          <ng-container *ngIf="editFiltersRef?.filters">
            <!-- <button fxFlex="1 1" mat-stroked-button (click)="editFiltersRef.clear()" [disabled]="editFiltersRef?.filtersEmpty">
                <mat-icon>close</mat-icon>
                Clear
              </button> -->
            <button
              fxFlex="1 1"
              mat-stroked-button
              (click)="discardTemporaryFilters()"
              [disabled]="!!editFiltersRef?.filtersSaved"
            >
              <mat-icon>undo</mat-icon>
              Revert
            </button>
            <button
              fxFlex="1 1"
              mat-flat-button
              color="primary"
              (click)="saveTemporaryFilters()"
              [disabled]="!!editFiltersRef?.filtersSaved"
              class="save-button"
            >
              <mat-icon>check</mat-icon>
              Save
            </button>
            <!-- <button fxFlex="1 1" mat-flat-button *ngIf="autoApplyFilters" [routerLink]="[filtersChannel.channelName, 'export']"
                class="export-button">
                <mat-icon>download</mat-icon>
                Export
              </button> -->
          </ng-container>
        </div>
      </div>
      <div
        class="scrolling-container"
        fxFlex="1 1"
        fxLayout="column"
        fxLayoutAlign="stretch stretch"
      >
        <!-- No Active Filters -->

        <!-- <ng-container *ngIf="!selectedFilters">
            <div class="margin" fxLayout="column" fxLayoutAlign="flex-start center">
              <suvo-core-icon-message icon="filter_alt" title="No Selected Filters" message="Create or choose some filters.">
              </suvo-core-icon-message>
              <button mat-flat-button color="primary" (click)="createEvent.emit()">
                <mat-icon>add</mat-icon>Create Custom Filters
              </button>
            </div>
          </ng-container> -->

        <!-- Edit Filters -->
        <div fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch stretch">
          <suvo-bi-edit-filters
            #editFiltersRef
            [autoApplyFilters]="autoApplyFilters"
            [filtersChannel]="filtersChannel"
            (filtersManuallyApplied)="filtersManuallyApplied.emit()"
            fxFlex="1 1"
            [autoSave]="autoSave"
          ></suvo-bi-edit-filters>
        </div>

        <mat-divider></mat-divider>
        <!-- Toolbar -->
        <div
          fxFlex="1 0"
          class="margin"
          fxLayout="row"
          fxLayoutAlign="center flex-start"
          fxLayoutGap="12px"
        >
          <button mat-stroked-button class="clear-filters-button" (click)="clearFilters()">
            <mat-icon>clear</mat-icon>Reset filters
          </button>
          <ng-container *ngIf="!autoApplyFilters">
            <button
              class="search-button"
              mat-flat-button
              color="primary"
              (click)="applyFiltersClicked()"
              [disabled]="editFiltersRef.filtersApplied"
            >
              <mat-icon>search</mat-icon>
              Search
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <div fxFlex="0 0 50%" class="select-section">
      <suvo-bi-select-filters
        [showNoneOption]="false"
        [createEvent]="createEvent"
        (close)="selectOpen = false"
        [filtersChannel]="filtersChannel"
      ></suvo-bi-select-filters>
    </div>
  </div>
</ng-container>
<ng-template #loadingFilters>
  <!-- <span> {{ filtersChannel?.channelName || 'none' }} </span> -->
  <div class="loading-filters" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon class="loading-filters-icon">filter_alt</mat-icon>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>
