import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IDataSourceDefinition } from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { IDataSourceDefinitionProperty } from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { IDataSourceItem } from '../../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { IViewTileDefinition } from '../../../interfaces/view-tile-definition.interface';

interface IDisplayProperty {
  displayIcon?: string;
  property: IDataSourceDefinitionProperty;
  control?: FormControl;
  config: any;
  // value: string;
}

@Component({
  selector: 'suvo-bi-properties-view-tile',
  templateUrl: './properties-view-tile.component.html',
  styleUrls: ['./properties-view-tile.component.scss'],
})
export class PropertiesViewTileComponent implements OnInit {
  @Input() viewTile: IViewTileDefinition;
  @Input() item: IDataSourceItem;
  @Input() form: FormGroup;
  @Input() definition: IDataSourceDefinition;
  @Input() editItemMode: boolean;

  displays: IDisplayProperty[];

  constructor() {}

  ngOnInit(): void {
    this.loadProperties();
  }

  ngOnChanges(): void {
    this.loadProperties();
  }

  trackDisplay(index: number, display: IDisplayProperty): string {
    return display.property.name;
  }

  loadProperties(): void {
    this.displays = this.viewTile.propertyKeys.map(
      (propertySetting: {
        property: string;
        icon: string;
      }): IDisplayProperty => {
        const key = propertySetting.property;

        return {
          displayIcon: propertySetting.icon,
          property: this.definition.properties.find((p) => p.name === key),
          control: this.form?.controls[key] as FormControl | undefined,
          config: propertySetting,
        };
      }
    );
  }
}
