import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouteSelectorComponent } from './components/route-selector/route-selector.component';
import { RouteSelectorItemComponent } from './components/route-selector-item/route-selector-item.component';
import { ListNavigationModule } from '../list-navigation/list-navigation.module';



@NgModule({
  declarations: [
    RouteSelectorComponent,
    RouteSelectorItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ListNavigationModule,
  ],
  exports: [
    RouteSelectorComponent,
  ]
})
export class RouteSelectorModule { }
