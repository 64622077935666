import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrganisationInvitationService } from '../../services/organisation-invitation/organisation-invitation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.scss'],
})
export class InvitationListComponent implements OnInit, OnDestroy {
  selectedInvitation = new FormControl();
  private readonly unsubscribe$ = new Subject<void>();
  invitationsList: any[];
  currentInvitation: any;

  currentInvitationErr: string;
  constructor(
    private organisationInvitationService: OrganisationInvitationService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private usersSnackbarService: UsersSnackbarService,
    public identityService: IdentityService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.pluckParamsFromRouting();
    await this.getMyOrganisations();
  }
  getMyOrganisations(): void {
    this.organisationInvitationService
      .get('invitations-list')
      .then((res: any[]) => {
        this.invitationsList = res;
        if (this.currentInvitation) {
          // TODO: refactor
          this.currentInvitation.name = this.invitationsList.filter(
            (invitation) =>
              invitation._id === this.currentInvitation.organisationId
          )[0].name;
        }
        console.log(this.currentInvitation);
        this.invitationsList = this.invitationsList.filter(
          (invitation) =>
            invitation._id !== this.currentInvitation?.organisationId
        );
      });
  }

  async getCurrentInvitation(token): Promise<void> {
    try {
      this.currentInvitation = await this.organisationInvitationService.get(
        `current-invitation/${token}`
      );
      console.log(this.currentInvitation);
      this.checkUserAuthorisation(this.currentInvitation);
    } catch (err) {
      this.currentInvitationErr =
        'The invitation link you followed is invalid. Please request another one from the sender.';
      console.log(err);
    }
  }

  checkUserAuthorisation(currentInvitation) {
    const { email } = currentInvitation;
    this.identityService.firebaseUserSubject.pipe(take(1)).subscribe((user) => {
      if (user?.email && user.email !== email) {
        this.currentInvitationErr =
          'The invitation link you followed is invalid. Please request another one from the sender.';
      }
    });
  }

  pluckParamsFromRouting(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activateRoute.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async (params: any) => {
          const { token } = params;
          if (token) {
            await this.getCurrentInvitation(token);
          }
          resolve();
        });
    });
  }

  navigateToDashboard(): void {
    this.router.navigateByUrl(`/private/login-referrer`);
  }

  acceptInvitation(organisationId): void {
    this.organisationInvitationService
      .post('invitation/accept', { organisationId })
      .then(async (res: any) => {
        if (this.currentInvitation?.organisationId === organisationId) {
          // Reset query params & refresh
          await this.router.navigate([], {
            queryParams: {
              token: null,
            },
            queryParamsHandling: 'merge',
          });
          this.currentInvitation = null;
        }
        await this.pluckParamsFromRouting();
        await this.getMyOrganisations();
        console.log(res);
        this.usersSnackbarService.open(res.response);
      })
      .catch((err) => {
        console.log(err);
        this.usersSnackbarService.open(err);
      });
  }

  declineInvitation(organisationId): void {
    this.organisationInvitationService
      .post('invitation/decline', { organisationId })
      .then(async (res: any) => {
        if (this.currentInvitation?.organisationId === organisationId) {
          // Reset query params & refresh
          await this.router.navigate([], {
            queryParams: {
              token: null,
            },
            queryParamsHandling: 'merge',
          });
          this.currentInvitation = null;
        }
        await this.pluckParamsFromRouting();
        await this.getMyOrganisations();
        console.log(res);
        this.usersSnackbarService.open(res.response);
      })
      .catch((err) => {
        console.log(err);
        this.usersSnackbarService.open(err);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
