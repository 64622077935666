import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouteSelectorModule } from '../route-selector/route-selector.module';
import { DataSourceRouteSelectComponent } from './components/data-source-route-select/data-source-route-select.component';
import { ManagePlaceholderScreenComponent } from './components/manage-placeholder-screen/manage-placeholder-screen.component';
import { SelectDataSourceScreenComponent } from './components/select-data-source-screen/select-data-source-screen.component';
import { PreferenceResolver } from './resolvers/preference-resolver.resolve';



@NgModule({
  declarations: [
    SelectDataSourceScreenComponent,
    ManagePlaceholderScreenComponent,
    DataSourceRouteSelectComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouteSelectorModule,
  ],
  exports: [
    SelectDataSourceScreenComponent,
    DataSourceRouteSelectComponent,
  ],
  providers: [
    PreferenceResolver
  ]
})
export class ManageBiSharedModule { }
