import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDataSourceDefinition } from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { IDataSourceItem } from '../../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { BreadcrumbsService } from '../../../../../../../../../../../SUVO-CORE-CLIENT-LIB/projects/suvo-core-client-lib/src/public-api';
import { IGridSettings } from '../../../../../smart-grid/interfaces/grid-settings.interfaces';
import { NewDataSourceDefinitionsService } from '../../../../data-source-definitions/services/new-data-source-definitions.service';
import { DataSourceFavouritesService } from '../../../../data-source-favourites/services/data-source-favourites.service';
import { NewDataSourceItemsService } from '../../../../data-source-items/services/new-data-source-items.service';
import { IDataSourceItemView } from '../../../interfaces/data-source-item-view.interface';
import { IViewTileDefinition } from '../../../interfaces/view-tile-definition.interface';
import { ViewTileType } from '../../../interfaces/view-tile-type.enum';
import { DataSourceItemViewsService } from '../../../services/data-source-item-views.service';
import { ItemFormsService } from '../../../services/item-forms.service';

@Component({
  selector: 'suvo-bi-data-source-item-view',
  templateUrl: './data-source-item-view.component.html',
  styleUrls: ['./data-source-item-view.component.scss'],
})
export class DataSourceItemViewComponent implements OnInit, OnDestroy {
  unsubscribeSubject = new Subject<boolean>();

  @Input() admin = false;

  view: IDataSourceItemView;
  item: IDataSourceItem;
  definition: IDataSourceDefinition;
  isLoading = true;

  public dataForm: FormGroup;

  public gridSettings: IGridSettings = {
    maxColumnCount: 3,
    canEdit: true,
    canEditJson: true,
  };
  public editGridMode = false;
  public editItemMode = false;

  private favouriteBreadcrumbVisible = false;

  public context = {
    canEditView: false,
    canEditItem: false,
  };

  stillHere = true;

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private dataSourceItemViewsService: DataSourceItemViewsService,
    private dataSourceItemsService: NewDataSourceItemsService,
    private dataSourceDefinitionsService: NewDataSourceDefinitionsService,
    private activatedRoute: ActivatedRoute,
    private itemFormsService: ItemFormsService,
    private injector: Injector,
  ) {
    // private dataSourceFavouritesService: DataSourceFavouritesService,
  }

  ngOnInit(): void {
    this.context.canEditItem = this.admin;
    this.context.canEditView = false;
    this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribeSubject)).subscribe((paramMap) => {
      const definitionAlias = paramMap.get('alias');
      const itemId = paramMap.get('itemId');
      this.load(definitionAlias, itemId);
    });
  }

  async load(definitionAlias: string, itemId: string): Promise<void> {
    this.isLoading = true;
    this.view = null;
    this.item = null;
    this.definition = null;

    let [view, item, definition] = await Promise.all([
      this.dataSourceItemViewsService.getOne(null, definitionAlias),
      this.dataSourceItemsService.getOne(definitionAlias, itemId),
      this.dataSourceDefinitionsService.getOne('alias', definitionAlias),
    ]);

    if (!view && definition) {
      view = this.createDefaultView(definition);
    }

    if (!view || !item || !definition) {
      this.isLoading = false;
      return;
    }

    if (!this.admin && this.stillHere) {
      const dataSourceFavouritesService = this.injector.get(DataSourceFavouritesService);
      dataSourceFavouritesService.setBreadcrumbStarForItemId(definitionAlias, itemId);
      this.favouriteBreadcrumbVisible = true;
    }

    const itemNameProperty = definition.displayOptions?.itemNameProperty;
    const itemBreadcrumbName = (itemNameProperty && item.data[itemNameProperty]) || '?';

    this.breadcrumbsService.setDynamicNameSwap('item-name', itemBreadcrumbName);
    this.breadcrumbsService.setDynamicNameSwap('definition-name', definition.name);

    this.view = view;
    this.item = item;
    this.definition = definition;
    this.isLoading = false;
  }

  createDefaultView(definition: IDataSourceDefinition): IDataSourceItemView {
    const showHiddenProperties = this.admin;

    const tileDefinition: IViewTileDefinition = {
      tileType: ViewTileType.Properties,
      propertyKeys: (showHiddenProperties
        ? definition.properties
        : definition.properties.filter((p) => !p.isHidden)
      ).map((property) => ({ property: property.name })),
    };

    const defaultView: IDataSourceItemView = {
      _id: null,
      sections: [{ rows: [{ columns: [{ tileDefinition }] }] }],
    };

    return defaultView;
  }

  setEditItemMode(enabled: boolean): void {
    if (enabled) {
      if (!this.dataForm) {
        const dataForm = this.itemFormsService.createDefinitionDataForm(this.definition);
        dataForm.patchValue(this.item.data);
        this.dataForm = dataForm;
      }
    }

    this.editItemMode = enabled;
  }

  onEditItemToggle(event: MatSlideToggleChange): void {
    if (!this.admin) return;
    if (this.editItemMode === event.checked) return;
    this.setEditItemMode(event.checked);
  }

  onSaveGrid = async (view: IDataSourceItemView): Promise<any> => {
    return this.dataSourceItemViewsService.patchOne(null, view._id, view);
  };

  ngOnDestroy(): void {
    this.stillHere = false;

    this.breadcrumbsService.removeDynamicNameSwap('item-name');
    this.breadcrumbsService.removeDynamicNameSwap('definition-name');

    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.complete();

    if (this.favouriteBreadcrumbVisible) {
      const dataSourceFavouritesService = this.injector.get(DataSourceFavouritesService);
      dataSourceFavouritesService.removeBreadcrumbStar();
    }
  }
}
