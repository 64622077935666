import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITourProgress } from '../../interfaces/tour-progress.interface';
import { ITourRectangle } from '../../interfaces/tour-rectangle.interface';
import { TourService } from '../../services/tour.service';

@Component({
  selector: 'suvo-bi-tour-outlet',
  templateUrl: './tour-outlet.component.html',
  styleUrls: ['./tour-outlet.component.scss'],
})
export class TourOutletComponent implements OnInit, OnDestroy {
  private readonly $unsubscribe = new Subject<void>();

  currentProgress: ITourProgress;
  cardProgress: ITourProgress;
  tourActive: boolean = false;
  windowRect: ITourRectangle = { top: 0, left: 0, width: 0, height: 0 };

  constructor(
    private readonly tourService: TourService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.tourService.progressSubject$
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((newProgress) => this.progressChanged(newProgress));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  progressChanged(newProgress?: ITourProgress) {
    this.currentProgress = newProgress;
    this.tourActive = newProgress ? true : false;
    if (newProgress) {
      this.currentProgress = newProgress;
      this.cardProgress = newProgress;
      this.tourActive = true;

      this.focusWindowOnSelector(
        newProgress?.step.selector,
        newProgress?.step.windowPadding
      );
    } else {
      this.currentProgress = null;
      this.tourActive = false;
    }
  }

  focusWindowOnSelector(selector?: string, padding?: number) {
    if (selector) {
      const selectedElement = this.document.querySelector(selector) as Element;
      if (!selectedElement) return;

      const selectedRect = selectedElement.getBoundingClientRect() as DOMRect;
      const documentElement = this.document.documentElement;

      const left =
        (window.pageXOffset || documentElement.scrollLeft) -
        (documentElement.clientLeft || 0);
      const top =
        (window.pageYOffset || documentElement.scrollTop) -
        (documentElement.clientTop || 0);

      if (!padding) {
        padding = 8;
      }

      this.windowRect = {
        top: selectedRect.top + top - padding,
        left: selectedRect.left + left - padding,
        height: selectedRect.height + 2 * padding,
        width: selectedRect.width + 2 * padding,
      };

      documentElement.scrollTo({ top: selectedRect.top - padding * 2 });

      return;
    }

    this.shrinkHideWindow();
  }

  shrinkHideWindow() {
    this.windowRect.top += 0.5 * this.windowRect.height;
    this.windowRect.left += 0.5 * this.windowRect.width;
    this.windowRect.height = 0;
    this.windowRect.width = 0;
  }

  expandHideWindow() {
    const size = 2048;
    this.windowRect.top -= 0.5 * size;
    this.windowRect.left -= 0.5 * size;
    this.windowRect.height += size;
    this.windowRect.width += size;
  }
}
