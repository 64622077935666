import { FilterOperatorType } from '../../../shared/interfaces/filters/filter-operator-type.enum';

export const DEFAULT_FILTERS_VALUE = {
  name: 'Projects filters',
  _id: undefined,
  query: {
    op: FilterOperatorType.And,
    rules: [],
  },
};
