import { Component, Input } from '@angular/core';
import { ITextColumnsTileDefinition } from '../../../../interfaces/tiles/definitions/text-columns-tile-definition.interface';

@Component({
  selector: 'suvo-bi-text-columns-tile',
  templateUrl: './text-columns-tile.component.html',
  styleUrls: ['./text-columns-tile.component.scss'],
})
export class TextColumnsTileComponent {
  @Input() public tileDefinition: ITextColumnsTileDefinition;
}
