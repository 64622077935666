import { IBiLink } from '@suvo-bi-lib';

export const NAV_LINKS: IBiLink[] = [
  {
    route: 'users/admins',
    displayName: 'Administrators',
    displayIcon: 'badge'
  },
  {
    route: 'users/customers',
    displayName: 'Customers',
    displayIcon: 'people'
  },
  {
    route: 'organisations',
    displayName: 'Organisations',
    displayIcon: 'ballot'
  }
];
