<a mat-list-item class="nav-item" [routerLink]="link.route">

  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <mat-icon *ngIf="showIcons && link.displayIcon">{{ link.displayIcon }}</mat-icon>
      <p class="text">{{ link.displayName }}</p>

    </div>
    <mat-icon>chevron_right</mat-icon>
  </div>

</a>