import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ChecklistItem } from '../interfaces/checklist-item.interface';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, RouterModule],
  selector: 'suvo-core-checklist',
  template: `
    @for (item of items; track $index) {
      <button
        mat-button
        style="
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-flow: row nowrap;
          gap: 16px;
          padding: 8px 16px;
        "
        [routerLink]="item.routerLink"
        [style.opacity]="item.isChecked() ? '40%' : '100%'"
        [style.cursor]="!!item.routerLink ? 'pointer' : 'default'"
      >
        <mat-icon color="primary">{{ item.isChecked() ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
        <h3 style="margin: 0; font-size: 1.25em">{{ item.title }}</h3>
      </button>
    }
  `,
})
export class ChecklistComponent {
  @Input() readonly items: ChecklistItem[];
}
