import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TableApiService } from '../../../tables/services/table-api.service';
import { IDataSourceSettings } from '../../interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../interfaces/data-source-settings.token';
import { IDataSourceItemView } from '../interfaces/data-source-item-view.interface';

@Injectable({
  providedIn: 'root'
})
export class DataSourceItemViewsService extends TableApiService<IDataSourceItemView> {

  constructor(
    protected httpClient: HttpClient,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings
  ) {
    super(httpClient, `${dataSourceSettings.dataServiceApi}data-source-item-views/`)
  }

}
