import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { OrganisationRoleService } from '../../../organisation-role/services/organisation-role.service';
import { OrganisationService } from '../../services/organisation/organisation.service';
import { CreateOrganisationComponent } from '../create-organisation/create-organisation.component';
import { OrganisationInvitationDialogComponent } from '../../../invitations/organisations/components/organisation-invitation-dialog/organisation-invitation-dialog.component';
import { UsersSnackbarService } from '../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-manage-organisations',
  templateUrl: './manage-organisations.component.html',
  styleUrls: ['./manage-organisations.component.scss'],
})
export class ManageOrganisationsComponent implements OnInit {
  organisationsList = [];

  selectedOrganisation = new FormControl();
  selectedOrganisationName = new FormControl();

  constructor(
    private organisationRoleService: OrganisationRoleService,
    private organisationService: OrganisationService,
    private dialog: MatDialog,
    private usersSnackbarService: UsersSnackbarService
  ) {}

  ngOnInit(): void {
    this.organisationRoleService.get('').then((res: any[]) => {
      this.organisationsList = res;
      this.selectedOrganisation.setValue(res[0]);
      this.selectedOrganisationName.setValue(res[0].organisationName);
    });
  }

  async selectOrganisation(): Promise<void> {
    await this.organisationRoleService.setCurrentRole(
      this.selectedOrganisation.value
    );
    this.selectedOrganisationName.setValue(
      this.selectedOrganisation.value.organisationName
    );
  }

  createOrganisation(): void {
    const dialogRef = this.dialog.open(CreateOrganisationComponent, {
      width: '800px',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (data) => {
        if (data) {
          console.log('Received and refreshing', data);
          this.usersSnackbarService.open('Organisation Created');
          this.refreshAndMapSelected();
        }
      });
  }

  async updateOrganisation(): Promise<void> {
    this.organisationService.updateOrganisation(
      this.selectedOrganisation.value.organisationId,
      {
        name: this.selectedOrganisationName.value,
      }
    );
    console.log('Name changed!');
    this.usersSnackbarService.open('Organisation name updated');
    this.refreshAndMapSelected();
  }

  refreshAndMapSelected(): void {
    this.organisationRoleService.get('').then((res: any[]) => {
      this.organisationsList = res;
      let selectedIndex = this.organisationsList.findIndex((orgRole) => {
        return (
          orgRole.organisationId ===
          this.selectedOrganisation.value.organisationId
        );
      });

      selectedIndex = selectedIndex < 0 ? 0 : selectedIndex;
      this.selectedOrganisation.setValue(res[selectedIndex]);
      this.selectedOrganisationName.setValue(
        res[selectedIndex].organisationName
      );
    });
  }

  openInvitationDialog(): void {
    const dialogRef = this.dialog.open(OrganisationInvitationDialogComponent, {
      width: '500px',
      data: { organisationId: this.selectedOrganisation.value?.organisationId },
    });
  }
}
