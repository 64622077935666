import { Component, Input } from '@angular/core';

@Component({
  selector: 'suvo-bi-chart-title',
  templateUrl: './chart-title.component.html',
  styleUrls: ['./chart-title.component.scss']
})
export class ChartTitleComponent {
  @Input() public title: string;
}
