import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule, LogoComponent, VisualizationsModule } from '@suvo-bi-lib';
import { SuvoUsersClientLibModule } from '@suvo-bi-users';
import { CommonComponentsModule } from '../../../../../submodules/SUVO-FRONTEND-FRAMEWORK/SUVO-CORE-CLIENT-LIB/projects/suvo-core-client-lib/src/public-api';
import { environment } from '../../environments/environment';
// import { MaterialModule } from './material/material.module';

@NgModule({
  declarations: [LogoComponent],
  exports: [
    // MaterialModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    CommonComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    AngularMaterialModule,
    FormsModule,
    SuvoUsersClientLibModule,
    LogoComponent
  ],
  imports: [
    // MaterialModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    CommonComponentsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    SuvoUsersClientLibModule.forRoot(environment),
    VisualizationsModule
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SharedModule {}
