import { Component, OnDestroy, inject } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITableTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/table-tile-definition.interface';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'app-table-setup-wizard',
  templateUrl: './table-setup-wizard.component.html',
  styleUrls: ['./table-setup-wizard.component.scss'],
})
export class TableSetupWizardComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  errors = [];

  private readonly tileDefinition: ITableTileDefinition;

  readonly formGroup = new FormGroup({
    title: new FormControl(),
    dataSource: new FormControl(),
    showColumns: new FormArray([]),
    showPageControls: new FormControl(true),
    showPageSizeOptions: new FormControl(true),
  });

  public tableShowColumnsOptions;
  public dataSourceObject;

  public get titleControl(): AbstractControl {
    return this.formGroup.get('title') as AbstractControl;
  }

  public get dataSourceControl(): AbstractControl {
    return this.formGroup.get('dataSource') as AbstractControl;
  }

  public get showColumnsControl(): FormArray {
    return this.formGroup.get('showColumns') as FormArray;
  }

  public get showColumnsString(): string {
    return this.showColumnsControl.value.join(', ');
  }

  constructor(private readonly tileCreatorService: TileCreatorService) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;

    this.dataSourceControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.showColumnsControl.clear();
    });

    if (this.tileDefinition) {
      this.formGroup.patchValue({
        title: this.tileDefinition.title,
        showPageControls: this.tileDefinition.showPageControls,
        showPageSizeOptions: this.tileDefinition.showPageSizeOptions,
        dataSource: this.tileDefinition.recipe?.dataSource,
      });

      this.tileDefinition.showColumns?.forEach((showColumn) => {
        this.showColumnsControl.push(new FormControl(showColumn));
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initTableShowColumnsOptions(dataSource: any): void {
    this.dataSourceObject = dataSource;
    this.tableShowColumnsOptions = dataSource.properties;
  }

  public onChange(event) {
    const interests = (<FormArray>this.showColumnsControl) as FormArray;

    if (event.checked) {
      interests.push(new FormControl(event.source.value));
    } else {
      const i = interests.controls.findIndex((x) => x.value === event.source.value);
      interests.removeAt(i);
    }
  }

  public onCreate(): void {
    this.errors = [];

    let showColumns = this.showColumnsControl.value;

    console.log(showColumns);

    if (showColumns.length == 0) {
      this.errors.push({ message: 'Please select at least one column' });
      return;
    }

    Object.assign(this.tileDefinition, {
      tileType: 'table',
      title: this.titleControl.value,
      showColumns: this.showColumnsControl.value,
      recipe: {
        dataSource: this.dataSourceControl.value,
      },
      canView: 'true',
      tableOptions: {
        canSort: false,
      },
      backgroundColor: 'white',
      defaultPageSize: 5,
      showPageControls: this.formGroup.controls.showPageControls.value,
      showPageSizeOptions: this.formGroup.controls.showPageSizeOptions.value,
    });

    this.tileCreatorService.onChange.next();
  }

  isChecked(name) {
    return this.tileDefinition?.showColumns?.find((cn) => cn == name);
  }
}
