<!-- <mat-list-item class="title-list-item" (click)="titlePressed()">

  <div matRipple [matRippleUnbounded]="false" class="padding title-container" fxLayout="row" fxLayoutAlign="space-between center">

    <p class="title">
      <mat-icon fxFlex class="icon">menu</mat-icon> {{ filters?.name || 'No Selected Filters' }}
    </p>


    <button class="save-button" *ngIf="filters" mat-flat-button color="primary" [disabled]="filters._id"
      (click)="saveFilters.emit($event); $event.stopImmediatePropagation(); $event.stopPropagation(); false">Save</button>

  </div>

</mat-list-item> -->
<div
  fxFlex="1 1 100%"
  fxLayout="row"
  fxLayoutAlign="space-between stretch"
  class="filters-title-container"
>
  <div
    fxFlex="1 1 50%"
    fxLayout="row"
    fxLayoutAlign="stretch stretch"
    class="filters-name-container"
  >
    <mat-icon>filter_alt</mat-icon>
    <span class="filters-name">{{
      filters?.name || "No Selected Filters"
    }}</span>
  </div>
  <button
    fxFlex="1 1 160px"
    mat-button
    class="switch-filters-button"
    (click)="titlePressed()"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="flex-start center">
        <span class="filters-name">Switch filters</span>
        <span
          *ngIf="filters && !filters._id && !autoSave"
          class="unsaved-star"
          matTooltip="Unsaved Changes"
          >*</span
        >
      </div>

      <mat-icon>chevron_right</mat-icon>
    </div>
  </button>
</div>
