import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { IOrganisationUpdate } from '../../interface/organisation.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(
    private readonly apiService: ApiService,
    private readonly settings: SuvoUsersClientLibSettingsService
  ) {}

  async updateOrganisation(
    organisationID: string,
    updatedOrganisation: IOrganisationUpdate
  ) {
    const requests = [
      this.apiService.put(
        `${this.settings.usersServiceApi}organisation/${organisationID}`,
        updatedOrganisation
      ),
    ];

    if (updatedOrganisation.name) {
      requests.push(
        this.apiService.patch(
          `${this.settings.subscriptionServiceApi}subscription/admin/customer/${organisationID}`,
          { organisationName: updatedOrganisation.name }
        )
      );
    }

    return await Promise.all(requests);
  }

  post<T>(route: string, body: any): Promise<T> {
    return this.apiService.post(`organisation/${route}`, body);
  }
}
