import { Injectable } from '@angular/core';
import {ISidenavState} from '@suvo-bi-lib';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminSidenavService {

  public sidenavActive = false;
  public sidenavOpen = false;

  public sidenavSubject = new BehaviorSubject<ISidenavState>({
    active: false,
    open: false,
  });

  constructor(
  ) { }

  emitChanges(): void {
    this.sidenavSubject.next({
      active: this.sidenavActive,
      open: this.sidenavOpen,
    })
  }

  setActive(active: boolean, open: boolean = true): void {
    this.sidenavActive = open;
    this.sidenavOpen = active;
    this.emitChanges();
  }

  toggle(): void {
    this.sidenavOpen = !this.sidenavOpen;
    this.emitChanges();
  }

  setOpen(open: boolean): boolean {
    if (this.sidenavOpen != open) {
      this.toggle();
    }
    return true;
  }

  open = () => this.setOpen(true);
  close = () => this.setOpen(false);

}
