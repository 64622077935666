import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from '@suvo-bi-users';
import { Subject } from 'rxjs';
import { IItemLookupTileDefinition } from '../../../interfaces/tiles/definitions/item-lookup-tile-definition.interface';
import { ITileComponent } from '../tile-component.interface';

@Component({
  selector: 'suvo-bi-item-lookup-tile',
  templateUrl: './item-lookup-tile.component.html',
  styleUrls: ['./item-lookup-tile.component.scss'],
})
export class ItemLookupTileComponent
  implements ITileComponent, OnInit, OnDestroy
{
  @Input() public tileDefinition: IItemLookupTileDefinition;

  public referenceId: FormControl;

  private $unsubscribe = new Subject<boolean>();

  allowed = false;

  constructor(private router: Router, private productService: ProductService) {}

  public ngOnInit(): void {
    this.referenceId = new FormControl();

    if (this.tileDefinition.dataSourceAlias) {
      this.allowed = this.productService.isFeatureEnabled('FINDANDPAGINATE', [
        'DATASOURCE:' + this.tileDefinition.dataSourceAlias,
      ]);
    }
  }

  public ngOnDestroy(): void {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

  public lookupItem(): void {
    this.router.navigateByUrl(
      `private/item/${this.tileDefinition.dataSourceAlias}/${this.referenceId.value}`
    );
  }
}
