import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TileCreatorComponent } from './components/tile-creator/tile-creator.component';
import { TableSetupWizardComponent } from './components/table-setup-wizard/table-setup-wizard.component';
import { ChartSetupWizardComponent } from './components/chart-setup-wizard/chart-setup-wizard.component';
import { MapSetupWizardComponent } from './components/map-setup-wizard/map-setup-wizard.component';
import { BannerSetupWizardComponent } from './components/banner-setup-wizard/banner-setup-wizard.component';
import { LinkBannerSetupWizardComponent } from './components/link-banner-setup-wizard/link-banner-setup-wizard.component';
import { SharedModule } from '../../shared/shared.module';
import { UiComponentsModule } from '../../shared/ui-components/ui-components.module';
import { HeroBannerSetupWizardComponent } from './components/hero-banner-setup-wizard/hero-banner-setup-wizard.component';
import { TextColumnsSetupWizardComponent } from './components/text-columns-setup-wizard/text-columns-setup-wizard.component';
import { PresentationSetupWizardComponent } from './components/presentation-setup-wizard/presentation-setup-wizard.component';
import { ContactsSetupWizardComponent } from './components/contacts-setup-wizard/contacts-setup-wizard.component';
import { TextRowsSetupWizardComponent } from './components/text-rows-setup-wizard/text-rows-setup-wizard.component';
import { IconColumnsSetupWizardComponent } from './components/icon-columns-setup-wizard/icon-columns-setup-wizard.component';
import { HeroBannerV3SetupWizardComponent } from './components/hero-banner-v3-setup-wizard/hero-banner-v3-setup-wizard.component';
import { MediaModule } from '../media/media.module';
import { TextEditorComponent } from './components/widgets/text-editor/text-editor.component';
import { LinksEditorComponent } from './components/widgets/links-editor/links-editor.component';
import { ImageEditorComponent } from './components/widgets/image-editor/image-editor.component';
import { SuvoCoreClientLibModule } from '../../../../../../../SUVO-CORE-CLIENT-LIB/projects/suvo-core-client-lib/src/public-api';
import { FreeTrialSetupWizardComponent } from './components/free-trial-setup-wizard/free-trial-setup-wizard.component';

@NgModule({
  declarations: [
    TileCreatorComponent,
    TableSetupWizardComponent,
    ChartSetupWizardComponent,
    MapSetupWizardComponent,
    BannerSetupWizardComponent,
    LinkBannerSetupWizardComponent,
    HeroBannerSetupWizardComponent,
    TextColumnsSetupWizardComponent,
    PresentationSetupWizardComponent,
    ContactsSetupWizardComponent,
    TextRowsSetupWizardComponent,
    IconColumnsSetupWizardComponent,
    HeroBannerV3SetupWizardComponent,
    TextEditorComponent,
    LinksEditorComponent,
    ImageEditorComponent,
    FreeTrialSetupWizardComponent,
  ],
  imports: [CommonModule, SharedModule, UiComponentsModule, MediaModule, SuvoCoreClientLibModule],
  exports: [TileCreatorComponent],
})
export class TileCreatorModule {}
