import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { FiltersChannelsService } from '../services/filters-channels.service';

@Injectable({ providedIn: 'root' })
export class UnsavedChangesGuard  {

  constructor(
    private readonly filtersChannelsService: FiltersChannelsService,
  ) { }

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Promise<boolean> | boolean {

    // TODO: Fix Hack

    // const channelName = component.currentChannel?.channelName;

    // if (!channelName) {
    //   return true;
    // }
    // if (nextState.url.indexOf(channelName) !== -1) {
    //   return true;
    // }

    // return this.filtersChannelsService.canDeactivate();

    return true;
  }

}