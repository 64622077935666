import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { TILE_TYPES } from '../../constants/tile-types.constant';
import { TileCreatorService } from '../../services/tile-creator.service';

@Component({
  selector: 'app-tile-creator',
  templateUrl: './tile-creator.component.html',
  styleUrls: ['./tile-creator.component.scss'],
})
export class TileCreatorComponent implements OnInit {
  @Input() public tileDefinition: any;

  public tileTypes = TILE_TYPES;

  public tileForm: FormGroup;

  public get tileTypeControl(): AbstractControl {
    return this.tileForm.get('tileType') as AbstractControl;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorService: TileCreatorService
  ) {}

  public ngOnInit(): void {
    this.initTileForm();
  }

  private initTileForm(): void {
    this.tileForm = this.fb.group({
      tileType: [this.tileTypes[0].key],
    });
  }

  public onTileCreate(): void {
    const tileType = this.tileTypeControl.value;
    this.tileCreatorService.requestTileCreate(tileType, this.tileDefinition);
  }
}
