<div *ngIf="entityInputControl" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <span class="header">
    {{ option.filterMethodConfig?.title || entityDefinition?.name || 'Reference' }}
  </span>

  <mat-form-field #formField class="full-width" appearance="outline">
    <!-- Chips -->
    <mat-chip-grid #entityChipList>
      <mat-chip-row
        *ngFor="let entity of selectedEntities"
        color="primary"
        class="chip"
        [ngStyle]="{
          'background-color': entity.chipColor,
          color: entity.chipAccent,
        }"
        (removed)="removeEntity(entity)"
      >
        {{ getEntityLabel(entity) }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>

      <!-- Input -->
      <input
        placeholder="Search..."
        #entityInput
        [formControl]="entityInputControl"
        [matAutocomplete]="entityAutocomplete"
        [matChipInputFor]="entityChipList"
        (focus)="onInputFocus()"
      />
    </mat-chip-grid>

    <!-- Auto Complete -->
    <mat-autocomplete
      autoActiveFirstOption
      #entityAutocomplete="matAutocomplete"
      (optionSelected)="autocompleteSelected($event)"
    >
      <mat-option *ngFor="let entity of filteredEntitiesSubject | async" [value]="entity" [class]="{'loadingEntities':loadingEntities}">
        {{ getEntityLabel(entity) }}
      </mat-option>
      <mat-option *ngIf="loadingEntities">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- <p>{{ option.filterProperties | json }}</p> -->
</div>
