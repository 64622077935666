export interface ISitemapRoute {
  children: ISitemapRoute[];
  name: string;
  isLocked: boolean;
  routeType?: string;
  dashboardId?: string;
  filterGroup?: string;
  navigationLinkIcon?: string;
  hideRightHandFilters? :boolean;
}
