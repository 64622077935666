<div
  class="trial-container text-center"
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.background]="tileDefinition.background"
>
  <div class="lead">
    Take advantage of our aggregated data platform.
    <br />
    <b class="highlight"
      >Free for the first {{ freeTrial === 1 ? '1 day' : freeTrial + ' days' }}.</b
    >
  </div>
  <button mat-raised-button class="free-trial-button" routerLink="/register">
    Sign up for a free trial
  </button>
</div>
