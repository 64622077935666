<div>
  <div *ngFor="let button of linksFormArray.controls; let i = index" [formGroup]="button" class="array-entry">
    <div class="array-entry-header">
      <h3>Button {{ i + 1 }}</h3>
      <button mat-icon-button (click)="removeButton(i)">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>

    <lib-text-editor
      [formGroup]="button"
      textFormControlName="buttonText"
      fontSizeFormControlName="buttonFontSize"
      fontColorFormControlName="buttonFontColor"
    ></lib-text-editor>

    <mat-form-field appearance="outline">
      <mat-label>Icon</mat-label>
      <input matInput formControlName="buttonIcon" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Route Link</mat-label>
      <input matInput formControlName="routeLink" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="color-input">
      <mat-label>Background Colour</mat-label>
      <input type="color" matInput formControlName="buttonBackgroundColor" />
    </mat-form-field>
  </div>

  <div style="text-align: center">
    <button mat-flat-button color="primary" (click)="addButton()">Add Link Button</button>
  </div>
</div>
