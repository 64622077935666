import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {AngularMaterialModule} from '../modules/angular-material/angular-material.module';
import {FaqsComponent} from './components/faqs.component';

@NgModule({
  declarations: [FaqsComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [FaqsComponent],
})
export class FaqsModule {}
