import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {IDataSourceDefinition} from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import {IDataSourceItem} from '../../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import {IViewTileDefinition} from '../../../interfaces/view-tile-definition.interface';
import * as L from 'leaflet';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {distinct, takeUntil} from 'rxjs/operators';

interface ICityItem {
  data: {
    name: string;
    longitude: number;
    latitude: number;
  };
}
interface IPosition {
  center: [number, number];
  zoom: number;
}
const DEFAULT_POSITION: IPosition = {
  center: [51.5028779, -0.114387],
  zoom: 12,
};

let mapIdCounter = 0;

@Component({
  selector: 'suvo-bi-rail-map-view-tile',
  templateUrl: './rail-map-view-tile.component.html',
  styleUrls: ['./rail-map-view-tile.component.scss'],
})
export class RailMapViewTileComponent
  implements OnInit, AfterViewInit, OnDestroy {
  unsubscribeSubject = new Subject<boolean>();

  @Input() viewTile: IViewTileDefinition;
  @Input() item: IDataSourceItem;
  @Input() form: FormGroup;
  @Input() definition: IDataSourceDefinition;
  @Input() editItemMode: boolean;

  private map: any;
  locationProperty: string;

  position: IPosition;

  mapId = "railMap"+mapIdCounter++;

  constructor() { }

  ngOnInit(): void {
    this.locationProperty = this.viewTile.locationProperty;
    this.position = this.getPosition();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.form?.valueChanges
        .pipe(
          distinct((value) => value[this.locationProperty]),
          takeUntil(this.unsubscribeSubject)
        )
        .subscribe(() => this.updatePosition());
    }
  }

  updatePosition(): void {
    this.position = this.getPosition();

    this.map.panTo(new L.LatLng(...this.position.center), this.position.zoom);
  }

  getPosition(): IPosition {
    const locationProperty = this.locationProperty;

    if (locationProperty) {
      if (locationProperty == 'coordinates') {
        let lat = this.item?.data['latitude'];
        let lng = this.item?.data['longitude'];

        if (isNaN(lat) || isNaN(lng)) {
          return null;
        }

        let defaultMapZoomLevel = this.item.data['defaultMapZoomLevel'];

        let zoom = defaultMapZoomLevel ? defaultMapZoomLevel : 10.5;

        return {
          center: [lat, lng],
          zoom: zoom,
        };
      } else if (locationProperty == 'city') {
        const city: ICityItem =
          this.form?.value[locationProperty] ||
          this.item?.data[locationProperty];
        if (city) {
          if (isNaN(city.data.latitude) || isNaN(city.data.longitude)) {
            return null;
          }

          return {
            center: [city.data.latitude, city.data.longitude],
            zoom: 10.5,
          };
        } else {
          return null;
        }
        // const country = (this.form?.value.country || this.item?.data['country'])
        // if (country) {

        // }
      }
    }

    return null;
  }

  initMap(): void {
    let options;

    if (this.position) {
      options = {
        center: this.position.center,
        zoom: this.position.zoom,
      };
    }

    this.map = L.map(this.mapId, options);

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        className: 'main-layer',
        maxZoom: 18,
        minZoom: 2,
        // attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }
    );
    const openrailwaymap = L.tileLayer(
      'http://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png',
      {
        attribution:
          '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap',
        minZoom: 2,
        maxZoom: 18,
        tileSize: 256,
      }
    );

    tiles.addTo(this.map);
    openrailwaymap.addTo(this.map);
    this.map.invalidateSize();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.complete();
  }
}
