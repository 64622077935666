import { Component, Input, OnInit } from '@angular/core';
import {IStat} from '../../interfaces/stat.interface';

@Component({
  selector: 'lib-basic-stat',
  templateUrl: './basic-stat.component.html',
  styleUrls: ['./basic-stat.component.scss']
})
export class BasicStatComponent implements OnInit {
  @Input() stat: IStat;

  constructor() { }

  ngOnInit(): void {
  }

}
