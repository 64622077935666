import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IDataSourceDefinition } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { IDataSourceValidationOptions } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-validation-options.interface';

@Injectable({
  providedIn: 'root',
})
export class ItemFormsService {
  constructor() {}

  createValidators(
    validationOptions: IDataSourceValidationOptions
  ): ValidatorFn[] {
    if (!validationOptions?.length) return null;

    const validators: ValidatorFn[] = [];
    if (validationOptions.max) {
      validators.push(Validators.max(validationOptions.max));
    }
    if (validationOptions.min) {
      validators.push(Validators.min(validationOptions.min));
    }
    //  TODO: Etc. Etc. Very cool

    return validators;
  }

  createDefinitionDataForm(definition: IDataSourceDefinition): FormGroup {
    const dataControls: { [key: string]: FormControl } = {};

    definition.properties.forEach((property) => {
      const control = new FormControl(
        null,
        this.createValidators(property.validationOptions)
      );
      dataControls[property.name] = control;
    });

    const dataForm = new FormGroup(dataControls);
    return dataForm;
  }
}
