export interface ITenantTheme {
  _id?: string;
  headingFont: string;
  standardFont: string;
  primaryColor: string;
  primaryContrast: 'light' | 'dark';
  secondaryColor: string;
  secondaryContrast: 'light' | 'dark';
  tertiaryColor: string;
  tertiaryContrast: 'light' | 'dark';
  primaryBackgroundColor: string;
}
