import { Component } from '@angular/core';
import { AdminSidenavService } from '../../../admin-navigation/services/admin-sidenav.service';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent {
  constructor(public adminSidenavService: AdminSidenavService) {}

  toggle() {
    this.adminSidenavService.toggle();
  }
}
