import { Component, Input } from '@angular/core';
import { IIconColumnsTileDefinition } from '../../../../interfaces/tiles/definitions/icon-columns-tile-definition.interface';

@Component({
  selector: 'suvi-bi-icon-columns-tile',
  templateUrl: './icon-columns-tile.component.html',
  styleUrls: ['./icon-columns-tile.component.scss'],
})
export class IconColumnsTileComponent {
  @Input() public tileDefinition: IIconColumnsTileDefinition;
}
