import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FiltersOption } from '../../../models/sections/filters-option.model';
import {IFilterProperty} from '../../../../../shared/interfaces/filters/filter-property.interface';

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss'],
})
export class FilterOptionComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  formGroup?: FormGroup;

  @Input() option!: FiltersOption;
  @Output() rulesChanged: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.resetSubscriptions();

    if (this.option.filterRules) {
      this.patchRules(this.option.filterRules);
    }
  }

  resetSubscriptions(): void {
    if (this.unsubscribe$.closed) this.unsubscribe$ = new Subject<boolean>();
    this.option.patchRulesSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rules) => {
        this.patchRules(rules);
      });
  }

  hookForm(formGroup: FormGroup): void {
    formGroup.valueChanges
      .pipe(debounceTime(10), takeUntil(this.unsubscribe$))
      .subscribe((values: any) => {
        let filterRules = this.getFilterRules(values);

        this.option.filterRules = filterRules?.map(r => {
          (r as IFilterProperty).optionId = this.option.optionId;

          return r;
        });
        
        this.rulesChanged.emit();
      });
  }

  getFilterRules(values: any): FilterRule[] {
    return [];
  }

  patchRules(rules: FilterRule[]) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearFormControl(formControlName: string, event?: MouseEvent): false {
    const formControl = this.formGroup?.get(formControlName);
    if (formControl) {
      formControl.reset();
    }
    event.stopPropagation();
    return false;
  }
}
