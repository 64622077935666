import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { IDataSourceSettings } from '../../data-source/interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../data-source/interfaces/data-source-settings.token';

@Injectable({ providedIn: 'root' })
export class EmailAlertService extends ApiService<any> {
  constructor(httpClient: HttpClient, @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings) {
    super(httpClient, dataSourceSettings.dataServiceApi, '');
  }

  async getSelectedEmailFilter(alias: string): Promise<any> {
    const url = `${alias}/email-alerts`;

    const response = await this.get(url);
    return response;
  }

  async postSelectedEmailFilter(alias: string, filterId: string): Promise<any> {
    const url = `${alias}/email-alerts`;

    const response = await this.post({ filterId }, url);
    return response;
  }

  async deleteSelectedEmailFilter(alias: string): Promise<any> {
    const url = `${alias}/email-alerts`;

    const response = await this.httpClient.delete(await this.getRequestUrl(`${url}`)).toPromise();
    return response;
  }

  async sendEmailAlerts(userId: string) {
    if (!userId) {
      throw Error('Cannot trigger email alerts send. User Id not provided.');
    }

    const url = `send-email-alerts/${userId}`;

    const response = await this.post({}, url);
    return response;
  }
}
