import { Injectable } from '@angular/core';
import { BranchModel } from '../../models/branch-model';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { CommonService, ApiService } from '@suvo-bi-core';

@Injectable({
  providedIn: 'root',
})
export class BranchService extends CommonService<BranchModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.dataServiceApi, 'branches/');
  }
}
