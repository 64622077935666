import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { TablesModule } from '../../tables/tables.module';
import { DataSourceItemsModule } from '../data-source-items/modules/data-source-items.module';
import { FavouriteItemsTableComponent } from './components/favourite-items-table/favourite-items-table.component';
import { FavouriteItemStarComponent } from './components/widgets/favourite-item-star/favourite-item-star.component';

@NgModule({
  declarations: [
    FavouriteItemStarComponent,
    FavouriteItemsTableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DataSourceItemsModule,
    TablesModule,
  ],
  providers: [
  ],
  exports: [
    FavouriteItemStarComponent,
    FavouriteItemsTableComponent,
  ]
})
export class DataSourceFavouritesModule { }
