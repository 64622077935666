<mat-form-field appearance="outline" class="form-field-no-padding" [class.with-value]="!!filterControl.value">
  <mat-label>{{ definitionProperty?.displayName }}</mat-label>
  <mat-select [formControl]="filterControl">
    <mat-option [value]="option" *ngFor="let option of options">
      {{ option }}
    </mat-option>
  </mat-select>

  <mat-icon (click)="onValueReset($event)">close</mat-icon>
</mat-form-field>
