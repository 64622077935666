<div class="outlet-container" fxLayout="column" fxLayoutAlign="flex-start center">
  <div class="outlet-padding">

    <div class="screen-container" fxLayout="column" fxLayoutAlign="flex-start stretch">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h4 class="margin">Your Organisations</h4>
        <button class="margin" mat-raised-button color="primary" (click)="createOrganisation()">
          <mat-icon>add</mat-icon> Create Organisation
        </button>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch">
        <!-- <div class="margin card" fxFlex="1 1 0" fxLayout="column" fxLayoutAlign="stretch stretch" *ngIf="!loading && localOrganisationsList?.length">
          <div class="container" *ngFor="let organisation of localOrganisationsList; let lastChild = last" [routerLink]="['../', organisation._id]">
            <div class="padding">
              <div fxLayout="row">
                <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                  <p class="entry">{{ organisation.organisationName }}</p>
                </div>
                <div class="entry-buttons-container" fxLayout="row" fxLayoutAlign="flex-end center">
                  <button mat-icon-button class="edit-entry">
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <mat-divider *ngIf="!lastChild"></mat-divider>
          </div>
        </div>
        <div class="margin card padding" fxFlex="1 1 0" fxLayout="column" fxLayoutAlign="stretch stretch" *ngIf="!loading && !localOrganisationsList?.length">
          <p class="text-center no-margin">You are in no additional organisations.</p>
        </div>
        <div class="margin card padding" fxFlex="1 1 0" fxLayout="column" fxLayoutAlign="stretch stretch" *ngIf="loading">
          <suvo-core-loading-spinner message="Loading Your Organisations"></suvo-core-loading-spinner>
        </div> -->

        <div class="margin card" fxFlex="1 1 0" fxLayout="column" fxLayoutAlign="stretch stretch">

          <ng-container *ngIf="organisationLinks; else loadingOrganisations">

            <ng-container *ngIf="organisationLinks.length; else noOrganisations">
              <suvo-bi-route-selector [links]="organisationLinks" [showIcons]="true"></suvo-bi-route-selector>
            </ng-container>
            <ng-template #noOrganisations>
              <p class="text-center no-margin">You are in no additional organisations.</p>
            </ng-template>

          </ng-container>
          <ng-template #loadingOrganisations>
            <suvo-core-loading-spinner message="Loading Your Organisations"></suvo-core-loading-spinner>
          </ng-template>

        </div>

      </div>
    </div>
  </div>
</div>