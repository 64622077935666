import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITableDataSource } from '../../../../shared/interfaces/table/table-data-source.interface';
import { ApiService } from '../../../../shared/services/api.service';
import { SnackBarService } from '../../../../shared/services/snack/snack-bar.service';
import { DataSourceDefinitionsService } from '../../data-source-definitions/services/data-source-definitions.service';
import { IDataSourceSettings } from '../../interfaces/data-source-settings.interface';
import { DataSourceSettingsRef } from '../../interfaces/data-source-settings.token';
import { IBreadcrumbStarState } from '../interfaces/breadcrumb-star-state.interface';

@Injectable({
  providedIn: 'root',
})
export class DataSourceFavouritesService extends ApiService<any> {
  breadcrumbStarSubject = new BehaviorSubject<IBreadcrumbStarState>({
    visible: false,
  });

  activeAlias?: string;
  activeItemId?: string;
  activeStatus?: boolean;

  constructor(
    protected httpClient: HttpClient,
    private readonly snackBarService: SnackBarService,
    @Inject(DataSourceSettingsRef) dataSourceSettings: IDataSourceSettings,
    private dataSourceDefintionsService: DataSourceDefinitionsService
  ) {
    super(httpClient, dataSourceSettings.dataServiceApi, '');
  }

  async setBreadcrumbStarForItemId(
    dataSourceAlias: string,
    dataSourceItemId: string
  ): Promise<void> {
    let datasourceDefintion =
      await this.dataSourceDefintionsService.getByShortcode(dataSourceAlias);

    if (datasourceDefintion?.canFavourite) {
      this.activeAlias = dataSourceAlias;
      this.activeItemId = dataSourceItemId;

      const requestUrl = `${dataSourceAlias}/${dataSourceItemId}/favourite/status`;
      const favouriteStatus = (await this.get(requestUrl)) as boolean;

      this.activeStatus = favouriteStatus;

      this.breadcrumbStarSubject.next({
        visible: true,
        favourited: favouriteStatus,
      });
    } else {
      this.breadcrumbStarSubject.next({
        visible: false,
        favourited: null,
      });
    }
  }

  async toggleFavouriteStatus(): Promise<void> {
    const { activeAlias, activeItemId } = this;

    if (activeAlias && activeItemId) {
      const requestUrl = `${activeAlias}/${activeItemId}/favourite`;
      const response = await this.post(null, requestUrl);

      if (
        activeAlias == this.activeAlias &&
        activeItemId == this.activeItemId
      ) {
        this.activeStatus = !this.activeStatus;

        this.snackBarService.open(
          this.activeStatus
            ? 'Added to favourites.'
            : 'Removed from favourites.'
        );

        this.breadcrumbStarSubject.next({
          visible: true,
          favourited: this.activeStatus,
        });
      }
    }
  }

  async getFavouriteDataSourceItems(
    alias: string
  ): Promise<ITableDataSource<any>> {
    const requestUrl = `${alias}/favourites/populated`;
    const favouriteDataSourceItems = (await this.get(
      requestUrl
    )) as ITableDataSource<any>;

    return favouriteDataSourceItems;
  }

  removeBreadcrumbStar(): void {
    this.activeAlias = null;
    this.activeItemId = null;
    this.activeStatus = null;
    this.breadcrumbStarSubject.next({ visible: false });
  }
}
