<button
  mat-icon-button
  aria-label="Clear"
  *ngIf="clearFormControlName ? clearFormGroup.value[clearFormControlName] : clearFormGroup.value.start"
  (click)="
    clearFormControlName ? clearFormGroup.get(clearFormControlName).reset() : clearFormGroup.reset();
    $event.stopPropagation();
    (false)
  "
>
  <mat-icon>close</mat-icon>
</button>
