import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IDataSourceDefinition } from '../../../../shared/interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { IPreferenceResolvedData } from '../../interfaces/preference-resolved-data.interface';

@Component({
  selector: 'lib-manage-placeholder-screen',
  templateUrl: './manage-placeholder-screen.component.html',
  styleUrls: ['./manage-placeholder-screen.component.scss'],
})
export class ManagePlaceholderScreenComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  dataSourceDefinition: IDataSourceDefinition;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        const preferenceResolvedData: IPreferenceResolvedData =
          data.preferenceResolvedData;

        if (preferenceResolvedData) {
          this.dataSourceDefinition =
            preferenceResolvedData.dataSourceDefinition;
        } else {
          this.dataSourceDefinition = null;
        }

        console.log(preferenceResolvedData);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
