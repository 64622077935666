import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FiltersChannel } from '../../../models/filters-channel.model';
import { Filters } from '../../../models/filters.model';
import { FiltersSidenavService } from '../../../services/filters-sidenav.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'suvo-bi-active-filters-widget',
  templateUrl: './active-filters-widget.component.html',
  styleUrls: ['./active-filters-widget.component.scss'],
})
export class ActiveFiltersWidgetComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  @Input() filtersChannel: FiltersChannel;
  activeFilters: Filters;

  filtersSidebarOpen = false;
  showFiltersToggle = false;

  constructor(
    private readonly filtersSidenavService: FiltersSidenavService,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.unsubscribe$.next(true);
    this.subscribe();
  }

  subscribe(): void {
    this.filtersChannel?.filters
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((filters: Filters) => {
        this.activeFilters = filters;
      });

    this.filtersSidenavService.sidenavSubject
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((state) => {
        console.log('state', state);
        this.filtersSidebarOpen = state.open;
      });

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((result) => {
        if (result.matches) {
          this.showFiltersToggle = true;
        } else {
          this.showFiltersToggle = false;
          this.filtersSidenavService.open();
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnChanges(): void {
    this.unsubscribe$.next(true);
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  toggleSidenav(): void {
    this.filtersSidenavService.toggle();
  }
}
