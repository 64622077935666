<div class="card" [style.background]="tileDefinition.background">
  <div class="main-container">
    <ng-container *ngFor="let contact of tileDefinition.contacts; let last = last">
      <div class="person-container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center">
        <div
          class="image-container"
          fxFlex="0 0 250px"
          fxLayoutAlign.lt-md="center center"
          [style.order]="contact.imgPosition === 'start' ? '0' : '1'"
          [style.justifyContent]="contact.imgPosition"
        >
          <div class="image-circle">
            <img class="image" [src]="contact.img" />
          </div>
        </div>
        <div
          class="text-container"
          fxFlex="0 0 70%"
          fxLayout="column"
          fxLayoutAlign.lt-md="center center"
        >
          <div class="name-container" fxLayout="column" fxLayoutAlign.lt-md="center center">
            <span
              class="name"
              [style.color]="contact.fullNameFontColor"
              [style.fontSize]="contact.fullNameFontSize | pxSuffix"
            >
              {{ contact.fullName }}
            </span>
            <span
              class="title"
              [style.color]="contact.roleFontColor"
              [style.fontSize]="contact.roleFontSize | pxSuffix"
            >
              {{ contact.role }}
            </span>
          </div>
          <div class="about-container">
            <p
              class="about"
              [style.color]="contact.textFontColor"
              [style.fontSize]="contact.textFontSize | pxSuffix"
            >
              {{ contact.text }}
            </p>
          </div>
          <div class="contact-container" fxLayout="row">
            <div *ngIf="contact.phone" class="phone-number-container">
              <mat-icon class="contact-icon">phone</mat-icon>
              <a class="tel" [href]="'tel:' + contact.phone">
                {{ contact.phone }}
              </a>
            </div>
            <div class="email-container">
              <mat-icon class="contact-icon">email</mat-icon>
              <a class="email" [href]="'mailto:' + contact.email">
                {{ contact.email }}
              </a>
            </div>
          </div>
          <mat-divider *ngIf="!last" class="divider"></mat-divider>
        </div>
      </div>
    </ng-container>
  </div>
</div>
