import { Component, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { IMapTileDefinition } from '../../../dashboards/interfaces/tiles/definitions/map-tile-definition.interface';
import { TileCreatorService } from '../../services/tile-creator.service';
import { TILE_REQUEST } from '../../tokens/tile-request.token';

@Component({
  selector: 'app-map-setup-wizard',
  templateUrl: './map-setup-wizard.component.html',
  styleUrls: ['./map-setup-wizard.component.scss'],
})
export class MapSetupWizardComponent implements OnInit {
  tileDefinition: IMapTileDefinition;

  public firstStepSetupForm: FormGroup;

  public dataSourceObject;

  public get titleControl(): AbstractControl {
    return this.firstStepSetupForm.get('title') as AbstractControl;
  }

  public get dataSourceControl(): AbstractControl {
    return this.firstStepSetupForm.get('dataSource') as AbstractControl;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly tileCreatorService: TileCreatorService
  ) {
    this.tileDefinition = inject(TILE_REQUEST).tileDefinition;
  }

  public ngOnInit(): void {
    this.initSetupForms();
  }

  public initSetupForms(): void {
    this.firstStepSetupForm = this.fb.group({
      title: ['', [Validators.required]],
      dataSource: ['', [Validators.required]],
    });

    if (this.tileDefinition) {
      this.firstStepSetupForm.patchValue({
        title: this.tileDefinition.title,
        dataSource: this.tileDefinition.recipe.dataSource,
      });
    }
  }

  public initDataSource(dataSource: any): void {
    this.dataSourceObject = dataSource;
  }

  public onCreate(): void {
    Object.assign(this.tileDefinition, {
      tileType: 'map',
      title: this.titleControl.value,
      recipe: {
        dataSource: this.dataSourceControl.value,
      },
      canView: 'true',
      mapOptions: {
        canScroll: true,
      },
    });
    this.tileCreatorService.onChange.next();
  }
}
