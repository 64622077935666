import { Injectable } from '@angular/core';
import { OrganisationRoleModel } from '@suvo-bi-users';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageBiOrganisationsService {
  selectedSettingsOrgRoleSubject: BehaviorSubject<OrganisationRoleModel> = new BehaviorSubject<OrganisationRoleModel>(null);

  constructor() {}
}
