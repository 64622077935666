<div class="padding">

  <ng-container [ngSwitch]="organisationListLoaded">
    <div class="org-select-row">
      <!-- Select Organisation -->
      <mat-form-field *ngSwitchCase="'loaded'" appearance="outline">
        <mat-label>Organisation</mat-label>
        <mat-select [formControl]="selectedOrganisation" (selectionChange)="selectOrganisation()" matNativeControl
          required>
          <ng-container *ngFor="let organisation of organisationsList">
            <mat-option [value]="organisation">{{ organisation.organisationName }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="createOrganisation()">Create Organisation</button>
    </div>


    <ng-container *ngSwitchCase="'loading'">
      <suvo-core-loading-spinner message="Loading Organisation"></suvo-core-loading-spinner>
    </ng-container>
  </ng-container>

  <!-- Organisation -->
  <!-- <p *ngIf="selectedOrganisation?.value?.organisationName"> {{ selectedOrganisation.value.organisationName }} ?</p> -->
  <suvo-users-card-entry label="Organisation Name" [entryControl]="selectedOrganisationName"
    (entrySaved)="updateOrganisation()" [forbidEdit]="selectedOrganisation?.value?.role !== 'ADMIN'">
  </suvo-users-card-entry>

  <div>
    <div class="org-select-row">
      <h4 class="margin">Members</h4>
      <button mat-raised-button color="primary" *ngIf="selectedOrganisation?.value?.role === 'ADMIN'"
        [disabled]="!selectedOrganisation.value?.organisationId" (click)="openInvitationDialog()">Add Member</button>
    </div>
    <div [ngSwitch]="memberListLoaded">
      <ng-container *ngSwitchCase="'loaded'">
        <div class="member-list-row">
          <div>
            <p><strong>Name</strong></p>
          </div>
          <div>
            <p><strong>Role</strong></p>
          </div>
        </div>
        <div *ngFor="let member of selectedOrganisationMembers" class="member-list-row">
          <div>
            <p>{{member.publicUserProfile.firstName}} {{member.publicUserProfile.lastName}}</p>
          </div>
          <div>
            <p>{{member.role}}</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'loading'">
        <suvo-core-loading-spinner message="Loading Organisation Members"></suvo-core-loading-spinner>
      </ng-container>
    </div>
  </div>



  <!-- <ng-container [ngSwitch]="organisationLoaded">

    <ng-container *ngSwitchCase="'loaded'">
      <p>Organisation!</p>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
      <suvo-core-loading-spinner message="Loading Organisation Info"></suvo-core-loading-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'none'">
      <p>You aren't in any organisations!</p>
    </ng-container>

  </ng-container> -->

</div>