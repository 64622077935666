<div fxLayout="column" fxLayoutAlign="flex-start stretch">
  <!-- Edit Toolbar -->
  <div *ngIf="editMode" class="edit-toolbar">
    <div class="edit-inputs-container" [formGroup]="gridForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ displayNameLabel ? displayNameLabel : 'Display Name' }}</mat-label>
        <input matInput formControlName="displayName" />
        <mat-error *ngIf="displayNameControl.errors?.required && displayNameControl.touched">
          Display Name is required
        </mat-error>
      </mat-form-field>
      <br />
      <!-- <mat-form-field appearance="outline">
        <mat-label>Short Code</mat-label>
        <input matInput formControlName="shortCode" />
        <mat-error *ngIf="shortCodeControl.errors?.required && shortCodeControl.touched">
          Short Code is required
        </mat-error>
      </mat-form-field> -->
    </div>

    <div fxFlex="1 1" fxLayout="row wrap" class="edit-buttons-container">
      <!-- <button
        fxFlex="1 0"
        mat-stroked-button
        color="primary"
        *ngIf="settings.canEditJson"
        (click)="editJson()"
      >
        <mat-icon>code</mat-icon>
        Json
      </button> -->
      <!-- <button fxFlex="1 0" mat-flat-button color="basic" disabled="true">
        <mat-icon>undo</mat-icon>
        Undo
      </button>
      <button fxFlex="1 0" mat-flat-button color="basic" disabled="true">
        <mat-icon>redo</mat-icon>
        Redo
      </button> -->
      <button
        fxFlex="1 0"
        mat-flat-button
        color="warn"
        [disabled]="!changesUnsaved"
        (click)="discardChanges()"
      >
        <mat-icon>undo</mat-icon>
        Revert
      </button>
      <ng-container *ngIf="saveMode != 'draftsAndPublish'">
        <button
          fxFlex="1 0"
          mat-flat-button
          color="primary"
          [disabled]="(!isCreation && !changesUnsaved) || gridForm.invalid"
          (click)="saveChanges(false)"
        >
          <mat-icon>save</mat-icon>
          {{ isCreation ? 'Create' : 'Save' }}
        </button>
      </ng-container>
      <ng-container *ngIf="saveMode == 'draftsAndPublish'">
        <button
          fxFlex="1 0"
          mat-flat-button
          color="primary"
          [disabled]="(!isCreation && !changesUnsaved) || gridForm.invalid"
          (click)="saveChanges(false)"
        >
          <mat-icon>save</mat-icon>
          {{ isCreation ? 'Create' : 'Save draft' }}
        </button>
        <button
          fxFlex="1 0"
          mat-flat-button
          color="primary"
          [disabled]="(!isCreation && !changesUnsaved) || gridForm.invalid"
          (click)="saveChanges(true)"
        >
          <mat-icon>publish</mat-icon>
          {{ isCreation ? 'Create' : 'Save & Publish' }}
        </button>
      </ng-container>
    </div>

    <!-- <div fxFlex="1 1" fxLayout="row wrap" class="edit-buttons-container">
      <button
        fxFlex="1 0"
        mat-flat-button
        color="basic"
        class="padding-btn"
        *ngIf="settings.canEditJson"
        (click)="toggleDisplayProperty('padding', grid)"
        [class.active]="!!grid?.displayProperties?.padding"
      >
        <mat-icon>padding</mat-icon>
        Padding
      </button>
      <button
        fxFlex="1 0"
        mat-flat-button
        color="basic"
        class="public-btn"
        (click)="toggleDashboardProperty('public')"
        [class.active]="grid?.public"
      >
        <mat-icon>lock_open</mat-icon>
        Public
      </button>
    </div> -->
  </div>

  <!-- SECTIONS CONTAINER -->

  <div
    cdkDropList
    [cdkDropListDisabled]="!editMode"
    [cdkDropListData]="grid.sections"
    (cdkDropListDropped)="sectionDropped($event)"
    *ngIf="grid?.sections; else errorSection"
    fxLayout="column"
    fxLayoutAlign="flex-start stretch"
  >
    <!-- SECTION -->

    <div cdkDrag *ngFor="let section of grid.sections; let sectionIndex = index">
      <!-- ROWS CONTAINER -->
      <div
        id="Section{{ sectionIndex }}"
        [cdkDropListConnectedTo]="sectionConnectedListIds"
        cdkDropList
        [cdkDropListDisabled]="!editMode"
        cdkDropListOrientation="vertical"
        [cdkDropListData]="section.rows"
        (cdkDropListDropped)="rowDropped($event)"
        fxLayout="column"
        fxLayoutAlign="flex-start stretch"
      >
        <!-- ROW -->
        <div
          cdkDrag
          *ngFor="let row of section.rows; let rowIndex = index"
          class="row-container edit-hover-background full-width"
          fxLayout="column"
          fxLayoutAlign="flex-start stretch"
        >
          <!-- ROW EDIT HEADER -->
          <div
            *ngIf="editMode"
            class="edit-row-toolbar"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <!-- DRAG ROW -->
            <div fxLayout="row" fxLayoutAlign="flex-start center">
              <button cdkDragHandle mat-icon-button matTooltip="Drag row">
                <mat-icon>drag_handle</mat-icon>
              </button>
            </div>

            <div></div>

            <!-- REMOVE ROW -->
            <div fxLayout="row" fxLayoutAlign="flex-end center">
              <button
                mat-icon-button
                class="row-edit-action"
                (click)="removeRow(sectionIndex, rowIndex)"
                matTooltip="Delete row"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>

          <!-- ROW TILES -->
          <div
            id="Row{{ rowIndex }}"
            cdkDropList
            [cdkDropListDisabled]="!editMode"
            [cdkDropListConnectedTo]="rowConnectedListIds"
            [cdkDropListOrientation]="rowDropListOrientation"
            [cdkDropListData]="row.columns"
            (cdkDropListDropped)="tileDropped($event)"
            [cdkDropListEnterPredicate]="tileEnterPredicate"
            class="row-tiles-container"
            fxLayout="row wrap"
            fxLayout.lt-md="column"
            fxLayoutAlign="stretch stretch"
          >
            <ng-container *ngFor="let column of row.columns; let columnIndex = index">
              <!-- TILE -->
              <div
                cdkDrag
                class="slim-padding edit-hover-background tile-edit"
                [class.hidden-tile]="!editMode && !!column.tileDefinition?.meta?.hidden"
                [class.no-padding]="!column.tileDefinition?.displayProperties?.padding"
                fxFlex="1 1 0px"
                fxLayout="column"
                fxLayoutAlign="stretch stretch"
              >
                <!-- TILE EDIT HEADER -->
                <div
                  *ngIf="editMode"
                  fxFlex="0 0 40px"
                  class="edit-tile-toolbar"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div fxLayout="row" fxLayoutAlign="flex-start center">
                    <button
                      cdkDragHandle
                      mat-icon-button
                      class="edit-hidden-button"
                      matTooltip="Drag tile"
                    >
                      <mat-icon>drag_handle</mat-icon>
                    </button>
                    <p class="edit-label">
                      {{ (column.tileDefinition?.tileType | titlecase) || 'Tile' }}
                    </p>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="flex-end center">
                    <button
                      matTooltip="Render within card"
                      mat-icon-button
                      class="edit-hidden-button card-btn"
                      (click)="
                        toggleColumnDisplayProperty('isCard', sectionIndex, rowIndex, columnIndex)
                      "
                      [class.active]="!!column.tileDefinition?.displayProperties?.isCard"
                    >
                      <mat-icon>check_box_outline_blank</mat-icon>
                    </button>

                    <button
                      matTooltip="Add padding"
                      mat-icon-button
                      class="edit-hidden-button padding-btn"
                      (click)="
                        toggleColumnDisplayProperty('padding', sectionIndex, rowIndex, columnIndex)
                      "
                      [class.active]="!!column.tileDefinition?.displayProperties?.padding"
                    >
                      <mat-icon>padding</mat-icon>
                    </button>

                    <button
                      matTooltip="Edit tile"
                      mat-icon-button
                      class="edit-hidden-button"
                      (click)="openColumnEditDialog(sectionIndex, rowIndex, columnIndex)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button
                      matTooltip="Edit tile JSON (Advanced)"
                      mat-icon-button
                      class="edit-hidden-button"
                      (click)="editColumnJson(sectionIndex, rowIndex, columnIndex)"
                    >
                      <mat-icon>code</mat-icon>
                    </button>

                    <button
                      matTooltip="Delete tile"
                      mat-icon-button
                      class="edit-hidden-button"
                      (click)="removeColumnFromRow(sectionIndex, rowIndex, columnIndex)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>

                <ng-container *ngIf="column.tileDefinitions">
                  <ng-container *ngFor="let tileDefinition of column.tileDefinitions">
                    <ng-container
                      [ngTemplateOutlet]="tileOutlet"
                      [ngTemplateOutletContext]="{ $implicit: tileDefinition }"
                    ></ng-container>
                  </ng-container>
                </ng-container>

                <ng-template
                  *ngIf="
                    column.tileDefinition && (editMode || column.tileDefinition?.tileType !== 'new')
                  "
                  [ngTemplateOutlet]="tileOutlet"
                  [ngTemplateOutletContext]="{ $implicit: column.tileDefinition }"
                >
                  <!-- TILE WRAPPER -->
                  <!-- TILE WRAPPER -->
                  <!-- TILE WRAPPER -->
                </ng-template>
              </div>
            </ng-container>

            <!-- EDIT ADD TILE TO ROW -->

            <div *ngIf="editMode" class="edit-add-tile-container">
              <button
                matTooltip="Add column"
                mat-button
                class="edit-add-tile"
                [disabled]="row.columns.length >= settings.maxColumnCount"
                (click)="addColumnToRow(sectionIndex, rowIndex)"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- EDIT ADD ROW AT END-->
        <div *ngIf="editMode" fxFlex="0 0 64px" fxLayout="column" fxLayoutAlign="center center">
          <button
            mat-flat-button
            class="new-button row-edit-action"
            (click)="addRow(sectionIndex, null)"
          >
            <mat-icon>add</mat-icon>
            Add Row
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #errorSection>
    <!-- TODO: Fix 'erorr' typo -->
    <suvo-core-error-message
      title="No grid sections"
      message="Provided model not fit for grid intepretation."
    ></suvo-core-error-message>
  </ng-template>
</div>
