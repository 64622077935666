<div class="nosub-card" fxFlex="1 1" fxLayout="column" fxLayoutAlign="space-between stretch">

  <!-- <div fxLayoutAlign="flex-end flex-end">
    <button class="close-button" mat-icon-button (click)="tileDefinition.hidden=true">
      <mat-icon>close</mat-icon>
    </button>
  </div> -->


  <div class="text-container" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">

    <!-- <img src="assets/private/shapes.svg" class="shapes"> -->

    <span class="title">This tile requires a subscription that you do not have.</span>
    <div>
      <span class="data-source">{{ dataSource | titlecase }} </span>
      <span class="feature highlight blue">{{ feature }}</span>
    </div>
    <a class="link" routerLink="/private/my-account/subscription">My Subscriptions</a>

  </div>

</div>