import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FilterOperatorType } from '../../../../../shared/interfaces/filters/filter-operator-type.enum';
import { ITableDataSource } from '../../../../../shared/interfaces/table/table-data-source.interface';
import { SnackBarService } from '../../../../../shared/services/snack/snack-bar.service';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';
import { Filters } from '../../../models/filters.model';
import { FiltersChannel } from '../../../models/filters-channel.model';
import { FiltersApiService } from '../../../services/filters-api.service';
import { FiltersSidenavService } from '../../../services/filters-sidenav.service';
import { FiltersUtilityService } from '../../../services/filters-utility.service';
import { EditFiltersNameDialogComponent } from '../../dialogs/edit-filters-name-dialog/edit-filters-name-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../../../dashboards/components/dialogs/delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'suvo-bi-select-filters',
  templateUrl: './select-filters.component.html',
  styleUrls: ['./select-filters.component.scss'],
})
export class SelectFiltersComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();

  @Input() filtersChannel: FiltersChannel;
  @Input() showNoneOption: boolean;
  @Input() createEvent: EventEmitter<boolean>;
  @Output() close = new EventEmitter();
  @Output() filtersName: string;

  selectedFiltersOptions: IFilters[];
  selectedFilters: IFilters;
  activeDialogRef: MatDialogRef<unknown>;

  constructor(
    private readonly filtersSidenavService: FiltersSidenavService,
    private readonly snackBarService: SnackBarService,
    private readonly filtersApiService: FiltersApiService,
    private readonly filtersUtilityService: FiltersUtilityService,
    private readonly matDialog: MatDialog,
  ) {}

  selected(filters: IFilters) {
    this.filtersChannel.switchSelection(filters);
    this.close.emit();
  }

  selectNone() {
    this.filtersChannel?.switchSelection();
    this.close.emit();
  }

  goBack() {
    this.close.emit();
  }

  ngOnInit(): void {
    // Sidenav Changes.

    this.filtersSidenavService.sidenavSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state.open) {
          this.close.emit();
        }
      });

    this.channelSwitched(this.filtersChannel);
    // Create clicked from externally.

    this.createEvent?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.createFilters(this.filtersChannel?.channelName);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.activeDialogRef?.close();
  }

  channelSwitched(filtersChannel: FiltersChannel) {
    if (filtersChannel) {
      this.filtersChannel = filtersChannel;
      this.selectedFilters = filtersChannel.selectedFilters;

      filtersChannel.selectedFiltersSubject
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((selectedFilters) => {
          this.selectedFilters = selectedFilters;
        });

      // Don't show filters which have a temporary version.

      filtersChannel.selectedFiltersOptionsSubject
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((selectedFiltersOptions) => {
          this.selectedFiltersOptions = selectedFiltersOptions.sort((a, b) => {
            return a.updatedAt > b.updatedAt ? -1 : 1;
          });
        });
    } else {
      this.filtersChannel = null;
      this.selectedFilters = null;
    }
  }
  /*  Edit Actions
   */

  openNameAction(
    title: string,
    actionText: string,
    actionIcon: string,
    prefillName?: string,
  ): Observable<string> {
    this.activeDialogRef?.close();

    const dialog = this.matDialog.open(EditFiltersNameDialogComponent, {
      data: { title, actionText, actionIcon, prefillName },
    });

    this.activeDialogRef = dialog;

    return dialog.afterClosed().pipe(take(1));
  }

  renameFilters(filters: IFilters) {
    this.openNameAction('Rename Filters', 'Rename', 'edit', filters.name).subscribe((name) => {
      if (name) {
        filters.name = name;
        this.filtersApiService.patchFilters(this.filtersChannel.channelName, filters);
      }
    });
  }

  async duplicateFilters(filters: IFilters) {
    const duplicateNameSuggestion = filters.name + ' Copy';

    this.openNameAction(
      'Duplicate Filters',
      'Create',
      'content_copy',
      duplicateNameSuggestion,
    ).subscribe(async (name) => {
      if (name) {
        const filtersChannel = this.filtersChannel;
        const newFiltersDto = {
          ...filters,
          name,
          type: 'GENERAL',
          _id: undefined,
        };
        await this.filtersApiService.createFilters(filtersChannel.channelName, newFiltersDto);

        filtersChannel?.addSavedFilters([filters]);

        this.updateChannelFilterOptions(filtersChannel);
      }
    });
  }

  async createFilters(alias: string) {
    const filtersName = this.filtersUtilityService.generateDefaultFiltersName(
      alias,
      this.selectedFiltersOptions,
    );

    this.openNameAction('Create Filters', 'Create', 'add', filtersName).subscribe(async (name) => {
      if (name) {
        const filtersChannel = this.filtersChannel;
        const newFiltersDto = {
          name,
          type: 'GENERAL',
          query: { op: FilterOperatorType.And, rules: [] },
          entity: filtersChannel.channelName,
        };

        await this.filtersApiService.createFilters(filtersChannel.channelName, newFiltersDto);

        filtersChannel?.addSavedFilters([newFiltersDto]);

        this.updateChannelFilterOptions(filtersChannel);
      }
    });
  }

  private prompt(prompt: string, fn: () => Promise<unknown>) {
    this.matDialog
      .open(DeleteConfirmationDialogComponent, {
        data: { confirmationText: prompt },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (data) => {
        if (data.confirmed) {
          try {
            await fn();
          } catch (err) {
            this.snackBarService.open(
              'Your request could not be processed. Please try again later.',
            );
            console.log(err);
          }
        }
      });
  }

  async deleteFilters(filters: IFilters) {
    this.prompt('Are you sure you want to delete this filter?', async () => {
      const filtersChannel = this.filtersChannel;
      await this.filtersApiService.deleteFilters(filtersChannel.channelName, filters);
      filtersChannel?.removeSavedFilter(filters);
      this.updateChannelFilterOptions(filtersChannel);
      this.snackBarService.open('Filter successfully deleted.');
    });
  }

  async updateChannelFilterOptions(filtersChannel: FiltersChannel) {
    let filters: ITableDataSource<IFilters> | void =
      await this.filtersApiService.getPaginatedFilters(
        filtersChannel.channelName,
        null,
        null,
        null,
      );

    const sessionSelectedFilterId = sessionStorage.getItem(
      `current_filters_${filtersChannel.channelName}`,
    );

    if (filters.data.length === 0) {
      const filtersName = this.filtersUtilityService.generateDefaultFiltersName(
        filtersChannel.channelName,
        filters.data,
      );

      let newFilters = new Filters({
        name: filtersName,
        type: 'GENERAL',
        entity: filtersChannel.channelName,
        query: {
          op: 'AND',
          rules: [],
        },
      });

      await this.filtersApiService.createFilters(filtersChannel.channelName, newFilters);

      filters.data.unshift(newFilters);

      filtersChannel.selectedFilterId = newFilters._id;
    }

    await filtersChannel.addSavedFilters(filters ? filters.data : null);
  }
}
