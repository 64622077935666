import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { OrganisationInvitationValidationComponent } from '@suvo-bi-users';
import { LoginPageComponent } from './login/login-page.component';
import { NewShopComponent } from './new-shop';
import { PublicComponent } from './public.component';
import { RegisterPageComponent } from './register/register-page.component';
import { VerifyPageComponent } from './verify/verify-page.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'register', component: RegisterPageComponent },
      { path: 'register/:token/:email', component: RegisterPageComponent },
      { path: 'login', component: LoginPageComponent },
      { path: 'login/:token/:email', component: LoginPageComponent },
      { path: 'invites', component: OrganisationInvitationValidationComponent },
      { path: 'verify', component: VerifyPageComponent },
    ],
  },

  {
    path: 'new-shop',
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: () => redirectLoggedInTo(['/shops']) },
    component: NewShopComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
