import { Component, Input } from '@angular/core';
import { FiltersChannel } from '../../../../../filters/models/filters-channel.model';
import { Filters } from '../../../../../filters/models/filters.model';
import { IStatsTileDefinition } from '../../../../interfaces/tiles/definitions/stats-tile-definition.interface';

@Component({
  selector: 'suvo-bi-stats-tile',
  template: `
    <mat-card>
      <mat-card-header *ngIf="tileDefinition?.title" style="padding: 16px">
        <mat-card-title>{{ tileDefinition.title }}</mat-card-title>
      </mat-card-header>
      <mat-card-content style="display: flex">
        <div
          *ngFor="let statDefinition of tileDefinition.statDefinitions"
          style="flex: 1"
        >
          <suvo-bi-stat [statDefinition]="statDefinition"></suvo-bi-stat>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class StatsTileComponent {
  @Input() tileDefinition: IStatsTileDefinition;
  @Input() filtersChannel: FiltersChannel;

  filters: Filters;
}
