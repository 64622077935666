import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Dashboard } from '../../../models/dashboard.model';
import { DashboardService } from '../../../services/dashboards.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-delete-option-choice',
  templateUrl: './delete-option-choice.component.html',
  styleUrls: ['./delete-option-choice.component.scss'],
})
export class DeleteOptionChoiceDialogComponent {
  constructor(
    private readonly matDialogRef: MatDialogRef<DeleteOptionChoiceDialogComponent>,
    private readonly matDialog: MatDialog,
    private readonly dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: { dashboard: Dashboard },
    private readonly router: Router
  ) {}

  public onDeleteCurrentDraft(): void {
    this.matDialogRef.close();

    const confirmationDialog = this.matDialog.open(
      DeleteConfirmationDialogComponent,
      {
        data: {
          confirmationText: 'Are you sure you want to delete current draft?',
        },
      }
    );
    confirmationDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data.confirmed) {
          this.dashboardService
            .deleteDraft(this.data.dashboard._id)
            .then(() => {
              this.router.navigate(['/', 'dashboards']);
            });
        }
      });
  }

  public onDeleteAllDrafts(): void {
    this.matDialogRef.close();

    const confirmationDialog = this.matDialog.open(
      DeleteConfirmationDialogComponent,
      {
        data: {
          confirmationText: 'Are you sure you want to delete all drafts?',
        },
      }
    );
    confirmationDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data.confirmed) {
          this.dashboardService
            .deleteAllDrafts(this.data.dashboard.baseId)
            .then(() => {
              this.router.navigate(['/', 'dashboards']);
            });
        }
      });
  }
}
