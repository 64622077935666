import { Injectable } from '@angular/core';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { CustomDomainModel } from '../models/custom-domain.model';

@Injectable({
  providedIn: 'root',
})
export class CustomDomainService extends CommonService<CustomDomainModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.usersServiceApi, 'custom-domain');
  }
}
