<mat-stepper linear #stepper>
  <mat-step [stepControl]="firstStepSetupForm" [formGroup]="firstStepSetupForm" [editable]="true">
    <ng-template matStepLabel>Main Data</ng-template>

    <div class="inputs-container">
      <mat-form-field appearance="fill">
        <mat-label>Heading</mat-label>
        <input matInput formControlName="heading" />
        <mat-error *ngIf="headingControl.hasError('required')">Heading is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Sub Heading</mat-label>
        <input matInput formControlName="subHeading" />
        <mat-error *ngIf="subHeadingControl.hasError('required')">Sub Heading is required</mat-error>
      </mat-form-field>

      <app-data-source-selector
        formControlName="displayFeatureTotal"
        (onDataSourceChange)="initDataSource($event)"
      ></app-data-source-selector>

      <mat-form-field appearance="fill">
        <mat-label>Heading Icon</mat-label>
        <input matInput formControlName="headingIcon" />
        <mat-error *ngIf="headingIconControl.hasError('required')">Heading Icon is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Image URI</mat-label>
        <input matInput formControlName="imgUri" />
        <mat-error *ngIf="imgUriControl.hasError('required')">Image URI is required</mat-error>
      </mat-form-field>
    </div>

    <div>
      <button mat-button matStepperNext (click)="firstStepSetupForm.markAllAsTouched()">Next</button>
    </div>
  </mat-step>

  <mat-step [stepControl]="secondStepSetupForm" [formGroup]="secondStepSetupForm">
    <ng-template matStepLabel>Link Buttons</ng-template>

    <ng-container *ngFor="let linkButton of linkButtonsControl.controls; let i = index">
      <div [formGroup]="linkButton">
        <div class="inputs-container">
          <mat-form-field appearance="fill">
            <mat-label>Text</mat-label>
            <input matInput formControlName="buttonText" />
            <mat-error *ngIf="linkButton.get('buttonText').hasError('required')">Text is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Icon</mat-label>
            <input matInput formControlName="buttonIcon" />
            <mat-error *ngIf="linkButton.get('buttonIcon').hasError('required')">Icon is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Color Theme</mat-label>
            <input matInput formControlName="buttonColorTheme" />
            <mat-error *ngIf="linkButton.get('buttonColorTheme').hasError('required')">
              Color Theme is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Route Link</mat-label>
            <input matInput formControlName="routeLink" />
            <mat-error *ngIf="linkButton.get('routeLink').hasError('required')">Route Link is required</mat-error>
          </mat-form-field>

          <mat-icon class="delete-btn" (click)="removeLinkButton(i)">delete_forever</mat-icon>
        </div>
      </div>
    </ng-container>

    <button class="add-link-btn" mat-raised-button color="primary" (click)="addLinkButton()">Add Link Button</button>

    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext (click)="secondStepSetupForm.markAllAsTouched()">Next</button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Confirm</ng-template>
    <p>Title: {{ headingControl.value }}</p>
    <p>Sub Heading: {{ this.subHeadingControl.value }}</p>
    <p>Display Feature Total: {{ dataSourceObject?.name }}</p>
    <p>Heading Icon: {{ headingIconControl.value }}</p>
    <p>Image URI: {{ imgUriControl.value }}</p>

    <div *ngFor="let linkButton of linkButtonsControl.controls">
      <span>Text: {{ linkButton.get('buttonText').value }}</span>
      <span>Icon: {{ linkButton.get('buttonIcon').value }}</span>
      <span>Color Theme: {{ linkButton.get('buttonColorTheme').value }}</span>
      <span>Route Link: {{ linkButton.get('routeLink').value }}</span>
    </div>

    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="onCreate()">Apply</button>
    </div>
  </mat-step>
</mat-stepper>
