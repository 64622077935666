import { NgModule } from '@angular/core';
import { ApiService } from './shared/services/api/api.service';
import { CommonService } from './shared/services/common/common.service';
import { SuvoCoreClientLibComponent } from './suvo-core-client-lib.component';
import { ErrorListComponent } from './features/error-list/components/error-list/error-list.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [SuvoCoreClientLibComponent, ErrorListComponent],
  imports: [CommonModule],
  providers: [ApiService, CommonService],
  exports: [SuvoCoreClientLibComponent, ErrorListComponent],
})
export class SuvoCoreClientLibModule {}
