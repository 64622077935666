<form [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>Title</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>

  <app-data-source-selector
    formControlName="dataSource"
    (onDataSourceChange)="initTableShowColumnsOptions($event)"
  ></app-data-source-selector>

  <div>
    <mat-checkbox class="example-margin" formControlName="showPageControls"
      >Show page controls</mat-checkbox
    >
    <br />
    <mat-checkbox class="example-margin" formControlName="showPageSizeOptions"
      >Show page size options</mat-checkbox
    >
  </div>
  <br />

  <h2>Table Columns</h2>

  <div class="table-columns">
    <mat-checkbox
      *ngFor="let option of tableShowColumnsOptions"
      class="example-margin"
      (change)="onChange($event)"
      [value]="option.name"
      [checked]="isChecked(option.name)"
    >
      {{ option.displayName }}
    </mat-checkbox>
  </div>
</form>

<div style="margin: 12px 0; text-align: right">
  <suvo-error-list [errors]="errors"></suvo-error-list>
  <button mat-flat-button color="primary" (click)="onCreate()">Apply</button>
</div>
