<div class="card chart-card" fxFlex="1 1" fxLayout="column" fxLayoutAlign="space-between stretch">
  <div class="row-padding chart-header no-padding-bottom" *ngIf="hasRestrictions">
    <suvo-bi-limited-data-banner
      [isTrial]="isTrialing"
      [featureShortCode]="'AGGREGATION'"
      [tags]="[tileDefinition.recipe?.dataSource]"
    ></suvo-bi-limited-data-banner>
  </div>
  <div
    class="row-padding chart-header"
    fxFlex="1 1 64px"
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
  >
    <suvo-bi-chart-title [title]="tileDefinition.title"></suvo-bi-chart-title>

    <!-- <button mat-icon-button disabled>
      <mat-icon>more_vert</mat-icon>
    </button> -->
    <suvo-bi-tile-filters-select
      *ngFor="let filter of tileDefinition.filterOptions"
      [localChartFilter]="filter"
      (filterChanged)="onChartLocalFilterChange($event)"
      [alias]="tileDefinition?.recipe?.dataSource"
    ></suvo-bi-tile-filters-select>
  </div>

  <div class="" fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch stretch">
    <!-- Skeleton Chart -->

    <ng-container *ngIf="isLoading; else chartLoaded">
      <div class="row-padding" fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch stretch">
        <ng-container *ngIf="tileDefinition.chartType.value === 'pie-chart'; else skeletonBars">
          <suvo-bi-skeleton-pie fxFlex="1 1"></suvo-bi-skeleton-pie>
        </ng-container>
        <ng-template #skeletonBars>
          <suvo-bi-skeleton-bars
            fxFlex="1 1"
            fxLayout="column"
            [barCount]="tileDefinition.recipe.maxGroups ?? 8"
            [direction]="
              tileDefinition.chartType.value.includes('horizontal') ? 'horizontal' : 'vertical'
            "
          >
          </suvo-bi-skeleton-bars>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #chartLoaded>
      <ng-container
        *ngIf="chartData.length; else noDataSection"
        [ngSwitch]="tileDefinition.chartType.value"
      >
        <!-- Bar Vertical -->
        <div
          *ngSwitchCase="'bar-vertical'"
          fxFlex="1 1 0"
          fxLayout="column"
          fxLayoutAlign="stretch stretch"
          class="chart-container"
        >
          <ngx-charts-bar-vertical
            class="chart"
            [scheme]="colorScheme"
            [results]="chartData"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [labelFormatting]="formatLabels && labelFormatting"
            [yAxisLabel]="yAxisLabel"
            [barPadding]="barPadding"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          >
          </ngx-charts-bar-vertical>
        </div>

        <!-- Bar Horizontal -->
        <div
          *ngSwitchCase="'bar-horizontal'"
          fxFlex="1 1 0"
          fxLayout="column"
          fxLayoutAlign="stretch stretch"
          class="chart-container"
        >
          <ngx-charts-bar-horizontal
            class="chart"
            [scheme]="colorScheme"
            [results]="chartData"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [labelFormatting]="formatLabels && labelFormatting"
            [yAxisLabel]="yAxisLabel"
            [barPadding]="barPadding"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          >
          </ngx-charts-bar-horizontal>
        </div>

        <!-- Pie Chart -->
        <div
          *ngSwitchCase="'pie-chart'"
          fxFlex="1 1 0"
          fxLayout="column"
          fxLayoutAlign="stretch stretch"
          class="chart-container"
        >
          <ngx-charts-pie-chart
            class="chart"
            [scheme]="colorScheme"
            [results]="chartData"
            [gradient]="gradient"
            [labels]="labels"
            [doughnut]="doughnut"
            [label]="label"
            [trimLabels]="trimLabels"
            [labelFormatting]="formatLabels && labelFormatting"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          >
          </ngx-charts-pie-chart>
        </div>

        <!-- No such chart type -->
        <suvo-core-error-message
          fxFlex="1 1"
          *ngSwitchDefault
          title="Chart Type Unsupported"
          [message]="tileDefinition.chartType.value"
        >
        </suvo-core-error-message>
      </ng-container>
      <ng-template #noDataSection>
        <suvo-core-icon-message
          fxFlex="1 1"
          title="No Chart Data"
          message="No data matches these filters."
        >
        </suvo-core-icon-message>
      </ng-template>
    </ng-template>
  </div>

  <div class="row-padding" fxFlex="1 1 " *ngIf="showLegend">
    <!-- Skeleton Legend -->

    <ng-container *ngIf="isLoading; else chartLegend">
      <suvo-bi-skeleton-legend
        [legendCount]="tileDefinition.recipe.maxGroups ?? 8"
      ></suvo-bi-skeleton-legend>
    </ng-container>
    <ng-template #chartLegend>
      <suvo-bi-chart-legend
        [legend]="legend"
        [colors]="realColors"
        (itemToggled)="updateVisibleChartData(true)"
      ></suvo-bi-chart-legend>
    </ng-template>
  </div>
</div>
