import { Injectable } from '@angular/core';
import { CommonService, ApiService } from '@suvo-bi-core';
import { BehaviorSubject } from 'rxjs';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { TenantRoleModel } from '../models/tenant-role.model';

@Injectable({ providedIn: 'root' })
export class TenantRoleService extends CommonService<TenantRoleModel> {
  /** @deprecated Use `CurrentTenantService.currentTenantRole()` Signal. */
  currentRoleSubject: BehaviorSubject<TenantRoleModel> = new BehaviorSubject<TenantRoleModel>(null);

  constructor(apiService: ApiService, settings: SuvoUsersClientLibSettingsService) {
    super(apiService, settings.usersServiceApi, '');
  }

  /** @deprecated */
  setCurrentRole(role: TenantRoleModel): void {
    this.currentRoleSubject.next(role);
  }

  /** @deprecated Use `CurrentTenantService.currentTenantRole()` Signal. */
  async getCurrentRole(): Promise<TenantRoleModel> {
    return this.currentRoleSubject.value;
  }

  /** @deprecated Use `CurrentTenantService.currentTenantRole()` Signal. */
  async loadRole(): Promise<TenantRoleModel> {
    if (this.currentRoleSubject.value) {
      return this.currentRoleSubject.value;
    }

    const role = await this.getOne('me/tenant-roles');
    this.setCurrentRole(role);
    return role;
  }

  /**
   * Delete the target user's tenant role for the current tenant.
   * Only really should be used to revoke admin roles.
   * @param firebaseUserId The target user's Firebase UID.
   */
  async deleteOne(firebaseUserId: string) {
    await this.delete(`tenant-role/${firebaseUserId}`);
  }
}
