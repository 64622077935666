import { Component, Input, OnInit } from '@angular/core';
import { ITourProgress } from '../../interfaces/tour-progress.interface';
import { TourService } from '../../services/tour.service';

@Component({
  selector: 'suvo-bi-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.scss']
})
export class TourCardComponent implements OnInit {

  @Input() currentProgress: ITourProgress = {
    currentIndex: 0,
    totalSteps: 0,
    step: {

    },
  };

  constructor(
    private readonly tourService: TourService,
  ) { }

  ngOnInit(): void {
  }

  navigatePrevious() {
    this.tourService.previousStep();
  }

  navigateNext() {
    this.tourService.nextStep();
  }

  navigateEnd() {
    this.tourService.endTour();
  }
}
