import { Component, Input } from '@angular/core';
import { CurrentTenantService } from '@suvo-bi-users';
import { IHeroBannerV3TileDefinition } from '../../../../interfaces/tiles/definitions/hero-banner-v3-tile-definition.interface';

@Component({
  selector: 'suvo-bi-hero-banner-v3-tile',
  templateUrl: './hero-banner-v3-tile.component.html',
  styleUrls: ['./hero-banner-v3-tile.component.scss'],
})
export class HeroBannerV3TileComponent {
  @Input() public tileDefinition: IHeroBannerV3TileDefinition;

  isRelative(url: string) {
    return /^(\/|\.\.?\/)/.test(url);
  }

  public get contentImgPath(): string {
    return this.isRelative(this.tenantService.currentTenantData.primaryWebUrl)
      ? `${this.tenantService.currentTenantData.primaryWebUrl}/${this.tileDefinition.contentImg}`
      : this.tileDefinition.contentImg;
  }

  constructor(private readonly tenantService: CurrentTenantService) {}

  scrollToElement(element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
