import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-organisation-users',
  templateUrl: './organisation-users.component.html',
  styleUrls: ['./organisation-users.component.scss']
})
export class OrganisationUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
