import { IFiltersSections } from '../interfaces/sections/filters-sections.interface';
import { CONTINENT_COUNTRIES } from './country-continents';
import { tendersProducts } from './tenders-products.constant';
import { tendersRegions } from './tenders-regions.constant';

let childProducts = new Set();
tendersProducts.forEach((p) => {
  childProducts[p.name] = p.items;
});

let tendersRegionCountries = new Set();
tendersRegions.forEach((r) => {
  if (r.items && r.items.length) {
    let items = JSON.parse(JSON.stringify(r.items));

    items.forEach((i) => {
      i.useParentFilterProperty = true;
    });

    tendersRegionCountries[r.name] = [...items];
  } else {
    tendersRegionCountries[r.name] = [];
  }

  if (r.name == 'Americas') {
    tendersRegionCountries[r.name].push(
      ...CONTINENT_COUNTRIES['North America'],
      ...CONTINENT_COUNTRIES['South America']
    );
  } else if (CONTINENT_COUNTRIES[r.name]) {
    tendersRegionCountries[r.name].push(...CONTINENT_COUNTRIES[r.name]);
  }
});

export const TENDERS_INFO_FILTERS_SECTIONS: IFiltersSections = {
  name: 'tenders',
  shortCode: 'tenders',
  sections: [
    {
      options: [
        {
          filterMethod: 'combined-nested-checkboxes',
          filterMethodConfig: {
            title: 'Location',
            showChildrenLabel: 'Show regions & countries',
            enableShowChildren: true,
            parentData: tendersRegions,
            parentKeyName: 'regionIds',
            parentLabel: 'region',
            childData: tendersRegionCountries,
            childKeyName: 'country',
            toggleGroupParentKey: 'value',
            toggleGroupChildKey: 'value',
            optionsColumns: 3,
          },
          filterPropertyKeys: ['regionIds', 'country'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'combined-nested-checkboxes',
          filterMethodConfig: {
            title: 'Products',
            showChildrenLabel: 'Show products',
            enableShowChildren: true,
            parentData: tendersProducts,
            parentKeyName: 'productIds',
            parentLabel: 'Product category',
            childData: childProducts,
            childKeyName: 'productIds',
            toggleGroupParentKey: 'value',
            toggleGroupChildKey: 'value',
          },
          filterPropertyKeys: ['productIds', 'productIds'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'keywords',
          filterMethodConfig: {
            title: 'Keywords',
            label: 'Any keywords',
          },
          filterPropertyKeys: ['keywords'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'between-dates',
          filterMethodConfig: {
            title: 'Publish Date',
          },
          filterPropertyKeys: ['publishDate'],
        },
        {
          filterMethod: 'between-dates',
          filterMethodConfig: {
            title: 'Deadline Date',
          },
          filterPropertyKeys: ['deadlineDate'],
        },
      ],
    },

    {
      options: [
        {
          filterMethod: 'radio',
          filterMethodConfig: {
            title: 'Bidding Type',
            options: [
              {
                name: 'Both',
                value: 0,
              },
              {
                name: 'Global',
                value: 1,
              },
              {
                name: 'Origin Country Only',
                value: 2,
              },
            ],
            defaultValue: 0,
          },
          filterPropertyKeys: ['globalId'],
        },
      ],
    },
    {
      options: [
        {
          filterMethod: 'checkbox',
          filterMethodConfig: {
            title:
              'Include Indian origin-country-only tenders? (Indian origin-country-only tenders are excluded by default due to large quantity)',
            defaultValue: false,
          },
          filterPropertyKeys: ['include_domestic_IN'],
        },
      ],
    },
  ],
};
