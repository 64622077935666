import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TileJsonDialogData } from '../../interfaces/tile-json-dialog-data.interface';

@Component({
  selector: 'suvo-bi-tile-json-dialog',
  templateUrl: './tile-json-dialog.component.html',
  styleUrls: ['./tile-json-dialog.component.scss']
})
export class TileJsonDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TileJsonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TileJsonDialogData
  ) {
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
