import { Injectable } from '@angular/core';
import {ITableOptions} from '../../../shared/interfaces/table/table-options.interface';
import {IReferenceProvider} from '../interfaces/reference-provider.interface';
import {IReferenceDefinition} from '../interfaces/reference-definition.interface';

@Injectable({
  providedIn: 'root'
})
export class ReferenceLookupService {

  constructor() { }

  referenceProviders: Map<String, {definition: IReferenceDefinition, provider: IReferenceProvider}> = new Map<String, {definition: IReferenceDefinition, provider: IReferenceProvider}>();

  getDefinition(alias){
    return this.getReferenceProvider(alias).definition;
  }

  getOne(alias: string, id: string){
    return this.getReferenceProvider(alias)?.provider?.getOne('', id);
  }

  getPaginated(alias: string, tableOptions: ITableOptions = null, prefixes: string){
    return this.getReferenceProvider(alias)?.provider?.getPaginated(prefixes, tableOptions, alias ? alias : '');
  }

  getReferenceProvider(alias){
    if(this.referenceProviders.has(alias)) {
      return this.referenceProviders.get(alias);
    } else {
      return this.referenceProviders.get("*");
    }
  }

  addReferenceProvider(alias: string, definition: IReferenceDefinition, provider: IReferenceProvider){
    this.referenceProviders.set(alias, {definition, provider});
  }
}
