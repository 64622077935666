<div
  class="lists-container"
  fxLayout="row"
  fxLayoutAlign="flex-start stretch"
  fxLayoutGap="8px"
>
  <ng-container *ngFor="let toggleGroup of toggleGroups">
    <div
      fxFlex="1 1"
      class="list-container"
      fxLayout="column"
      fxLayoutAlign="flex-start stretch"
    >
      <p class="parent-checkbox">
        <mat-checkbox
          [checked]="toggleGroup.checked"
          [indeterminate]="toggleGroup.indeterminate"
          (change)="parentCheckedChanged(toggleGroup, $event)"
          color="primary"
          >{{ toggleGroup.name }}</mat-checkbox
        >
      </p>
      <p
        class="child-checkbox"
        *ngFor="let child of toggleGroup.children; let i = index"
      >
        <mat-checkbox
          [formControl]="toggleGroup.getControl(child.name)"
          (change)="childCheckedChanged()"
          color="primary"
          >{{ child.name }}
        </mat-checkbox>
      </p>
    </div>
  </ng-container>
</div>
