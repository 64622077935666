import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../../shared/shared.module';
import { SkeletonTableRowsComponent } from './components/skeleton-table-rows/skeleton-table-rows.component';
import { TableComponent } from './components/table/table.component';
import { TableSearchComponent } from './components/table-search/table-search.component';



@NgModule({
  declarations: [
    TableComponent,
    SkeletonTableRowsComponent,
    TableSearchComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    TableComponent,
    SkeletonTableRowsComponent,
  ]
})
export class TablesModule { }
