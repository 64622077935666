import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { IFilters } from '../../../../../shared/interfaces/filters/filters.interface';
import { IFilterGroup } from '../../../../../shared/interfaces/filters/filter-group.interface';
import { FilterOperatorType } from '../../../../../shared/interfaces/filters/filter-operator-type.enum';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { FiltersChannel } from '../../../models/filters-channel.model';
import { Filters } from '../../../models/filters.model';
import { FiltersSections } from '../../../models/sections/filters-sections.model';
import { SnackBarService } from '../../../../../shared/services/snack/snack-bar.service';
import { FiltersApiService } from '../../../services/filters-api.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../../dashboards/components/dialogs/delete-confirmation/delete-confirmation.component';
import {FiltersOption} from '../../../models/sections/filters-option.model';
import {P} from '@angular/cdk/keycodes';

@Component({
  selector: 'suvo-bi-edit-filters',
  templateUrl: './edit-filters.component.html',
  styleUrls: ['./edit-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFiltersComponent implements OnInit {
  @Input() filtersChannel: FiltersChannel;
  @Input() autoSave = true;
  @Input() autoApplyFilters = false;
  @Input() verticalOnly = false;
  @Output() filtersManuallyApplied = new EventEmitter<boolean>();

  private readonly unsubscribe$ = new Subject<void>();

  rulesChangedDebounceSubject = new Subject<void>();

  filtersSections: FiltersSections;
  selectedFilters: IFilters;
  filters: IFilters;

  filtersSaved = true;
  filtersEmpty = true;
  filtersApplied = true;

  controlsDisabled = false;

  uuid: number;
  debugNumber = 0;

  constructor(
    private readonly filtersService: FiltersApiService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initialise(this.filtersChannel);
    this.rulesChangedDebounceSubject
      .pipe(
        map(() => {
          if (!this.rulesMatch(this.filters.query.rules, this.getRules())) {
            this.filtersSaved = false;
            this.filtersChannel.setUnsavedChanges(true);
          } else {
            this.filtersSaved = true;
            this.filtersChannel.setUnsavedChanges(false);
          }
          return;
        }),
        debounceTime(800),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.updateFilterRules();
      });
  }

  //TODO : Make this smarter
  rulesMatch(rulesA, rulesB) {
    return JSON.stringify(rulesA) == JSON.stringify(rulesB);
  }

  async ngAfterViewInit(): Promise<void> {
    // Channel Changed
  }

  pushToChannel(filters?: IFilters): void {
    this.filtersApplied = true;

    if (filters) {
      this.filtersChannel?.filters.next(new Filters(filters));
    } else {
      this.filtersChannel?.filters.next(new Filters(null));
    }

    this.changeDetectorRef.detectChanges();
  }

  updateFilters(filters: IFilters) {
    this.filtersApplied = false;
    this.filters = filters;

    if (this.autoApplyFilters) {
      this.pushToChannel(filters);
    } else {
      this.changeDetectorRef.detectChanges();
    }
  }

  async initialise(filtersChannel: FiltersChannel): Promise<void> {
    // this.filtersSections = null;
    this.filtersChannel = filtersChannel;

    if (filtersChannel !== this.filtersChannel) {
      return;
    }

    this.filtersSections = await this.filtersService.getFiltersSections(filtersChannel.channelName);

    filtersChannel.selectedFiltersSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedFilters) => {
        this.selectionSwitched(selectedFilters);
      });

    if (filtersChannel.selectedFilters) {
      this.selectionSwitched(filtersChannel.selectedFilters);
    }
  }

  selectionSwitched(selectedFilters: IFilters): void {
    this.filtersSaved = true;

    this.selectedFilters = selectedFilters;
    this.filters = selectedFilters;
    this.changeDetectorRef.detectChanges();

    this.updateFilters(selectedFilters);
    this.patchOptions(selectedFilters);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRules() {
    const rules: FilterRule[] = [];

    this.filtersSections.sections.forEach((section) => {
      const sectionRules = [];
      section.columns.forEach((option) => {
        if(option?.filterRules && option?.filterRules.length){
          sectionRules.push(...option.filterRules);
        } else {
          console.log("option.filterRules", option.filterRules);
        }
      });

      if (sectionRules.length) {
        if (section.filterGroup) {
          const rule: IFilterGroup = {
            op: section.filterGroup,
            rules: sectionRules,
          };
          rules.push(rule);
        } else {
          rules.push(...sectionRules);
        }
      }
    });

    return rules;
  }

  async updateFilterRules(): Promise<void> {
    this.controlsDisabled = true;

    const rules = this.getRules();

    const newFilters = {
      ...this.filters,
      query: { op: FilterOperatorType.And, rules },
    };

    // filters.query.rules = rules;
    this.filtersEmpty = rules.length === 0;

    if (!newFilters._id) {
      this.updateFilters(newFilters);
    } else {
      if (!this.rulesMatch(this.filters.query.rules, rules)) {
        this.filtersSaved = false;
        this.filtersChannel.setUnsavedChanges(true);

        const temporaryFilters = this.createTemporaryFilters(newFilters);

        this.updateFilters(temporaryFilters);

        if (this.autoSave) {
          await this.saveTemporaryFilters();
        }
      }
    }

    this.controlsDisabled = false;

    this.changeDetectorRef.detectChanges();
  }

  async saveTemporaryFilters(): Promise<void> {
    if (!this.filters._id && this.selectedFilters._id) {
      this.filtersSaved = true;
      this.filtersChannel.setUnsavedChanges(false);

      this.selectedFilters.query = this.filters.query;
      this.filters = this.selectedFilters;

      await this.filtersService.patchFilters(this.filtersChannel.channelName, this.selectedFilters);

      this.filtersChannel.updateFilters(this.selectedFilters);

      if (!this.autoSave) {
        this.snackBarService.open('Filter changes saved.', {
          verticalPosition: 'top',
        });
      }
    }
  }

  discardTemporaryFilters() {
    if (!this.filters._id) {
      this.filters = this.selectedFilters;
      this.filtersSaved = true;
      this.filtersChannel.setUnsavedChanges(false);

      this.patchOptions(this.filters);
      this.updateFilters(this.filters);
    }
  }

  createTemporaryFilters(filters: IFilters): IFilters {
    /*  Create temporary filters.
     */

    const { name, query } = filters;

    const temporaryFilters = { name, query, _id: undefined };
    // new DataSourceFilters({ name, rules, temporaryForId: _id, _id: undefined })
    return temporaryFilters;
  }

  applyFiltersClicked(): void {
    if (!this.autoApplyFilters && !this.filtersApplied) {
      this.filtersManuallyApplied.emit();
      this.pushToChannel(this.filters);
    }
  }

  rulesChanged(): void {
    this.debugNumber += 1;
    this.rulesChangedDebounceSubject.next();
  }

  recurseProperties(rule: FilterRule, appendTo: IFilterProperty[]): void {
    if ((rule as IFilterGroup).rules) {
      (rule as IFilterGroup).rules.forEach((rule) => {
        this.recurseProperties(rule, appendTo);
      });
    } else {
      appendTo.push(rule as IFilterProperty);
    }
  }

  patchOptions(filters: IFilters): void {
    if (filters) {
      const filterSectionsOptions = this.filtersSections.sections.map((s) => s.columns);
      const filterOptions = filterSectionsOptions.reduce((pv, cv, ci) => {
        return pv.concat(cv);
      });
      const filterProperties: IFilterProperty[] = [];
      this.recurseProperties(filters.query, filterProperties);

      filterOptions.forEach((option: FiltersOption) => {
        let properties;

        if(option.optionId) {
          properties = filterProperties.filter((p) => p.optionId == option.optionId);
        } else {
          properties = filterProperties.filter((p) => {
            return option.filterPropertyKeys.find((kp) => {
              return kp === p.key && !p.optionId;
            });
          });
        }

        properties = properties.map((p) => {
          const {optionId, ...rest} = p;
          return rest;
        });

        option.filterRules = properties;
        option.patchRulesSubject.next(properties);
      });
    } else {
      // Patch empty values in.

      this.filtersSections.sections.forEach((section) => {
        section.columns.forEach((option) => {
          option.patchRulesSubject.next(null);
        });
      });
    }
  }

  createJsonDialog(title: string, inputJson: string) {}

  editSectionsJson() {}

  clear() {
    this.dialog
      .open(DeleteConfirmationDialogComponent, {
        data: {
          confirmationText: 'Are you sure you want to clear these filters?',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result) => {
        console.log(result);
        if (result?.confirmed) {
          this.filters.query.rules = [];

          this.patchOptions(this.filters);
          this.updateFilters(this.filters);

          await this.filtersService.patchFilters(
            this.filtersChannel.channelName,
            this.selectedFilters,
          );
        }
      });
  }
}
