<!-- <h1 mat-dialog-title>Hello.</h1>
<div mat-dialog-content>
  <p>Configure Tile JSON <mat-icon>code</mat-icon>
  </p>
  <mat-form-field appearance="fill">
    <mat-label>JSON</mat-label>
    <textarea matInput [(ngModel)]="data.json"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.json" cdkFocusInitial>Ok</button>
</div> -->

<mat-dialog-content>
  <div class="dialog-container" fxLayout="column" fxLayoutAlign="stretch center">
    <h4 class="section" fxFlex="0 0 64px" mat-dialog-title>
      {{ data.title }}
      <mat-icon class="title-icon">code</mat-icon>
    </h4>
    <mat-form-field class="section form-section" fxFlex="1 1" appearance="fill">
      <textarea matInput [(ngModel)]="data.json"></textarea>
    </mat-form-field>
    <div class="section" fxFlex="0 0 64px" mat-dialog-actions>
      <button mat-flat-button color="basic" (click)="cancel()">
        <mat-icon>close</mat-icon>
        Cancel
      </button>
      <button mat-flat-button color="primary" [mat-dialog-close]="data.json" cdkFocusInitial>
        <mat-icon>code</mat-icon>
        Save
      </button>
    </div>
  </div>
</mat-dialog-content>
