import { FilterOperatorType } from "../../../../shared/interfaces/filters/filter-operator-type.enum";
import { IFiltersSection } from "../../interfaces/sections/filters-section.interface";
import { FiltersOption } from "./filters-option.model";

export class FiltersSection implements IFiltersSection {
  columns: FiltersOption[];
  filterGroup?: FilterOperatorType;
  asRow = false;

  constructor(init: IFiltersSection) {

    this.columns = [];
    this.filterGroup = init.filterGroup;

    if (init.options) {
      init.options.forEach(option => { this.columns.push(new FiltersOption(option)) })
    }

  }
}