<div fxFlex="1 1" fxLayout="row wrap">

  <ng-container *ngFor="let itemWidth of legend">

    <div class="chip" [ngStyle]="{
      width: itemWidth
    }">
      <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '100%'
      }"></ngx-skeleton-loader>
    </div>

  </ng-container>

</div>
