import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '@suvo-bi-core';
import { SharedModule } from '../../shared/shared.module';
import { ChannelDebugLabelComponent } from './components/common/channel-debug-label/channel-debug-label.component';
import { ClearControlButtonComponent } from './components/common/clear-control-button/clear-control-button.component';
import { NestedCheckboxesComponent } from './components/common/nested-checkboxes/nested-checkboxes.component';
import { EditFiltersComponent } from './components/control/edit-filters/edit-filters.component';
import { FiltersAccordionComponent } from './components/control/filters-accordion/filters-accordion.component';
import { FiltersSidenavComponent } from './components/control/filters-sidenav/filters-sidenav.component';
import { FiltersTitleComponent } from './components/control/filters-title/filters-title.component';
import { SelectFiltersComponent } from './components/control/select-filters/select-filters.component';
import { EditFiltersNameDialogComponent } from './components/dialogs/edit-filters-name-dialog/edit-filters-name-dialog.component';
import { NestedCheckboxesDialogComponent } from './components/dialogs/nested-checkboxes-dialog/nested-checkboxes-dialog.component';
import { PromptSaveDialogComponent } from './components/dialogs/prompt-save-dialog/prompt-save-dialog.component';
import { FilterWidgetsModule } from './components/filter-widgets/filter-widgets.module';
import { BetweenBoxesFilterOptionComponent } from './components/options/between-boxes-filter-option/between-boxes-filter-option.component';
import { BetweenDatesFilterOptionComponent } from './components/options/between-dates-filter-option/between-dates-filter-option.component';
import { CheckboxesFilterOptionComponent } from './components/options/checkboxes-filter-option/checkboxes-filter-option.component';
import { FilterOptionComponent } from './components/options/filter-option/filter-option.component';
import { KeywordsFilterOptionComponent } from './components/options/keywords-filter-option/keywords-filter-option.component';
import { CombinedNestedCheckboxesFilterOptionComponent } from './components/options/combined-nested-checkboxes-filter-option/combined-nested-checkboxes-filter-option.component';
import { NestedCheckboxesFilterOptionComponent } from './components/options/nested-checkboxes-filter-option/nested-checkboxes-filter-option.component';
import { RadioFilterOptionComponent } from './components/options/radio-filter-option/radio-filter-option.component';
import { ReferenceAutocompleteOptionComponent } from './components/options/reference-autocomplete-option/reference-autocomplete-option.component';
import { YearsDropDownOptionComponent } from './components/options/years-drop-down-option/years-drop-down-option.component';
import { SectionWrapperComponent } from './components/sections/section-wrapper/section-wrapper.component';
import { IFiltersSettings } from './interfaces/filters-settings.interface';
import { FiltersSettingsRef } from './constants/filters-settings-ref.token.const';
import { FiltersApiService } from './services/filters-api.service';
import { FiltersChannelsService } from './services/filters-channels.service';
import { ExportComponent } from './components/export/export.component';
import { FiltersContentComponent } from './components/control/filters-content/filters-content.component';
import { CheckboxFilterOptionComponent } from './components/options/checkbox-filter-option/checkbox-filter-option.component';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    FiltersSidenavComponent,
    FiltersContentComponent,
    EditFiltersComponent,
    SectionWrapperComponent,
    CombinedNestedCheckboxesFilterOptionComponent,
    FilterOptionComponent,
    NestedCheckboxesDialogComponent,
    BetweenBoxesFilterOptionComponent,
    SelectFiltersComponent,
    EditFiltersNameDialogComponent,
    FiltersTitleComponent,
    ReferenceAutocompleteOptionComponent,
    YearsDropDownOptionComponent,
    ClearControlButtonComponent,
    ChannelDebugLabelComponent,
    RadioFilterOptionComponent,
    FiltersAccordionComponent,
    NestedCheckboxesComponent,
    KeywordsFilterOptionComponent,
    BetweenDatesFilterOptionComponent,
    NestedCheckboxesFilterOptionComponent,
    CheckboxesFilterOptionComponent,
    CheckboxFilterOptionComponent,
    PromptSaveDialogComponent,
    ExportComponent,
  ],
  exports: [
    FiltersSidenavComponent,
    FiltersContentComponent,
    FiltersAccordionComponent,
    EditFiltersComponent,
    FilterWidgetsModule,
    ExportComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    CommonComponentsModule,
    FilterWidgetsModule,
  ],
  providers: [FiltersApiService],
})
export class FiltersModule {
  static forRoot(config: IFiltersSettings): ModuleWithProviders<FiltersModule> {
    return {
      ngModule: FiltersModule,
      providers: [
        {
          provide: FiltersSettingsRef,
          useValue: config,
        },
      ],
    };
  }
}
