import { Component } from '@angular/core';
import { IDataSourceItem } from '../../../../../shared/interfaces/data-source/data-source-items/data-source-item.interface';
import { ITableDataSource } from '../../../../../shared/interfaces/table/table-data-source.interface';
import { NewDataSourceDefinitionsService } from '../../../data-source-definitions/services/new-data-source-definitions.service';
import { DataSourceItemsTableComponent } from '../../../data-source-items/components/data-source-items-table/data-source-items-table.component';
import { DataSourceItemChipsService } from '../../../data-source-items/services/data-source-item-chips.service';
import { NewDataSourceItemsService } from '../../../data-source-items/services/new-data-source-items.service';
import { DataSourceFavouritesService } from '../../services/data-source-favourites.service';
import { SessionService } from '../../../../../shared/services/session.service';

@Component({
  selector: 'suvo-bi-favourite-items-table',
  templateUrl: './favourite-items-table.component.html',
  styleUrls: ['./favourite-items-table.component.scss'],
})
export class FavouriteItemsTableComponent extends DataSourceItemsTableComponent {
  // Skeleton table - TODO: will come from table properties
  public columnWidths = [10, 35, 10, 10, 10, 10, 15];

  constructor(
    protected dataSourceDefinitionsService: NewDataSourceDefinitionsService,
    protected dataSourceItemsService: NewDataSourceItemsService,
    protected dataSourceItemChipsService: DataSourceItemChipsService,
    private dataSourceFavouritesSerivce: DataSourceFavouritesService,
    protected readonly sessionService: SessionService
  ) {
    super(dataSourceDefinitionsService, dataSourceItemsService, dataSourceItemChipsService, sessionService);
  }

  getPaginated(): Promise<ITableDataSource<IDataSourceItem>> {
    return this.dataSourceFavouritesSerivce.getFavouriteDataSourceItems(this.dataSourceAlias);
  }

  protected getRowRouterLink(item: IDataSourceItem): string[] {
    return ['/', 'private', 'item', this.dataSourceAlias, item._id.toString()];
  }
}
