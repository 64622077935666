<mat-dialog-content>
  <div class="trial-restriction-dialog-container">
    <button mat-icon-button class="close-icon" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
    <h1>Trial Restrictions</h1>

    <div>
      <ng-container *ngFor="let prettyRestriction of prettyRestrictions | keyvalue">
        <div class="restriction-row">
          <div class="title-cell">
            <b>{{ prettyRestriction.key }}</b>
          </div>
          <div class="restriction-list">
            <div *ngFor="let restriction of prettyRestriction.value | keyvalue">
              {{ restriction.key }}:&nbsp;
              <i>{{ restriction.value.join(', ') }}.</i>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-container>
    </div>

    <button
      button
      mat-raised-button
      color="primary"
      class="upgrade-btn"
      cdkFocusInitial
      [routerLink]="['/', 'private', 'my-account', 'subscription']"
      mat-dialog-close
    >
      View upgrade options
    </button>
  </div>
</mat-dialog-content>
