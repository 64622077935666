import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'suvo-bi-skeleton-legend',
  templateUrl: './skeleton-legend.component.html',
  styleUrls: ['./skeleton-legend.component.scss'],
})
export class SkeletonLegendComponent implements OnInit {
  @Input() public legendCount = 8;

  public legend: string[];

  ngOnInit(): void {
    const legend: string[] = [];

    for (let i = 0; i < this.legendCount; i++) {
      legend.push(`${this.randomBetween(60, 140)}px`);
    }

    this.legend = legend;
  }

  randomBetween(min: number, max: number): number {
    return min + (max - min) * Math.random();
  }
}
