import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToggleGroup } from '../../../models/utility/toggle-group.model';

@Component({
  selector: 'suvo-bi-nested-checkboxes-dialog',
  templateUrl: './nested-checkboxes-dialog.component.html',
  styleUrls: ['./nested-checkboxes-dialog.component.scss'],
})
export class NestedCheckboxesDialogComponent implements OnInit {
  toggleGroups: ToggleGroup[];
  title: string;

  primaryToggleGroups;
  secondaryToggleGroups;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      toggleGroups: ToggleGroup[];
      onFilterApprove: () => void;
    }
  ) {}

  ngOnInit(): void {
    this.toggleGroups = this.data.toggleGroups;
    this.title = this.data.title;

    this.primaryToggleGroups = this.toggleGroups.filter((t) => !t.isSecondary);
    this.secondaryToggleGroups = this.toggleGroups.filter((t) => t.isSecondary);
  }

  handleDoneClick() {
    this.data.onFilterApprove();
  }
}
