<div class="section-wrapper" [ngSwitch]="option.filterMethod">
  <!-- <div class="margin" fxLayout="row" fxLayoutAlign="flex-start center">
    <p>Section: {{ section | json }}</p>
  </div> -->
  <!-- Filter methods -->

  <!-- Location -->
  <ng-container *ngSwitchCase="'combined-nested-checkboxes'">
    <suvo-bi-combined-nested-checkboxes-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-combined-nested-checkboxes-filter-option>
  </ng-container>

  <!-- Between Boxes -->
  <ng-container *ngSwitchCase="'between-boxes'">
    <suvo-bi-between-boxes-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-between-boxes-filter-option>
  </ng-container>

  <!-- Reference Autocomplete -->
  <ng-container *ngSwitchCase="'reference-autocomplete'">
    <suvo-bi-reference-autocomplete-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-reference-autocomplete-option>
  </ng-container>

  <!-- Years Drop Down -->
  <ng-container *ngSwitchCase="'years-drop-down'">
    <suvo-bi-years-drop-down-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-years-drop-down-option>
  </ng-container>

  <!-- Radio Options -->
  <ng-container *ngSwitchCase="'radio'">
    <suvo-bi-radio-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-radio-filter-option>
  </ng-container>

  <!-- Between Dates Options -->
  <ng-container *ngSwitchCase="'between-dates'">
    <suvo-bi-between-dates-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-between-dates-filter-option>
  </ng-container>

  <!-- Keywords -->
  <ng-container *ngSwitchCase="'keywords'">
    <suvo-bi-keywords-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-keywords-filter-option>
  </ng-container>

  <!-- Nested Checkboxes -->
  <ng-container *ngSwitchCase="'nested-checkboxes'">
    <suvo-bi-nested-checkboxes-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-nested-checkboxes-filter-option>
  </ng-container>

  <!-- Checkboxes -->
  <ng-container *ngSwitchCase="'checkboxes'">
    <suvo-bi-checkboxes-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-checkboxes-filter-option>
  </ng-container>

  <!-- Checkbox -->
  <ng-container *ngSwitchCase="'checkbox'">
    <suvo-bi-checkbox-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-checkbox-filter-option>
  </ng-container>

  <!-- Dropdown -->
  <ng-container *ngSwitchCase="'dropdown'">
    <suvo-bi-dropdown-filter-option
      [option]="option"
      (rulesChanged)="innerRulesChanged(option)"
    >
    </suvo-bi-dropdown-filter-option>
  </ng-container>

  <!-- No such filter method -->
  <ng-container *ngSwitchDefault>
    <suvo-core-error-message
      [title]="option.filterMethod"
      message="No such filter method."
    ></suvo-core-error-message>
  </ng-container>
</div>
