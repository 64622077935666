import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take, switchMap } from 'rxjs/operators';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private config: SuvoUsersClientLibSettingsService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = {};
    return this.angularFireAuth.idToken.pipe(
      take(1),
      switchMap((idToken: string | null) => {
        headers['Platform-ID'] = this.config.platformId;
        if (idToken) {
          headers['Authorization'] = idToken;
        }

        return next.handle(req.clone({ setHeaders: headers }));
      }),
    );
  }
}
