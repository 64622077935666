import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DataSourceItemsService} from '../../../../../data-source/data-source-items/services/data-source-items.service';
import { ExportButtonTileComponent } from '../export-button-tile/export-button-tile.component';

@Component({
  selector: 'suvo-bi-advanced-export-tile',
  templateUrl: './advanced-export-tile.component.html',
  styleUrls: ['./advanced-export-tile.component.scss']
})
export class AdvancedExportTileComponent extends ExportButtonTileComponent implements OnInit {
  public numberOfFilters = 0;
  public isExporting = false;

  public optionsForm: FormGroup;
  public fileNameControl: FormControl;

  constructor(
    protected readonly dataSourceItemsService: DataSourceItemsService,
  ) {
    super(dataSourceItemsService);
  }

  ngOnInit(): void {
    this.fileNameControl = new FormControl('export-file', [
      Validators.required,
      Validators.maxLength(64),
      // Validators.pattern(new RegExp(/^\w+/g))
    ])
    this.optionsForm = new FormGroup({
      fileName: this.fileNameControl,
    })
    this.filtersChannel?.filters.pipe(takeUntil(this.unsubscribe$)).subscribe(filters => {
      this.numberOfFilters = filters?.query?.rules?.length || 0;
    })
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public getFileNameError(): string {
    if (this.fileNameControl.hasError('required')) {
      return 'File name required.'
    }
    if (this.fileNameControl.hasError('maxLength')) {
      return 'File name too long.'
    }
    if (this.fileNameControl.hasError('pattern')) {
      return 'Invalid file name.'
    }
  }

  public async export(): Promise<void> {
    if (this.isExporting || this.optionsForm.invalid) {
      return;
    }

    const dataSourceAlias = this.tileDefinition.dataSource
    const filters = this.filtersChannel.filters.getValue();
    const fileName = this.fileNameControl.value;

    this.exportDataSourceItems(dataSourceAlias, filters, fileName);
  }
}
