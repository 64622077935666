<div
  class="tile-wrapper"
  fxFlex="1 1"
  fxLayout="column"
  fxLayoutAlign="stretch stretch"
  [ngSwitch]="tileDefinition?.tileType"
  *ngIf="tileDefinition?.canView || editMode"
  [class.no-card]="tileDefinition?.displayProperties?.noCard"
>
  <!-- Banner -->
  <suvo-bi-banner-tile
    fxFlex="1 1"
    *ngSwitchCase="'banner'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-banner-tile>

  <!-- Banner Text -->
  <suvo-bi-banner-text-tile
    fxFlex="1 1"
    *ngSwitchCase="'banner-text'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-banner-text-tile>

  <!-- Chart -->
  <suvo-bi-chart-tile
    fxFlex="1 1"
    *ngSwitchCase="'chart'"
    [tileDefinition]="tileDefinition"
    [filtersChannel]="filtersChannel"
    [editMode]="editMode"
  ></suvo-bi-chart-tile>

  <!-- Counts -->
  <suvo-bi-stats-tile
    fxFlex="1 1"
    *ngSwitchCase="'stats'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-stats-tile>

  <!-- Link Banner -->
  <suvo-bi-link-banner-tile
    fxFlex="1 1"
    *ngSwitchCase="'link-banner'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-link-banner-tile>

  <!-- Link Button -->
  <suvo-bi-link-button-tile
    fxFlex="1 1"
    *ngSwitchCase="'link-button'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-link-button-tile>

  <!-- Map -->
  <suvo-bi-map-tile
    fxFlex="1 1"
    *ngSwitchCase="'map'"
    [tileDefinition]="tileDefinition"
    [filtersChannel]="filtersChannel"
  ></suvo-bi-map-tile>

  <!-- Visualise Filters -->
  <suvo-bi-filters-tile
    fxFlex="1 1"
    *ngSwitchCase="'filters'"
    [tileDefinition]="tileDefinition"
    [filtersChannel]="filtersChannel"
  ></suvo-bi-filters-tile>

  <!-- Item Lookup -->
  <suvo-bi-item-lookup-tile
    fxFlex="1 1"
    *ngSwitchCase="'item-lookup'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-item-lookup-tile>

  <!-- Table -->
  <suvo-bi-table-tile
    fxFlex="1 1"
    *ngSwitchCase="'table'"
    [tileDefinition]="tileDefinition"
    [filtersChannel]="filtersChannel"
    [editMode]="editMode"
  ></suvo-bi-table-tile>

  <!-- <suvo-bi-table-tile fxFlex="1 1" *ngSwitchCase="'table'" [tileDefinition]="tileDefinition" [filtersChannel]="filtersChannel">
  </suvo-bi-table-tile> -->

  <!-- Visualise Filters -->
  <suvo-bi-filters-info-tile
    fxFlex="1 1"
    *ngSwitchCase="'see-filters'"
    [tileDefinition]="tileDefinition"
    [filtersChannel]="filtersChannel"
  ></suvo-bi-filters-info-tile>

  <!-- Export Button -->
  <suvo-bi-export-button-tile
    fxFlex="1 1"
    *ngSwitchCase="'export-button'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-export-button-tile>

  <suvo-bi-hero-banner-tile
    fxFlex="1 1"
    *ngSwitchCase="'hero-banner'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-hero-banner-tile>

  <suvo-bi-hero-banner-v2-tile
    fxFlex="1 1"
    *ngSwitchCase="'hero-banner-v2'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-hero-banner-v2-tile>

  <suvo-bi-hero-banner-v3-tile
    fxFlex="1 1"
    *ngSwitchCase="'hero-banner-v3'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-hero-banner-v3-tile>

  <suvi-bi-icon-columns-tile
    fxFlex="1 1"
    *ngSwitchCase="'icon-columns'"
    [tileDefinition]="tileDefinition"
  ></suvi-bi-icon-columns-tile>

  <suvo-bi-text-columns-tile
    fxFlex="1 1"
    *ngSwitchCase="'text-columns'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-text-columns-tile>

  <suvo-bi-presentation-tile
    fxFlex="1 1"
    *ngSwitchCase="'presentation'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-presentation-tile>

  <suvo-bi-text-rows-tile
    fxFlex="1 1"
    *ngSwitchCase="'text-rows'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-text-rows-tile>

  <suvo-bi-contacts-tile
    fxFlex="1 1"
    *ngSwitchCase="'contacts'"
    [tileDefinition]="tileDefinition"
  ></suvo-bi-contacts-tile>

  <suvo-free-trial-tile
    fxFlex="1 1"
    *ngSwitchCase="'free-trial'"
    [tileDefinition]="tileDefinition"
  ></suvo-free-trial-tile>

  <!-- New tile definition -->
  <app-tile-creator
    fxFlex="1 1"
    *ngSwitchCase="'new'"
    [tileDefinition]="tileDefinition"
  ></app-tile-creator>

  <!-- No tile definition -->
  <suvo-bi-invalid-tile
    fxFlex="1 1"
    *ngSwitchDefault
    [tileDefinition]="tileDefinition"
  ></suvo-bi-invalid-tile>
</div>

<div
  class="tile-wrapper"
  fxFlex="1 1"
  fxLayout="column"
  fxLayoutAlign="stretch stretch"
  *ngIf="!editMode && tileDefinition && !tileDefinition?.canView"
>
  <!-- No tile definition -->
  <suvo-bi-no-subscription fxFlex="1 1" [tileDefinition]="tileDefinition"></suvo-bi-no-subscription>
</div>
