import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService, ApiService } from '@suvo-bi-core';
import { BehaviorSubject } from 'rxjs';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { IUser } from '../../authentication/interfaces/user.interface';
import { PublicUserModel } from '../models/public-user-model';

@Injectable({
  providedIn: 'root',
})
export class PublicUsersService extends CommonService<PublicUserModel> {
  public userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  constructor(
    apiService: ApiService,
    private settings: SuvoUsersClientLibSettingsService,
    private http: HttpClient
  ) {
    super(apiService, settings.usersServiceApi, '');
  }

  public async getAllUsersFromOrganisation(
    organisationId: string
  ): Promise<PublicUserModel[]> {
    return this.http
      .get<PublicUserModel[]>(
        `${this.settings.usersServiceApi}all-users/usersFromOrganisations?organisationId=${organisationId}`
      )
      .toPromise();
  }
}
