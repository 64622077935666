import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ITendersCheckboxItem } from '../../../interfaces/tenders-info/tenders-checkbox-item.interface';
import { ITendersCheckboxItemList } from '../../../interfaces/tenders-info/tenders-checkbox-item-list.interface';
import { FilterOptionComponent } from '../filter-option/filter-option.component';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';

@Component({
  selector: 'suvo-bi-nested-checkboxes-filter-option',
  templateUrl: './nested-checkboxes-filter-option.component.html',
  styleUrls: ['./nested-checkboxes-filter-option.component.scss'],
})
export class NestedCheckboxesFilterOptionComponent extends FilterOptionComponent {
  checkboxesModel: ITendersCheckboxItemList[];
  columnWidth = '100%';
  columnFlex = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.columnWidth = this.option.filterMethodConfig.columnWidth || '100%';
    this.columnFlex = `0 0 ${this.columnWidth}`;
    this.checkboxesModel = this.option.filterMethodConfig.data;
  }

  getFilterRules(): FilterRule[] {
    const key = this.option.filterPropertyKeys[0];
    const inValues: string[] = [];

    this.checkboxesModel.forEach((itemList) => {
      if (itemList.checked && itemList.value.length) {
        inValues.push(itemList.value);
      }

      itemList.items.forEach((item) => {
        if (item.checked && item.value.length) {
          inValues.push(item.value);
        }
      });
    });

    if (inValues.length) {
      return [
        {
          key,
          in: inValues.join(','),
        },
      ];
    } else {
      return [];
    }
  }

  async patchRules(rules: FilterRule[]): Promise<void> {
    if (!this.checkboxesModel) {
      return;
    }

    if (rules?.length) {
      /**
       *    This will be a little bit tricky.
       */

      const property = rules[0] as IFilterProperty;
      const inValues = property.in.split(',');

      this.checkboxesModel.forEach((itemList) => {
        if (inValues.some((value) => value === itemList.value)) {
          itemList.checked = true;
          itemList.items.forEach((item) => {
            item.checked = true;
          });
        } else {
          itemList.checked = false;
          itemList.items.forEach((item) => {
            item.checked = inValues.some((value) => {
              let options = item.value.split(',');

              return options.includes(value);
            });
          });
          this.checkCategoryIntermediate(itemList);
        }
      });
    } else {
      this.checkboxesModel.forEach((itemList) => {
        itemList.checked = false;
        itemList.items.forEach((item) => {
          item.checked = false;
        });
        itemList.indeterminate = false;
      });
    }
  }
  emitPropertyChanges() {
    this.option.filterRules = this.getFilterRules();
    this.rulesChanged.emit();
  }

  itemChecked(
    event: MatCheckboxChange,
    item: ITendersCheckboxItem,
    itemList: ITendersCheckboxItemList
  ): void {
    const checked = event.checked;

    item.checked = checked;

    this.checkCategoryIntermediate(itemList, checked);

    this.emitPropertyChanges();
  }

  checkCategoryIntermediate(
    itemList: ITendersCheckboxItemList,
    checkedChange?: boolean
  ) {
    const oneChecked =
      checkedChange || !!itemList.items.find((item) => item.checked);
    const oneUnchecked =
      checkedChange === false || !!itemList.items.find((item) => !item.checked);

    itemList.checked = oneChecked && !oneUnchecked;
    itemList.indeterminate = oneChecked && oneUnchecked;
  }

  categoryChecked(
    event: MatCheckboxChange,
    itemList: ITendersCheckboxItemList
  ): void {
    const checked = event.checked;

    itemList.items.forEach((item) => {
      item.checked = checked;
    });

    itemList.checked = checked;
    itemList.indeterminate = false;
    this.emitPropertyChanges();
  }
}
