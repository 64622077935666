<a id="logo" href="/">
  <img class="logo" src="/assets/logo-informally.svg" />
</a>

<div class="container">
  <mat-card appearance="outlined" #card>
    <mat-stepper [linear]="true">
      <mat-step>
        <div class="step-container">
          <ng-template matStepLabel>Your Experience</ng-template>
          <app-prior-experience-step [parentFormGroup]="questionnaireFormGroup" controlName="priorExperience" />
          <div class="controls">
            <div style="visibility: hidden"></div>
            <button
              [disabled]="!questionnaireFormGroup.controls.priorExperience.valid"
              mat-flat-button
              color="primary"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <div class="step-container">
          <ng-template matStepLabel>Your Goals</ng-template>
          <app-requirements-step [parentFormGroup]="questionnaireFormGroup" controlName="purposes" />
          <div class="controls">
            <button mat-flat-button color="primary" matStepperPrevious>Back</button>
            <button
              [disabled]="!questionnaireFormGroup.controls.purposes.valid"
              mat-flat-button
              color="primary"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <div class="step-container">
          <ng-template matStepLabel>Your Shop</ng-template>
          <app-shop-details-step [parentFormGroup]="formGroup" controlName="shopDetails" />
          <div class="controls">
            <button mat-flat-button color="primary" matStepperPrevious>Back</button>
            <button
              [disabled]="!formGroup.controls.shopDetails.valid"
              mat-flat-button
              color="primary"
              matStepperNext
              (click)="onSubmit()"
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-card>
</div>
