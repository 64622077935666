<div class="lib-login-container">
  <div class="lib-login-container-2">
    <h2>Admin Portal Login</h2>
    <lib-login
      [loginComponentOptions]="loginComponentOptions"
      (loginStatusChange)="onLoginStatusChange($event)"
    ></lib-login>
    <p class="message" *ngIf="message">{{ message }}</p>
  </div>
</div>
