<h4 mat-dialog-title>Create User</h4>
<mat-dialog-content>
  <div class="new-user-container" *ngIf="newUserForm">
    <form [formGroup]="newUserForm">
      <mat-form-field appearance="outline" *ngIf="newUserForm.get('firstName')">
        <mat-label> First Name </mat-label>
        <input matInput formControlName="firstName" required>
        <mat-error *ngIf="newUserForm?.controls.firstName.hasError('required')">
          First name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="newUserForm.get('lastName')">
        <mat-label> Last Name </mat-label>
        <input matInput formControlName="lastName" required>
        <mat-error *ngIf="newUserForm?.controls.lastName.hasError('required')">
          Last name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="newUserForm.get('company')">
        <mat-label> Company </mat-label>
        <input matInput formControlName="company" required>
        <mat-error *ngIf="newUserForm?.controls.company.hasError('required')">
          Company is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="newUserForm.get('phone')">
        <mat-label> Phone </mat-label>
        <input matInput formControlName="phone" required>
        <mat-error
          *ngIf="newUserForm?.controls.phone.hasError('pattern') && !newUserForm?.controls.phone.hasError('required')">
          Please enter a valid phone number
        </mat-error>
        <mat-error *ngIf="newUserForm?.controls.phone.hasError('required')">
          phone is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Email </mat-label>
        <input matInput formControlName="email" required>
        <mat-error
          *ngIf="newUserForm?.controls.email.hasError('email') && !newUserForm?.controls.email.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="newUserForm?.controls.email.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="newUserForm?.controls.email.hasError('emailExists')">
          Email is <strong>already in use</strong>
        </mat-error>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill" *ngIf="newUserForm.get('gender')">
        <mat-label> Gender </mat-label>
        <mat-select formControlName="gender">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let gender of genders" [value]="gender">{{gender}}</mat-option>
        </mat-select>
        <mat-error *ngIf="newUserForm?.controls.gender.hasError('required')">
          Gender is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="newUserForm.get('ethnicity')">
        <mat-label> Ethnicity </mat-label>
        <mat-select formControlName="ethnicity">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let ethnicity of ethnicityList" [value]="ethnicity">{{ethnicity}}</mat-option>
        </mat-select>
        <mat-error *ngIf="newUserForm?.controls.ethnicity.hasError('required')">
          Ethnicity is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Date of Birth</mat-label>
        <input type="date" matInput formControlName="dateOfBirth">
      </mat-form-field> -->

      <mat-form-field appearance="outline">
        <mat-label> Password </mat-label>
        <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" required>
        <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass" tabindex="-1" type="button">
          <mat-icon> {{hidePass ? 'visibility_off' : 'visibility'}} </mat-icon>
        </button>
        <mat-error *ngIf="newUserForm?.controls.password.hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="newUserForm?.controls.password.hasError('pattern')">
          Passwords must be 8 characters and contain at least 1 lowercase, uppercase and numeric
          character.
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label> Confirm Password </mat-label>
        <input matInput [type]="hideConfirmPass ? 'password' : 'text'" formControlName="confirmPassword" required [errorStateMatcher]="matcher">
        <button mat-icon-button matSuffix (click)="hideConfirmPass = !hideConfirmPass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPass" tabindex="-1" type="button">
          <mat-icon> {{hideConfirmPass ? 'visibility_off' : 'visibility'}} </mat-icon>
        </button>
        <mat-error *ngIf="newUserForm?.hasError('notSame')">
          Passwords must match!
        </mat-error>
      </mat-form-field>

    </form>

    <div class="general-error" *ngIf="generalError">{{generalError.message}}</div>

    <button mat-raised-button color="primary" (click)="createUser()" [disabled]="!newUserForm.valid">Create User</button>

    <div *ngIf="creatingUser" class="dialog-loading-overlay" fxLayout="column" fxLayoutAlign="center center">
      <span class="login-status-message">Creating user</span>
      <br>
      <mat-spinner></mat-spinner>
    </div>
  </div>

</mat-dialog-content>