import { Component, Input } from '@angular/core';
import { CurrentTenantService } from '@suvo-bi-users';
import { IHeroBannerTileDefinition } from '../../../../interfaces/tiles/definitions/hero-banner-tile-definition.interface';

@Component({
  selector: 'suvo-bi-hero-banner-tile',
  templateUrl: './hero-banner-tile.component.html',
  styleUrls: ['./hero-banner-tile.component.scss'],
})
export class HeroBannerTileComponent {
  @Input() public tileDefinition: IHeroBannerTileDefinition;

  public get contentImgPath(): string {
    return `${this.tenantService.currentTenantData.primaryWebUrl}/${this.tileDefinition.contentImg}`;
  }

  constructor(
    private readonly tenantService: CurrentTenantService
  ) {
  }

  scrollToElement(element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
