import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-marketing-checkbox',
  templateUrl: './marketing-checkbox.component.html',
  styleUrls: ['./marketing-checkbox.component.scss'],
})
export class MarketingCheckboxComponent {
  @Input() value: boolean
  @Output() valueChange = new EventEmitter<boolean>()

  constructor() {}

  handleCheck (event) {
    this.valueChange.emit(event.checked)
  }
}
