export interface IItemsTableColumnOptions {
  key: string;
  priority?: number;
}

export interface IItemsTableOptions {
  defaultPageSize?: number;
  showPageControls?: boolean;
  showPageSizeOptions?: boolean;
  columns?: IItemsTableColumnOptions[];
  searchOptions?: {
    key: string;
  }
  itemRoutePrefixes?: string[] | string;
  canSort?: boolean;
  loadingType?: 'skeletal'|'spinner';
}