<form [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>Title</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>

  <app-data-source-selector
    formControlName="dataSource"
    (onDataSourceChange)="initDataSource($event)"
  ></app-data-source-selector>

  <mat-form-field appearance="outline">
    <mat-label>Chart Type</mat-label>
    <mat-select formControlName="chartType">
      <mat-option *ngFor="let chartType of chartTypes" [value]="chartType.key">
        {{ chartType.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Group</mat-label>
    <mat-select formControlName="groupBy">
      <mat-option *ngFor="let group of availableDataSourceGroups" [value]="group.name">
        {{ group.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Metric</mat-label>
    <mat-select formControlName="metric">
      <mat-option *ngFor="let metric of availableDataSourceMetrics" [value]="metric.name">
        {{ metric.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Sorting Order</mat-label>
    <mat-select formControlName="sortOrder">
      <mat-option *ngFor="let sortOrder of sortOrders" [value]="sortOrder.key">
        {{ sortOrder.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<div style="padding: 12px 0; text-align: right">
  <button mat-button (click)="onCreate()">Apply</button>
</div>
