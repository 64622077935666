import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDataSourceDefinitionProperty } from '../../../../../../shared/interfaces/data-source/data-source-definitions/data-source-property-definition.interface';
import { NewDataSourceDefinitionsService } from '../../../../../data-source/data-source-definitions/services/new-data-source-definitions.service';
import { LocalChartFilterChange } from '../../../../interfaces/tiles/local-chart-filter-change.interface';
import { LocalChartFilter } from '../../../../interfaces/tiles/local-chart-filter.interface';

@Component({
  selector: 'suvo-bi-tile-filters-select',
  templateUrl: './tile-filters-select.component.html',
  styleUrls: ['./tile-filters-select.component.scss'],
})
export class TileFiltersSelectComponent implements OnInit, OnDestroy {
  @Input() public alias;
  @Input() public localChartFilter: LocalChartFilter;

  @Output() public filterChanged: EventEmitter<LocalChartFilterChange> =
    new EventEmitter();

  public definitionProperty: IDataSourceDefinitionProperty;
  public filterForm: FormGroup;
  public options: any[] = [];

  public get filterControl(): FormControl {
    return this.filterForm.get('filter') as FormControl;
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private newDataSourceDefinitionsService: NewDataSourceDefinitionsService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.initForm();
    this.initOptions();
    await this.initLabel();
    this.initFilterChangesSubscription();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onFilterChange(newFilterValue: number | null): void {
    this.filterChanged.emit({
      localChartFilter: this.localChartFilter,
      newValue: newFilterValue,
    });
  }

  public onValueReset(event: MouseEvent): void {
    event.stopPropagation();
    this.filterControl.reset();
  }

  private async initLabel(): Promise<any> {
    this.definitionProperty =
      await this.newDataSourceDefinitionsService.getPropertyByAliasAndName(
        this.alias,
        this.localChartFilter.property
      );
  }

  private initForm(): void {
    this.filterForm = this.fb.group({
      filter: [''],
    });
  }

  private initOptions(): void {
    const { min, max } = this.localChartFilter;
    for (let i = min; i <= max; i++) {
      this.options.push(i);
    }
  }

  private initFilterChangesSubscription(): void {
    this.filterControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.onFilterChange(value);
      });
  }
}
