import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FiltersChannel } from '../../../models/filters-channel.model';

@Component({
  selector: 'suvo-bi-filters-accordion',
  templateUrl: './filters-accordion.component.html',
  styleUrls: ['./filters-accordion.component.scss'],
})
export class FiltersAccordionComponent implements OnInit {
  @ViewChild('expansionPanel') expansionPanel;

  @Input() filtersChannel: FiltersChannel;
  @Input() autoApplyFilters = false;
  @Input() enableAccordionClose = true;
  @Input() exportDisabled = false;

  public expansionOpen = false;
  public showContent = false;

  ngOnInit(): void {}

  expansionOpened(): void {
    this.expansionOpen = true;
  }

  expansionClosed(): void {
    this.expansionOpen = false;
    this.showContent = false;
  }

  expansionFinished(): void {
    this.showContent = true;
  }

  onFiltersApplied() {
    if (this.enableAccordionClose) {
      this.expansionPanel.close();
    }
  }
}
