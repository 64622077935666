import { Component, Input } from '@angular/core';
import { ITileComponent } from '../../tile-component.interface';

@Component({
  selector: 'suvo-bi-new-tile',
  templateUrl: './new-tile.component.html',
  styleUrls: ['./new-tile.component.scss']
})
export class NewTileComponent implements ITileComponent {
  @Input() public tileDefinition: any;
}
