<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="flex-start stretch">
  <h4 class="header-top-margin">{{ option.filterMethodConfig?.title || 'Between Dates' }}</h4>
  <div
    fxFlex="1 1"
    fxLayout="row wrap"
    fxLayoutAlign="flex-start"
    fxLayoutGap="8px"
    class="list-container"
  >
    <p fxFlex="1 1 18%" class="item-checkbox" *ngFor="let item of checkboxItems; let i = index">
      <mat-checkbox [checked]="item.checked" (change)="itemChecked($event, item)" color="primary"
        >{{ item.name }}
      </mat-checkbox>
    </p>
  </div>
</div>
