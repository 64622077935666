import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProductService, SubscriptionService } from '@suvo-bi-users';
import { DataSourceItemsService } from '../../../../../data-source/data-source-items/services/data-source-items.service';
import { FiltersChannel } from '../../../../../filters/models/filters-channel.model';
import { Filters } from '../../../../../filters/models/filters.model';
import { IMapTileDefinition } from '../../../../interfaces/tiles/definitions/map-tile-definition.interface';
import { ITileComponent } from '../../tile-component.interface';
import { ClusterClickPreviewDialogComponent } from '../cluster-click-preview-dialog/cluster-click-preview-dialog.component';

@Component({
  selector: 'suvo-bi-map-tile',
  templateUrl: './map-tile.component.html',
  styleUrls: ['./map-tile.component.scss'],
})
export class MapTileComponent implements ITileComponent, OnInit, OnDestroy {
  @Input() public tileDefinition: IMapTileDefinition;
  @Input() public filtersChannel: FiltersChannel;

  @ViewChild(GoogleMap) public googleMap: GoogleMap;

  public markers = [];
  public clusterImage =
    'https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m';
  public mapOptions: google.maps.MapOptions = null;
  public loading = true;

  isTrialing = false;
  hasRestrictions = false;

  private filters: Filters;
  private newBounds = null;
  private center: any = {
    lat: 0,
    lng: 0,
  };
  private $unsubscribe = new Subject<boolean>();

  constructor(
    private dataSourceItemsService: DataSourceItemsService,
    private readonly subscriptionService: SubscriptionService,
    private readonly productService: ProductService,
    private router: Router,
    private readonly dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    if (this.filtersChannel) {
      this.filtersChannel?.filters
        .pipe(
          takeUntil(this.$unsubscribe),
          filter((filters) => !!filters),
        )
        .subscribe((filters) => {
          this.filters = filters;
          this.setMapTileGeolocations();
        });
    } else {
      this.setMapTileGeolocations();
    }

    this.mapOptions = {
      scrollwheel: this.tileDefinition.mapOptions.canScroll,
      minZoom: 2,
      maxZoom: 15,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    };

    this.getSubscriptionStatus();
  }

  public ngOnDestroy(): void {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

  private getSubscriptionStatus(): void {
    this.subscriptionService.currentSubscription
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((currentSubscription) => {
        if (currentSubscription && currentSubscription?.status === 'trialing') {
          this.isTrialing = true;
        }
        this.hasRestrictions = this.productService.hasRestrictions('GEOLOCATION', [
          'DATASOURCE:' + this.tileDefinition.recipe?.dataSource,
        ]);
      });
  }

  public async setMapTileGeolocations(): Promise<void> {
    this.loading = true;

    const compositeRecipe = { ...this.tileDefinition.recipe };
    if (this.filters) {
      compositeRecipe.filters = this.filters;
    }

    const geolocations = (await this.dataSourceItemsService.getGeolocated(
      compositeRecipe,
    )) as any[];
    const newBounds = new google.maps.LatLngBounds();

    const markers: any[] = geolocations.map((geolocation) => {
      newBounds.extend(
        new google.maps.LatLng({
          lat: +geolocation.point.latitude,
          lng: +geolocation.point.longitude,
        }),
      );
      return {
        position: {
          lat: +geolocation.point.latitude,
          lng: +geolocation.point.longitude,
        },
        label: {
          color: 'white',
          text: geolocation.name,
          dataSourceItemId: geolocation.dataSourceItemId,
        },
        dataSourceItemId: geolocation.dataSourceItemId,
        title: geolocation.dataSourceItemId,
        info: 'Info',
        options: {},
      };
    });

    this.markers = markers;

    this.loading = false;

    setTimeout(() => {
      if (this.markers.length) {
        this.googleMap?.fitBounds(newBounds);
      } else {
        this.googleMap?.panTo(new google.maps.LatLng({ lat: 48.9137228, lng: -15.4660533 }));
        this.mapOptions.zoom = 3.75;
      }
    }, 0);
  }

  public onMarkerClick(marker: any): void {
    this.goToItem(marker.dataSourceItemId);
  }

  public goToItem(itemId) {
    let prefixes = ['/', 'private', 'item', this.tileDefinition.recipe?.dataSource];
    this.router.navigate([...prefixes, itemId]);
  }

  public async onClusterClick(cluster): Promise<void> {
    const dataSourceIds = cluster.markers_.map((marker) => marker.title);
    if (this.tileDefinition?.recipe?.dataSource && this.googleMap.getZoom() === 15) {
      const fetchDataPromise = this.dataSourceItemsService.getPaginated(
        this.tileDefinition?.recipe?.dataSource,
        null,
        null,
        null,
        dataSourceIds,
      );

      this.dialog.open(ClusterClickPreviewDialogComponent, {
        width: '500px',
        maxHeight: '500px',
        data: {
          fetchDataPromise,
          alias: this.tileDefinition?.recipe?.dataSource,
          prefixes: ['/', 'private', 'item', this.tileDefinition.recipe?.dataSource],
        },
      });
    }
  }
}
