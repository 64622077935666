import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrl: './image-editor.component.scss',
})
export class ImageEditorComponent {
  @Input() label;
  @Input() formGroup;
  @Input() imageOffsetLeftFormControlName;
  @Input() imageOffsetRightFormControlName;
  @Input() imageOffsetTopFormControlName;
  @Input() imageOffsetBottomFormControlName;
}
