<ng-container [formGroup]="formGroup">
  <h2>What will your shop be called?</h2>

  <mat-form-field appearance="outline">
    <mat-label>Shop Name</mat-label>
    <input matInput required placeholder="My Data Shop" formControlName="name" (input)="generateDomain()" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Subdomain</mat-label>
    <input matInput required formControlName="subdomain" />
    <span matSuffix class="domain-suffix">.informally.io</span>
    <mat-hint>
      This is a temporary subdomain for your shop. After setup, you'll be able to replace this with your own domain.
    </mat-hint>
  </mat-form-field>

  <h2>Where will your shop be located?</h2>

  <mat-form-field appearance="outline">
    <mat-label>Location</mat-label>
    <mat-select required formControlName="location">
      @for (country of countryOptions; track $index) {
        <mat-option [value]="country.code">{{ country.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</ng-container>
