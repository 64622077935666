import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ToggleGroupNavigationComponent } from '../../../toggle-group-navigation/components/toggle-group-navigation/toggle-group-navigation.component';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormComponent } from '../../../contact/components/contact-form/contact-form.component';

@Component({
  selector: 'suvo-bi-list-navigation',
  templateUrl: './list-navigation.component.html',
  styleUrls: ['./list-navigation.component.scss'],
})
export class ListNavigationComponent extends ToggleGroupNavigationComponent {
  @Input() theme: string;
  @Input() borderRadius = '24px';
  @Input() showAskAQuestion = false;

  @Output() onLinkClick = new EventEmitter();

  constructor(private dialog: MatDialog) {
    super();
  }

  openRequestInfo() {
    this.dialog.open(ContactFormComponent, {
      data: { title: 'How can we help?' },
    });
  }
}
