import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepComponent } from '../../../classes/abstract-step.component';
import { IStepCheckbox } from '../../../interfaces/step-checkbox.interface';

@Component({
  selector: 'app-prior-experience-step',
  templateUrl: './prior-experience-step.component.html',
  styleUrls: ['../../../styles/step.styles.scss', './prior-experience-step.component.scss'],
})
export class PriorExperienceStepComponent extends StepComponent {
  @Input() public readonly parentFormGroup: FormGroup;
  @Input() public readonly controlName: string;

  public readonly formGroup = new FormGroup({});

  public readonly checkboxes: IStepCheckbox[] = [
    {
      name: 'hasUsedECommerce',
      label: 'I have used E-commerce systems before.',
    },
    {
      name: 'hasUsedBI',
      label: 'I have used BI systems before.',
    },
  ];
}
