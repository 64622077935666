<div
  class="card container"
  fxLayout="column"
  ngClass="height-desktop"
>
  <suvo-bi-limited-data-banner
    class="restrictions-banner"
    [isTrial]="isTrialing"
    [featureShortCode]="'FINDANDPAGINATE'"
    [tags]="[tileDefinition.recipe?.dataSource]"
    *ngIf="hasRestrictions"
  ></suvo-bi-limited-data-banner>
  <ng-container *ngIf="!loading; else skeleton">
    <google-map [options]="mapOptions" height="100%" width="100%">
      <map-marker-clusterer
        *ngIf="!printView"
        [imagePath]="clusterImage"
        (clusterClick)="onClusterClick($event)"
      >
        <map-marker
          *ngFor="let marker of markers"
          [title]="marker.title"
          [position]="marker.position"
          (mapClick)="onMarkerClick(marker)"
        ></map-marker>
      </map-marker-clusterer>
    </google-map>
  </ng-container>

  <ng-template #skeleton>
    <div
      class="row-padding"
      fxFlex="1 1"
      fxLayout="column"
      fxLayoutAlign="stretch stretch"
    >
      <suvo-bi-skeleton-map fxFlex="1 1"></suvo-bi-skeleton-map>
    </div>
  </ng-template>
</div>
