<div class="card">
  <div class="page-section counters" [style.background]="tileDefinition.background">
    <div class="page-section-content" fxLayoutAlign="center center">
      <div fxFlex="90%" fxFlex.xs="100%">
        <div fxLayout="row wrap" fxFlex="1 1 100%" fxLayoutAlign="center center">
          <div class="stat" fxLayout="row" *ngFor="let column of tileDefinition.iconColumns">
            <ng-container
              *ngIf="column.iconPosition === 'start'"
              [ngTemplateOutlet]="icon"
              [ngTemplateOutletContext]="{ column: column }"
            >
            </ng-container>

            <div fxLayout="column" class="content-container">
              <span
                [style.color]="column.headingFontColor"
                [style.fontSize]="column.headingFontSize | pxSuffix"
                [style.textAlign]="column.iconPosition === 'start' ? 'left' : 'right'"
              >
                {{ column.heading }}
              </span>
              <span
                [style.color]="column.textFontColor"
                [style.fontSize]="column.textFontSize | pxSuffix"
                [style.textAlign]="column.iconPosition === 'start' ? 'left' : 'right'"
              >
                {{ column.text }}
              </span>
            </div>

            <ng-container
              *ngIf="column.iconPosition === 'end'"
              [ngTemplateOutlet]="icon"
              [ngTemplateOutletContext]="{ column: column }"
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #icon let-column="column">
  <div fxLayout="column" class="icon-container">
    <span class="material-icons border-circle lrg-icon icon-color-secondary">
      {{ column.icon }}
    </span>
  </div>
</ng-template>
