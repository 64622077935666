import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'suvo-bi-organisation-invites-view',
  templateUrl: './organisation-invites-view.component.html',
  styleUrls: ['./organisation-invites-view.component.scss']
})
export class OrganisationInvitesViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
