<div class="table-container">
  <table mat-table [dataSource]="dataSource$" class="table" matSort cdkDropList cdkDropListOrientation="horizontal"
         (cdkDropListDropped)="listDropped($event)">

    <ng-container *ngFor="let property of propertyColumns, index as i" [matColumnDef]="property.name">

      <th mat-header-cell cdkDrag mat-sort-header *matHeaderCellDef [style.width]="isLoading ? columnWidths[i] + '%' : 'auto'">
        {{ property.displayName }}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="property.displayOptions?.isChippable; else basicCell">
          <div fxLayoutAlign="center center" class="chip" [ngStyle]="getChipStyles(row, property)">
            <p fxFlex="nogrow">{{ row.data[property.name] }}</p>
          </div>
        </ng-container>
        <ng-template #basicCell>
          <span [class.index-value]="property.name === 'index'">{{ row.data[property.name] }}</span>
        </ng-template>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="propertyColumnNames"></tr>
    <tr mat-row class="row" [class.hidden]="isLoading" *matRowDef="let row; columns: propertyColumnNames"
        [routerLink]="getRowRouterLink(row)">
    </tr>

  </table>
</div>

<ng-container *ngIf="isLoading">
  <suvo-bi-skeleton-table-rows class="skeleton" [rowCount]="pageSize" [columnWidths]="columnWidths">
  </suvo-bi-skeleton-table-rows>
</ng-container>
<ng-container *ngIf="!isLoading && noData">
  <div class="no-data-container">
    <ng-content select="[noDataContent]"></ng-content>
  </div>
</ng-container>


<mat-paginator *ngIf="!noData && options.showPageControls" [pageSize]="pageSize" [hidePageSize]="!options.showPageSizeOptions"
  [pageSizeOptions]="options.showPageSizeOptions && pageSizeOptions" showFirstLastButtons></mat-paginator>
