import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TourOutletComponent } from './components/tour-outlet/tour-outlet.component';
import { TourWindowComponent } from './components/tour-window/tour-window.component';
import { TourCardComponent } from './components/tour-card/tour-card.component';
import { TourService } from './services/tour.service';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [
    TourOutletComponent,
    TourWindowComponent,
    TourCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
  ],
  providers: [
  ],
  exports: [
    TourOutletComponent,
  ]
})
export class TourModule { }
