import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'suvo-bi-invite-entry',
  templateUrl: './invite-entry.component.html',
  styleUrls: ['./invite-entry.component.scss']
})
export class InviteEntryComponent implements OnInit {

  @Input() name;
  @Input() isFocused: boolean;
  @Output() actioned: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  action(accepted: boolean): void {
    this.actioned.emit(accepted);
  }

}
