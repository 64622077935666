<div class="card view-margin" fxFlex="1 1" fxLayout="row wrap" fxLayout="stretch stretch">


  <div *ngFor="let display of displays; trackBy: trackDisplay; let i=index; let even=even; let last=last" fxFlex="1 1 50%" fxLayout="row"
    fxLayoutAlign="flex-start center" class="property-padding" [class.border-right]="even"
    [class.border-bottom]="i < displays.length - (even ? 2 : 1)">
    <mat-icon class="property-icon" *ngIf="!!display.displayIcon">{{ display.displayIcon }}</mat-icon>
    <div fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" class="text-container">
      <span class="property-name">{{ display.property?.displayName || '?' }}</span>
      <suvo-bi-property fxLayoutAlign="center center" [property]="display.property" [item]="item" [control]="display.control"
        [editItemMode]="editItemMode">
      </suvo-bi-property>

    </div>
  </div>


</div>
