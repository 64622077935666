import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditProfileModule, SubscriptionModule } from '@suvo-bi-users';
import { SharedModule } from '../../shared/shared.module';
import { ListNavigationModule } from '../list-navigation/list-navigation.module';
import { InviteEntryComponent } from '../manage-bi-identity/components/screen-parts/invite-entry/invite-entry.component';
import { OrganisationInviteListComponent } from '../manage-bi-identity/components/screen-parts/organisation-invite-list/organisation-invite-list.component';
import { ManageBiSharedModule } from '../manage-bi-shared/manage-bi-shared.module';
import { RouteSelectorModule } from '../route-selector/route-selector.module';
import { ManageBiOrganisationsComponent } from './components/manage-bi-organisations/manage-bi-organisations.component';
import { OrganisationMembersViewComponent } from './components/screen-parts/organisation-members-view/organisation-members-view.component';
import { OrganisationProfileComponent } from './components/screen-parts/organisation-profile/organisation-profile.component';
import { OrganisationInvitesViewComponent } from './components/screens/organisation-invites-view/organisation-invites-view.component';
import { OrganisationOutgoingInvitesViewComponent } from './components/screens/organisation-outgoing-invites-view/organisation-outgoing-invites-view.component';
import { OrganisationSelectorViewComponent } from './components/screens/organisation-selector-view/organisation-selector-view.component';
import { OrganisationSettingsViewComponent } from './components/screens/organisation-settings-view/organisation-settings-view.component';
import { OrganisationSubscriptionsViewComponent } from './components/screens/organisation-subscriptions-view/organisation-subscriptions-view.component';
import { OrganisationUsersComponent } from './components/screens/organisation-users/organisation-users.component';
import { OrganisationsContainerComponent } from './components/screens/organisations-container/organisations-container.component';
import { SingleOrganisationViewComponent } from './components/screens/single-organisation-view/single-organisation-view.component';
import { ManageBiOrganisationsRoutingModule } from './manage-bi-organisations-routing.module';

@NgModule({
  declarations: [
    InviteEntryComponent,
    ManageBiOrganisationsComponent,
    OrganisationInvitesViewComponent,
    OrganisationInviteListComponent,
    OrganisationSelectorViewComponent,
    OrganisationProfileComponent,
    OrganisationUsersComponent,
    SingleOrganisationViewComponent,
    OrganisationsContainerComponent,
    OrganisationSubscriptionsViewComponent,
    OrganisationSettingsViewComponent,
    OrganisationOutgoingInvitesViewComponent,
    OrganisationMembersViewComponent
  ],
  imports: [
    ManageBiOrganisationsRoutingModule,
    CommonModule,
    SharedModule,
    ManageBiSharedModule,

    EditProfileModule,
    SubscriptionModule,
    RouteSelectorModule,
    ListNavigationModule,
  ],
})
export class ManageBiOrganisationsModule { }
