import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { IDataSourceDefinition } from '../../../interfaces/data-source/data-source-definitions/data-source-definition.interface';
import { NewDataSourceDefinitionsService } from '../../../../features/data-source/data-source-definitions/services/new-data-source-definitions.service';

@Component({
  selector: 'app-data-source-selector',
  templateUrl: './data-source-selector.component.html',
  styleUrls: ['./data-source-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DataSourceSelectorComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DataSourceSelectorComponent,
      multi: true,
    },
  ],
})
export class DataSourceSelectorComponent implements OnInit, ControlValueAccessor, Validators {
  @Input() label: string;

  @Output() onDataSourceChange: EventEmitter<IDataSourceDefinition> =
    new EventEmitter<IDataSourceDefinition>();

  public definitions: IDataSourceDefinition[] = [];
  public isDisabled: boolean = false;

  public control!: AbstractControl;

  private _value: string = '';

  public set value(val: string) {
    if (val !== undefined && this._value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  public get value(): string {
    return this._value;
  }

  constructor(private readonly newDataSourceDefinitionsService: NewDataSourceDefinitionsService) {}

  public ngOnInit(): void {
    this.initDefinitions();
  }

  public onChange: any = () => {};

  public onTouch: any = () => {};

  public writeValue(value: string): void {
    this.value = value;
  }

  public validate(control: AbstractControl): void {
    this.control = control;
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public changeDataSource(dataSource: IDataSourceDefinition): void {
    this.onDataSourceChange.emit(dataSource);
  }

  public onBlur(): void {
    this.onTouch();
  }

  private async initDefinitions(): Promise<void> {
    this.definitions = await this.newDataSourceDefinitionsService.getAll();

    if (!this.checkIfRequired(this.control)) {
      this.definitions.unshift({
        alias: '',
        name: 'None',
      } as IDataSourceDefinition);
    }

    // If we update the form's value before we have our definitions
    const alreadyExistedDefinition = this.definitions.find(
      (definition) => definition.alias === this.value,
    );
    if (alreadyExistedDefinition) {
      this.changeDataSource(alreadyExistedDefinition);
    }
  }

  private checkIfRequired(control: AbstractControl): boolean {
    return control?.hasValidator(Validators.required);
  }
}
