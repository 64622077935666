import { Component, Input } from '@angular/core';
import { IBannerTextTileDefinition } from '../../../../interfaces/tiles/definitions/banner-text-tile-definition.interface';
import { ITileComponent } from '../../tile-component.interface';

@Component({
  selector: 'suvo-bi-banner-text-tile',
  templateUrl: './banner-text-tile.component.html',
  styleUrls: ['./banner-text-tile.component.scss'],
})
export class BannerTextTileComponent implements ITileComponent {
  @Input() public tileDefinition: IBannerTextTileDefinition;
}
