<button
  mat-tonal-button
  (click)="toggleSidenav()"
  class="active-filters-widget"
  *ngIf="showFiltersToggle"
>
  <div class="container" fxLayout="row" fxLayoutAlign="flex-start center">
    <div class="active-filters" fxHide.xs fxShow fxLayout="column" fxLayoutAlign="center center">
      Show&nbsp;filters
    </div>
    <div fxLayoutAlign="center center">
      <mat-icon
        class="icon"
        [matBadge]="activeFilters?.query?.rules?.length || null"
        matBadgeColor="warn"
        >filter_alt
      </mat-icon>
    </div>
  </div>
</button>
