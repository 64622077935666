import { Subject } from 'rxjs';
import { FilterRule } from '../../../../shared/interfaces/filters/filter-rule.type';
import { IFiltersOption } from '../../interfaces/sections/filters-option.interface';

export class FiltersOption implements IFiltersOption {
  filterMethod: string;
  filterMethodConfig: any;
  filterPropertyKeys: string[];
  filterRules: FilterRule[];
  filterValueType?: 'OBJECT_ID';

  patchRulesSubject: Subject<any>;
  preInitPatchRules: FilterRule[];

  definitionAlias?;

  constructor(init: IFiltersOption) {
    Object.assign(this, init);
    this.patchRulesSubject = new Subject<any>();
  }
}
