import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, take, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IdentityService } from '../../../../shared/services/identity/identity.service';


@Injectable()
export class UnverifiedEmailInterceptor implements HttpInterceptor {

  constructor(private router: Router, private identityService: IdentityService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let email;
        this.identityService.firebaseUserSubject.pipe(take(1), filter((user) => !!user)).subscribe(user => email = user.email);
        if (error?.error?.message === 'Unverified email') {
          this.router.navigate(['email-verification-message', email]);
        }
        return throwError(error);
      }));
  }
}
