import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { IDashboardSettings } from '../../../interfaces/dashboard-settings.interface';
import { IDashboard } from '../../../interfaces/dashboard.interface';
import { Dashboard } from '../../../models/dashboard.model';
import { DashboardService } from '../../../services/dashboards.service';
import { FilterDemoTourService } from '../../../services/filter-demo-tour.service';
import { ProductService, SubscriptionService } from '@suvo-bi-users';
import { takeUntil } from 'rxjs/operators';
import { FiltersChannel } from '../../../../filters/models/filters-channel.model';
import { IGridSettings } from '../../../../smart-grid/interfaces/grid-settings.interfaces';
import { FiltersChannelsService } from '../../../../filters/services/filters-channels.service';
import { FiltersSidenavService } from '../../../../filters/services/filters-sidenav.service';
import { DashboardSettingsService } from '../../../services/dashboards-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteOptionChoiceDialogComponent } from '../../dialogs/delete-option-choice/delete-option-choice.component';
import { Router } from '@angular/router';

@Component({
  selector: 'suvo-bi-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public settings: IDashboardSettings;
  @Input() public dashboard: Dashboard;
  @Input() public filtersChannel: FiltersChannel;
  @Input() public isCreation: boolean = false;
  @Input() public isEditable: boolean = false;
  displayNameLabel: string = 'Page title';

  @Output() dashboardChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  // @Output() saveDashboard: EventEmitter<any> = new EventEmitter();
  // @Output() dashboardChanged: EventEmitter<any> = new EventEmitter();
  // @Output() deleteDashboard: EventEmitter<any> = new EventEmitter();
  // @Output() toggleFiltersDrawer: EventEmitter<any> = new EventEmitter();

  public hasRestrictions = false;
  @Input() public editMode: boolean = false;
  public currentDashboard: Dashboard;
  public isTrialing = false;
  public filtersType: string;
  public gridSettings: IGridSettings = {
    maxColumnCount: 3,
    canEdit: this.dashboardSettingsService.canEditDashboards,
    canEditJson: this.dashboardSettingsService.canEditDashboards,
  };

  private autoApplyFilters: boolean;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly filtersChannelsService: FiltersChannelsService,
    private readonly filtersSidenavService: FiltersSidenavService,
    private readonly filterDemoTourService: FilterDemoTourService,
    private readonly subscriptionService: SubscriptionService,
    private readonly dashboardSettingsService: DashboardSettingsService,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
    private productService: ProductService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.subscriptionService.currentSubscription
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentSubscription) => {
        if (currentSubscription && currentSubscription?.status === 'trialing') {
          this.isTrialing = true;
        }

        if (this.isEditable) {
          this.hasRestrictions = false;
        } else if (this.filtersChannel) {
          //TODO : Replace this Hack, Make this more appropriate for full dashboards, composed of potentially many features?
          this.hasRestrictions = this.productService.hasRestrictions('AGGREGATION', [
            'DATASOURCE:' + this.filtersChannel.channelName,
          ]);
        }
      });
  }

  ngOnChanges() {
    if (!this.dashboard._id) {
      this.updateEditMode(true);
    }

    if (this.settings.filterGroup) {
      this.filtersType = this.settings?.filterLayout?.type || 'sidenav';
      this.autoApplyFilters = this.settings?.filterLayout?.autoApplyFilters ?? false;
      // this.filtersSidenavService.setActive(this.filtersType === 'sidenav');
    }

    this.currentDashboard = this.dashboard;
    // this.onRowCountChanged();
  }

  public ngOnDestroy(): void {
    // if (this.unsavedChanges) {
    //   this.discardChanges();
    // }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onTileChange(tileDefinition, column): void {
    column.tileDefinition = tileDefinition;
  }

  /*  EDIT MODE
   */

  public updateEditMode(enabled?: boolean): void {
    if (enabled != undefined) {
      this.editMode = enabled;
    } else if (this.editMode) {
      this.editMode = false;
    } else {
      this.editMode = true;
    }
  }

  // createJsonDialog(title: string, inputJson: string) {
  //   this.activeDialog?.close();

  //   const jsonDialog = this.dialog.open(TileJsonDialogComponent, {
  //     width: '768px',
  //     height: '768px',
  //     data: { title, json: inputJson },
  //   })

  //   this.activeDialog = jsonDialog;

  //   return jsonDialog;
  // }

  // editColumnJson(rowIndex: number, columnIndex: number) {

  //   const column = this.currentDashboard.rows[rowIndex].columns[columnIndex];
  //   const inputJson = JSON.stringify(column.tileDefinition, null, 4);
  //   const jsonDialog = this.createJsonDialog('Edit Tile Json', inputJson);

  //   jsonDialog.afterClosed().pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(result => {
  //     if (result) {
  //       column.tileDefinition = new TileDefinition(JSON.parse(result));
  //       this.onChanged();
  //     }
  //   })
  // }

  // editDashboardJson() {

  //   var { rows, ...restOfDashboard } = this.currentDashboard;
  //   const inputJson = JSON.stringify(restOfDashboard, null, 4);
  //   const jsonDialog = this.createJsonDialog('Edit Dashboard Properties Json', inputJson);

  //   jsonDialog.afterClosed().pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(result => {
  //     if (result) {
  //       let newDashboard = JSON.parse(result) as any;
  //       newDashboard.rows = rows;
  //       this.currentDashboard = new Dashboard(newDashboard);
  //       this.onChanged();
  //     }
  //   })

  // }

  /*  DRAG AND DROP
   */

  /*  SAVING AND CHANGES
   */

  public saveChangesAsync = async (dashboard: IDashboard, publish: boolean): Promise<any> => {
    if (this.settings.canEdit) {
      await this.dashboardService.saveDashboard(dashboard as Dashboard);

      if (publish) {
        await this.dashboardService.publishDraft(dashboard);
      }
    }
  };

  public createDashboard(dashboard: Dashboard): void {
    this.dashboardService.createDashboard(dashboard).then(() => {
      this.router.navigate(['/', 'dashboards']);
    });
  }

  /*  DEMO TOUR
   */

  public demoTour(): void {
    this.filterDemoTourService.forceTour();
  }

  public onDeleteClick(): void {
    this.matDialog.open(DeleteOptionChoiceDialogComponent, {
      data: {
        dashboard: this.dashboard,
      },
    });
  }

  gridContentChanged(ev) {
    this.dashboardChanged.next(ev);
  }
}
