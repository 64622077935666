<div
  class="padding bottom-padding"
  fxLayout="row"
  fxLayoutAlign="flex-start stretch"
>
  <h4 class="header-top-margin" style="opacity: 0.8">
    {{ option.filterMethodConfig.title }}
  </h4>
  <form [formGroup]="formGroup">
    <mat-checkbox
      color="primary"
      formControlName="checkbox"
      [checked]="checked"
      (change)="onToggle($event)"
      color="primary"
      >{{ option.filterMethodConfig.name }}</mat-checkbox
    >
  </form>
</div>
