<h4 mat-dialog-title>{{ data.title }}</h4>

<ng-container *ngIf="formControl">
  <div mat-dialog-content>
    <p>Choose a name for this set of filters.</p>

    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput type="text" [formControl]="formControl" [value]="data.prefillName" />

      <mat-error *ngIf="formControl.hasError('required')"> * Required. </mat-error>

      <mat-error *ngIf="formControl.hasError('maxlength')"> Too long. </mat-error>
    </mat-form-field>
  </div>
</ng-container>

<div mat-dialog-actions>
  <button color="basic" mat-stroked-button [mat-dialog-close]="">Cancel</button>
  <button
    color="primary"
    mat-flat-button
    [disabled]="formControl.invalid"
    [mat-dialog-close]="formControl.value"
  >
    {{ data.actionText || 'Confirm' }}
  </button>
</div>
