<div class="tile-creator-container">
  <h2>Add new tile</h2>
  <br />

  <div class="inputs" [formGroup]="tileForm">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Tile type</mat-label>
      <mat-select formControlName="tileType">
        <mat-option *ngFor="let tileType of tileTypes" [value]="tileType.key">
          {{ tileType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="buttons">
    <button class="save-btn" mat-raised-button color="primary" (click)="onTileCreate()">
      Add tile
    </button>
  </div>
</div>
