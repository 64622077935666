import { Injectable } from '@angular/core';
import { CommonService, ApiService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationInvitationService extends CommonService<any> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService
  ) {
    super(apiService, settings.usersServiceApi, 'organisations/');
  }
}
