<mat-form-field  appearance="fill">
  <mat-label>{{ title }}</mat-label>
  <button mat-icon-button matPrefix (click)="f_input.click()" [disabled]="isLoading">
      <mat-icon *ngIf="!isLoading">attach_file</mat-icon>
      <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
  </button>
  <input
    type="file"
    (change)="onFileSelected($event)"
    hidden
    #f_input
    >
  <mat-hint>Select a file to upload</mat-hint>
  <input
    type="input"
    matInput
    value="{{ publicUrl }}"
    (change)="handleUrlInput($event)"
    [disabled]="isLoading"
    />
</mat-form-field>
