import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToggleGroup } from '../../../models/utility/toggle-group.model';
import { DataSourceFilters } from '../../../../data-source/data-source-filters/models/data-source-filters.model';
import { FiltersUtilityService } from '../../../services/filters-utility.service';

@Component({
  selector: 'suvo-bi-nested-checkboxes',
  templateUrl: './nested-checkboxes.component.html',
  styleUrls: ['./nested-checkboxes.component.scss'],
})
export class NestedCheckboxesComponent implements OnInit {
  @Input() toggleGroups: ToggleGroup[];

  constructor(
    private filtersService: FiltersUtilityService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  parentCheckedChanged(toggleGroup, ev) {
    toggleGroup.setChecked(ev.checked);

    toggleGroup.indeterminate = false;
  }

  childCheckedChanged() {
    this.filtersService.updateParentState(this.toggleGroups);

    this.changeDetectorRef.markForCheck();
  }
}
