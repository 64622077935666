import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../../../../shared/shared.module';
import { TablesModule } from '../../../tables/tables.module';
import { DataSourceItemsTableComponent } from '../components/data-source-items-table/data-source-items-table.component';
import { DataSourceItemsService } from '../services/data-source-items.service';
import { NewDataSourceItemsService } from '../services/new-data-source-items.service';

@NgModule({
  declarations: [
    DataSourceItemsTableComponent,
  ],
  providers: [
    DataSourceItemsService,
    NewDataSourceItemsService,
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    TablesModule,
  ],
  exports: [
    DataSourceItemsTableComponent,
  ]
})
export class DataSourceItemsModule { }
