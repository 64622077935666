import { IFilterCondition } from "./filter-condition.interface";

export interface IFilterProperty {
  key: string;
  eq?: any;
  neq?: any;
  in?: string;
  con?: IFilterCondition[];
  type?: 'OBJECT_ID' | 'DATE';
  useForSearch?: boolean;
  optionId?: string;
}