import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FilterRule } from '../../../../../shared/interfaces/filters/filter-rule.type';
import { ITendersCheckboxItem } from '../../../interfaces/tenders-info/tenders-checkbox-item.interface';
import { FilterOptionComponent } from '../filter-option/filter-option.component';
import { IFilterProperty } from '../../../../../shared/interfaces/filters/filter-property.interface';

@Component({
  selector: 'suvo-bi-checkboxes-filter-option',
  templateUrl: './checkboxes-filter-option.component.html',
  styleUrls: ['./checkboxes-filter-option.component.scss'],
})
export class CheckboxesFilterOptionComponent extends FilterOptionComponent {
  checkboxItems: ITendersCheckboxItem[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkboxItems = this.option.filterMethodConfig?.data;
  }

  getFilterRules(): FilterRule[] {
    const key = this.option.filterPropertyKeys[0];
    const inValues = this.checkboxItems
      .filter((item) => item.checked)
      .map((item) => item.value);

    if (inValues.length) {
      return [
        {
          key,
          in: inValues.join(','),
        },
      ];
    } else {
      return [];
    }
  }

  async patchRules(rules: IFilterProperty[]): Promise<void> {
    if (!this.checkboxItems) {
      return;
    }

    if (rules?.length) {
      /**
       *    This will be a little bit tricky.
       */

      const property: IFilterProperty = rules[0];
      const inValues = property.in?.split(',') || [];

      this.checkboxItems.forEach((item) => {
        item.checked = inValues.some((value) => item.value === value);
      });
    } else {
      this.checkboxItems.forEach((item) => {
        item.checked = false;
      });
    }
  }

  emitPropertyChanges() {
    this.option.filterRules = this.getFilterRules();
    this.rulesChanged.emit();
  }

  itemChecked(event: MatCheckboxChange, item: ITendersCheckboxItem): void {
    const checked = event.checked;
    item.checked = checked;

    this.emitPropertyChanges();
  }
}
