import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataSourceFavouritesService } from '../../../services/data-source-favourites.service';

@Component({
  selector: 'suvo-bi-favourite-item-star',
  templateUrl: './favourite-item-star.component.html',
  styleUrls: ['./favourite-item-star.component.scss']
})
export class FavouriteItemStarComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();

  debounce: boolean;

  visible: boolean;
  favourited: boolean;

  debugDisplayString?: string;

  constructor(
    private readonly dataSourceFavouritesService: DataSourceFavouritesService
  ) { }

  ngOnInit(): void {

    this.dataSourceFavouritesService.breadcrumbStarSubject.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.debounce = false;
      Object.assign(this, { ...state });
    })

  }

  clicked(event: MouseEvent): void {
    if (this.debounce) {
      return;
    }

    this.debounce = true;
    setTimeout(() => { this.debounce = false }, 2000);

    this.dataSourceFavouritesService.toggleFavouriteStatus();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
