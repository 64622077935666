export enum ITableSortDirection {
  Ascending = 'ASC',
  Descending = 'DSC',
  None = '',
}

export interface ITableSort {
  property?: string;
  direction?: ITableSortDirection;
}
