import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'suvo-bi-skeleton-bars',
  templateUrl: './skeleton-bars.component.html',
  styleUrls: ['./skeleton-bars.component.scss'],
})
export class SkeletonBarsComponent implements OnInit {
  @Input() public direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() public barCount = 8;

  public isVertical: boolean;

  public barHeights: string[];
  public barWidth: string;

  public ngOnInit(): void {
    this.isVertical = this.direction === 'vertical';

    const barHeights = [];

    for (let i = 0; i < this.barCount; i++) {
      barHeights.push(`${this.randomBetween(10, 100)}%`);
    }

    this.barWidth = `${100 / (this.barCount * 1.4)}%`;
    this.barHeights = barHeights;
  }

  private randomBetween(min: number, max: number): number {
    return min + (max - min) * Math.random();
  }
}
